import { ILmChartWidgetTab } from "@app/model/widget";
import { ChartOptions } from "chart.js";

const mainPalette = ['#741b47', '#b45f06', '#274e13', '#351c75', '#0b5393', '#06aeba'];
const mainData = {
    labels: [],
    datasets: [
        {
            backgroundColor: mainPalette,
            data: []
        }
    ]
};
const mainChartOptions: ChartOptions = {
    // ...LM_BAR_CHART_RATING_OPTIONS,
    // plugins:{
    //     annotation:{
    //         annotations: annotations
    //     }
    // }
};
const defaultPageSize = 14;

export const BRANCHES_PRODUCTIVITY: ILmChartWidgetTab = {
    id: 'productivity',
    title: 'Branches',
    subtitle: 'PRODUCTIVITY',
    btn:{id: 'productivity', icon: 'lm-icon icon-star', textOnly: true, color: "#ccc", activeColor: '#ffc107', active: true},
    data: mainData,
    chartType: 'bar',
    tabChartType: 'productivity',
    currentPage: 0,
    pageSize: defaultPageSize,
    chartOptions: {...mainChartOptions, scales:{
        y:{
            grace:'60%',
            beginAtZero: true,
            min: 0,
            max: 5,
            ticks:{
                callback: (tickValue, index, ticks) =>{
                    return index < mainPalette.length ? tickValue + '★' : '';
                },
            }
        }
    }},
};

export const BRANCHES_SHIPMENTS: ILmChartWidgetTab = {
    id: 'total_shipments',
    title: 'Branches',
    subtitle: 'SHIPMENTS',
    btn:{id: 'total_shipments', icon: 'lm-icon icon-pin', textOnly: true, color: "#ccc", activeColor: '#00aeba', active: true},
    data: mainData,
    chartType: 'bar',
    tabChartType: 'total_shipments',
    currentPage: 0,
    pageSize: defaultPageSize,
    chartOptions: mainChartOptions,
};

export const BRANCHES_DELIVERIES_AND_PICKUPS: ILmChartWidgetTab = {
    id: 'total_deliveries_and_pickups',
    title: 'Branches',
    subtitle: 'DELIVERIES_AND_PICKUPS',
    btn: {id: 'total_deliveries_and_pickups', icon: 'lm-icon icon-box-approved', textOnly: true, color: "#ccc", activeColor: '#00AEBA'} ,
    data: mainData,
    chartType: 'bar',
    tabChartType: 'total_deliveries_and_pickups',
    currentPage: 0,
    pageSize: defaultPageSize,
    chartOptions: mainChartOptions,
};

export const BRANCHES_ON_TIME: ILmChartWidgetTab = {
    id: 'on_time_deliveries',
    title: 'Branches',
    subtitle: 'ON_TIME_DELIVERIES',
    btn: {id: 'on_time_deliveries', icon: 'lm-icon icon-timecheck', textOnly: true, color: "#ccc", activeColor: '#ba6ef5'},
    data: mainData,
    chartType: 'bar',
    tabChartType: 'on_time_deliveries',
    currentPage: 0,
    pageSize: defaultPageSize,
    chartOptions: mainChartOptions,
}

export const BRANCHES_VISITED_STOPS_PER_HOUR: ILmChartWidgetTab = {
    id: 'visited_per_hour',
    title: 'Branches',
    subtitle: 'SHIPMENTS_PER_HOUR',
    btn:{id: 'visited_per_hour', icon: 'lm-icon icon-map', textOnly: true, color: "#ccc", activeColor: '#ba6ef5'},
    data: mainData,
    chartType: 'bar',
    tabChartType: 'visited_per_hour',
    currentPage: 0,
    pageSize: defaultPageSize,
    chartOptions: mainChartOptions,
};

export const BRANCHES_PACKAGES: ILmChartWidgetTab = {
    id: 'total_packages',
    title: 'Branches',
    subtitle: 'PACKAGES',
    btn:{id: 'total_packages', icon: 'lm-icon icon-box', textOnly: true, color: "#ccc", activeColor: '#de921f'},
    data: mainData,
    chartType: 'bar',
    tabChartType: 'total_packages',
    currentPage: 0,
    pageSize: defaultPageSize,
    chartOptions: mainChartOptions,
};


export const BRANCHES_COST_PER_SHIPMENT: ILmChartWidgetTab = {
    id: 'cost_per_shipment',
    title: 'Branches',
    subtitle: 'COST_PER_SHIPMENT',
    btn:{id: 'cost_per_shipment', icon: 'lm-icon icon-dollar-coin', textOnly: true, color: "#ccc", activeColor: '#1c90d4'},
    data: mainData,
    chartType: 'bar',
    tabChartType: 'cost_per_shipment',
    currentPage: 0,
    pageSize: defaultPageSize,
    chartOptions: mainChartOptions,
};

export const BRANCHES_RECIPIENT_EXPERIENCE: ILmChartWidgetTab = {
    id: 'rating_count',
    title: 'Branches',
    subtitle: 'CUSTOMER_EXPERIENCE',
    btn:{id: 'rating_count', icon: 'lm-icon icon-heart', textOnly: true, color: "#ccc", activeColor: '#ba6ef5'},
    data: mainData,
    chartType: 'bar',
    tabChartType: 'rating_count',
    currentPage: 0,
    pageSize: defaultPageSize,
    chartOptions: {...mainChartOptions, scales:{
        y:{
            grace:'60%',
            beginAtZero: true,
            min: 0,
            max: 5,
            ticks:{
                callback: (tickValue, index, ticks) =>{
                    return index < mainPalette.length ? tickValue + ' ❤' : '';
                },
            }
        }
    }},
};
