import { CommonModule } from '@angular/common';
import { Directive, HostBinding, ElementRef, Input, NgModule, Renderer2} from '@angular/core';
import { getElement, getStyle } from '../utils';


export type LmResponsifyCssRulle = 'width' | 'height' | 'scaleX' | 'scaleY' | 'scale';

export interface ILmResponsifyConfig {
    resizer?: string;
    responder?: string;
    child?: string;
    rule: LmResponsifyCssRulle;
    axis: string;
  }

  @Directive({
    selector: 'autoScale, [responsify]',
    host: {
        '(window:resize)': 'respond($event)'
      }
  })
  export class LmResponsifyDirective {
    @Input('autoScale') autoScale;
    @Input('responsify') config: ILmResponsifyConfig;
    @HostBinding('style') get _() {
      return this.style;
    }
    responder: Element | HTMLElement;
    child: Element | HTMLElement;
    size: any;
    rule: string;
    style: any;

    constructor(private _el: ElementRef, private _renderer: Renderer2) { }

    respond(){
        if(this.child) return this._renderer.setStyle(this.responder, 'scale', ((innerWidth/this.size)).toFixed(4))
        this.style = {scale: ((innerWidth/this.size)).toFixed(4)};
    }

    ngAfterViewInit(): void {
        const {resizer, responder, child, rule, axis} = this.config ?? {};

        const hook = getElement(responder ?? this._el.nativeElement.localName);
        const sizes = {
            'x': () => (resizer && parseInt((getStyle(resizer, 'width')))) ?? innerWidth,
            'y': () => (resizer && parseInt((getStyle(resizer, 'height')))) ?? innerHeight
        }

        this.child = (child && hook.querySelector(child));
        this.responder = this.child ?? hook;
        this.size = sizes[axis ?? 'x']();
        this.rule = rule ?? 'scale';
    }

    
    ngOnInit() {}

    ngOnDestroy() {}

  }
  @NgModule({
    imports: [CommonModule],
    exports: [LmResponsifyDirective],
    declarations: [LmResponsifyDirective]
  })
  export class LmResponsifyDirectiveModule {}