import { Component, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, take } from 'rxjs/operators';
import { DataService } from '@app/services/data.service';
import { ModalGridService } from '@app/services/modal-grid.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { Globals } from '@app/services/globals';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DepotUtils } from '@app/utils/depot-utils';
import { FormErrorsUtils } from '@app/utils/form-errors-utils';

@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss']
})
export class VehicleFormComponent implements OnInit {
  @ViewChild('makeSelect', { static: false }) makeSelect: NgSelectComponent;
  @ViewChild('modelSelect', { static: false }) modelSelect: NgSelectComponent;
  @ViewChild('yearSelect', { static: false }) yearSelect: NgSelectComponent;
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;

  listen = [];

  vehicleTypes = [];

  bicycleLabel = '';
  scooterLabel = '';
  carLabel = '';
  vanLabel = '';
  largeVanLabel = '';
  truckLabel = '';

  myForm: FormGroup;
  errors;
  data;
  vehicleId = null;
  vehicleType = 0;

  makes: Observable<any>;
  makesLoading = false;
  makeInput = new Subject<string>();
  selectedMake: any = <any>null;
  makeInputValue = '';

  models: Observable<any>;
  modelsLoading = false;
  modelInput = new Subject<string>();
  selectedModel: any = <any>null;
  modelDisabled = true;
  modelInputValue = '';

  years = [];
  yearOptionsObservable: Observable<any[]>;
  yearsLoading = false;
  yearInput = new Subject<string>();
  yearOptions = [];
  yearDisabled = true;
  yearInputValue = '';

  // Make
  makeId = null;
  makeCompanyId = null;
  makeName = '';
  makeCompanyName = '';
  makeCustomName = '';

  // Model
  modelId = null;
  modelCompanyId = null;
  modelName = '';
  modelCompanyName = '';
  modelCustomName = '';

  // Year
  year = null;

  // Modes
  makeMode = 'default';
  modelMode = 'default';

  selectedDepotItem;
  depots = [];
  depotId = null;

  // the plate number can't be string because empty strings can't be saved in the db as it is now
  plateNumber = null;
  // plateNumber: String = '';
  // vehicleIdentificationNumber = null;
  co2Emissions = null;
  dailyCost = null;
  vehicleCargoCapacity = null;
  consumption;
  consumptionTypesOptions = [];
  consumptionUnitOptions = [];
  vehicleConsumptionType;
  consumptionUnit = this.globals.consumptionUnits['JOULE_PER_KILOMETER'];
  driver: String = '';
  servicePartner: String = '';
  nextService: String = '';
  lastService: String = '';
  problem: String = '';
  status = 1;
  isClickedOnce = false;
  demoDisableRequestMsg;

  gasolineLabel;
  dieselLabel;
  CNGLabel;
  LPGLabel;
  electricLabel;

  joulePerKmLabel;
  kcalPerKmLabel;
  ltPer100kmLabel;
  ltPerMileLabel;
  mgJoulePerCmLabel;
  milePerGallonLmpLabel;
  milePerGallonUsLabel;
  wattPerKmLabel;

  constructor(
    private http: HttpClient,
    private router: Router,
    formBuilder: FormBuilder,
    private dataService: DataService,
    private modalGridService: ModalGridService,
    private projectProblemDataService: ProjectProblemDataService,
    public globals: Globals,
    private sanitizer: DomSanitizer,
    public translate: TranslateService,
    public depotUtils: DepotUtils,
    public formErrorsUtils: FormErrorsUtils
  ) {
    this.myForm = formBuilder.group({
      'vehicle': formBuilder.group({
        'id': [this.vehicleId],
        'vehicle_type': [this.vehicleType],
        'make': [this.makeId],
        'selectedMake': [this.selectedMake],
        'model': [this.modelId],
        'selectedModel': [this.selectedModel],
        'makeCompany': [this.makeCompanyId],
        'modelCompany': [this.modelCompanyId],
        'vehicle_info_trim_selected_year': [this.year],
        'plate_number': [this.plateNumber],
        'co2_emissions': [this.co2Emissions],
        'cost_per_day': [this.dailyCost],
        // 'vehicle_identification_number': [this.vehicleIdentificationNumber],
        'maximum_cargo_capacity': [this.vehicleCargoCapacity],
        'consumption': [this.consumption],
        'vehicle_consumption_type': [this.vehicleConsumptionType],
        'consumption_unit': [this.consumptionUnit],
        'default_company_depot_id': [this.depotId],
        'custom': formBuilder.group({
          'makeName': [this.makeCustomName],
          'modelName': [this.modelCustomName],
        }),
      }),
      'depot': [this.selectedDepotItem],
    });
  }

  public getFormData(id) {
    this.http.get('api/v1/vehicles/' + id).pipe(take(1)).subscribe(response => {
      this.data = response['item'];
      const vehicle = this.data.vehicle;
      this.vehicleId = vehicle.id;
      this.vehicleType = vehicle.vehicle_type;
      if (this.data.vehicleInfoMake.id) {
        this.makeId = this.data.vehicleInfoMake.id;
        this.makeName = this.data.vehicleInfoMake.name;
        this.makeMode = 'default';
      } else if (this.data.vehicleInfoMakeCompany.id) {
        this.makeCompanyId = this.data.vehicleInfoMakeCompany.id;
        this.makeCompanyName = this.data.vehicleInfoMakeCompany.name;
        this.makeMode = 'company';
      }
      if (this.data.vehicleInfoModel.id) {
        this.modelId = this.data.vehicleInfoModel.id;
        this.modelName = this.data.vehicleInfoModel.name;
        this.modelMode = 'default';
      } else if (this.data.vehicleInfoModelCompany.id) {
        this.modelCompanyId = this.data.vehicleInfoModelCompany.id;
        this.modelCompanyName = this.data.vehicleInfoModelCompany.name;
        this.modelMode = 'company';
      }
      this.year = vehicle.vehicle_info_trim_selected_year;
      this.plateNumber = vehicle.plate_number;
      this.co2Emissions = vehicle.co2_emissions;
      this.dailyCost = vehicle.cost_per_day ?? null;
      this.vehicleCargoCapacity = vehicle.maximum_cargo_capacity;
      this.consumption = vehicle.consumption ?? null;
      this.modelDisabled = false;
      this.yearDisabled = false;
      this.vehicleConsumptionType = vehicle.vehicle_consumption_type ?? null;
      this.consumptionUnit = vehicle.consumption_unit ?? null;

      // load depot
      const depot = this.depotUtils.getFirstDepotWithDepotId(vehicle.default_company_depot_id);
      this.selectedDepotItem = {
        companyDepot: {
          id: depot.companyDepot.id,
          address: {
            value: depot.companyDepot.address.value,
          },
        },
        name: this.depotUtils.getDepotName(depot.companyDepot)
      };

      this.setForm();
    });
  }

  patchForm() {
    this.myForm.patchValue({
      'vehicle': {
        'make': this.makeId,
        'model': this.modelId,
        'makeCompany': this.makeCompanyId,
        'modelCompany': this.modelCompanyId,
        'vehicle_info_trim_selected_year': this.year,
        'default_company_depot_id': this.depotId,
        'plate_number': this.plateNumber,
        'co2_emissions': this.co2Emissions,
        'cost_per_day': this.dailyCost,
        'maximum_cargo_capacity': this.vehicleCargoCapacity,
        'consumption': this.consumption,
        'vehicle_consumption_type': this.vehicleConsumptionType,
        'consumption_unit': this.consumptionUnit,
        'custom': {
          'makeName': this.makeCustomName,
          'modelName': this.modelCustomName,
        },
      },
      'depot': this.selectedDepotItem
    });
    this.updateFields();
  }

  setForm() {
    this.myForm.setValue({
      'vehicle': {
        'id': this.vehicleId,
        'vehicle_type': this.vehicleType,
        'make': this.makeId,
        'model': this.modelId,
        'selectedMake': this.selectedMake,
        'selectedModel': this.selectedModel,
        'makeCompany': this.makeCompanyId,
        'modelCompany': this.modelCompanyId,
        'vehicle_info_trim_selected_year': this.year,
        'plate_number': this.plateNumber,
        'co2_emissions': this.co2Emissions,
        'cost_per_day': this.dailyCost,
        // 'vehicle_identification_number': this.vehicleIdentificationNumber,
        'maximum_cargo_capacity': this.vehicleCargoCapacity,
        'consumption': this.consumption,
        'vehicle_consumption_type': this.vehicleConsumptionType,
        'consumption_unit': this.consumptionUnit,
        'default_company_depot_id': this.depotId,
        'custom': {
          'makeName': this.makeCustomName,
          'modelName': this.modelCustomName,
        },
      },
      'depot': this.selectedDepotItem,
    });
    this.updateFields();
  }

  updateFields() {
    if (this.makeId) {
      this.selectedMake = {
        id: this.makeId,
        name: this.makeName,
        mode: this.makeMode
      };
    } else if (this.makeCompanyId) {
      this.selectedMake = {
        id: this.makeCompanyId,
        name: this.makeCompanyName,
        mode: this.makeMode
      };
    }
    if (this.modelId) {
      this.selectedModel = {
        id: this.modelId,
        name: this.modelName,
        mode: this.modelMode
      };
    } else if (this.modelCompanyId) {
      this.selectedModel = {
        id: this.modelCompanyId,
        name: this.modelCompanyName,
        mode: this.modelMode
      };
    }
    if (this.selectedMake) {
      (<HTMLInputElement>document.getElementById('custom-input-make')).value = this.selectedMake.name;
    }
    if (this.selectedModel) {
      (<HTMLInputElement>document.getElementById('custom-input-model')).value = this.selectedModel.name;
    }
    if (this.year) {
      (<HTMLInputElement>document.getElementById('custom-input-year')).value = this.year;
    }
    M.updateTextFields();
  }

  resetForm() {
    this.errors = [];
    this.vehicleId = null;
    this.vehicleType = 0;
    this.makeId = null;
    this.makeCompanyId = null;
    this.modelId = null;
    this.modelCompanyId = null;
    this.makeName = '';
    this.makeCompanyName = '';
    this.makeCustomName = '';
    this.modelName = '';
    this.modelCompanyName = '';
    this.modelCustomName = '';
    this.year = null;
    this.selectedMake = null;
    this.selectedModel = null;
    this.plateNumber = null;
    this.co2Emissions = null;
    this.dailyCost = null;
    this.vehicleCargoCapacity = null;
    this.consumption = null;
    this.vehicleConsumptionType = null;
    this.driver = '';
    this.servicePartner = '';
    this.nextService = '';
    this.lastService = '';
    this.problem = '';
    this.status = 1;
    this.selectedDepotItem = {
      companyDepot: {
        id: this.globals.depotId,
        address: {
          value: this.globals.depotAddress,
        },
      },
      name: this.depotUtils.getDepotName(this.globals.depots[this.globals.depotId].companyDepot)
    };
    this.depotId = null;
    this.makeMode = 'default';
    this.modelMode = 'default';
    (<HTMLInputElement>document.getElementById('custom-input-make')).value = '';
    (<HTMLInputElement>document.getElementById('custom-input-model')).value = '';
    (<HTMLInputElement>document.getElementById('custom-input-year')).value = '';
    this.setForm();
    this.myForm.markAsUntouched();
    this.myForm.markAsPristine();
  }

  initializeSelect() {
    const elems = document.querySelectorAll('select');
    const instances = M.FormSelect.init(elems);
  }

  public submitVehicleForm(createNew = false, clone = false) {
    this.isClickedOnce = true;
    const formValues = this.myForm.value;
    const formVehicleValues = this.myForm.value.vehicle;

    // Update 'SelectedMake'
    if (formVehicleValues.selectedMake) {
      if (formVehicleValues.selectedMake.mode === 'default') {
        this.makeId = formVehicleValues.selectedMake.id;
        this.makeName = formVehicleValues.selectedMake.name;
      } else if (formVehicleValues.selectedMake.mode === 'company') {
        this.makeCompanyId = formVehicleValues.selectedMake.id;
        this.makeCompanyName = formVehicleValues.selectedMake.name;
      } else if (formVehicleValues.selectedMake.mode === 'custom') {
        this.makeCustomName = formVehicleValues.selectedMake.name;
      } else {
        this.makeCustomName = '';
      }
    }

    // Update 'SelectedModel'
    if (formVehicleValues.selectedModel) {
      if (formVehicleValues.selectedModel.mode === 'default') {
        this.modelId = formVehicleValues.selectedModel.id;
        this.modelName = formVehicleValues.selectedModel.name;
      } else if (formVehicleValues.selectedModel.mode === 'company') {
        this.modelCompanyId = formVehicleValues.selectedModel.id;
        this.modelCompanyName = formVehicleValues.selectedModel.name;
      } else if (formVehicleValues.selectedModel.mode === 'custom') {
        this.modelCustomName = formVehicleValues.selectedModel.name;
      } else {
        this.modelCustomName = '';
      }
    }

    // Update plateNumber
    if (formVehicleValues.plate_number) {
      this.plateNumber = formVehicleValues.plate_number;
    }

    if (formVehicleValues.co2_emissions) {
      this.co2Emissions = formVehicleValues.co2_emissions;
    }
    if (formVehicleValues.cost_per_day) {
      this.dailyCost = formVehicleValues.cost_per_day;
    }

    this.consumption = formVehicleValues.consumption;
    this.vehicleConsumptionType = formVehicleValues.vehicle_consumption_type;
    this.consumptionUnit = formVehicleValues.consumption_unit;
    this.vehicleCargoCapacity = formVehicleValues.maximum_cargo_capacity;


    if (!formVehicleValues.year && this.yearInputValue.length === 4) {
      this.year = this.yearInputValue;
    } else {
      this.year = null;
    }

    if (formValues.depot) {
      this.depotId = formValues.depot.companyDepot.id;
    } else {
      this.depotId = this.globals.depotId;
    }

    this.patchForm();

    const myObserver = {
      next: (response) => {
      },
      error: (error) => {
        this.formErrorsUtils.checkResponseForErrorCodes(error);
        const errorsObject = error.error.errors;
        const errors = [];
        const keyify = (obj, prefix = '') =>
          Object.keys(obj).reduce((res, el) => {
            if (obj[el]['0'] && typeof obj[el]['0'] === 'string') {
              errors.push(obj[el]['0']);
            }
            if (obj[el]['0'] && typeof obj[el]['0'] === 'object') {
              if (obj[el]['0']['load']) {
                errors.push(obj[el]['0']['load']['0']);
              }
            }
            if (Array.isArray(obj[el])) {
              return res;
            } else if (typeof obj[el] === 'object' && obj[el] !== null) {
              return [...res, ...keyify(obj[el], prefix + el + '.')];
            } else {
              return [...res, prefix + el];
            }
          }, []);
        const output = keyify(errorsObject);
        this.errors = errors;
        this.isClickedOnce = false;
      },
      complete: () => {
        this.isClickedOnce = false;
        if (!createNew) {
          this.modalGridService.closeRightModal();
        }
        if (clone) {
          this.vehicleId = null;
          this.plateNumber = null;
          this.myForm.patchValue({
            'vehicle': {
              'plate_number': this.plateNumber,
            },
          });
        } else {
          this.resetForm();
        }
        this.globals.getVehicles();
        this.modalGridService.updateVehicleGrid();
      },
    };

    if (this.globals.demoState === this.globals.companyDemoStateConstants['IN_PROGRESS']) {
      alert(this.demoDisableRequestMsg);
      this.isClickedOnce = false;
    } else {
      if (this.vehicleId) {
        this.http.put('api/v1/vehicles/' + this.vehicleId, this.myForm.value).pipe(take(1)).subscribe(myObserver);
      } else {
        this.http.post('api/v1/vehicles', this.myForm.value).pipe(take(1)).subscribe(myObserver);
      }
    }
  }

  makeSelectFocusOut() {
    if (!this.myForm.value.vehicle.make) {
      if (this.makeSelect.itemsList['_filteredItems']) {
        const firstItem = this.makeSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.makeSelect.select(firstItem);
        }
      }
    }
  }

  modelSelectFocusOut() {
    if (!this.myForm.value.vehicle.model) {
      if (this.modelSelect.itemsList['_filteredItems']) {
        const firstItem = this.modelSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.modelSelect.select(firstItem);
        }
      }
    }
  }

  // sets default values per vehicle selection
  onVehicleChange(event) {
    switch (event) {
      // bicycle
      case 0:
        this.consumption = null;
        this.vehicleConsumptionType = null;
        break;
      // motorcycle
      case 1:
        this.consumption = 2;
        this.vehicleConsumptionType = this.globals.consumptionTypes['GASOLINE'];
        break;
      // car
      case 2:
        this.consumption = 7;
        this.vehicleConsumptionType = this.globals.consumptionTypes['GASOLINE'];
        break;
      // van
      case 3:
        this.consumption = 10;
        this.vehicleConsumptionType = this.globals.consumptionTypes['DIESEL'];
        break;
      // long van
      case 4:
        this.consumption = 15;
        this.vehicleConsumptionType = this.globals.consumptionTypes['DIESEL'];
        break;
      // truck
      case 5:
        this.consumption = 20;
        this.vehicleConsumptionType = this.globals.consumptionTypes['DIESEL'];
        break;
    }

    this.myForm.patchValue({
      'vehicle': {
        'consumption': this.consumption,
        'vehicle_consumption_type': this.vehicleConsumptionType
      }
    });

    M.updateTextFields();
  }

  inputMake() {
    this.makeId = '';
    this.myForm.patchValue({
      'vehicle': {
        'make': this.makeId,
      },
    });
    this.makeInputValue = (<HTMLInputElement>document.getElementById('custom-input-make')).value;
    this.makeSelect.filter(this.makeInputValue);
  }

  inputModel() {
    this.modelId = '';
    this.myForm.patchValue({
      'vehicle': {
        'model': this.modelId,
      },
    });
    this.modelInputValue = (<HTMLInputElement>document.getElementById('custom-input-model')).value;
    this.modelSelect.filter(this.modelInputValue);
  }

  inputYear() {
    this.yearInputValue = (<HTMLInputElement>document.getElementById('custom-input-year')).value;
    this.yearSelect.filter(this.yearInputValue);
  }

  public onMakeChange(event) {
    if (event) {
      (<HTMLInputElement>document.getElementById('custom-input-make')).value = event.name;
      this.selectedMake = event;
      this.myForm.patchValue({
        'vehicle': {
          'selectedMake': this.selectedMake,
        },
      });
      this.makeMode = event.mode;
      if (this.makeMode === 'default') {
        this.makeId = event.id;
        this.makeName = event.name;
        this.makeCompanyId = null;
        this.makeCompanyName = '';
        this.makeCustomName = '';
      } else if (this.makeMode === 'company') {
        this.makeId = null;
        this.makeName = '';
        this.makeCompanyId = event.id;
        this.makeCompanyName = event.name;
        this.makeCustomName = '';
      } else if (this.makeMode === 'custom') {
        this.makeId = null;
        this.makeName = '';
        this.makeCompanyId = null;
        this.makeCompanyName = '';
        this.makeCustomName = event.name;
      }
      this.modelDisabled = false;
    } else {
      this.modelDisabled = true;
    }
    if (this.year) {
      (<HTMLInputElement>document.getElementById('custom-input-year')).value = '';
      this.year = null;
    }
    if (this.selectedModel) {
      (<HTMLInputElement>document.getElementById('custom-input-model')).value = '';
      this.selectedModel = {};
    }

    // Clean up rest-connected fields
    this.modelId = null;
    this.modelName = '';
    this.modelCompanyId = null;
    this.modelCompanyName = '';
    this.modelCustomName = '';
  }

  public onModelChange(event) {
    if (event) {
      (<HTMLInputElement>document.getElementById('custom-input-model')).value = event.name;
      this.selectedModel = event;
      this.myForm.patchValue({
        'vehicle': {
          'selectedModel': this.selectedModel,
        },
      });
      this.modelMode = event.mode;
      if (this.modelMode === 'default') {
        this.modelId = event.id;
        this.modelName = event.name;
        this.modelCompanyId = null;
        this.modelCompanyName = '';
        this.modelCustomName = '';
      } else if (this.modelMode === 'company') {
        this.modelId = null;
        this.modelName = '';
        this.modelCompanyId = event.id;
        this.modelCompanyName = event.name;
        this.modelCustomName = '';
      } else if (this.modelMode === 'custom') {
        this.modelId = null;
        this.modelName = '';
        this.modelCompanyId = null;
        this.modelCompanyName = '';
        this.modelCustomName = event.name;
      }
      this.yearDisabled = false;
      this.years = event.years ? event.years : [];
    } else {
      this.yearDisabled = true;
    }
    if (this.year) {
      (<HTMLInputElement>document.getElementById('custom-input-year')).value = '';
      this.year = null;
    }
  }

  public onYearChange(event) {
    if (event) {
      this.year = event;
      this.myForm.patchValue({
        'vehicle': {
          'vehicle_info_trim_selected_year': this.year,
        },
      });
      (<HTMLInputElement>document.getElementById('custom-input-year')).value = event;
    }
  }

  public onYearSearch(event) {
    if (event.term.length === 4 && !this.years.includes(Number(event.term))) {
      this.years.push(Number(event.term));
      this.years = this.years.slice(0);
    }
  }

  public focusYear() {
    this.yearSelect.open();
  }

  setVehicleTypes() {
    const bicycle = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle0);
    const scooter = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle1);
    const car = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle2);
    const van = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle3);
    const largeVan = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle4);
    const truck = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle5);

    this.vehicleTypes = [
      {
        index: 0,
        image: bicycle,
        typeName: this.bicycleLabel
      },
      {
        index: 1,
        image: scooter,
        typeName: this.scooterLabel
      },
      {
        index: 2,
        image: car,
        typeName: this.carLabel
      },
      {
        index: 3,
        image: van,
        typeName: this.vanLabel
      },
      {
        index: 4,
        image: largeVan,
        typeName: this.largeVanLabel
      },
      {
        index: 5,
        image: truck,
        typeName: this.truckLabel
      }
    ];
  }

  getTranslations() {
    // vehicles
    this.listen.push(this.translate.get('VEHICLE_TYPES.BICYCLE').subscribe((res: string) => { this.bicycleLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.SCOOTER').subscribe((res: string) => { this.scooterLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.CAR').subscribe((res: string) => { this.carLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.VAN').subscribe((res: string) => { this.vanLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.LARGE_VAN').subscribe((res: string) => { this.largeVanLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.TRUCK').subscribe((res: string) => { this.truckLabel = res; }));
    this.listen.push(this.translate.get('ALERTS.DEMO_REQUEST_NOT_POSSIBLE').subscribe((res: string) => { this.demoDisableRequestMsg = res; }));

    // fuel types
    this.listen.push(this.translate.get('VEHICLE.FUEL_GASOLINE').subscribe((res: string) => { this.gasolineLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE.FUEL_DIESEL').subscribe((res: string) => { this.dieselLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE.FUEL_CNG').subscribe((res: string) => { this.CNGLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE.FUEL_LPG').subscribe((res: string) => { this.LPGLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE.FUEL_ELECTRIC').subscribe((res: string) => { this.electricLabel = res; }));

    // consumption units
    this.listen.push(this.translate.get('VEHICLE.JOULE_PER_KILOMETER').subscribe((res: string) => { this.joulePerKmLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE.KCAL_PER_KILOMETER').subscribe((res: string) => { this.kcalPerKmLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE.LITTERS_PER_100KM').subscribe((res: string) => { this.ltPer100kmLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE.LITTER_PER_MILE').subscribe((res: string) => { this.ltPerMileLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE.MEGA_JOULE_PER_CM').subscribe((res: string) => { this.mgJoulePerCmLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE.MILE_PER_GALLON_LMP').subscribe((res: string) => { this.milePerGallonLmpLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE.MILE_PER_GALLON_US').subscribe((res: string) => { this.milePerGallonUsLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE.WATT_PER_KILOMETER').subscribe((res: string) => { this.wattPerKmLabel = res; }));

    // populate fuel type options
    this.consumptionTypesOptions = [
      {
        value: this.globals.consumptionTypes['GASOLINE'],
        label: this.gasolineLabel
      },
      {
        value: this.globals.consumptionTypes['DIESEL'],
        label: this.dieselLabel
      },
      {
        value: this.globals.consumptionTypes['CNG'],
        label: this.CNGLabel
      },
      {
        value: this.globals.consumptionTypes['LPG'],
        label: this.LPGLabel
      },
      {
        value: this.globals.consumptionTypes['ELECTRIC'],
        label: this.electricLabel
      }
    ];

    // populate consumption unit options
    this.consumptionUnitOptions = [
      {
        value: this.globals.consumptionUnits['JOULE_PER_KILOMETER'],
        label: this.joulePerKmLabel
      },
      {
        value: this.globals.consumptionUnits['KCAL_PER_KILOMETER'],
        label: this.kcalPerKmLabel
      },
      {
        value: this.globals.consumptionUnits['LITTERS_PER_100KM'],
        label: this.ltPer100kmLabel
      },
      {
        value: this.globals.consumptionUnits['LITTER_PER_MILE'],
        label: this.ltPerMileLabel
      },
      {
        value: this.globals.consumptionUnits['MEGA_JOULE_PER_CM'],
        label: this.mgJoulePerCmLabel
      },
      {
        value: this.globals.consumptionUnits['MILE_PER_GALLON_LMP'],
        label: this.milePerGallonLmpLabel
      },
      {
        value: this.globals.consumptionUnits['MILE_PER_GALLON_US'],
        label: this.milePerGallonUsLabel
      },
      {
        value: this.globals.consumptionUnits['WATT_PER_KILOMETER'],
        label: this.wattPerKmLabel
      }
    ];

    setTimeout(() => {
      this.setVehicleTypes();
    }, 300);
  }

  ngOnInit() {
    const self = this;

    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.makes = concat(
      of([]), // default items
      this.makeInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.makesLoading = true),
        switchMap(term => this.dataService.getMakes(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.makesLoading = false)
        ))
      )
    );
    this.models = concat(
      of([]), // default items
      this.modelInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.modelsLoading = true),
        switchMap(term => this.dataService.getModels(term, this.selectedMake?.id, this.selectedMake?.mode).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.modelsLoading = false)
        ))
      )
    );
    this.selectedDepotItem = {};
    const depotsDataRefreshIntervalId = setInterval(depotsDataChecker, 200);
    function depotsDataChecker() {
      if (self.globals.depotsDataDone) {
        clearInterval(depotsDataRefreshIntervalId);

        self.selectedDepotItem = {
          companyDepot: {
            id: self.globals.depotId,
            address: {
              value: self.globals.depotAddress,
            },
          },
          name: self.depotUtils.getDepotName(self.globals.depots[self.globals.depotId].companyDepot)
        };
      }
    }
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
