import { Component, OnInit, OnDestroy, Input, Injector, AfterViewInit, HostBinding, NgModule, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LmBaseWidgetComponent } from './widget-base';
import { CommonModule } from '@angular/common';
import { LmButtonConfig, LmButtonModule } from '../button/button.component';
import { LmSemiCircleChartComponent, LmSemiCircleChartModule } from '@app/shared/charts/semicircle-chart.component';
import { ILmCSemiCircleChartWidgetTab } from '@app/model/widget';
import { LmResponsifyDirective, LmResponsifyDirectiveModule } from '@app/shared/directives/responsify.directive';

@UntilDestroy()
@Component({
    selector: 'lm-semicirclechart-widget',
    styles:[`
        lm-semicircle-chart{width: 100%; max-width:300px}
    `],
    template: `
        <div class="lm-widget --chart">
            <div class="lm-widget-header">
                <div class="lm-widget-title">{{title}}</div>
                <div class="lm-widget-tabs" *ngIf="tabs && !!tabs.length && !hideTabs">
                    <span *ngFor="let tab of tabs">
                        <lm-button class="lm-widget-tab" [ngStyle]="{'color': tab.active ? tab.activeColor : tab.color}" [config]="tab" (click)="tab.command(tab)"></lm-button>
                    </span>
                </div>
            </div>
            <div class="font-small lm-txt-color-grey lm-trim">{{subtitle}}</div>
            <div class="lm-widget-content lm-jc-center-flex">
                <lm-semicircle-chart 
                    [responsify]="{child:'.lm-semicircle .-content'}"
                    #chart
                    [value]="value"     
                    [options]="chartOptions"
                >
                </lm-semicircle-chart>
            </div>
        </div>
    `
})

export class LmSemiCircleChartWidgetComponent extends LmBaseWidgetComponent implements OnInit, OnDestroy, AfterViewInit {
    static nextId = 0;
    @HostBinding() id = `lm-semicirclechart-widget-${LmSemiCircleChartWidgetComponent.nextId++}`;
    @ViewChild('chart')  chart: LmSemiCircleChartComponent;
    
    @Input() value;
    @Input() chartOptions;
    @Input() tabconfig?: ILmCSemiCircleChartWidgetTab[];
    @Input() tabs?: LmButtonConfig[];
    @Input() activeTab?: ILmCSemiCircleChartWidgetTab;

    initCb = this.init.bind(this);
    switchTabCb = this.switchTab.bind(this);
    pageChangeCb = this.pageChange.bind(this);
    getTabCb = this.getTab.bind(this);

    constructor(injector: Injector) {
        super(injector);
    }

    setChart(tab){
        this.value = tab.value;
        this.chartOptions = tab.chartOptions;
    }

    switchTab(tab) {
        this.setChart(tab);
        if(this.chart) this.chart.refresh(tab);
    }

    getTab(tab?){}

    pageChange(e?){}
    init(){}

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
    ngOnInit() {
        super.ngOnInit();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
}


@NgModule({
    imports: [CommonModule, LmButtonModule, LmSemiCircleChartModule, LmResponsifyDirectiveModule],
    exports: [LmSemiCircleChartWidgetComponent],
    declarations: [LmSemiCircleChartWidgetComponent]
  })
  export class LmSemiCircleChartWidgetModule {}