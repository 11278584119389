import { Component, OnInit, ViewChild, TemplateRef, Injector, ViewContainerRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { Router } from '@angular/router';
import { StepsService } from '@app/services/steps.service';
import * as moment from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Module } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
// import { Chart } from 'chart.js';
import { ChartService } from '@app/services/chart.service';
import { GridsService } from '@app/services/grids.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectModalComponent } from '@app/modals/project-modal/project-modal.component';
import { ProjectProblemsGridGuidedTourComponent } from './project-problems-grid-guided-tour/project-problems-grid-guided-tour.component';
import { LmNotificationService } from '@app/core/services/notification.service';
import { ProjectProblemModalFormComponent } from '@app/project/project-problem-modal/project-problem-modal.component';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  ChartOptions
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

@Component({
  selector: 'app-project-problems-grid',
  templateUrl: './project-problems-grid.component.html',
  styleUrls: ['./../grids.scss']
})
export class ProjectProblemsGridComponent implements OnInit {
  @ViewChild(ProjectProblemsGridGuidedTourComponent, { static: false }) projectProblemsGridGuidedTourComponent: ProjectProblemsGridGuidedTourComponent;
  @ViewChild(ProjectModalComponent, { static: false }) projectModalComponent: ProjectModalComponent;
  @ViewChild('title', { static: false }) title: TemplateRef<any>;
  @ViewChild('departure', { static: false }) departure: TemplateRef<any>;
  @ViewChild('statsContainer', { static: false }) statsContainer;
  @ViewChild('gridContainer', { static: false }) gridContainer;
  @ViewChild('openChartButton', { static: false }) openChartButton;
  @ViewChild('closeChartButton', { static: false }) closeChartButton;
  projectProblemModal = ProjectProblemModalFormComponent;

  public modules: Module[] = [
    ServerSideRowModelModule
  ];

  listen = [];

  projectId;
  defaultDepartureTime = '';

  rowData = [];
  gridApi;
  gridColumnApi;
  rowModelType = 'serverSide';
  rowBuffer = 0;
  stopPointsDataArray = [];
  columnDefs;
  cacheBlockSize = 50;

  selectedProjectProblem = null;

  pagesCount = 0;
  searchTextChanged = new Subject<string>();
  searchString: String = '';

  clicked;
  showSortDropdown = false;
  selected = new Set();
  selectedOrder = '';
  pagination = {
    limit: 10,
    offset: 0,
    count: -1,
  };

  departureTimeTitle;
  nameTitle;
  driversTitle;
  stopsTitle;
  statusTitle;
  deleteProjectWarningLabel;
  distanceTitle;
  stopPointsLabel;
  canceledLabel = '';
  completedLabel = '';

  chart;
  chartData = {};
  projectsNames = [];
  totalPoints = [];
  completedPoints = [];
  cancelledPoints = [];

  charts = [];
  selectedChart;
  periods = [];
  selectedPeriod;
  currentChartPage = 0;
  firstPage = true;
  lastPage = false;
  pageSize = 10;
  dataLoading = false;

  chartExpanded = true;
  chartDisabled = false;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private router: Router,
    private stepsService: StepsService,
    private chartService: ChartService,
    private activatedRoute: ActivatedRoute,
    public gridsService: GridsService,
    private notificationSvc: LmNotificationService,
    private _injector: Injector,
    private _vcr: ViewContainerRef,
  ) {
    this.listen.push(this.stepsService.updateProjectsGridListen().subscribe(defaultDepartureTime => {
      this.defaultDepartureTime = defaultDepartureTime;
    }));
  }

  nameRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().date) {
        columnObject += '<div class="double-cell bold-letters standard-width">' + params.getValue().date + '</div>';
        columnObject += '<div class="double-cell" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
      }
    }
    return columnObject;
  }

  statusRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      columnObject += '<div class="double-cell">' + params.getValue().entityStatus + '</div>';
      columnObject += '<div class="double-cell">' + params.getValue().optimizationState + '</div>';
    }
    return columnObject;
  }

  dropdownRenderer(params) {
    const self = this;
    const dots = document.createElement('div');
    dots.classList.add('dropdown-dots');
    dots.innerHTML = '<i class="fas fa-ellipsis-v"></i>';
    const dropdown = document.getElementById('dropdown-container');
    dots.addEventListener('click', function () {
      setTimeout(() => {
        if (!self.selectedProjectProblem) {
          dropdown.style.top = 'calc(' + dots.getBoundingClientRect().bottom + 'px - 10px)';
          dropdown.style.left = 'calc(' + dots.getBoundingClientRect().left + 'px - 9vw)';
          dropdown.style.display = 'block';
          self.selectedProjectProblem = params.data;
        } else {
          self.selectedProjectProblem = null;
          dropdown.style.display = 'none';
        }
      }, 20);
    });
    return dots;
  }

  rowClicked(event) {
    const target = event.event.target.closest('div');
    if (!event.data.noDataText && !target.classList.contains('dropdown-dots')) {
      this.router.navigateByUrl('/projects/projectView/' + event.data.id);
    }
  }

  deleteProjectProblem() {
    if (this.selectedProjectProblem) {
      if (this.selectedProjectProblem.id) {
        const dropdown = document.getElementById('dropdown-container');
        dropdown.style.display = 'none';
        let url = 'api/v1/project/problems/' + this.selectedProjectProblem.id;
        this.http.delete(url).pipe(take(1)).subscribe(response => {
          this.updateProjectProblemsGrid();
        });
      }
    }
  }

  startTour() {
    this.projectProblemsGridGuidedTourComponent.startGuide();
  }

  newButtonClicked(): void {
    const data = {
      globals: this.globals,
      id: null,
      values: {
        projectId: this.projectId,
        departure_time: this.defaultDepartureTime,
      }
    }
    this.notificationSvc
      .showDialog<boolean>({ componentType: this.projectProblemModal, injector: this._injector, vcr: this._vcr, data: data})
      .subscribe();
  }

  editProject() {
    this.projectModalComponent.loadProject(this.projectId);
  }

  deleteProject() {
    if (confirm(this.deleteProjectWarningLabel)) {
      this.http.delete('api/v1/projects/' + this.projectId).pipe(take(1)).subscribe(response => {
        this.router.navigate(['projects']);
      });
    }
  }

  getDataForChart() {
    let data = [];
    switch (this.selectedChart.value) {
      case 'points':
        // data = this.totalPoints;
        data = [this.totalPoints, this.completedPoints, this.cancelledPoints];
        break;
    }
    return data;
  }

  openChart() {
    this.chartExpanded = true;
    this.statsContainer.nativeElement.classList.add('expanded');
    this.gridContainer.nativeElement.classList.add('reduced-chart');
    this.openChartButton.nativeElement.classList.add('active');
    this.closeChartButton.nativeElement.classList.remove('active');
  }

  closeChart() {
    this.chartExpanded = false;
    this.statsContainer.nativeElement.classList.remove('expanded');
    this.gridContainer.nativeElement.classList.remove('reduced-chart');
    this.openChartButton.nativeElement.classList.remove('active');
    this.closeChartButton.nativeElement.classList.add('active');
  }

  emptyChartArrays() {
    this.projectsNames = [];
    this.totalPoints = [];
    this.completedPoints = [];
    this.cancelledPoints = [];
  }

  displayDummyChartValues() {
    this.projectsNames = [
      moment.utc().subtract(7, 'day').format('DD-MM'),
      moment.utc().subtract(6, 'day').format('DD-MM'),
      moment.utc().subtract(5, 'day').format('DD-MM'),
      moment.utc().subtract(4, 'day').format('DD-MM'),
      moment.utc().subtract(3, 'day').format('DD-MM'),
      moment.utc().subtract(2, 'day').format('DD-MM'),
      moment.utc().subtract(1, 'day').format('DD-MM'),
    ];
    this.totalPoints = [340, 330, 445, 605, 641, 681, 746];
    this.completedPoints = [310, 290, 400, 555, 601, 621, 712];
    this.cancelledPoints = [];
    this.totalPoints.forEach((element, index) => {
      this.cancelledPoints.push(element - this.completedPoints[index]);
    });
    this.chartDisabled = true;
  }

  getChartData() {
    this.dataLoading = true;
    let pageSize = 0, monthlyGroup = false;
    if (this.selectedPeriod.value === 180) {
      monthlyGroup = true;
      pageSize = 6;
    } else if (this.selectedPeriod.value === 365) {
      monthlyGroup = true;
      pageSize = 12;
    } else {
      pageSize = this.selectedPeriod.value;
    }
    // let params = '?pageSize=' + pageSize;
    // params += '&page=' + this.currentChartPage;
    // params += '&ids=';
    // params += '&date=' + moment().utc().format();
    // // params += '&date=2020-10-08T14:45:00Z';
    // params += '&days=' + this.selectedPeriod.value;
    // params += '&projectId=' + this.projectId;
    // if (monthlyGroup) {
    //   params += '&monthlyGroup=true';
    // }
    // if (this.currentChartPage === 0) {
    //   this.firstPage = true;
    // } else {
    //   this.firstPage = false;
    // }
    // this.http.get('api/v1/stats/project-problem-stats' + params).pipe(take(1)).subscribe(response => {

    let params = '?days=' + this.selectedPeriod['value'];
    params += '&date=' + moment().utc().format();
    params += '&projectIds=' + this.projectId;

    if (this.selectedPeriod['value'] <= 7) {
      params += '&groupBy=weekly';
    }
    else if (this.selectedPeriod['value'] <= 30) {
      params += '&groupBy=monthly';
    }
    else {
      params += '&groupBy=yearly';
    }

    const responseItems = {
      "2021-12-19": {
        "completedShipments": 1,
        "canceledShipments": 1,
        "totalShipments": 2
      },
      "2022-01-12": {
        "completedShipments": 1,
        "canceledShipments": 0,
        "totalShipments": 1
      }
    }

    // this.http.get('api/internal/v1/statistics/shipment-statistics' + params).pipe(take(1)).subscribe(response => {
    let groupByValue = 'daily';
    if (this.selectedPeriod.value == 7 || this.selectedPeriod.value == 30) {
      groupByValue = 'daily';
    } else {
      groupByValue = 'monthly'
    }
    this.http.get(`api/internal/v1/statistics/productivity-statistics?daysBeforeUntilDateCount=${this.selectedPeriod.value}&untilDateTime=${moment().utc().format()}&groupBy=${groupByValue}`).pipe(take(1)).subscribe(response => {
      this.dataLoading = false;
      const data = response['items'];
      if (Object.keys(data).length) {
        if (Object.keys(data).length < this.pageSize) {
          this.lastPage = true;
        } else if (Object.keys(data).length === this.pageSize) {
          this.lastPage = false;
        }
        Object.keys(data).forEach(index => {
          if (data[index]) {
            if (this.totalPoints.length < pageSize) {
              this.chartData[index] = data[index];
              if (groupByValue == 'monthly') {
                this.projectsNames.push(moment(index, 'YYYY-MM').format('MMM'));
              } else {
                this.projectsNames.push(moment(index, 'YYYY-MM-DD').format('DD-MM'));
              }

              this.totalPoints.push(data[index]['totalShipments']);
              this.completedPoints.push(data[index]['completedShipments']);
              this.cancelledPoints.push(data[index]['totalShipments'] - data[index]['completedShipments']);
            }
          }
        });
        // this.projectsNames.reverse();
        // this.totalPoints.reverse();
        // this.completedPoints.reverse();
        // this.cancelledPoints.reverse();

        this.chartDisabled = false;
      }
      else {
        this.lastPage = true;
        this.displayDummyChartValues();
      }
      this.initChart();
    });
  }

  chartTypeChange() {
    this.initChart();
  }

  chartPeriodChange() {
    this.currentChartPage = 0;
    this.firstPage = true;
    this.lastPage = false;
    this.emptyChartArrays();
    this.getChartData();
    this.chart.update();
  }

  initChart() {
    const ctx = <HTMLCanvasElement>document.getElementById('project-problems-chart');
    const data = this.getDataForChart();
    if (this.chart) { this.chart.destroy(); }
    const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptions));
    chartOptions.plugins.legend.display = true;
    if (ctx) {
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.projectsNames,
          datasets: [
            {
              label: this.completedLabel,
              data: this.completedPoints,
              //fill: true,
              borderColor: '#00aeba',
              // borderColor: '#4fbd76',
              // backgroundColor: '#00aeba70',
              backgroundColor: '#00aeba70',
            },
            {
              label: this.canceledLabel,
              data: this.totalPoints,
              fill: '-1',
              borderColor: '#00aeba',
              backgroundColor: '#FF000030',
            },
            // {
            //   label: 'cancelled',
            //   data: this.cancelledPoints,
            //   fill: false,
            //   borderColor: 'red',
            //   backgroundColor: 'red',
            // },
          ]
        },
        options: chartOptions
      });
    }
  }

  getProjectProblemsData() {
    let url = 'api/v1/project/' + this.projectId + '/problem';
    url += '?pageSize=' + this.cacheBlockSize;
    url += '&page=' + this.pagesCount;
    url += '&searchQuery=' + this.searchString;
    return this.http.get(url);
  }

  createServerSideDatasource() {
    const self = this;
    return {
      getRows: function (params) {
        self.getProjectProblemsData().pipe(take(1)).subscribe(response => {
          if (response['items'].length) {
            let lastRow = -1;
            self.pagesCount++;
            if (response['items'].length < self.cacheBlockSize) {
              lastRow = response['items'].length + ((self.pagesCount - 1) * self.cacheBlockSize);
            }
            params.successCallback(self.setProjectProblemGridData(response['items']), lastRow);
          } else {
            const noData = { noDataText: 'No data' }
            params.successCallback([noData], 1);
          }
        }, error => {
          params.failCallback();
        });
      },
    };
  }

  setProjectProblemGridData(data) {
    const self = this;
    self.stopPointsDataArray = [];
    let projectProblem, gridObject = {}, distance = '';
    data.forEach(projectProblemData => {
      projectProblem = projectProblemData.projectProblem;
      if (projectProblem.totalDistance) {
        distance = (projectProblem.totalDistance / 1000).toFixed(2) + 'km';
      }
      gridObject = {
        id: projectProblem.id,
        name: {
          name: projectProblem.title,
          date: moment(projectProblem.departure_datetime).format('YYYY-MM-DD'),
        },
        departure: moment(projectProblem.departure_datetime).format('HH:mm'),
        distance: distance,
        stops: projectProblem.selfStopPointsCount,
        drivers: projectProblem.routeCount,
        status: {
          entityStatus: this.globals.projectProblemEntityStatusLabels[projectProblem.entity_status],
          optimizationState: this.globals.projectProblemOptimizationStateLabels[projectProblem.optimization_state]
        },
        fullData: projectProblem
      };
      self.stopPointsDataArray.push(gridObject);
    });
    return self.stopPointsDataArray;
  }

  updateProjectProblemsGrid() {
    this.pagesCount = 0;
    // this.gridApi.purgeServerSideCache([]);
    this.gridApi.refreshServerSideStore({ purge: true });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setServerSideDatasource(this.createServerSideDatasource());
  }

  search($event) {
    this.searchTextChanged.next($event);
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.DEPARTURE_TIME').subscribe((res: string) => { this.departureTimeTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.TITLE').subscribe((res: string) => { this.nameTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.DISTANCE').subscribe((res: string) => { this.distanceTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.STOPS').subscribe((res: string) => { this.stopsTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.DRIVERS').subscribe((res: string) => { this.driversTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.STATUS').subscribe((res: string) => { this.statusTitle = res; }));
    this.listen.push(this.translate.get('WARNINGS.PROJECT_DELETE').subscribe((res: string) => { this.deleteProjectWarningLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.STATUS_COMPLETED').subscribe((res: string) => { this.completedLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.STATUS_CANCELED').subscribe((res: string) => { this.canceledLabel = res; }));
    this.listen.push(this.translate.get('PROJECT.STOP_POINTS').subscribe((res: string) => { this.stopPointsLabel = res; }));

    this.charts = [
      {
        label: this.stopPointsLabel,
        value: 'points',
      },
    ];
    this.selectedChart = this.charts[0];

    this.columnDefs = [
      {
        headerName: this.nameTitle,
        field: 'name',
        cellRenderer: this.nameRenderer,
        width: this.gridsService.widthCalculator(32)
      },
      {
        headerName: this.departureTimeTitle,
        field: 'departure',
        width: this.gridsService.widthCalculator(20),
        headerClass: 'text-center',
        cellClass: 'text-center'
      },
      {
        headerName: this.distanceTitle, field: 'distance',
        width: this.gridsService.widthCalculator(10),
        headerClass: 'text-center',
        cellClass: 'text-center'
      },
      {
        headerName: this.stopsTitle, field: 'stops',
        width: this.gridsService.widthCalculator(10),
        headerClass: 'text-center', cellClass: 'text-center'
      },
      {
        headerName: this.driversTitle,
        field: 'drivers',
        width: this.gridsService.widthCalculator(10),
        headerClass: 'text-center',
        cellClass: 'text-center'
      },
      {
        headerName: this.statusTitle, field: 'status',
        cellRenderer: this.statusRenderer,
        width: this.gridsService.widthCalculator(15),
        headerClass: 'text-center',
        cellClass: 'text-center'
      },
      {
        headerName: '', field: '',
        cellClass: 'dropdown-cell',
        cellRenderer: this.dropdownRenderer.bind(this),
        width: this.gridsService.widthCalculator(3)
      },
    ];
  }

  ngOnInit(): void {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.listen.push(this.activatedRoute.params.subscribe(params => {
      this.projectId = params['id'];
    }));

    this.http.get('api/internal/v2/project/projects/' + this.projectId).pipe(take(1)).subscribe(response => {
      if (response['items'] && response['items'][0]) {
        const project = response['items'][0]['project'];
        this.defaultDepartureTime = project.default_project_departure_datetime;
      }
    });
    this.periods = [...this.chartService.periods];
    this.periods.splice(0, 1);
    this.selectedPeriod = this.periods[0];

    this.getChartData();

    this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe((text: string) => {
        this.updateProjectProblemsGrid();
      });

  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}

interface EventObject {
  event: string;
  value: any;
}
