// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        lm-bar-chart, lm-doughnut-chart, lm-pie-chart, lm-semicircle-chart{width: 100%}
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRlbGl2ZXJpZXMtd2lkZ2V0LmNvbXBvbmVudC50cyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO1FBQ1EsbUVBQW1FLFdBQVciLCJmaWxlIjoiZGVsaXZlcmllcy13aWRnZXQuY29tcG9uZW50LnRzIiwic291cmNlc0NvbnRlbnQiOlsiXG4gICAgICAgIGxtLWJhci1jaGFydCwgbG0tZG91Z2hudXQtY2hhcnQsIGxtLXBpZS1jaGFydCwgbG0tc2VtaWNpcmNsZS1jaGFydHt3aWR0aDogMTAwJX1cbiAgICAiXX0= */`, "",{"version":3,"sources":["webpack://./src/app/dashboard-report/hybrid-widgets/deliveries-widget.component.ts"],"names":[],"mappings":";QACQ,mEAAmE,WAAW;;AAEtF,4YAA4Y","sourcesContent":["\n        lm-bar-chart, lm-doughnut-chart, lm-pie-chart, lm-semicircle-chart{width: 100%}\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
