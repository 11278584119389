/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LmReportsApiService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
      super(config, http);
    }

  //shipments-statistics
  static readonly apiReportsOverviewGetPath = 'api/internal/v2/statistics/shipments-overview-statistics?untilDateTime={untilDateTime}&daysBeforeUntilDateCount={daysBeforeUntilDateCount}&projectId={projectId}&shipment_type={shipment_type}';
  static readonly apiReportsShipmentsGetPath = 'api/internal/v2/statistics/shipment-statistics?untilDateTime={untilDateTime}&daysBeforeUntilDateCount={daysBeforeUntilDateCount}&projectId={projectId}&shipment_type={shipment_type}';
  static readonly apiReportsDriversGetPath = 'api/internal/v2/statistics/driver-shipment-statistics?untilDateTime={untilDateTime}&daysBeforeUntilDateCount={daysBeforeUntilDateCount}&projectId={projectId}&shipment_type={shipment_type}&pageSize={pageSize}&page={page}&tabChartType={tabChartType}';
  static readonly apiReportsProductivityGetPath = 'api/internal/v2/statistics/shipment-statistics-productivity?untilDateTime={untilDateTime}&daysBeforeUntilDateCount={daysBeforeUntilDateCount}&projectId={projectId}&shipment_type={shipment_type}';
  static readonly apiCancelationGetPath = 'api/internal/v2/statistics/canceled-shipment-statistics?untilDateTime={untilDateTime}&daysBeforeUntilDateCount={daysBeforeUntilDateCount}&projectId={projectId}&shipment_type={shipment_type}';
  static readonly apiDeliveriesGetPath = 'api/internal/v2/statistics/shipment-statistics-shipper-share?untilDateTime={untilDateTime}&daysBeforeUntilDateCount={daysBeforeUntilDateCount}&projectId={projectId}&shipment_type={shipment_type}';
  static readonly apiBranchesGetPath = 'api/internal/v2/statistics/shipment-statistics-branches?untilDateTime={untilDateTime}&daysBeforeUntilDateCount={daysBeforeUntilDateCount}&projectId={projectId}&shipment_type={shipment_type}&pageSize={pageSize}&page={page}&tabChartType={tabChartType}';
  static readonly apiActivityGetPath = 'api/internal/v1/activityLog/activity-logs';


     // OVERVIEW
     apiReportsOverviewGet$Response(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<StrictHttpResponse<Array<any>>> {
      const rb = new RequestBuilder(this.rootUrl, LmReportsApiService.apiReportsOverviewGetPath, 'get');
      if(params){
          rb.path('untilDateTime', params.untilDateTime);
          rb.path('daysBeforeUntilDateCount', params.daysBeforeUntilDateCount);
          rb.path('projectId', params.projectId);
          rb.path('shipment_type', params.shipment_type);
      }
      return this.http.request(rb.build({responseType: 'json', accept: 'application/json'})).pipe(filter((r: any) => r instanceof HttpResponse), map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>));
    }
    
    apiReportsOverviewGet(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<Array<any>> {
        return this.apiReportsOverviewGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }


   // SHIPMENTS
   apiReportsShipmentsGet$Response(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmReportsApiService.apiReportsShipmentsGetPath, 'get');
    if(params){
        rb.path('untilDateTime', params.untilDateTime);
        rb.path('daysBeforeUntilDateCount', params.daysBeforeUntilDateCount);
        rb.path('projectId', params.projectId);
        rb.path('shipment_type', params.shipment_type);
    }
    return this.http.request(rb.build({responseType: 'json', accept: 'application/json'})).pipe(filter((r: any) => r instanceof HttpResponse), map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>));
  }

  apiReportsShipmentsGet(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<Array<any>> {
      return this.apiReportsShipmentsGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }
  

  
 
  apiReportsProductivityGet$Response(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmReportsApiService.apiReportsProductivityGetPath, 'get');
    if(params){
        rb.path('untilDateTime', params.untilDateTime);
        rb.path('daysBeforeUntilDateCount', params.daysBeforeUntilDateCount);
        rb.path('projectId', params.projectId);
        rb.path('shipment_type', params.shipment_type);
    }
    return this.http.request(rb.build({responseType: 'json', accept: 'application/json'})).pipe(filter((r: any) => r instanceof HttpResponse), map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>));
  }

  apiReportsProductivityGet(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<Array<any>> {
    return this.apiReportsProductivityGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
}
 


  // DRIVERS
  apiReportsDriversGet$Response(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string, pageSize?:string, page?: string, tabChartType?: string}): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmReportsApiService.apiReportsDriversGetPath, 'get');
    if(params){
        rb.path('untilDateTime', params.untilDateTime);
        rb.path('daysBeforeUntilDateCount', params.daysBeforeUntilDateCount);
        rb.path('projectId', params.projectId);
        rb.path('shipment_type', params.shipment_type);
        rb.path('pageSize', params.pageSize);
        rb.path('page', params.page);
        rb.path('tabChartType', params.tabChartType);
    }
    return this.http.request(rb.build({responseType: 'json', accept: 'application/json'})).pipe(filter((r: any) => r instanceof HttpResponse), map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>));
  }

  apiReportsDriversGet(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string, pageSize?:string, page?: string, tabChartType?: string}): Observable<Array<any>> {
      return this.apiReportsDriversGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }

  

  apiCancelationGet$Response(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmReportsApiService.apiCancelationGetPath, 'get');
    if(params){
        rb.path('untilDateTime', params.untilDateTime);
        rb.path('daysBeforeUntilDateCount', params.daysBeforeUntilDateCount);
        rb.path('projectId', params.projectId);
        rb.path('shipment_type', params.shipment_type);
    }
    return this.http.request(rb.build({responseType: 'json', accept: 'application/json'})).pipe(filter((r: any) => r instanceof HttpResponse), map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>));
  }

  apiCancelationGet(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<Array<any>> {
      return this.apiCancelationGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }



  apiBranchesGet$Response(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string, pageSize?:string, page?: string, tabChartType?: string}): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmReportsApiService.apiBranchesGetPath, 'get');
    if(params){
        rb.path('untilDateTime', params.untilDateTime);
        rb.path('daysBeforeUntilDateCount', params.daysBeforeUntilDateCount);
        rb.path('projectId', params.projectId);
        rb.path('shipment_type', params.shipment_type);
        rb.path('pageSize', params.pageSize);
        rb.path('page', params.page);
        rb.path('tabChartType', params.tabChartType);
    }
    return this.http.request(rb.build({responseType: 'json', accept: 'application/json'})).pipe(filter((r: any) => r instanceof HttpResponse), map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>));
  }

  apiBranchesGet(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string, pageSize?:string, page?: string, tabChartType?: string}): Observable<Array<any>> {
      return this.apiBranchesGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }



  apiDeliveriesGet$Response(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmReportsApiService.apiDeliveriesGetPath, 'get');
    if(params){
        rb.path('untilDateTime', params.untilDateTime);
        rb.path('daysBeforeUntilDateCount', params.daysBeforeUntilDateCount);
        rb.path('projectId', params.projectId);
        rb.path('shipment_type', params.shipment_type);
    }
    return this.http.request(rb.build({responseType: 'json', accept: 'application/json'})).pipe(filter((r: any) => r instanceof HttpResponse), map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>));
  }

  apiDeliveriesGet(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<Array<any>> {
      return this.apiDeliveriesGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }



  apiActivityGet$Response(params?: { lastObtainedId?: string}): Observable<StrictHttpResponse<Array<any>>> {
    let path = LmReportsApiService.apiActivityGetPath;
    if (params?.lastObtainedId) path += '?lastObtainedId=' + params.lastObtainedId;
    const rb = new RequestBuilder(this.rootUrl, path, 'get');
    return this.http.request(rb.build({responseType: 'json', accept: 'application/json'})).pipe(filter((r: any) => r instanceof HttpResponse), map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>));
  }

  apiActivityGet(params?: { lastObtainedId?: string}): Observable<Array<any>> {
      return this.apiActivityGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }  

}