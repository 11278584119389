import { GenericService } from '@app/services/generic.service';
import { ColourService } from '@app/services/colour.service';
import { take } from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { DashboardDummyComponent } from './dashboard-dummy/dashboard-dummy.component';
import { ModalService } from '@app/services/modal.service';
import { HttpClient } from '@angular/common/http';
import { _ } from '@ag-grid-community/core';
import { ImageUtils } from '@app/utils/image-utils';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html'
})

export class DashboardComponent implements OnInit, AfterViewInit {

    @ViewChild(DashboardDummyComponent, { static: false }) dashboardDummyComponent: DashboardDummyComponent;

    listen = [];
    hasFinishedLoadingShipmentsStats = false;
    hasFinishedLoadingProductivityStats = false;
    hasFinishedLoadingDriversStats = false;
    hasFinishedLoadingCollaboratorsStats = false;

    // filters per panel
    shipmentsFilterSelected = 'shipments'; // shipments, map, income
    productivityFilterSelected = 'rating'; // rating, avgDurationStopPoint, avgDeliveriesDriver, avgStopPointsDistance
    driversFilterSelected = 'rating'; // rating, avgDurationStopPoint, avgDeliveries, recipientsRating
    currentTimeValue;

    // subtitles
    shipmentsSubtitle;
    mapSubtitle;
    incomeSubtitle;
    ratingSubtitle;
    partnersSubtitle;
    avgDurationStopPointSubtitle;
    avgDeliveriesDriverSubtitle;
    avgStopPointsDistanceSubtitle;
    avgDeliveriesSubtitle;
    recipientsRatingSubtitle;

    allProjectsLabel;
    allPartnersLabel;

    // activity labels
    driverFinishRouteLabel;
    invoicingLabel;
    invoicingCollaboratorLabel;
    paymentLabel;
    paymentCollaboratorLabel;
    inviteSentLabel;
    inviteAcceptedLabel;
    shipmentsAcceptedLabel;
    shipmentsThinkLabel;
    shipmentsCollaboratorThinkLabel;
    shipmentsCompletedLabel;
    shipmentsCollaboratorCompletedLabel;
    requestedNewPickupsLabel;
    projectCompletedLabel;
    projectThinkAutoLabel;
    projectThinkLabel;
    projectAddShipmentsLabel;

    logoSrc = 'data:image/png;base64,LOGO_HASH';
    logoBase64 = '';

    projects = [];
    selectedProject;
    partners = [];
    selectedPartner;
    currentDriverPage = 0;
    driversData;
    chartOthersLabel;

    selectedDatesQuery = '';
    customDatesSelected;

    activeShipmentsChartTitle = '';
    activeProductivityChartTitle = '';
    activeDriversChartTitle = '';
    yesterdayLabel;
    twoDaysAgoLabel;
    nowLabel;

    // used in pagination for activity log
    isActivityLogsLoading;
    lastObtainedActivityId;
    hasLoadedAllActivityPages;

    constructor(
        private http: HttpClient,
        public globals: Globals,
        public translate: TranslateService,
        private modalService: ModalService,
        private colourService: ColourService,
        private genericService: GenericService,
        private imageUtils: ImageUtils
    ) {
        this.listen.push(this.modalService.submitDateRangeListen().subscribe((dates) => {
            this.currentTimeValue = 'custom';
            this.hasFinishedLoadingDriversStats = false;
            this.hasFinishedLoadingProductivityStats = false;
            this.hasFinishedLoadingShipmentsStats = false;
            this.hasFinishedLoadingCollaboratorsStats = false;

            // update time filters
            const currentActiveFilterButton = document.querySelector('.filter-button.active');
            if (currentActiveFilterButton) {
                currentActiveFilterButton.classList.remove('active');
            }
            const selectedFilterButton = document.querySelector('[time="custom"]');
            if (selectedFilterButton) {
                selectedFilterButton.classList.add('active');
            }

            // request
            const startQuery = moment(dates[0]).format('DD-MM-YYYY');
            const endQuery = moment(dates[1]).format('DD-MM-YYYY');
            this.selectedDatesQuery = 'startDate=' + startQuery + '&endDate=' + endQuery + '&timezone=' + moment.tz.guess();

            // get full dates of selected range
            let customDates = [];
            const start = moment(dates[0]);
            const end = moment(dates[1]);
            const startDate = moment(start).startOf('day');
            const lastDate = moment(end).startOf('day');

            while (startDate.add(1, 'days').diff(lastDate) < 0) {
                customDates.push(startDate.clone().toDate());
            }

            // add first and last dates
            customDates.unshift(start.clone().toDate());
            customDates.push(end.clone().toDate());

            // is same day is found, keep only the first
            if (moment(customDates[0]).format('DD/MM/YYYY') == moment(customDates[1]).format('DD/MM/YYYY')) {
                customDates = [customDates[0]]
            }

            this.customDatesSelected = customDates;
            this.setChartsCustom(customDates);
        }));
        this.listen.push(this.genericService.listenOpenProjectProblemReportModal().subscribe(data => {
            this.http.get(`api/internal/v2/drivers?projectProblemId=${data.projectProblemId}`).subscribe(res => {
                const driverIds = [];
                res['items'].forEach(currDriver => {
                    driverIds.push(currDriver.driver.id);
                });
                this.dashboardDummyComponent.projectProblemReportModalComponent.loadAndOpen(driverIds, data.departureTime, data.projectProblemId, data.projectName);
            });
        }));
        // this.listen.push(this.genericService.listenOpenDriverReportModal().subscribe(data => {
        //     console.log(data)
        //     this.dashboardDummyComponent.driverReportModalComponent.loadAndOpen(data.driverId, data.departureTime, data.projectProblemId);
        // }));
    }

    // shipments filter select
    shipmentsFilterSelect(filterType) {
        if (this.shipmentsFilterSelected != filterType) {
            this.shipmentsFilterSelected = filterType;

            switch (filterType) {
                case 'shipments':
                    this.activeShipmentsChartTitle = this.shipmentsSubtitle;
                    this.dashboardDummyComponent.dashboardShipmentsComponent.initShipmentsChart();
                    break;
                case 'map':
                    this.activeShipmentsChartTitle = this.mapSubtitle;
                    break;
                case 'income':
                    this.activeShipmentsChartTitle = this.incomeSubtitle;
                    this.dashboardDummyComponent.dashboardShipmentsComponent.initIncomeChart();
                    break;
            }

            // // refresh chart
            // this.filterByTime(this.currentTimeValue);
        }
    }

    // productivity filter select
    productivityFilterSelect(filterType) {
        if (this.productivityFilterSelected != filterType) {
            this.productivityFilterSelected = filterType;

            switch (filterType) {
                case 'rating':
                    if (!this.globals.collaboratorModeEnabled) {
                        this.activeProductivityChartTitle = this.ratingSubtitle;
                    } else {
                        this.activeProductivityChartTitle = this.partnersSubtitle;
                    }
                    this.dashboardDummyComponent.dashboardProductivityComponent.initRatingChart();
                    break;
                case 'ratingRecipients':
                    this.activeProductivityChartTitle = this.recipientsRatingSubtitle;
                    this.dashboardDummyComponent.dashboardProductivityComponent.initRecipientsRatingChart();
                    break;
                case 'avgDurationStopPoint':
                    this.activeProductivityChartTitle = this.avgDurationStopPointSubtitle;
                    this.dashboardDummyComponent.dashboardProductivityComponent.initAvgDurationStopPointChart();
                    break;
                case 'avgDeliveriesDriver':
                    this.activeProductivityChartTitle = this.avgDeliveriesDriverSubtitle;
                    this.dashboardDummyComponent.dashboardProductivityComponent.initAvgDeliveriesDriver();
                    break;
                case 'avgStopPointsDistance':
                    this.activeProductivityChartTitle = this.avgStopPointsDistanceSubtitle;
                    this.dashboardDummyComponent.dashboardProductivityComponent.initAvgStopPointsDistance();
                    break;
            }
        }
    }

    // productivity filter select
    driversFilterSelect(filterType) {
        if (this.driversFilterSelected != filterType) {
            this.driversFilterSelected = filterType;
            this.dashboardDummyComponent.dashboardDriversComponent.currentPage = 0;
            this.dashboardDummyComponent.dashboardDriversComponent.currentActiveChart = filterType;
            this.dashboardDummyComponent.dashboardDriversComponent.getNewData();

            switch (filterType) {
                case 'rating':
                    this.activeDriversChartTitle = this.ratingSubtitle;
                    // this.dashboardDummyComponent.dashboardDriversComponent.initRatingChart();
                    break;
                case 'avgDurationStopPoint':
                    this.activeDriversChartTitle = this.avgDurationStopPointSubtitle;
                    // this.dashboardDummyComponent.dashboardDriversComponent.initAvgDurationStopPoint();
                    break;
                case 'avgDeliveries':
                    this.activeDriversChartTitle = this.avgDeliveriesSubtitle;
                    // this.dashboardDummyComponent.dashboardDriversComponent.initAvgDeliveries();
                    break;
                case 'recipientsRating':
                    this.activeDriversChartTitle = this.recipientsRatingSubtitle;
                    // this.dashboardDummyComponent.dashboardDriversComponent.initRecipientsRating();
                    break;
            }
        }
    }

    // filter results based on selected project
    changeProjectSelect(selectedProject) {
        this.selectedProject = selectedProject;
        this.dashboardDummyComponent.dashboardProjectsLiveComponent.initGrid(selectedProject.id);

        // set the timeAxisEntries & y axis values of every chart
        switch (this.currentTimeValue) {
            case 'year':
                this.setChartsYear();
                break;
            case 'month':
                this.setChartsMonth();
                break;
            case 'week':
                this.setChartsWeek();
                break;
            case 'day':
                this.setChartsDay();
                break;
            case 'custom':
                this.setChartsCustom(this.customDatesSelected);
                break;
        }
    }

    // filter results based on selected partner
    changePartnerSelect(selectedPartner) {
        this.selectedPartner = selectedPartner;

        // set the timeAxisEntries & y axis values of every chart
        switch (this.currentTimeValue) {
            case 'year':
                this.setChartsYear();
                break;
            case 'month':
                this.setChartsMonth();
                break;
            case 'week':
                this.setChartsWeek();
                break;
            case 'day':
                this.setChartsDay();
                break;
            case 'custom':
                this.setChartsCustom(this.customDatesSelected);
                break;
        }
    }

    // filter results based on time selected from filters in white bar
    filterByTime(timeValue) {
        if (timeValue == 'custom') {
            this.dashboardDummyComponent.dateRangePickerModalComponent.openModal();
        }

        if (
            (!this.globals.collaboratorModeEnabled && this.hasFinishedLoadingDriversStats && this.hasFinishedLoadingProductivityStats && this.hasFinishedLoadingShipmentsStats && this.hasFinishedLoadingCollaboratorsStats && this.currentTimeValue != timeValue)
            || (this.globals.collaboratorModeEnabled && this.hasFinishedLoadingProductivityStats && this.hasFinishedLoadingShipmentsStats && this.currentTimeValue != timeValue)
        ) {

            // else {
            this.hasFinishedLoadingDriversStats = false;
            this.hasFinishedLoadingProductivityStats = false;
            this.hasFinishedLoadingShipmentsStats = false;
            this.hasFinishedLoadingCollaboratorsStats = false;
            // }

            // if (timeValue != this.currentTimeValue) {
            if (timeValue != 'custom') {
                this.currentTimeValue = timeValue;

                const currentActiveFilterButton = document.querySelector('.filter-button.active');
                if (currentActiveFilterButton) {
                    currentActiveFilterButton.classList.remove('active');
                }
                const selectedFilterButton = document.querySelector('[time="' + this.currentTimeValue + '"]');
                if (selectedFilterButton) {
                    selectedFilterButton.classList.add('active');
                }
            }

            // set the timeAxisEntries & y axis values of every chart
            switch (timeValue) {
                case 'year':
                    this.setChartsYear();
                    break;
                case 'month':
                    this.setChartsMonth();
                    break;
                case 'week':
                    this.setChartsWeek();
                    break;
                case 'day':
                    this.setChartsDay();
                    break;
            }
            // }
        } else {
            console.warn('wait for the requests to finish first!');
        }
    }

    // time change for charts
    setChartsCustom(dates) {
        // < 45 days
        // COURIER
        if (!this.globals.collaboratorModeEnabled) {
            if (dates.length < 45) {
                let fullMonthDates = [];
                dates.forEach(date => {
                    fullMonthDates.push(moment(date).format('YYYY-MM-DD'));
                });

                // shipments data
                // shipments (canceled/completed)
                this.http.get(`api/internal/v1/statistics/shipment-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}&groupBy=daily&chartType=shipments`).subscribe(resShipments => {
                    // income
                    this.http.get(`api/internal/v1/statistics/shipment-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}&groupBy=daily&chartType=income`).subscribe(resIncome => {
                        this.setShipmentsChartData('days', fullMonthDates, resShipments['items'], resIncome['items']);
                    });
                });

                // productivity data
                this.http.get(`api/internal/v1/statistics/productivity-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}&groupBy=daily`).subscribe(res => {
                    this.setProductivityChartData('days', fullMonthDates, res['items']);
                });

                // drivers data
                this.http.get(`api/internal/v1/statistics/driver-statistics?return_mode=dashboard&daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}&getDriverAvatars=true`).subscribe(res => {
                    this.setDriversChartData(res['items']);
                });

                // consignors data
                this.http.get(`api/internal/v1/statistics/collaborator-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}`).subscribe(res => {
                    this.calculateConsignorStats(res['items']);
                });
            }
            // < 45 months (1368 = 45 months in days)
            else if (dates.length < 1368) {
                let fullMonthDates = [];
                dates.forEach(date => {
                    if (!fullMonthDates.includes(moment(date).format('YYYY-MM'))) {
                        fullMonthDates.push(moment(date).format('YYYY-MM'));
                    }
                });

                // shipments data
                // shipments (canceled/completed)
                this.http.get(`api/internal/v1/statistics/shipment-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}&groupBy=monthly&chartType=shipments`).subscribe(resShipments => {
                    // income
                    this.http.get(`api/internal/v1/statistics/shipment-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}&groupBy=monthly&chartType=income`).subscribe(resIncome => {
                        this.setShipmentsChartData('months', fullMonthDates, resShipments['items'], resIncome['items']);
                    });
                });

                // productivity data
                this.http.get(`api/internal/v1/statistics/productivity-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}&groupBy=monthly`).subscribe(res => {
                    this.setProductivityChartData('months', fullMonthDates, res['items']);
                });

                // drivers data
                this.http.get(`api/internal/v1/statistics/driver-statistics?return_mode=dashboard&daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}&getDriverAvatars=true`).subscribe(res => {
                    this.setDriversChartData(res['items']);
                });

                // consignors data
                this.http.get(`api/internal/v1/statistics/collaborator-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}`).subscribe(res => {
                    this.calculateConsignorStats(res['items']);
                });
            }
            // > 45 months
            else {
                let fullMonthDates = [];
                dates.forEach(date => {
                    if (!fullMonthDates.includes(moment(date).format('YYYY'))) {
                        fullMonthDates.push(moment(date).format('YYYY'));
                    }
                });

                // shipments data
                // shipments (canceled/completed)
                this.http.get(`api/internal/v1/statistics/shipment-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}&groupBy=yearly&chartType=shipments`).subscribe(resShipments => {
                    // income
                    this.http.get(`api/internal/v1/statistics/shipment-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}&groupBy=yearly&chartType=income`).subscribe(resIncome => {
                        this.setShipmentsChartData('years', fullMonthDates, resShipments['items'], resIncome['items']);
                    });
                });

                // productivity data
                this.http.get(`api/internal/v1/statistics/productivity-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}&groupBy=yearly`).subscribe(res => {
                    this.setProductivityChartData('years', fullMonthDates, res['items']);
                });

                // drivers data
                this.http.get(`api/internal/v1/statistics/driver-statistics?return_mode=dashboard&daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}&getDriverAvatars=true`).subscribe(res => {
                    this.setDriversChartData(res['items']);
                });

                // consignors data
                this.http.get(`api/internal/v1/statistics/collaborator-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&projectIds=${this.selectedProject.id}`).subscribe(res => {
                    this.calculateConsignorStats(res['items']);
                });
            }
        }
        // COLLABORATOR
        else {
            if (dates.length < 45) {
                let fullMonthDates = [];
                dates.forEach(date => {
                    fullMonthDates.push(moment(date).format('YYYY-MM-DD'));
                });

                this.http.get(`api/internal/v1/statistics/partner-shipment-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&partnerCompanyIds=${this.selectedPartner.id}&groupBy=daily`).subscribe(res => {
                    this.setShipmentsCollaboratorChartData('days', fullMonthDates, res['items']);
                });

                this.http.get(`api/internal/v1/statistics/partner-rating-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&partnerCompanyIds=${this.selectedPartner.id}&groupBy=daily`).subscribe(res => {
                    this.setRatingCollaboratorChartData('days', fullMonthDates, res['items']);
                });
            }
            // < 45 months (1368 = 45 months in days)
            else if (dates.length < 1368) {
                let fullMonthDates = [];
                dates.forEach(date => {
                    if (!fullMonthDates.includes(moment(date).format('YYYY-MM'))) {
                        fullMonthDates.push(moment(date).format('YYYY-MM'));
                    }
                });

                this.http.get(`api/internal/v1/statistics/partner-shipment-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&partnerCompanyIds=${this.selectedPartner.id}&groupBy=monthly`).subscribe(res => {
                    this.setShipmentsCollaboratorChartData('months', fullMonthDates, res['items']);
                });

                this.http.get(`api/internal/v1/statistics/partner-rating-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&partnerCompanyIds=${this.selectedPartner.id}&groupBy=monthly`).subscribe(res => {
                    this.setRatingCollaboratorChartData('months', fullMonthDates, res['items']);
                });
            }
            // > 45 months
            else {
                let fullMonthDates = [];
                dates.forEach(date => {
                    if (!fullMonthDates.includes(moment(date).format('YYYY'))) {
                        fullMonthDates.push(moment(date).format('YYYY'));
                    }
                });

                this.http.get(`api/internal/v1/statistics/partner-shipment-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&partnerCompanyIds=${this.selectedPartner.id}&groupBy=yearly`).subscribe(res => {
                    this.setShipmentsCollaboratorChartData('years', fullMonthDates, res['items']);
                });

                this.http.get(`api/internal/v1/statistics/partner-rating-statistics?daysBeforeUntilDateCount=${dates.length}&untilDateTime=${moment(dates[dates.length - 1]).utc().format()}&partnerCompanyIds=${this.selectedPartner.id}&groupBy=yearly`).subscribe(res => {
                    this.setRatingCollaboratorChartData('years', fullMonthDates, res['items']);
                });
            }
        }
    }

    // time change for charts
    setChartsYear() {
        // get last 12 months
        let fullMonthDates = [];
        for (let daysNum = 0; daysNum < 12; daysNum++) {
            fullMonthDates.unshift(moment().subtract(daysNum, 'month').format('YYYY-MM'));
        }

        // COURIER
        if (!this.globals.collaboratorModeEnabled) {
            // shipments data
            // shipments (canceled/completed)
            this.http.get(`api/internal/v1/statistics/shipment-statistics?daysBeforeUntilDateCount=365&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}&groupBy=monthly&chartType=shipments`).subscribe(resShipments => {
                // income
                this.http.get(`api/internal/v1/statistics/shipment-statistics?daysBeforeUntilDateCount=365&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}&groupBy=monthly&chartType=income`).subscribe(resIncome => {
                    this.setShipmentsChartData('months', fullMonthDates, resShipments['items'], resIncome['items']);
                });
            });

            // productivity data
            this.http.get(`api/internal/v1/statistics/productivity-statistics?daysBeforeUntilDateCount=365&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}&groupBy=monthly`).subscribe(res => {
                this.setProductivityChartData('months', fullMonthDates, res['items']);
            });

            // drivers data
            this.http.get(`api/internal/v1/statistics/driver-statistics?return_mode=dashboard&daysBeforeUntilDateCount=365&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}&getDriverAvatars=true`).subscribe(res => {
                this.setDriversChartData(res['items']);
            });

            // consignors data
            this.http.get(`api/internal/v1/statistics/collaborator-statistics?daysBeforeUntilDateCount=365&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}`).subscribe(res => {
                this.calculateConsignorStats(res['items']);
            });
        }
        // COLLABORATOR
        else {
            this.http.get(`api/internal/v1/statistics/partner-shipment-statistics?daysBeforeUntilDateCount=365&untilDateTime=${moment().utc().format()}&partnerCompanyIds=${this.selectedPartner.id}&groupBy=monthly`).subscribe(res => {
                this.setShipmentsCollaboratorChartData('months', fullMonthDates, res['items']);
            });

            this.http.get(`api/internal/v1/statistics/partner-rating-statistics?daysBeforeUntilDateCount=365&untilDateTime=${moment().utc().format()}&partnerCompanyIds=${this.selectedPartner.id}&groupBy=monthly`).subscribe(res => {
                this.setRatingCollaboratorChartData('months', fullMonthDates, res['items']);
            });
        }
    }

    // time change for charts
    setChartsMonth() {
        // get last 30 days (these strings are used for matching w/ data from be)
        let fullMonthDates = [];
        for (let daysNum = 0; daysNum < 30; daysNum++) {
            fullMonthDates.unshift(moment(moment().subtract(1, 'day')).subtract(daysNum, 'day').format('YYYY-MM-DD'));
        }

        // COURIER
        if (!this.globals.collaboratorModeEnabled) {
            // shipments data
            // shipments (canceled/completed)
            this.http.get(`api/internal/v1/statistics/shipment-statistics?daysBeforeUntilDateCount=30&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}&groupBy=daily&chartType=shipments`).subscribe(resShipments => {
                // income
                this.http.get(`api/internal/v1/statistics/shipment-statistics?daysBeforeUntilDateCount=30&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}&groupBy=daily&chartType=income`).subscribe(resIncome => {
                    this.setShipmentsChartData('days', fullMonthDates, resShipments['items'], resIncome['items']);
                });
            });

            // productivity data
            this.http.get(`api/internal/v1/statistics/productivity-statistics?daysBeforeUntilDateCount=30&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}&groupBy=daily`).subscribe(res => {
                this.setProductivityChartData('days', fullMonthDates, res['items']);
            });

            // drivers data
            this.http.get(`api/internal/v1/statistics/driver-statistics?return_mode=dashboard&daysBeforeUntilDateCount=30&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}&getDriverAvatars=true`).subscribe(res => {
                this.setDriversChartData(res['items']);
            });

            // consignors (collaborators) data 
            this.http.get(`api/internal/v1/statistics/collaborator-statistics?daysBeforeUntilDateCount=30&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}`).subscribe(res => {
                this.calculateConsignorStats(res['items']);
            });
        }
        // COLLABORATOR
        else {
            this.http.get(`api/internal/v1/statistics/partner-shipment-statistics?daysBeforeUntilDateCount=30&untilDateTime=${moment().utc().format()}&partnerCompanyIds=${this.selectedPartner.id}&groupBy=daily`).subscribe(res => {
                this.setShipmentsCollaboratorChartData('days', fullMonthDates, res['items']);
            });

            this.http.get(`api/internal/v1/statistics/partner-rating-statistics?daysBeforeUntilDateCount=30&untilDateTime=${moment().utc().format()}&partnerCompanyIds=${this.selectedPartner.id}&groupBy=daily`).subscribe(res => {
                this.setRatingCollaboratorChartData('days', fullMonthDates, res['items']);
            });
        }
    }

    setChartsWeek() {
        // get last 7 days
        let fullMonthDates = [];
        for (let daysNum = 0; daysNum < 7; daysNum++) {
            fullMonthDates.unshift(moment(moment().subtract(1, 'day')).subtract(daysNum, 'day').format('YYYY-MM-DD'));
        }

        // COURIER
        if (!this.globals.collaboratorModeEnabled) {
            // shipments data
            // shipments (canceled/completed)
            this.http.get(`api/internal/v1/statistics/shipment-statistics?daysBeforeUntilDateCount=7&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}&groupBy=weekly&chartType=shipments`).subscribe(resShipments => {
                // income
                this.http.get(`api/internal/v1/statistics/shipment-statistics?daysBeforeUntilDateCount=7&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}&groupBy=weekly&chartType=income`).subscribe(resIncome => {
                    this.setShipmentsChartData('days', fullMonthDates, resShipments['items'], resIncome['items']);
                });
            });

            // productivity data
            this.http.get(`api/internal/v1/statistics/productivity-statistics?daysBeforeUntilDateCount=7&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}&groupBy=weekly`).subscribe(res => {
                // console.log('productivity data res:', res)
                if((<any>res).items) this.setProductivityChartData('days', fullMonthDates, res['items']);
            });

            // drivers data
            this.http.get(`api/internal/v1/statistics/driver-statistics?return_mode=dashboard&daysBeforeUntilDateCount=7&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}&getDriverAvatars=true`).subscribe(res => {
                this.setDriversChartData(res['items']);
            });


            // consignors data
            this.http.get(`api/internal/v1/statistics/collaborator-statistics?daysBeforeUntilDateCount=7&untilDateTime=${moment().utc().format()}&projectIds=${this.selectedProject.id}`).subscribe(res => {
                this.calculateConsignorStats(res['items']);
            });
        }
        // COLLABORATOR
        else {
            this.http.get(`api/internal/v1/statistics/partner-shipment-statistics?daysBeforeUntilDateCount=7&untilDateTime=${moment().utc().format()}&partnerCompanyIds=${this.selectedPartner.id}&groupBy=weekly`).subscribe(res => {
                this.setShipmentsCollaboratorChartData('days', fullMonthDates, res['items']);
            });

            this.http.get(`api/internal/v1/statistics/partner-rating-statistics?daysBeforeUntilDateCount=7&untilDateTime=${moment().utc().format()}&partnerCompanyIds=${this.selectedPartner.id}&groupBy=weekly`).subscribe(res => {
                this.setRatingCollaboratorChartData('days', fullMonthDates, res['items']);
            });
        }
    }

    setChartsDay() {
        // COURIER
        if (!this.globals.collaboratorModeEnabled) {
            // shipments data
            // shipments (canceled/completed)
            this.http.get(`api/internal/v1/statistics/shipment-statistics?daysBeforeUntilDateCount=1&untilDateTime=${moment().subtract(1, 'days').format('YY-MM-DD')}&projectIds=${this.selectedProject.id}&groupBy=daily&chartType=shipments`).subscribe(resShipments => {
                // income
                this.http.get(`api/internal/v1/statistics/shipment-statistics?daysBeforeUntilDateCount=1&untilDateTime=${moment().subtract(1, 'days').format('YY-MM-DD')}&projectIds=${this.selectedProject.id}&groupBy=daily&chartType=income`).subscribe(resIncome => {
                    this.setShipmentsChartData('single', null, resShipments['items'], resIncome['items']);
                });
            });

            // productivity data
            this.http.get(`api/internal/v1/statistics/productivity-statistics?daysBeforeUntilDateCount=1&untilDateTime=${moment().subtract(1, 'days').format('YY-MM-DD')}&projectIds=${this.selectedProject.id}&groupBy=daily`).subscribe(res => {
                this.setProductivityChartData('single', null, res['items']);
            });

            // drivers data
            this.http.get(`api/internal/v1/statistics/driver-statistics?return_mode=dashboard&daysBeforeUntilDateCount=1&untilDateTime=${moment().subtract(1, 'days').format('YY-MM-DD')}&projectIds=${this.selectedProject.id}&getDriverAvatars=true`).subscribe(res => {
                this.setDriversChartData(res['items']);
            });

            // consignors data
            this.http.get(`api/internal/v1/statistics/collaborator-statistics?daysBeforeUntilDateCount=1&untilDateTime=${moment().subtract(1, 'days').format('YY-MM-DD')}&projectIds=${this.selectedProject.id}`).subscribe(res => {
                this.calculateConsignorStats(res['items']);
            });
        }
        // COLLABORATOR
        else {
            this.http.get(`api/internal/v1/statistics/partner-shipment-statistics?daysBeforeUntilDateCount=1&untilDateTime=${moment().subtract(1, 'days').format('YY-MM-DD')}&partnerCompanyIds=${this.selectedPartner.id}&groupBy=daily`).subscribe(res => {
                this.setShipmentsCollaboratorChartData('single', null, res['items'])
            });

            this.http.get(`api/internal/v1/statistics/partner-rating-statistics?daysBeforeUntilDateCount=1&untilDateTime=${moment().subtract(1, 'days').format('YY-MM-DD')}&partnerCompanyIds=${this.selectedPartner.id}&groupBy=daily`).subscribe(res => {
                this.setRatingCollaboratorChartData('single', null, res['items']);
            });
        }
    }

    // type: days, months, years, single
    setShipmentsChartData(type, fullMonthDates, dataShipments, dataIncome) {
        // resets
        this.hasFinishedLoadingShipmentsStats = true;
        this.resetShipments();

        switch (type) {
            case 'years':
                this.dashboardDummyComponent.dashboardShipmentsComponent.timeAxisEntries = fullMonthDates;
                break;
            case 'months':
                fullMonthDates.forEach(date => {
                    this.dashboardDummyComponent.dashboardShipmentsComponent.timeAxisEntries.push(moment(date).format('MM-YY'));
                });
                break;
            case 'days':
                fullMonthDates.forEach(date => {
                    this.dashboardDummyComponent.dashboardShipmentsComponent.timeAxisEntries.push(moment(date).format('DD-MM'));
                });
                break;
            case 'single':
                // time axis
                this.dashboardDummyComponent.dashboardShipmentsComponent.timeAxisEntries = [this.yesterdayLabel];

                // shipments (canceled/completed)
                if (dataShipments[moment().format('YYYY-MM-DD')]) {
                    this.dashboardDummyComponent.dashboardShipmentsComponent.shipmentsCompleted[0] = dataShipments[moment().format('YYYY-MM-DD')]['completedShipments'];
                    this.dashboardDummyComponent.dashboardShipmentsComponent.shipmentsCanceled[0] = dataShipments[moment().format('YYYY-MM-DD')]['canceledShipments'];
                }

                // income
                if (dataIncome[moment().format('YYYY-MM-DD')]) {
                    this.dashboardDummyComponent.dashboardShipmentsComponent.income[0] = Number(dataIncome[moment().format('YYYY-MM-DD')]['totalAmount'].toFixed(2));
                }

                // charts init
                // shipments
                if (this.shipmentsFilterSelected == 'shipments') {
                    this.dashboardDummyComponent.dashboardShipmentsComponent.initShipmentsChart();
                } else if (this.shipmentsFilterSelected == 'income') {
                    this.dashboardDummyComponent.dashboardShipmentsComponent.initIncomeChart();
                }
                break;
        }

        if (type != 'single') {
            // shipments (canceled/completed)
            Object.keys(dataShipments).forEach(date => {
                const index = fullMonthDates.indexOf(date);
                this.dashboardDummyComponent.dashboardShipmentsComponent.shipmentsCompleted[index] = dataShipments[date]['completedShipments'];
                this.dashboardDummyComponent.dashboardShipmentsComponent.shipmentsCanceled[index] = dataShipments[date]['canceledShipments'];
            });

            // income
            Object.keys(dataIncome).forEach(date => {
                const index = fullMonthDates.indexOf(date);
                this.dashboardDummyComponent.dashboardShipmentsComponent.income[index] = Number(dataIncome[date]['totalAmount'].toFixed(2));
            });

            // charts init
            if (this.shipmentsFilterSelected == 'shipments') {
                this.dashboardDummyComponent.dashboardShipmentsComponent.initShipmentsChart();
            } else if (this.shipmentsFilterSelected == 'income') {
                this.dashboardDummyComponent.dashboardShipmentsComponent.initIncomeChart();
            }
        }
    }

    // type: days, months, years, single
    setProductivityChartData(type, fullMonthDates, dataProductivity) {
        // resets
        this.hasFinishedLoadingProductivityStats = true;
        this.resetProductivity();

        // doughnut rating
        let totalRecipientRatingAvg = 0, totalRecipientRatingCount = 0;
        Object.keys(dataProductivity).forEach(statIndex => {
            if (dataProductivity[statIndex]['recipient_rating']) {
                totalRecipientRatingAvg += dataProductivity[statIndex]['recipient_rating'];
                totalRecipientRatingCount += 1;
            }
        });

        if (totalRecipientRatingAvg) {
            totalRecipientRatingAvg = (totalRecipientRatingAvg / totalRecipientRatingCount) / 2;
        }

        this.dashboardDummyComponent.dashboardRatingComponent.createRatingDoughnut(totalRecipientRatingAvg); // set rating doughnut for time period picked

        switch (type) {
            case 'years':
                this.dashboardDummyComponent.dashboardProductivityComponent.timeAxisEntries = fullMonthDates;
                break;
            case 'months':
                fullMonthDates.forEach(date => {
                    this.dashboardDummyComponent.dashboardProductivityComponent.timeAxisEntries.push(moment(date).format('MM-YY'));
                });
                break;
            case 'days':
                fullMonthDates.forEach(date => {
                    this.dashboardDummyComponent.dashboardProductivityComponent.timeAxisEntries.push(moment(date).format('DD-MM'));
                });
                break;
            case 'single':
                // time axis
                this.dashboardDummyComponent.dashboardProductivityComponent.timeAxisEntries = [this.yesterdayLabel];

                // dataset
                if (dataProductivity[moment().format('YYYY-MM-DD')]) {
                    this.dashboardDummyComponent.dashboardProductivityComponent.rating[0] = Number(dataProductivity[moment().format('YYYY-MM-DD')]['rating']).toFixed(2);
                    this.dashboardDummyComponent.dashboardProductivityComponent.driverShipmentsAvg[0] = Number(dataProductivity[moment().format('YYYY-MM-DD')]['averageShipmentsPerDriver']).toFixed(2);
                    this.dashboardDummyComponent.dashboardProductivityComponent.durationAvg[0] = Math.floor(moment.duration(dataProductivity[moment().format('YYYY-MM-DD')]['averageStopOverDurationPerShipment'], 'minutes').asMinutes());
                    this.dashboardDummyComponent.dashboardProductivityComponent.distanceAvg[0] = dataProductivity[moment().format('YYYY-MM-DD')]['totalDistance'];
                }

                // charts init
                if (this.productivityFilterSelected == 'rating') {
                    this.dashboardDummyComponent.dashboardProductivityComponent.initRatingChart();
                } else if (this.productivityFilterSelected == 'avgDurationStopPoint') {
                    this.dashboardDummyComponent.dashboardProductivityComponent.initAvgDurationStopPointChart();
                } else if (this.productivityFilterSelected == 'avgDeliveriesDriver') {
                    this.dashboardDummyComponent.dashboardProductivityComponent.initAvgDeliveriesDriver();
                } else {
                    this.dashboardDummyComponent.dashboardProductivityComponent.initAvgStopPointsDistance();
                }
                break;
        }

        if (type != 'single') {
            // dataset
            Object.keys(dataProductivity).forEach(date => {
                if (dataProductivity[date]) {
                    const index = fullMonthDates.indexOf(date);
                    this.dashboardDummyComponent.dashboardProductivityComponent.rating[index] = Number(dataProductivity[date]['rating'] > 5 ? 5 : dataProductivity[date]['rating']).toFixed(2);
                    this.dashboardDummyComponent.dashboardProductivityComponent.driverShipmentsAvg[index] = dataProductivity[date]['averageShipmentsPerDriver'];
                    this.dashboardDummyComponent.dashboardProductivityComponent.durationAvg[index] = Math.floor(moment.duration(dataProductivity[date]['averageStopOverDurationPerShipment'], 'minutes').asMinutes());
                    this.dashboardDummyComponent.dashboardProductivityComponent.distanceAvg[index] = Number((dataProductivity[date]['averageDistanceBetweenShipments'] / 1000).toFixed(1));
                }
            });

            // charts init
            if (this.productivityFilterSelected == 'rating') {
                this.dashboardDummyComponent.dashboardProductivityComponent.initRatingChart();
            } else if (this.productivityFilterSelected == 'avgDurationStopPoint') {
                this.dashboardDummyComponent.dashboardProductivityComponent.initAvgDurationStopPointChart();
            } else if (this.productivityFilterSelected == 'avgDeliveriesDriver') {
                this.dashboardDummyComponent.dashboardProductivityComponent.initAvgDeliveriesDriver();
            } else {
                this.dashboardDummyComponent.dashboardProductivityComponent.initAvgStopPointsDistance();
            }
        }
    }

    // type: days, months, years, single
    setShipmentsCollaboratorChartData(type, fullMonthDates, dataShipments) {
        // resets
        this.hasFinishedLoadingShipmentsStats = true;
        this.resetShipments();

        switch (type) {
            case 'years':
                this.dashboardDummyComponent.dashboardShipmentsComponent.timeAxisEntries = fullMonthDates;
                break;
            case 'months':
                fullMonthDates.forEach(date => {
                    this.dashboardDummyComponent.dashboardShipmentsComponent.timeAxisEntries.push(moment(date).format('MM-YY'));
                });
                break;
            case 'days':
                fullMonthDates.forEach(date => {
                    this.dashboardDummyComponent.dashboardShipmentsComponent.timeAxisEntries.push(moment(date).format('DD-MM'));
                });
                break;
            case 'single':
                // time axis
                this.dashboardDummyComponent.dashboardShipmentsComponent.timeAxisEntries = [this.yesterdayLabel];

                // shipments (canceled/completed)
                if (dataShipments[moment().format('YYYY-MM-DD')]) {
                    this.dashboardDummyComponent.dashboardShipmentsComponent.shipmentsCompleted[0] = dataShipments[moment().format('YYYY-MM-DD')]['completedShipments'];
                }

                // charts init
                if (this.shipmentsFilterSelected == 'shipments') {
                    this.dashboardDummyComponent.dashboardShipmentsComponent.initShipmentsChart();
                }
        }

        if (type != 'single') {
            // shipments (canceled/completed)
            Object.keys(dataShipments).forEach(date => {
                const index = fullMonthDates.indexOf(date);
                this.dashboardDummyComponent.dashboardShipmentsComponent.shipmentsCompleted[index] = dataShipments[date]['completedShipments'];
                this.dashboardDummyComponent.dashboardShipmentsComponent.shipmentsCanceled[index] = dataShipments[date]['canceledShipments'];
            });

            // charts init
            if (this.shipmentsFilterSelected == 'shipments') {
                this.dashboardDummyComponent.dashboardShipmentsComponent.initShipmentsChart();
            }
        }
    }

    // type: days, months, years, single
    setRatingCollaboratorChartData(type, fullMonthDates, dataRating) {
        // resets
        this.hasFinishedLoadingProductivityStats = true;
        this.resetProductivity();

        switch (type) {
            case 'years':
                this.dashboardDummyComponent.dashboardProductivityComponent.timeAxisEntries = fullMonthDates;
                break;
            case 'months':
                fullMonthDates.forEach(date => {
                    this.dashboardDummyComponent.dashboardProductivityComponent.timeAxisEntries.push(moment(date).format('MM-YY'));
                });
                break;
            case 'days':
                fullMonthDates.forEach(date => {
                    this.dashboardDummyComponent.dashboardProductivityComponent.timeAxisEntries.push(moment(date).format('DD-MM'));
                });
                break;
            case 'single':
                // time axis
                this.dashboardDummyComponent.dashboardProductivityComponent.timeAxisEntries = [this.yesterdayLabel];

                // rating
                if (dataRating[moment().format('YYYY-MM-DD')]) {
                    this.dashboardDummyComponent.dashboardProductivityComponent.rating[0] = Number(dataRating[moment().format('YYYY-MM-DD')]['averageRouteRating'].toFixed(2));
                    this.dashboardDummyComponent.dashboardProductivityComponent.recipientsRating[0] = Number(dataRating[moment().format('YYYY-MM-DD')]['averageRecipientsRating'] / 2).toFixed(2);

                    // rating doughnut chart init
                    if (dataRating) {
                        this.dashboardDummyComponent.dashboardRatingComponent.createRatingDoughnut(dataRating[moment().format('YYYY-MM-DD')]['averageRecipientsRating']);
                    }
                }

                // partners chart
                if (this.globals.partnersArray.length) {
                    this.dashboardDummyComponent.dashboardConsignorsComponent.partnersData = [this.globals.partnersArray[0]['name']];
                    this.dashboardDummyComponent.dashboardConsignorsComponent.partnersRatingData = [100];
                    this.dashboardDummyComponent.dashboardConsignorsComponent.consignorColourData = [this.colourService.pieChartColours[0]];
                    this.dashboardDummyComponent.dashboardConsignorsComponent.initPartnersChart();
                }

                // charts init
                if (this.productivityFilterSelected == 'rating') {
                    this.dashboardDummyComponent.dashboardProductivityComponent.initRatingChart();
                } else {
                    this.dashboardDummyComponent.dashboardProductivityComponent.initRecipientsRatingChart();
                }
        }

        if (type != 'single') {
            // rating
            let avgRecipientRatingTotal = 0;
            let recipientRatingsCount = 0;
            let avgRouteRatingTotal = 0;
            let routeRatingsCount = 0;

            Object.keys(dataRating).forEach(date => {
                if (dataRating[date]['averageRecipientsRating'] || dataRating[date]['averageRouteRating']) {
                    const index = fullMonthDates.indexOf(date);
                    this.dashboardDummyComponent.dashboardProductivityComponent.rating[index] = Number(dataRating[date]['averageRouteRating']).toFixed(2);
                    this.dashboardDummyComponent.dashboardProductivityComponent.recipientsRating[index] = Number(dataRating[date]['averageRecipientsRating'] / 2).toFixed(2);
                    // doughnut rating
                    avgRouteRatingTotal += dataRating[date]['averageRouteRating'];
                    avgRecipientRatingTotal += dataRating[date]['averageRecipientsRating'];

                    if (dataRating[date]['averageRouteRating']) {
                        routeRatingsCount += 1;
                    }

                    if (dataRating[date]['averageRecipientsRating']) {
                        recipientRatingsCount += 1;
                    }
                }
            });

            if (!routeRatingsCount) {
                routeRatingsCount = 1;
            }
            if (!recipientRatingsCount) {
                recipientRatingsCount = 1;
            }

            avgRecipientRatingTotal = avgRecipientRatingTotal / recipientRatingsCount;
            avgRouteRatingTotal = avgRouteRatingTotal / routeRatingsCount;

            // rating chart init
            if (dataRating) {
                if (this.globals.collaboratorModeEnabled) {
                    this.dashboardDummyComponent.dashboardRatingComponent.createRatingDoughnut(avgRouteRatingTotal);
                } else {
                    this.dashboardDummyComponent.dashboardRatingComponent.createRatingDoughnut(avgRecipientRatingTotal / 2);
                }
            }

            // partners chart
            if (this.globals.partnersArray.length) {
                this.dashboardDummyComponent.dashboardConsignorsComponent.partnersData = [this.globals.partnersArray[0]['name']];
                this.dashboardDummyComponent.dashboardConsignorsComponent.partnersRatingData = [100];
                this.dashboardDummyComponent.dashboardConsignorsComponent.consignorColourData = [this.colourService.pieChartColours[0]];
                this.dashboardDummyComponent.dashboardConsignorsComponent.initPartnersChart();
            }

            // charts init
            if (this.productivityFilterSelected == 'rating') {
                this.dashboardDummyComponent.dashboardProductivityComponent.initRatingChart();
            } else {
                this.dashboardDummyComponent.dashboardProductivityComponent.initRecipientsRatingChart();
            }
        }
    }

    setDriversChartData(driversStats) {
        this.hasFinishedLoadingDriversStats = true;

        this.dashboardDummyComponent.dashboardDriversComponent.allDriversData = driversStats;
        // this.dashboardDummyComponent.dashboardDriversComponent.metaPagesCount = Math.floor(driversStats['ratings'].length / 5);
        this.dashboardDummyComponent.dashboardDriversComponent.metaPagesCount = driversStats['ratings'].length < 5 ? 1 : Math.ceil(driversStats['ratings'].length / 5);

        // get first 5 items
        this.dashboardDummyComponent.dashboardDriversComponent.currentPage = 0;

        this.dashboardDummyComponent.dashboardDriversComponent.driversNamesCurrentData = [];
        this.dashboardDummyComponent.dashboardDriversComponent.driversRatingCurrentData = [];
        this.dashboardDummyComponent.dashboardDriversComponent.driversDurationCurrentData = [];
        this.dashboardDummyComponent.dashboardDriversComponent.driversShipmentsCurrentData = [];
        this.dashboardDummyComponent.dashboardDriversComponent.driversRecipientsRatingCurrentData = [];
        this.dashboardDummyComponent.dashboardDriversComponent.driversAvatarsCurrentData = [];

        if (driversStats['ratings'].length) {
            let loopAmount = 0;
            if (driversStats['ratings'].length >= 5) {
                loopAmount = 5;
            } else {
                loopAmount = driversStats['ratings'].length;
            }

            for (let driverIndex = loopAmount * this.dashboardDummyComponent.dashboardDriversComponent.currentPage; driverIndex < loopAmount * (this.dashboardDummyComponent.dashboardDriversComponent.currentPage + 1); driverIndex++) {
                // get names
                this.dashboardDummyComponent.dashboardDriversComponent.driversNamesCurrentData.push(driversStats['driver_id_to_driver_info_map'][driversStats['ratings'][driverIndex]['driver_id']]['name']);

                this.dashboardDummyComponent.dashboardDriversComponent.driversAvatarsCurrentData.push(this.globals.driverDefaultBase64);
                if (driversStats['driver_id_to_driver_info_map'][driversStats['ratings'][driverIndex]['driver_id']]['imageHash']) {
                    // get driver image asyncronously
                    const imageHash = driversStats['driver_id_to_driver_info_map'][driversStats['ratings'][driverIndex]['driver_id']]['imageHash'];
                    this.http.get(`api/internal/v1/images/drivers/${imageHash}`).subscribe(avatars => {
                        this.dashboardDummyComponent.dashboardDriversComponent.driversAvatarsCurrentData[driverIndex] = avatars['base64']
                    });
                }

                // rating
                const rating = driversStats['ratings'][driverIndex]['value'] > 5 ? 5 : driversStats['ratings'][driverIndex]['value'];
                if (rating) {
                    this.dashboardDummyComponent.dashboardDriversComponent.driversRatingCurrentData.push(rating.toFixed(2));
                } else {
                    this.dashboardDummyComponent.dashboardDriversComponent.driversRatingCurrentData.push(0);
                }

                // shipments
                this.dashboardDummyComponent.dashboardDriversComponent.driversShipmentsCurrentData.push(driversStats['average_shipments_per_day_array'][driverIndex]['value']);

                // duration
                this.dashboardDummyComponent.dashboardDriversComponent.driversDurationCurrentData.push(Math.floor(moment.duration(driversStats['average_stop_over_duration_per_shipment_array'][driverIndex]['value'], 'seconds').asMinutes()));

                // recipients rating
                const recipientRating = driversStats['recipient_ratings'][driverIndex]['value'] / 2 > 5 ? 5 : driversStats['recipient_ratings'][driverIndex]['value'] / 2;
                if (recipientRating) {
                    this.dashboardDummyComponent.dashboardDriversComponent.driversRecipientsRatingCurrentData.push(recipientRating.toFixed(2));
                } else {
                    this.dashboardDummyComponent.dashboardDriversComponent.driversRecipientsRatingCurrentData.push(0);
                }
            }
        }

        if (this.driversFilterSelected == 'rating') {
            this.dashboardDummyComponent.dashboardDriversComponent.initRatingChart();
        } else if (this.driversFilterSelected == 'avgDeliveries') {
            this.dashboardDummyComponent.dashboardDriversComponent.initAvgDeliveries();
        } else if (this.driversFilterSelected == 'avgDurationStopPoint') {
            this.dashboardDummyComponent.dashboardDriversComponent.initAvgDurationStopPoint();
        } else {
            this.dashboardDummyComponent.dashboardDriversComponent.initRecipientsRating();
        }
    }

    checkForNextActivityPage() {
        const elem = document.getElementById('activity-container');
        if (elem.scrollHeight / 1.2 < elem.scrollTop + elem.clientHeight && !this.isActivityLogsLoading && !this.hasLoadedAllActivityPages) {
            this.setActivityLog(); // pages are fetched via lastObtainedId
        }
    }

    setActivityLog() {
        let activityUrl;
        if (this.lastObtainedActivityId) {
            activityUrl = 'api/internal/v1/activityLog/activity-logs?lastObtainedId=' + this.lastObtainedActivityId;
        } else {
            activityUrl = 'api/internal/v1/activityLog/activity-logs';
        }

        this.isActivityLogsLoading = true;
        this.http.get(activityUrl).subscribe(logsRes => {
            // make sure dom has loaded
            setTimeout(() => {
                this.isActivityLogsLoading = false;

                if (logsRes) {
                    if (logsRes['items']) {
                        let activities = [];

                        this.lastObtainedActivityId = null;
                        if (logsRes['items']['activityLogs'].length) {
                            logsRes['items']['activityLogs'].forEach(log => {
                                this.lastObtainedActivityId = log['id'];

                                // time label
                                let timeLabel;
                                let timezone = moment.tz.guess();
                                let time = moment.utc(log['creation_datetime']).tz(timezone).format('HH:mm');
                                // let date = moment.utc(log['creation_datetime']).tz(timezone).format('DD-MM'); // can be used for older than 2 days entries
                                let hoursDiff = moment().diff(moment.utc(log['creation_datetime']).tz(timezone), 'hours');
                                let minsDiff = moment().diff(moment.utc(log['creation_datetime']).tz(timezone), 'minutes');

                                if (hoursDiff > 24 && hoursDiff < 48) {
                                    timeLabel = this.yesterdayLabel.toLowerCase();
                                } else if (hoursDiff >= 48 && hoursDiff < 72) {
                                    timeLabel = this.twoDaysAgoLabel.toLowerCase();
                                } else if (hoursDiff >= 72) {
                                    timeLabel = moment.utc(log['creation_datetime']).tz(timezone).format('DD/MM');
                                }
                                else if (minsDiff < 5) {
                                    timeLabel = this.nowLabel.toLowerCase();
                                } else {
                                    timeLabel = time;
                                }

                                // driver event log
                                if (log['driver_id']) {
                                    let userName, initials, imageHash;
                                    logsRes['items']['userProfiles'].forEach(user => {
                                        if (log['driver_id'] == user['driver_id']) {
                                            userName = user['name'];
                                            imageHash = user['hash'];
                                        }
                                    });

                                    if (log['is_from_mily']) {
                                        userName = 'Mily';
                                        initials = 'M';
                                    } else {
                                        // will check for avatar first & if not found then find initials of name
                                        if (userName) {
                                            const names = userName.split(' ');
                                            if (names.length > 1) {
                                                initials = (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
                                            } else {
                                                initials = names[0].charAt(0).toUpperCase();
                                            }
                                        } else {
                                            initials = '-';
                                        }
                                    }

                                    // pp departure datetime
                                    let projectProblemDepartureDatetime;
                                    logsRes['items']['projectProblems'].forEach(projectProblem => {
                                        if (projectProblem['id'] == log['driver_project_problem_id']) {
                                            projectProblemDepartureDatetime = projectProblem['departure_datetime'];
                                        }
                                    });

                                    activities.push({
                                        id: log['id'],
                                        time: timeLabel,
                                        hoverTimeLabel: timeLabel == this.yesterdayLabel || timeLabel == this.twoDaysAgoLabel ? timeLabel + ' ' + time : time,
                                        title: userName,
                                        avatar: null,
                                        imageHash: imageHash,
                                        text: this.driverFinishRouteLabel,
                                        type: 'route',
                                        isFromMily: log['is_from_mily'],
                                        initials: initials,
                                        driverId: log['driver_id'],
                                        projectProblemDepartureDatetime: projectProblemDepartureDatetime,
                                        projectProblemId: log['driver_project_problem_id']
                                    });
                                }
                                // invoicing event log
                                else if (log['invoice_id']) {
                                    let userName, collaboratorName, initials, imageHash;
                                    if (this.globals.collaboratorModeEnabled) {
                                        logsRes['items']['companies'].forEach(partner => {
                                            if (log['partner_company_id'] == partner['id']) {
                                                userName = partner['name'];
                                                imageHash = partner['hash'];
                                            }
                                        });
                                    } else {
                                        logsRes['items']['userProfiles'].forEach(user => {
                                            if (log['invoice_user_profile_id'] == user['id']) {
                                                userName = user['name'];
                                                imageHash = user['hash'];
                                            }
                                        });
                                    }

                                    logsRes['items']['collaborators'].forEach(collaborator => {
                                        if (log['collaborator_id'] == collaborator['id']) {
                                            collaboratorName = collaborator['collaborator_name'];
                                        }
                                    });

                                    // collaborator -> get due on datetime & creation datetime
                                    let dueOnDatetimeValue, creationDatetimeValue;
                                    if (this.globals.collaboratorModeEnabled) {
                                        logsRes['items']['invoices'].forEach(invoice => {
                                            if (invoice['id'] == log['invoice_id']) {
                                                creationDatetimeValue = moment(invoice['creation_datetime']).format('DD/MM');
                                                dueOnDatetimeValue = moment(invoice['due_on_datetime']).format('DD/MM');
                                            }
                                        });
                                    }

                                    let invoicingLabelValue;
                                    if (!this.globals.collaboratorModeEnabled) {
                                        invoicingLabelValue = this.invoicingLabel.replace('COLLABORATOR_COMPANY_NAME', collaboratorName);
                                    } else {
                                        invoicingLabelValue = this.invoicingCollaboratorLabel.replace('START_DATE', creationDatetimeValue).replace('END_DATE', dueOnDatetimeValue);
                                    }

                                    let isFromMily;
                                    if (log['is_from_mily'] && !this.globals.collaboratorModeEnabled) {
                                        isFromMily = true;
                                        userName = 'Mily';
                                        initials = 'M';
                                    } else {
                                        // will check for avatar first & if not found then find initials of name
                                        if (userName) {
                                            const names = userName.split(' ');
                                            if (names.length > 1) {
                                                initials = (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
                                            } else {
                                                initials = names[0].charAt(0).toUpperCase();
                                            }
                                        } else {
                                            initials = '-';
                                        }
                                    }

                                    // collaborator value
                                    let collaboratorValue;
                                    if (this.globals.collaboratorModeEnabled) {
                                        collaboratorValue = log['partner_company_id'];
                                    } else {
                                        collaboratorValue = log['collaborator_id'];
                                    }

                                    activities.push({
                                        id: log['id'],
                                        time: timeLabel,
                                        hoverTimeLabel: timeLabel == this.yesterdayLabel || timeLabel == this.twoDaysAgoLabel ? timeLabel + ' ' + time : time,
                                        title: userName,
                                        avatar: null,
                                        imageHash: imageHash,
                                        text: invoicingLabelValue,
                                        type: 'invoice',
                                        isFromMily: isFromMily,
                                        initials: initials,
                                        collaboratorId: collaboratorValue,
                                        invoiceId: log['invoice_id']
                                    });
                                }
                                // pay on delivery event log
                                else if (log['pay_on_delivery_id']) {
                                    let userName, collaboratorName, initials, imageHash;
                                    if (this.globals.collaboratorModeEnabled) {
                                        logsRes['items']['companies'].forEach(partner => {
                                            if (log['partner_company_id'] == partner['id']) {
                                                userName = partner['name'];
                                                imageHash = partner['hash'];
                                            }
                                        });
                                    } else {
                                        logsRes['items']['userProfiles'].forEach(user => {
                                            if (log['pay_on_delivery_user_profile_id'] == user['id']) {
                                                userName = user['name'];
                                                imageHash = user['hash'];
                                            }
                                        });
                                    }

                                    logsRes['items']['collaborators'].forEach(collaborator => {
                                        if (log['collaborator_id'] == collaborator['id']) {
                                            collaboratorName = collaborator['collaborator_name'];
                                        }
                                    });

                                    // collaborator -> get due on datetime & creation datetime
                                    let dueOnDatetimeValue;
                                    if (this.globals.collaboratorModeEnabled) {
                                        logsRes['items']['payOnDeliveries'].forEach(pod => {
                                            if (pod['id'] == log['pay_on_delivery_id']) {
                                                dueOnDatetimeValue = moment(pod['due_on_datetime']).format('DD/MM');
                                            }
                                        });
                                    }

                                    let podLabelValue;
                                    if (!this.globals.collaboratorModeEnabled) {
                                        podLabelValue = this.paymentLabel.replace('COLLABORATOR_COMPANY_NAME', collaboratorName);
                                    } else {
                                        podLabelValue = this.paymentCollaboratorLabel.replace('DUE_ON_DATE', dueOnDatetimeValue);
                                    }

                                    let isFromMily;
                                    if (log['is_from_mily'] && !this.globals.collaboratorModeEnabled) {
                                        isFromMily = true;
                                        userName = 'Mily';
                                        initials = 'M';
                                    } else {
                                        // will check for avatar first & if not found then find initials of name
                                        if (userName) {
                                            const names = userName.split(' ');
                                            if (names.length > 1) {
                                                initials = (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
                                            } else {
                                                initials = names[0].charAt(0).toUpperCase();
                                            }
                                        } else {
                                            initials = '-';
                                        }
                                    }

                                    // collaborator value
                                    let collaboratorValue;
                                    if (this.globals.collaboratorModeEnabled) {
                                        collaboratorValue = log['partner_company_id'];
                                    } else {
                                        collaboratorValue = log['collaborator_id'];
                                    }

                                    activities.push({
                                        id: log['id'],
                                        time: timeLabel,
                                        hoverTimeLabel: timeLabel == this.yesterdayLabel || timeLabel == this.twoDaysAgoLabel ? timeLabel + ' ' + time : time,
                                        title: userName,
                                        avatar: null,
                                        imageHash: imageHash,
                                        text: podLabelValue,
                                        type: 'payment',
                                        isFromMily: isFromMily,
                                        initials: initials,
                                        collaboratorId: collaboratorValue,
                                        paymentId: log['pay_on_delivery_id']
                                    });
                                }
                                // collaborator invite & handling for collaborator portal
                                else if (log['collaborator_invite_collaborator_id']) {
                                    let userName, avatar, collaboratorName, initials, imageHash;

                                    logsRes['items']['userProfiles'].forEach(user => {
                                        if (log['collaborator_invite_user_profile_id'] == user['id']) {
                                            userName = user['name'];
                                            // avatar = user['avatar'];
                                            imageHash = user['hash'];
                                        }
                                    });

                                    logsRes['items']['collaborators'].forEach(collaborator => {
                                        if (log['collaborator_invite_collaborator_id'] == collaborator['id']) {
                                            collaboratorName = collaborator['collaborator_name'];
                                        }
                                    });


                                    if (log['is_from_mily']) {
                                        userName = 'Mily';
                                        initials = 'M';
                                    } else {
                                        // will check for avatar first & if not found then find initials of name
                                        if (userName) {
                                            const names = userName.split(' ');
                                            if (names.length > 1) {
                                                initials = (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
                                            } else {
                                                initials = names[0].charAt(0).toUpperCase();
                                            }
                                        } else {
                                            initials = '-';
                                        }
                                    }

                                    // invite types
                                    let inviteLabel;
                                    if (log['collaborator_invite_type'] == this.globals.dashboardActivityCollaboratorInviteConstants['ACCEPTED']) {
                                        inviteLabel = this.inviteAcceptedLabel.replace('COLLABORATOR_COMPANY_NAME', collaboratorName);
                                    } else {
                                        inviteLabel = this.inviteSentLabel.replace('COLLABORATOR_COMPANY_NAME', collaboratorName);
                                    }

                                    activities.push({
                                        id: log['id'],
                                        time: timeLabel,
                                        hoverTimeLabel: timeLabel == this.yesterdayLabel || timeLabel == this.twoDaysAgoLabel ? timeLabel + ' ' + time : time,
                                        title: userName,
                                        avatar: avatar,
                                        imageHash: imageHash,
                                        text: inviteLabel,
                                        type: 'collaborator',
                                        collaboratorId: log['collaborator_invite_collaborator_id'],
                                        isFromMily: log['is_from_mily'],
                                        initials: initials
                                    });
                                }
                                // collaborator shipments (courier sees these for their collaborators)
                                else if (log['collaborator_shipments_collaborator_id'] || log['collaborator_shipments_type'] || log['collaborator_shipments_type'] === 0) {
                                    let userName, initials, shipmentsLabel, collaboratorName, imageHash;
                                    logsRes['items']['userProfiles'].forEach(user => {
                                        if (log['collaborator_shipments_user_profile_id'] == user['id']) {
                                            userName = user['name'];
                                            imageHash = user['hash'];
                                        }
                                    });

                                    // if in collaborator portal -> use own company as collaborator
                                    if (this.globals.collaboratorModeEnabled) {
                                        logsRes['items']['companies'].forEach(company => {
                                            if (log['collaborator_shipments_partner_company_id'] == company['id']) {
                                                collaboratorName = company['name'];
                                            }
                                        });
                                    } else {
                                        logsRes['items']['collaborators'].forEach(collaborator => {
                                            if (log['collaborator_shipments_collaborator_id'] == collaborator['id']) {
                                                collaboratorName = collaborator['collaborator_name'];
                                            }
                                        });
                                    }

                                    if (log['is_from_mily']) {
                                        userName = 'Mily';
                                        initials = 'M';
                                    } else {
                                        // will check for avatar first & if not found then find initials of name
                                        if (userName) {
                                            const names = userName.split(' ');
                                            if (names.length > 1) {
                                                initials = (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
                                            } else {
                                                initials = names[0].charAt(0).toUpperCase();
                                            }
                                        } else {
                                            initials = '-';
                                        }
                                    }

                                    // status types
                                    // collaborator
                                    if (this.globals.collaboratorModeEnabled) {
                                        if (log['collaborator_shipments_type'] == this.globals.dashboardActivityCollaboratorShipmentsConstants['ACCEPTED']) {
                                            shipmentsLabel = this.shipmentsAcceptedLabel.replace('COLLABORATOR_COMPANY_NAME', collaboratorName);
                                        } else if (log['collaborator_shipments_type'] == this.globals.dashboardActivityCollaboratorShipmentsConstants['THINK']) {
                                            shipmentsLabel = this.shipmentsCollaboratorThinkLabel.replace('COLLABORATOR_COMPANY_NAME', collaboratorName);
                                        } else {
                                            shipmentsLabel = this.shipmentsCollaboratorCompletedLabel;
                                        }
                                    }
                                    // courier
                                    else {
                                        if (log['collaborator_shipments_type'] == this.globals.dashboardActivityCollaboratorShipmentsConstants['ACCEPTED']) {
                                            shipmentsLabel = this.shipmentsAcceptedLabel;
                                        } else if (log['collaborator_shipments_type'] == this.globals.dashboardActivityCollaboratorShipmentsConstants['THINK']) {
                                            shipmentsLabel = this.shipmentsThinkLabel;
                                        } else {
                                            shipmentsLabel = this.shipmentsCompletedLabel;
                                        }
                                    }

                                    activities.push({
                                        id: log['id'],
                                        time: timeLabel,
                                        hoverTimeLabel: timeLabel == this.yesterdayLabel || timeLabel == this.twoDaysAgoLabel ? timeLabel + ' ' + time : time,
                                        title: userName,
                                        avatar: null,
                                        imageHash: imageHash,
                                        text: shipmentsLabel.replace('COLLABORATOR_COMPANY_NAME', collaboratorName),
                                        type: 'shipments',
                                        isFromMily: log['is_from_mily'],
                                        initials: initials,
                                        status: log['collaborator_shipments_type']
                                    });
                                }
                                // project problem
                                else if (log['project_problem_id']) {
                                    let userName, initials, projectLabel, projectName, projectProblemDatetime, imageHash;
                                    logsRes['items']['userProfiles'].forEach(user => {
                                        if (log['project_problem_user_profile_id'] == user['id']) {
                                            userName = user['name'];
                                            imageHash = user['hash'];
                                        }
                                    });

                                    if (log['is_from_mily']) {
                                        userName = 'Mily';
                                        initials = 'M';
                                    } else {
                                        // will check for avatar first & if not found then find initials of name
                                        if (userName) {
                                            const names = userName.split(' ');
                                            if (names.length > 1) {
                                                initials = (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
                                            } else {
                                                initials = names[0].charAt(0).toUpperCase();
                                            }
                                        } else {
                                            initials = '-';
                                        }
                                    }

                                    // project name & project problem time
                                    logsRes['items']['projectProblems'].forEach(projectProblem => {
                                        if (log['project_problem_id'] == projectProblem['id']) {
                                            projectName = projectProblem['project_title'];
                                            projectProblemDatetime = projectProblem['departure_datetime'];
                                        }
                                    });

                                    // status types
                                    if (log['project_problem_type'] == this.globals.dashboardActivityProjectProblemConstants['ADD_SHIPMENTS']) {
                                        projectLabel = this.projectAddShipmentsLabel.replace('PROJECT_NAME', projectName);
                                    } else if (log['project_problem_type'] == this.globals.dashboardActivityProjectProblemConstants['THINK']) {
                                        projectLabel = this.projectThinkLabel.replace('PROJECT_NAME', projectName);
                                    } else if (log['project_problem_type'] == this.globals.dashboardActivityProjectProblemConstants['THINK_AUTO']) {
                                        projectLabel = this.projectThinkAutoLabel.replace('PROJECT_NAME', projectName);
                                    } else {
                                        projectLabel = this.projectCompletedLabel.replace('PROJECT_NAME', projectName);
                                    }

                                    activities.push({
                                        id: log['id'],
                                        time: timeLabel,
                                        hoverTimeLabel: timeLabel == this.yesterdayLabel || timeLabel == this.twoDaysAgoLabel ? timeLabel + ' ' + time : time,
                                        title: userName,
                                        avatar: null,
                                        imageHash: imageHash,
                                        text: projectLabel,
                                        type: 'project',
                                        isFromMily: log['is_from_mily'],
                                        initials: initials,
                                        projectProblemId: log['project_problem_id'],
                                        projectProblemStatus: log['project_problem_type'],
                                        projectName: projectName,
                                        projectProblemDatetime: projectProblemDatetime
                                    });
                                }
                                // pickup request
                                else if (log['collaborator_pickup_request_collaborator_id']) {
                                    let userName, initials, imageHash;
                                    logsRes['items']['collaborators'].forEach(collaborator => {
                                        if (log['collaborator_pickup_request_collaborator_id'] == collaborator['id']) {
                                            userName = collaborator['collaborator_name'];
                                            imageHash = collaborator['hash'];
                                        }
                                    });

                                    if (log['is_from_mily']) {
                                        userName = 'Mily';
                                        initials = 'M';
                                    } else {
                                        // will check for avatar first & if not found then find initials of name
                                        if (userName) {
                                            const names = userName.split(' ');
                                            if (names.length > 1) {
                                                initials = (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
                                            } else {
                                                initials = names[0].charAt(0).toUpperCase();
                                            }
                                        } else {
                                            initials = '-';
                                        }
                                    }

                                    activities.push({
                                        id: log['id'],
                                        time: timeLabel,
                                        hoverTimeLabel: timeLabel == this.yesterdayLabel || timeLabel == this.twoDaysAgoLabel ? timeLabel + ' ' + time : time,
                                        title: userName,
                                        avatar: null,
                                        imageHash: imageHash,
                                        text: this.requestedNewPickupsLabel,
                                        type: 'pickups',
                                        isFromMily: log['is_from_mily'],
                                        initials: initials,
                                        projectProblemId: log['project_problem_id'],
                                        projectProblemStatus: log['project_problem_type']
                                    });
                                }
                            });
                        }
                        // if an empty array is returned, then determine that everything has been loaded
                        else {
                            this.hasLoadedAllActivityPages = true;
                        }

                        activities = [...activities];
                        // this.dashboardDummyComponent.dashboardActivityComponent.activities = [...activities];
                        this.dashboardDummyComponent.dashboardActivityComponent.activities = this.dashboardDummyComponent.dashboardActivityComponent.activities.concat(activities);
                        this.dashboardDummyComponent.dashboardActivityComponent.changeDetectorRef.detectChanges(); // (on-push specific) force-update dom
                    }
                }

                // load activities avatars
                this.loadActivitiesAvatarsAsync();
            }, 500);

        });
    }

    loadActivitiesAvatarsAsync() {
        const activities = this.dashboardDummyComponent.dashboardActivityComponent.activities;
        const hashesAlreadyGot = [];

        if (activities.length) {
            // if an imageHash exists, perform a request to the appropriate endpoint for the avatar based on the activity type
            activities.forEach((activity) => {
                let url = '';
                if (activity.imageHash) {
                    if (activity.type == 'route') {
                        if (!this.globals.collaboratorModeEnabled) {
                            url = `api/internal/v1/images/drivers/${activity.imageHash}`;
                        } else {
                            url = `api/internal/v1/images/partner-drivers/${activity.imageHash}`;
                        }
                    } else if (activity.type == 'invoice' || activity.type == 'payment' || activity.type == 'pickups') {
                        if (!this.globals.collaboratorModeEnabled) {
                            url = `api/internal/v1/images/company-logo/${activity.imageHash}`;
                        } else {
                            url = `api/internal/v1/images/partner-company-logo/${activity.imageHash}`;
                        }
                    } else if (activity.type == 'collaborator' || activity.type == 'project' || activity.type == 'shipments') {
                        if (!this.globals.collaboratorModeEnabled) {
                            url = `api/internal/v1/images/user-profile/${activity.imageHash}`;
                        } else {
                            url = `api/internal/v1/images/partner-user-profile/${activity.imageHash}`;
                        }
                    }

                    if (!hashesAlreadyGot.includes(activity.imageHash)) {
                        hashesAlreadyGot.push(activity.imageHash);
                        // perform request
                        this.http.get(url).subscribe(image => {
                            this.showAvatar(activity.imageHash, image['base64'])
                        });
                    }
                }
            });

        }
    }

    showAvatar(hash, base64) {
        const activities = this.dashboardDummyComponent.dashboardActivityComponent.activities;
        if (activities.length) {
            activities.forEach((activity, index) => {
                if (activity.imageHash == hash) {
                    this.dashboardDummyComponent.dashboardActivityComponent.activities[index]['avatar'] = base64;
                }
            });
        }
        this.dashboardDummyComponent.dashboardActivityComponent.activities = [...activities];
        this.dashboardDummyComponent.dashboardActivityComponent.changeDetectorRef.detectChanges();
    }

    initializeCharts() {
        // initial time filter
        let initialTimeFilterInterval = setInterval(() => {
            if (this.dashboardDummyComponent.dashboardShipmentsComponent &&
                this.dashboardDummyComponent.dashboardProductivityComponent &&
                this.dashboardDummyComponent.dashboardDriversComponent &&
                this.dashboardDummyComponent.dashboardProjectsLiveComponent &&
                this.dashboardDummyComponent.dashboardConsignorsComponent &&
                this.dashboardDummyComponent.dashboardActivityComponent) {
                // this.filterByTime('month');
                this.setChartsWeek();
                this.setActivityLog();
                this.currentTimeValue = 'week';
                clearInterval(initialTimeFilterInterval);
                initialTimeFilterInterval = null;
            } else if (this.globals.collaboratorModeEnabled) {
                if (this.dashboardDummyComponent.dashboardShipmentsComponent &&
                    this.dashboardDummyComponent.dashboardProductivityComponent &&
                    this.dashboardDummyComponent.dashboardActivityComponent &&
                    this.dashboardDummyComponent.dashboardConsignorsComponent) {
                    // this.filterByTime('month');
                    this.setChartsWeek();
                    this.setActivityLog();
                    this.currentTimeValue = 'week';
                    clearInterval(initialTimeFilterInterval);
                    initialTimeFilterInterval = null;
                }
            }
        }, 500);
    }

    resetShipments() {
        this.dashboardDummyComponent.dashboardShipmentsComponent.timeAxisEntries = [];
        this.dashboardDummyComponent.dashboardShipmentsComponent.shipmentsCompleted = [];
        this.dashboardDummyComponent.dashboardShipmentsComponent.shipmentsCanceled = [];
        this.dashboardDummyComponent.dashboardShipmentsComponent.income = [];
    }

    resetProductivity() {
        this.dashboardDummyComponent.dashboardProductivityComponent.timeAxisEntries = [];
        this.dashboardDummyComponent.dashboardProductivityComponent.rating = [];
        this.dashboardDummyComponent.dashboardProductivityComponent.recipientsRating = [];
        this.dashboardDummyComponent.dashboardProductivityComponent.distanceAvg = [];
        this.dashboardDummyComponent.dashboardProductivityComponent.driverShipmentsAvg = [];
        this.dashboardDummyComponent.dashboardProductivityComponent.durationAvg = [];

        // this.dashboardDummyComponent.dashboardRatingComponent.createRatingDoughnut(0);
    }

    calculateConsignorStats(data) {
        this.hasFinishedLoadingCollaboratorsStats = true;

        this.dashboardDummyComponent.dashboardConsignorsComponent.consignorsData = [];
        this.dashboardDummyComponent.dashboardConsignorsComponent.shipmentsData = [];
        // this.dashboardDummyComponent.dashboardConsignorsComponent.consignorColourData = [];
        this.dashboardDummyComponent.dashboardConsignorsComponent.consignorColourData = [this.colourService.pieChartColours[0], this.colourService.pieChartColours[1], this.colourService.pieChartColours[2]];

        if (data) {
            let totals = [];
            Object.keys(data).forEach((name, index) => {
                this.dashboardDummyComponent.dashboardConsignorsComponent.consignorsData.push(name);
                this.dashboardDummyComponent.dashboardConsignorsComponent.consignorColourData.push(this.colourService.calculatePieChartColor(index));
                totals[index] = data[name]['total_shipments_count'];
            });
            this.dashboardDummyComponent.dashboardConsignorsComponent.shipmentsData = this.dashboardDummyComponent.dashboardConsignorsComponent.getPercentagesPerCollaborator(totals);


            // group collaborators with <2% total shipments
            const calculatedPercentagesData = [...this.dashboardDummyComponent.dashboardConsignorsComponent.shipmentsData];
            const calculatedConsignorsData = [...this.dashboardDummyComponent.dashboardConsignorsComponent.consignorsData];
            const calculatedColoursData = [...this.dashboardDummyComponent.dashboardConsignorsComponent.consignorColourData];
            const groupPercentageThreshold = 2; // if less than this value, then the collaborator will be merged into 'Others' group
            let otherCollaboratorsPercentageTotal = 0;
            let indexesToRemove = [];
            calculatedPercentagesData.forEach((percentage, index) => {
                if (percentage < groupPercentageThreshold) {
                    otherCollaboratorsPercentageTotal += percentage;
                    indexesToRemove.push(index);
                }
            });

            let finalShipmentsArray = calculatedPercentagesData.filter((percentage, index) => !indexesToRemove.includes(index));
            let finalConsignorsArray = calculatedConsignorsData.filter((name, index) => !indexesToRemove.includes(index));
            let finalColoursArray = calculatedColoursData.filter((colour, index) => !indexesToRemove.includes(index));

            if (otherCollaboratorsPercentageTotal > 0) {
                finalShipmentsArray.push(Number(otherCollaboratorsPercentageTotal.toFixed(2)));
                finalConsignorsArray.push(this.chartOthersLabel);
                finalColoursArray.push(this.colourService.calculatePieChartColor(finalColoursArray.length));
            }

            this.dashboardDummyComponent.dashboardConsignorsComponent.shipmentsData = finalShipmentsArray;
            this.dashboardDummyComponent.dashboardConsignorsComponent.consignorsData = finalConsignorsArray;
            this.dashboardDummyComponent.dashboardConsignorsComponent.consignorColourData = finalColoursArray;
        }

        // consignors pie chart
        this.dashboardDummyComponent.dashboardConsignorsComponent.initChart();
    }

    calculateProductivity(projectProblemsStats) {
        let productivityStats = {
            avgDuration: 0,
            avgShipments: 0,
            avgDistance: 0
        };
        Object.keys(projectProblemsStats).forEach(ppId => {
            productivityStats['avgDuration'] += projectProblemsStats[ppId]['totalDuration'];
            productivityStats['avgShipments'] += projectProblemsStats[ppId]['averageShipments'];
            productivityStats['avgDistance'] += projectProblemsStats[ppId]['totalDistance'];
        });
        productivityStats['avgDuration'] = productivityStats['avgDuration'] / Object.keys(projectProblemsStats).length;
        productivityStats['avgShipments'] = productivityStats['avgShipments'] / Object.keys(projectProblemsStats).length;
        productivityStats['avgDistance'] = productivityStats['avgDistance'] / Object.keys(projectProblemsStats).length;

        return productivityStats;
    }

    newProjectProblem() {
    }

    loadMainLogoAsync(imageHash) {
        if (this.globals.collaboratorModeEnabled) {
            const hash = this.globals.portalSettings['live_tracking_image_hash'];
            this.http.get('api/v1/image-cloud?imageHashKey=' + hash).pipe(take(1)).subscribe(response => {
                this.logoBase64 = this.logoSrc.replace('LOGO_HASH', response['items']);
            });
        } else {
            this.imageUtils.fetchImagesViaHashes(`api/internal/v1/images/company-logo`, [imageHash]).then(images => {
                this.logoBase64 = this.logoSrc.replace('LOGO_HASH', images[0]);
            });
        }
    }

    getTranslations() {
        this.listen.push(this.translate.get('DASHBOARD.ALL_PROJECTS').subscribe((res: string) => { this.allProjectsLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ALL_PARTNERS').subscribe((res: string) => { this.allPartnersLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.SHIPMENTS').subscribe((res: string) => { this.shipmentsSubtitle = res; }));
        this.listen.push(this.translate.get('DASHBOARD.MAP').subscribe((res: string) => { this.mapSubtitle = res; }));
        this.listen.push(this.translate.get('DASHBOARD.INCOME').subscribe((res: string) => { this.incomeSubtitle = res; }));
        this.listen.push(this.translate.get('DASHBOARD.RATING').subscribe((res: string) => { this.ratingSubtitle = res; }));
        this.listen.push(this.translate.get('DASHBOARD.PARTNERS').subscribe((res: string) => { this.partnersSubtitle = res; }));
        this.listen.push(this.translate.get('DASHBOARD.AVG_DURATION_STOP_POINT').subscribe((res: string) => { this.avgDurationStopPointSubtitle = res; }));
        this.listen.push(this.translate.get('DASHBOARD.AVG_DELIVERIES_DRIVER').subscribe((res: string) => { this.avgDeliveriesDriverSubtitle = res; }));
        this.listen.push(this.translate.get('DASHBOARD.AVG_DISTANCE_BETWEEN_STOP_POINTS').subscribe((res: string) => { this.avgStopPointsDistanceSubtitle = res; }));
        this.listen.push(this.translate.get('DASHBOARD.AVG_DELIVERIES').subscribe((res: string) => { this.avgDeliveriesSubtitle = res; }));
        this.listen.push(this.translate.get('DASHBOARD.RECIPIENTS_RATING').subscribe((res: string) => { this.recipientsRatingSubtitle = res; }));
        this.listen.push(this.translate.get('GENERIC.YESTERDAY').subscribe((res: string) => { this.yesterdayLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.TWO_DAYS_AGO').subscribe((res: string) => { this.twoDaysAgoLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.NOW').subscribe((res: string) => { this.nowLabel = res; }));

        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_FINISH_ROUTE').subscribe((res: string) => { this.driverFinishRouteLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_INVOICING').subscribe((res: string) => { this.invoicingLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_INVOICING_COLLABORATOR').subscribe((res: string) => { this.invoicingCollaboratorLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_PAYMENT').subscribe((res: string) => { this.paymentLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_PAYMENT_COLLABORATOR').subscribe((res: string) => { this.paymentCollaboratorLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_INVITE_SENT').subscribe((res: string) => { this.inviteSentLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_INVITE_ACCEPTED').subscribe((res: string) => { this.inviteAcceptedLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_SHIPMENTS_COMPLETED').subscribe((res: string) => { this.shipmentsCompletedLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_SHIPMENTS_COLLABORATOR_COMPLETED').subscribe((res: string) => { this.shipmentsCollaboratorCompletedLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_SHIPMENTS_THINK').subscribe((res: string) => { this.shipmentsThinkLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_SHIPMENTS_COLLABORATOR_THINK').subscribe((res: string) => { this.shipmentsCollaboratorThinkLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_SHIPMENTS_ACCEPTED').subscribe((res: string) => { this.shipmentsAcceptedLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_REQUESTED_NEW_PICKUPS').subscribe((res: string) => { this.requestedNewPickupsLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_PROJECT_ADD_SHIPMENTS').subscribe((res: string) => { this.projectAddShipmentsLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_PROJECT_THINK').subscribe((res: string) => { this.projectThinkLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_PROJECT_THINK_AUTO').subscribe((res: string) => { this.projectThinkAutoLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_PROJECT_COMPLETE').subscribe((res: string) => { this.projectCompletedLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.CHART_OTHERS').subscribe((res: string) => { this.chartOthersLabel = res; }));

        // initial subtitles values
        if (this.globals.collaboratorModeEnabled) {
            this.activeShipmentsChartTitle = this.ratingSubtitle;
            this.activeProductivityChartTitle = this.partnersSubtitle;
        } else {
            this.activeShipmentsChartTitle = this.shipmentsSubtitle;
            this.activeProductivityChartTitle = this.ratingSubtitle;
        }
        this.activeDriversChartTitle = this.ratingSubtitle;
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        if (this.globals.companyLogoHash || (this.globals.collaboratorModeEnabled && this.globals.portalSettings['live_tracking_image_hash'])) {
            this.loadMainLogoAsync(this.globals.companyLogoHash);
        }

        // get partners
        if (this.globals.partnersData && this.globals.partnersData['companies']) {
            setTimeout(() => {
                this.selectedPartner = null;
                this.globals.partnersData['companies'].forEach(partner => {
                    this.partners.push({
                        id: partner.id,
                        name: partner.name
                    });

                    this.partners.unshift({
                        id: null,
                        name: this.allPartnersLabel
                    });
                });
                this.partners = [...this.partners];

                this.changePartnerSelect(this.partners[0]); // set default partner
            }, 500);
        }

        if (!this.globals.collaboratorModeEnabled) {
            this.http.get(this.globals.projectsListUrl).pipe(take(1)).subscribe(response => {
                const data = response['items'];
                let project;
                const projects = [];
                data.forEach((projectData, index) => {
                    project = projectData.project;
                    projects.push({
                        id: project.id,
                        name: project.title,
                    });
                });

                this.projects = [...projects];

                this.projects.unshift({
                    id: null,
                    name: this.allProjectsLabel
                });

                this.changeProjectSelect(this.projects[0]); // set default project

                // initial chart (time) to show
                this.initializeCharts();
            });
        } else {
            this.initializeCharts();
        }
    }

    ngAfterViewChecked() {

    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
