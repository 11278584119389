import { Inject, Injectable, Injector, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { BehaviorSubject, combineLatest, defer, finalize, forkJoin, from, mergeMap, mergeWith, Observable, of, Subject, switchMap, take, tap } from 'rxjs';
import { Globals } from '@app/services/globals';
import { INITIAL_DATA } from '@app/model/initial-data';
import { ColourService } from '@app/services/colour.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LmReportsApiService } from '@app/api/services/reports-services.service';
import { IPeriodFormatter, IPeriodProcessor, Period } from '@app/core/services/period-processor';
import { ImageUtils } from '@app/utils/image-utils';
import { ILmChartWidgetTab, ILmCSemiCircleChartWidgetTab } from '@app/model/widget';
import { SHIPMENTS as sc, PRODUCTIVITY as pc, VERTICAL as vc, VERTICAL_OPTIONS as vco, DOUGHNUT as dc, DRIVERS as drc, BRANCHES as brc, SEMICIRCLE as smc, GENERAL_PERFORMANCE, DELIVERIES_OVERVIEW, EXPERIENCE_OVERVIEW, PRODUCTIVITY_OVERVIEW, SHIPMENTS_OVERVIEW, VERTICAL_OPTIONS} from './dashboard-report-dummy-data';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { DELIVERIES as ds} from './dashboard-report-dummy-data';
import { HttpClient } from '@angular/common/http';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { extractInteger, extractIntegerFromString, extractNumber, numberToFixed } from '@app/shared/utils';
import { thirdPartyErrorFilterIntegration } from '@sentry/angular';
import { FulfillmentUtils } from '@app/utils/fulfillment-event-utils';
import { lmAvatarAnnotation, lmVerticalLabelAnnotation } from '@app/model/charts-config';
import { format } from 'date-fns';
import { error } from 'console';

export type LmReportsBarWidgetUpdateType = 'shipments' | 'drivers' | 'branches';
export type LmReportsQueryFragmentType = Date | string | number[];

export type LmDashboardReportQueryType = {
    untilDateTime: Date | string;
    daysBeforeUntilDateCount: number;
    projectId?: number[];
    shipment_type?: number[];
    pageSize?: number;
    page?: number;
    tabChartType?: string;
}

export type LmDashboardReportPagingQuery = {
    pageSize?: number;
    page?: number;
    tabChartType?: string;
}


@UntilDestroy()
@Injectable()
export class DashboardReportViewModelService implements OnInit, OnDestroy {
    Period: IPeriodProcessor;
    Formatter: IPeriodFormatter;
    query: LmDashboardReportQueryType;
    driversQuery:LmDashboardReportPagingQuery;
    branchesQuery:LmDashboardReportPagingQuery;
    private _basePageQuery = {page:0, pageSize: 14}
    

    update = new Subject<string>()   
    shipments = new BehaviorSubject<ILmChartWidgetTab[]>([...sc]);
    drivers = new BehaviorSubject<ILmChartWidgetTab[]>([...drc]);
    branches = new BehaviorSubject<ILmChartWidgetTab[]>([...brc]);
    productivity = new BehaviorSubject<ILmChartWidgetTab[]>([...pc]);
    cancelations = new Subject();
    deliveries = new BehaviorSubject<(ILmChartWidgetTab | ILmCSemiCircleChartWidgetTab)[]>([...<any>ds]);
    
    shipmentProgressOptions = SHIPMENTS_OVERVIEW
    productivityProgressOptions = PRODUCTIVITY_OVERVIEW
    experienceProgressOptions = EXPERIENCE_OVERVIEW
    deliveriesProgressOptions = DELIVERIES_OVERVIEW
    activityData;

    words:any;
    logoBase64 = '';
    
    constructor(
        private injector: Injector, 
        @Inject(forwardRef(() => LmReportsApiService)) public apiSvc: LmReportsApiService,
        public globals: Globals,
        private _imageUtils: ImageUtils,
        private _http: HttpClient,
        public translateSvc: TranslateService,
        private _fulfillmentUtils: FulfillmentUtils
    ){
        this.Period = Period();
        this.Formatter = this.Period.formatter.setIntlOptions({month: 'numeric', day: 'numeric'});
        const {start, end} = this.Period;
        const days = this.Formatter.countDaysBetween(start, end);
        this.query = {untilDateTime: end, daysBeforeUntilDateCount: days, projectId: [], shipment_type: []};
        this.driversQuery = {...this._basePageQuery, tabChartType: 'productivity'};
        this.branchesQuery = {...this._basePageQuery, tabChartType: 'productivity'};
        this.words = {};
        this.translate$().subscribe();
    }

    translate$(){
        return from(['DASHBOARD', 'CANCEL']).pipe(
            mergeMap(item => forkJoin([of(item), this.translateSvc.get(item)])),
            tap(([_key, _trans]) => this.words[_key] = _trans),
            finalize(() => {this._fulfillmentUtils.setupReasonLabels(this.words.CANCEL)}),
            untilDestroyed(this)
        )
    };


    private getDatesLabels(labels) {
        if (!labels[0]) return labels
        const dateParts = labels[0].trim().split('-');
        if (dateParts.length === 2) {
            // Format as MM/YYYY if only year and month are present
            this.Formatter = this.Period.formatter.setIntlOptions({month: 'numeric', year: 'numeric'});
            return labels.map(l => this.Formatter.format(l).INTLdate);
        } else if (dateParts.length === 3) {
            // Format as DD/MM if day, month, and year are present
            this.Formatter = this.Period.formatter.setIntlOptions({month: 'numeric', day: 'numeric'});
            return labels.map(l => this.Formatter.format(l).INTLdate);
        } else {
            return labels;
        }
    }


    private updateProgressOptions(title: string, options, latestValue: number, oldValue: number, fractionDigits: number = 2, divisor: number = 0.05) {
        if (title) options.title = title;
        options.amount = latestValue;
        options.percentage = numberToFixed(latestValue / divisor, fractionDigits);
        options.progress = numberToFixed(latestValue - oldValue, fractionDigits);
    
        return { ...options };
    }


    getOverview(_query): Observable<any>{
        return this.apiSvc.apiReportsOverviewGet(_query).pipe(
            switchMap(res => of((<any>res).items)),
            tap(res => {
                const {
                    latest:{total_shipments:l_ships, productivity:l_pr, experience:l_ex, completed_deliveries_on_time_percentage:l_del}, 
                    old:{total_shipments:o_ships, productivity:o_pr, experience:o_ex, completed_deliveries_on_time_percentage:o_del}, 
                } = res;
                const w = this.words['DASHBOARD'] ?? {};
                
                this.shipmentProgressOptions = this.updateProgressOptions(w['SHIPMENTS'], this.shipmentProgressOptions, l_ships ?? 0, o_ships ?? 0, 0, 1);
                this.productivityProgressOptions = this.updateProgressOptions(w['PRODUCTIVITY'],this.productivityProgressOptions, l_pr ?? 0, o_pr ?? 0);
                this.experienceProgressOptions = this.updateProgressOptions(w['EXPERIENCE'], this.experienceProgressOptions, l_ex ?? 0, o_ex ?? 0);
                this.deliveriesProgressOptions = this.updateProgressOptions(w['DELIVERED_ON_TIME'], this.deliveriesProgressOptions, l_del ?? 0, o_del ?? 0, 2, 1);
            })
        );
    }


    getShipments(_query): Observable<any>{
        return this.apiSvc.apiReportsShipmentsGet(_query).pipe(
            switchMap(res => of((<any>res).items)),
            tap(res => {
                const shipments:ILmChartWidgetTab[] = [...sc];
                const labels = Object.keys(res);
                const values = Object.values(res);
                const ids = shipments.map(({id}) => id);

                // const sets = values
                //     .map((v:any) => Object.keys(v).map(key => ({[key]: [v[key]]})))
                //     .reduce((nu, v, i) => [...nu, { [ids[i]]: (values.map((_v:any) => _v[ids[i]])) }], []);

                const sets = values.length > 0 
                ? Object.keys(values[0]).map((key) => {
                    return { [key]: values.map(v => v[key]) }
                }) : [];

                const w = this.words['DASHBOARD'] ?? {};
                shipments.map(ship => {
                    ship.data.labels = this.getDatesLabels(labels);
                    const {id,data:{datasets:ds}, filters:fl, subtitle} = ship;
                    const set = ((sets.find(set => Object.keys(set)[0] === id) ?? {})[id]) ?? [];
                            
                    if (['on_time_deliveries', 'first_attempt_success_rate', 'total_miles','total_co2_emissions'].includes(<string>id)) ship.data.datasets[0].data = set;
                   
                    if (id === 'shipments_in_total') ship.data.datasets = ds.map(dts => ({...dts, data:[...set.map(s => s[dts.stack])]}));
                   
                    if (id === 'deliveries_and_pickups') {
                        const deliveries = set.map(s => s['deliveries']);
                        const pickups = set.map(s => s['pickups']);
                        const tracking = set.map(s => s['live_tracking']);
                        const filters = set.map(s => s['percentages'])

                        ship.data.datasets = ds.map(dts => {
                            const {stack, label, order, backgroundColor} = dts;
                            dts.label = w[label] || label
                            if (stack === 'deliveries' || stack === 'deliveries'){
                                if (order === 1) dts.data = deliveries.map(d => d['success']);
                                if (order === 2) dts.data = deliveries.map(d => d['total']);
                            }
                            if (stack === 'pickups'){
                                if (order === 1) dts.data = pickups.map(d => d['success']);
                                if (order === 2) dts.data = pickups.map(d => d['total']);
                            }
                            if (order === 0) dts.data = tracking
                            return dts;
                        });

                        ship.filters[0].data.datasets.map(fl =>{
                            fl.label = w[fl.label] || fl.label
                            if (fl.stack === 'deliveries') fl.data = filters.map(f => f['deliveries']);
                            if (fl.stack === 'pickups') fl.data = filters.map(f => f['pickups']);
                            return fl;
                        })
                        ship.filters[0].data.labels = ship.data.labels;
                        ship.filters[0].label = w[ship.filters[0].label] || ship.filters[0].label;
                    }
                    ship.title = w['SHIPMENTS'];
                    ship.subtitle = w[subtitle] || subtitle;
                    ship.data.datasets.map(dataset => dataset.label = w[dataset.label] || dataset.label );
                });

                this.shipments.next(shipments);
                this.update.next('shipments');
            })
        );
    }


    getProductivity(_query): Observable<any> {
        return this.apiSvc.apiReportsProductivityGet(_query).pipe(
            switchMap(res => of((<any>res).items)),
            tap(res => {
                const productivity = [...pc];
                const labels = Object.keys(res);
                const values = Object.values(res);
                const keys = values.length > 0 ? Object.keys(values[0]) : [];
                const sets = keys.map(key => ({
                    [key]: values.map(entry => entry[key])
                }));
                const w = this.words['DASHBOARD'] ?? {};
                
                productivity.map(productivityItem => {
                    productivityItem.data.labels = this.getDatesLabels(labels);
                    const {id,data:{datasets:ds}, filters:fl, subtitle} = productivityItem;
                    const set = ((sets.find(set => Object.keys(set)[0] === id) ?? {})[id]) ?? [];
                    if(['productivity_rating', 'visited_per_hour', 'packages', 'average_delay', 'cost_per_shipment', 'recipient_experience'].includes(<string>id)) productivityItem.data.datasets[0].data = set;
                
                    // if (productivityItem.data.labels.length > productivityItem.pageSize) {
                    //     productivityItem.pages = [...this.splitDataForPagination(productivityItem.data, productivityItem.pageSize)];
                    // }
                    productivityItem.title = w['PRODUCTIVITY'];
                    productivityItem.subtitle = w[subtitle] || subtitle;
                });
                this.productivity.next(productivity);
                this.update.next('productivity');
            })
        );
    }


    getAvatar$(hash) {
        return defer(() => this._imageUtils.fetchImagesViaHashes(`api/internal/v1/images/drivers`, [hash])) 
    }


    getPagedDrivers(_query) {
        return this.apiSvc.apiReportsDriversGet(_query).pipe(
            switchMap((res:any) => {
                if(res.itemsMeta.totalCount === 0) return forkJoin([of(res), of([])]);

                const driverDataArray = Object.values(res.items.driver_data);
                const avatars$ = driverDataArray.map((dato:any) => from(dato.hash? this.getAvatar$(dato.hash) : of(null)));
                return forkJoin([of(res), combineLatest(avatars$)]);
            }),
            tap(([res, images]) => {
                const chart = this.driversQuery.tabChartType;
                const {items:{driver_stats:_stats, driver_data: _data}, itemsMeta} = res;
                const w = this.words['DASHBOARD'] ?? {};
                const drivers = [...drc];
                const driver = drivers.find(d => d.tabChartType === chart);

                drivers.map(driver => {
                    const {subtitle} = driver;
                    driver.title = w['DRIVERS'];
                    driver.subtitle = w[subtitle] || subtitle;
                })

                if (itemsMeta.totalCount > 0) {
                    const img = images.flatMap(i => i);

                    const combined = Object.keys(_stats).map(id => ({
                        label: _data[id]?.name || `Driver ${id}`,
                        value: Number(Object.values(_stats[id])[0])
                    }));
                    combined.sort((a, b) => b.value - a.value);
                    const labels = combined.map(item => item.label);

                    drivers.map(driver => {
                        driver.data.labels = [...labels];
                    })
                    const annotations = Object.assign({}, ...labels.map((l, i) => ({[l]: lmAvatarAnnotation(l, img[i])})));
                    const dt = structuredClone(driver.data);
                    dt.datasets[0].data = combined.map(item => item.value);
                    dt.labels = labels;
                    driver.pages = [dt];
                    driver.data.labels = labels;
                    driver.data.datasets[0].data = undefined;
                    driver.chartOptions.plugins.annotation.annotations = annotations;                    
                }
                else {
                    driver.data.labels = [];
                    driver.data.datasets[0].data = undefined;
                    driver.chartOptions.plugins.annotation.annotations = undefined;  
                    driver.pages = undefined;
                }

                const {pageIndex, pagesCount} = itemsMeta;
                driver.currentPage = pageIndex;
                driver.pageCount = pagesCount;
                
                this.drivers.next([...drivers, driver]);
                this.update.next('drivers');
            })
        )
    }


    getCancelled(_query): Observable<any>{
        return this.apiSvc.apiCancelationGet(_query).pipe(
            switchMap(res => of((<any>res).items)),
            tap(res => {
                const {canceled_percentages:percents} = res;
                for(let key in percents) if(percents[key] < 0.5) delete percents[key];
                const {cancelledEventReasonsLabels:names} = this._fulfillmentUtils;

                const sortedArray = Object.entries(percents).sort((a, b) => Number(b[1]) - Number(a[1]));
                const _labels = sortedArray.map(item => item[0]).map(k => names[k]);   
                const annotations = Object.assign({}, ..._labels.map(l => ({[l]: lmVerticalLabelAnnotation(l)})));

                const options = {...VERTICAL_OPTIONS}
                options.plugins.annotation.annotations = annotations;
                const cancelations = {...vc, labels: _labels};
                
                const _datas = sortedArray.map(item => item[1])
                const height = _datas.length*60 + 'px'; //thickness, paddings, strokes - label: height, paddings

                cancelations.datasets[0].data = <any>_datas;
                cancelations.datasets[1].data = _datas.map(d => d=100);

                this.cancelations.next({h:height});
                setTimeout(() => {
                    this.cancelations.next({d:cancelations,o:options});
                }, 100);
            })
        );
    }

    getDeliveries(_query): Observable<any>{
        return this.apiSvc.apiDeliveriesGet(_query).pipe(
            switchMap(res => of((<any>res).items)),
            tap(res => {
                const deliveries = {...ds.find(item => item.id === 'shipper_share')};
                const labels: string[] = Object.keys(res.collaborators).map(key => res.collaborators[key]);
                const values: number[] = Object.keys(res.shipper_share).map(key => res.shipper_share[key]);

                deliveries.data.labels = labels;
                deliveries.data.datasets[0].data = values;
                
                this.deliveries.next(<any>deliveries);
            })
        );
    }


    getPagedBranches(_query): Observable<any> {
        return this.apiSvc.apiBranchesGet(_query).pipe(
            switchMap(res => of((<any>res))),
            tap(res => {
                const chart = this.branchesQuery.tabChartType;
                const branches = [...brc];
                const branch =  branches.find(b => b.tabChartType === chart);

                const w = this.words['DASHBOARD'] ?? {};
                const {items, itemsMeta} = res;
                const _names = items.branches
                const _stats = items[chart];

                branches.map(branch => {
                    const {subtitle} = branch;
                    branch.title = w['BRANCHES'];
                    branch.subtitle = w[subtitle] || subtitle;                 
                });
                
                if(itemsMeta.totalCount > 0) {                    
                    const combined = Object.keys(_stats).map(key => ({
                        label: _names[key] || `Branch ${key}`,
                        value: Number(_stats[key])
                    }));
                    combined.sort((a, b) => b.value - a.value);
                    const labels = combined.map(item => item.label);

                    branches.map(branch => { 
                        branch.data.labels = labels;                    
                    });

                    const bd = structuredClone(branch.data);
                    bd.datasets[0].data = combined.map(item => item.value);
                    
                    branch.pages = [bd];
                    branch.data.labels = labels;
                    branch.data.datasets[0].data = undefined;
                }
                else{
                    branch.data.labels = [];
                    branch.data.datasets[0].data = undefined;
                    branch.pages = undefined;
                }

                const {pageIndex, pagesCount} = itemsMeta;
                branch.currentPage = pageIndex;
                branch.pageCount = pagesCount;
                
                this.branches.next([...branches, branch]);
                this.update.next('branches');
            })
        );
    }

    getActivity(_query): Observable<any>{
        return this.apiSvc.apiActivityGet(_query).pipe(
            switchMap(res => of((<any>res).items)),
            tap(res => {
                this.activityData = res;
            })
        );
    }

    getLogo(imageHash) {
        const _src = 'data:image/png;base64,LOGO_HASH';
        if (this.globals.collaboratorModeEnabled) {
            const hash = this.globals.portalSettings['live_tracking_image_hash'];
            this._http.get('api/v1/image-cloud?imageHashKey=' + hash).pipe(take(1))
            .subscribe(response => {
                if (response['items']) this.logoBase64 = _src.replace('LOGO_HASH', response['items']);
            });
        } else {
            this._imageUtils.fetchImagesViaHashes(`api/internal/v1/images/company-logo`, [imageHash])
            .then(images => {
                if (images[0]) this.logoBase64 = _src.replace('LOGO_HASH', images[0]);
            });
        }
    }

    ngOnInit(): void { }
    ngOnDestroy(): void {}

    fragmentRequest$ = ({fragment}) => {
        return of(null).pipe(
            switchMap(_=> {
                this.Period.end = this.Period.apiYesterday();
                this.query = {
                     ...this.query, 
                     untilDateTime: this.Period.end,
                     daysBeforeUntilDateCount: this.Formatter.countDaysBetween(this.Period[fragment], this.Period.end)
                }
                this.driversQuery = Object.assign({}, this.driversQuery, this._basePageQuery);
                this.branchesQuery = Object.assign({}, this.branchesQuery, this._basePageQuery)
                // this.driversQuery.pageSize = 4;
                return this.getAll$(this.query)
            })
        ) 
    }

    getAll$(query){
        return of(null).pipe(
            mergeWith(
              this.getOverview(query),
              this.getShipments(query),
              this.getProductivity(query),
              this.getCancelled(query),
              this.getDeliveries(query),
              this.getPagedDrivers({...query, ...this.driversQuery}),
              this.getPagedBranches({...query, ...this.branchesQuery}),
            )
        ) 
    }

// private splitDataForPagination(data: any, chunkSize: number) {
    //     const { labels, datasets } = data;
    //     const totalChunks = Math.ceil(labels.length / chunkSize);
    //     const splitArray = [];
    
    //     for (let i = 0; i < totalChunks; i++) {
    //         const start = i * chunkSize;
    //         const end = start + chunkSize;
    
    //         splitArray.push({
    //             labels: labels.slice(start, end),
    //             datasets: datasets.map(dataset => ({
    //                 ...dataset, // Spread all properties from the original dataset
    //                 data: dataset.data.slice(start, end), // Override data with sliced data
    //             }))
    //         });
    //     }
    
    //     return splitArray;
    // }
}
