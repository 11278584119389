import { Component, OnInit, ViewChild, Injectable, OnDestroy, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import * as moment from 'moment-timezone';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, take } from 'rxjs/operators';
import { DataService } from '@app/services/data.service';
import { DraggableMarkerService } from '@app/services/draggableMarker.service';
import { Globals } from '@app/services/globals';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { StopPointPickupServiceService } from '@app/services/stop-point-pickup-service.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { AddressService } from '@app/services/address.service';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { StopPointService } from '@app/services/stop-point.service';
import * as libphonenumber from 'libphonenumber-js';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-delivery-form',
  templateUrl: './delivery-form.component.html',
  styleUrls: ['./delivery-form.component.scss', '../stop-form/stop-form.component.scss']
})

@Injectable()
export class DeliveryFormComponent implements OnInit, OnDestroy {

  @ViewChild(NgSelectComponent, { static: false }) timeWindowErrorMsg;
  @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;
  @Output() toggleModal = new EventEmitter<string>();
  @Output() dataLoading = new EventEmitter<string>();
  @Output() updateFormValidity = new EventEmitter<string>();

  getStopPointUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points/';
  stopPointsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points';
  days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  myForm: FormGroup;
  errors = [];
  data;
  isClickedOnce = false;
  stopPointId = null;
  projectProblemId = null;
  stopPointProjectProblemId = null;
  projectProblemDepartureDatetime;
  projectProblemDayOfWeek;
  companyId = null;
  address = null;
  serviceType = this.globals.stopPointServiceTypeConstants['DELIVERY'];
  contactName: String = '';
  payAmount = '';
  email = 'dummy@mail.not';
  countryPrefix = '';
  phoneNumber = '';
  telephone = '';
  doubleTimeWindow = false;
  selectedTimeWindow = null;
  selectedTimeWindowId = null;
  timeZone = '';
  customTimeWindows = [];
  timeWindows = [];
  timeWindow = [480, 1200];
  timeWindowDouble = [480, 840, 1020, 1200];
  timeWindowIds = [];
  timeWindowUnformatted = ['08:00', '20:00'];
  durationUnformatted = '02:00';
  durationSeconds = [120];
  duration = '';
  deliverySourceModelName = '';
  deliverySourceModelId = null;
  pricelistServiceType = null;
  entityStatus = 1;
  final = false;
  createCustomerEntityType = null;
  notes = '';
  priority = this.globals.stopPointPriorityConstants['NORMAL'];
  load = null;
  addressType: String = 'houseNumber';
  freeformAddress = '';
  country = 'GR';
  lat = '';
  lon = '';
  timeWindowOptions;
  timeWindowOptionsDouble;
  durationOptions;
  positionSet = false;
  listen = [];

  addresses: Observable<any>;
  addressesLoading = false;
  addressInput = new Subject<string>();
  selectedAddress: any = <any>[];
  addressAutofillInterval;

  requestedPayOnDeliveryPaymentMethod = null;
  executedPayOnDeliveryPaymentMethod = null;
  chequeCollectionDatePayOnDelivery = moment().add(8, 'days').format('YYYY-MM-DD');
  paymentOptions = [];
  creditCardLabel = '';
  payOnDeliveryLabel = '';
  cashLabel = '';
  bankDepositLabel = '';
  chequeLabel: string;
  chequeDayLabel: string;

  inWarehouse = false;

  countryCode = '';
  state = '';
  county = '';
  city = '';
  district = '';
  street = '';
  street2 = '';
  houseNumber = '';
  postalCode = '';
  isPlace = false;
  placeName = '';
  noNameLabel = '';
  noNameConstant = '_NO_NAME';

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private dataService: DataService,
    private draggableMarkerService: DraggableMarkerService,
    formBuilder: FormBuilder,
    public globals: Globals,
    public modalService: ModalService,
    private viewProjectProblemService: ViewProjectProblemService,
    private stopPointPickupService: StopPointPickupServiceService,
    private projectProblemDataService: ProjectProblemDataService,
    private addressService: AddressService,
    private dateTimeCalculatorService: DateTimeCalculatorService,
    private stopPointService: StopPointService,
  ) {
    this.initializeSlider();
    this.listen.push(this.draggableMarkerService.markerDragListen().subscribe((response) => {
      if (response.forSameDayDelivery) {
        this.positionSet = true;
        this.lat = response.lat;
        this.lon = response.lng;
        if (this.selectedAddress.label) {
          this.freeformAddress = this.selectedAddress.label;
        }
        this.patchAddresses();
      }
    }));
    this.listen.push(this.stopPointPickupService.submitDeliveryFormListen().subscribe((response) => {
      this.submitDeliveryForm(response.id, response.load, response.pricelistServiceType);
    }));
    this.listen.push(this.stopPointPickupService.loadDeliveryFormListen().subscribe(data => {
      this.getFormData(data.id, data.projectProblemId);
    }));
    // get submitted date for day cheque
    this.listen.push(this.modalService.submitDateListen().subscribe((data) => {
      if (data.customMode == 'podChequeDay') {
        this.chequeCollectionDatePayOnDelivery = moment(data.date).format('YYYY-MM-DD');
        this.myForm.patchValue({
          'stopPoint': {
            'cheque_collection_date_pay_on_delivery': this.chequeCollectionDatePayOnDelivery
          }
        });
      }
      M.updateTextFields();
    }));
    this.listen.push(this.viewProjectProblemService.createStopFromMapClickListen().subscribe((response) => {
      if (response.forSameDayDelivery) {
        if (!response.helperOpen) {
          this.positionSet = true;
          this.lat = response.lat;
          this.lon = response.lng;
          this.dataLoading.emit('true');
          this.http.get(`api/v1/search/reverse-locations?searchQuery=${this.lat},${this.lon}`).pipe(take(1)).subscribe(location => {
            this.dataLoading.emit('false');
            if (location['data']['addresses']['items']) {
              if (location['data']['addresses']['items'].length) {
                this.setAddress(location['data']['addresses']['items'][0]['address']);
                // this.freeformAddress = location['data']['addresses']['items']['View'][0]['Result'][0]['Location']['Address']['Label'];
                // this.country = location['data']['addresses']['items']['View'][0]['Result'][0]['Location']['Address']['Country'];
              } else {
                this.freeformAddress = 'Address';
                this.country = 'GR';
              }
            }
            (<HTMLInputElement>document.getElementById('delivery-custom-input')).value = this.freeformAddress;

            this.updateFormValidity.emit('true');
          });
          this.addressType = 'houseNumber';
          this.draggableMarkerService.addDraggableMarker(Number(response.lat), Number(response.lng), true);
        }
      }
    }));
    this.myForm = formBuilder.group({
      'stopPoint': formBuilder.group({
        'id': [this.stopPointId],
        'stopPointId': [this.stopPointId],
        'project_problem_id': [this.stopPointProjectProblemId],
        'service_type': [this.serviceType],
        'contact_name': [this.contactName],
        'pay_amount': [this.payAmount],
        'requested_pay_on_delivery_payment_method': [this.requestedPayOnDeliveryPaymentMethod],
        'executed_pay_on_delivery_payment_method': [this.executedPayOnDeliveryPaymentMethod],
        'cheque_collection_date_pay_on_delivery': [this.chequeCollectionDatePayOnDelivery],
        'email': [this.email],
        'countryPrefix': [this.countryPrefix],
        'phoneNumber': [this.phoneNumber],
        'telephone': [this.telephone],
        'companyTimeWindow': [this.selectedTimeWindow],
        'companyTimeWindowCategoryId': [this.selectedTimeWindowId],
        'doubleTimeWindow': [this.doubleTimeWindow],
        'time_windows': [this.timeWindows],
        'time_window_unformatted': [this.timeWindow],
        'time_window_unformatted2': [this.timeWindowDouble],
        'duration_seconds': this.durationSeconds,
        'duration': [this.duration],
        'final': [this.final],
        'entity_status': [this.entityStatus],
        'createCustomerEntityType': [this.createCustomerEntityType],
        'notes': [this.notes],
        'priority': [this.priority],
        'load': [this.load],
        'deliverySources': formBuilder.group({
          'modelName': [this.deliverySourceModelName],
          'modelId': [this.deliverySourceModelId],
          'pricelist_service_type': [this.pricelistServiceType],
        }),
        'address': formBuilder.group({
          'countryCode': [this.countryCode],
          'state': [this.state],
          'county': [this.county],
          'city': [this.city],
          'district': [this.district],
          'street': [this.street],
          'street2': [this.street2],
          'houseNumber': [this.houseNumber],
          'postalCode': [this.postalCode],
          'isPlace': [this.isPlace],
          'placeName': [this.placeName],
          'value': [this.freeformAddress],
          'lat': [this.lat],
          'lon': [this.lon],
          'term': [this.selectedAddress],
        }),
      }),
    });
  }

  public setStopFormData(projectProblemId, projectProblemDepartureDatetime, entityStatus) {
    this.projectProblemId = projectProblemId;
    // this.projectProblemDepartureDatetime = projectProblemDepartureDatetime;
    this.projectProblemDayOfWeek = this.days[moment(projectProblemDepartureDatetime).day()];
    if (entityStatus) {
      this.entityStatus = entityStatus;
    }
    this.setForm();
  }

  public getFormData(stopPointId, projectProblemId = null) {
    // if (!this.projectProblemId) {
    //   this.inGeneralWarehouse = true;
    // }

    if (!projectProblemId) {
      this.inWarehouse = true;
    }

    this.stopPointService.getStopPoint(stopPointId, projectProblemId).pipe(take(1)).subscribe(response => {
      this.data = response['item'];
      const stopPoint = this.data.stopPoint;
      this.stopPointId = stopPoint.id;
      this.stopPointProjectProblemId = stopPoint.project_problem_id ?? null;
      if (this.stopPointProjectProblemId) this.inWarehouse = false;
      this.serviceType = stopPoint.service_type;
      this.contactName = stopPoint.contact_name;
      if (this.contactName === this.noNameConstant) {
        this.contactName = this.noNameLabel;
      }

      if (stopPoint.telephone === 'n/a') { stopPoint.telephone = ''; }
      if (stopPoint.telephone === '+30n/a') { stopPoint.telephone = ''; }
      if (stopPoint.telephone) {
        if (stopPoint.telephone.length > 5) {
          const phoneObj = libphonenumber.parsePhoneNumber(stopPoint.telephone);
          this.phoneNumber = phoneObj.nationalNumber;
          this.countryPrefix = '+' + phoneObj.countryCallingCode;
        }
      }

      if (stopPoint.pay_amount) {
        this.payAmount = stopPoint.pay_amount;
      }

      // requested method must fetch its value from executed instead of requested if requested is not NO_PAYMENT!
      this.requestedPayOnDeliveryPaymentMethod = stopPoint.requested_pay_on_delivery_payment_method;
      if (stopPoint.executed_pay_on_delivery_payment_method != this.globals.paymentOptionsConstants['NO_PAYMENT']) {
        this.requestedPayOnDeliveryPaymentMethod = stopPoint.executed_pay_on_delivery_payment_method;
      }

      // load dates for cheques
      if (stopPoint.cheque_collection_date_pay_on_delivery) {
        this.chequeCollectionDatePayOnDelivery = stopPoint.cheque_collection_date_pay_on_delivery;
      }

      this.priority = stopPoint.priority;
      // if (this.priority === this.globals.stopPointPriorityConstants['HIGH']) {
      //   this.priorityOn = true;
      // } else {
      //   this.priorityOn = false;
      // }

      this.timeWindows = stopPoint.time_windows;
      this.setTimeWindows();

      this.duration = stopPoint.duration;
      this.durationSeconds = [moment.duration(this.duration).asSeconds()];
      this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);

      this.entityStatus = stopPoint.entity_status;

      if (stopPoint.note) {
        this.notes = stopPoint.note;
      }
      if (stopPoint.deliverySources.length) {
        this.deliverySourceModelName = stopPoint.deliverySources[0].modelName;
        this.deliverySourceModelId = stopPoint.deliverySources[0].modelId;
      }

      // this.portalAccess = stopPoint.portal_access ? stopPoint.portal_access : this.globals.portalAccessConstants['NO_ACCESS'];
      // this.setPortalSwitches();
      this.setAddress(stopPoint.address);
      this.positionSet = true;
      this.updateFormValidity.emit('true');
      this.setForm();
    });
  }

  setAddress(address) {
    this.freeformAddress = this.addressService.getAddressLabel(address);
    this.placeName = this.addressService.getAddressPlace(address);
    if (address.countryCode) {
      this.countryCode = address.countryCode;
    } else {
      this.countryCode = '';
    }
    if (address.state) {
      this.state = address.state;
    } else {
      this.state = '';
    }
    if (address.county) {
      this.county = address.county;
    } else {
      this.county = '';
    }
    if (address.city) {
      this.city = address.city;
    } else {
      this.city = '';
    }
    if (address.district) {
      this.district = address.district;
    } else {
      this.district = '';
    }
    if (address.street) {
      this.street = address.street;
    } else {
      this.street = '';
    }
    if (address.street2) {
      this.street2 = address.street2;
    } else {
      this.street2 = '';
    }
    if (address.houseNumber) {
      this.houseNumber = address.houseNumber;
    } else {
      this.houseNumber = '';
    }
    if (address.postalCode) {
      this.postalCode = address.postalCode;
    } else {
      this.postalCode = '';
    }
    if (address.isPlace) {
      this.isPlace = address.isPlace;
    } else {
      this.isPlace = false;
    }
    if (address.lat) {
      this.lat = address.lat;
    }
    if (address.lon) {
      this.lon = address.lon;
    }
    this.patchAddresses();
  }

  setTimeWindows() {
    this.timeWindowUnformatted = [];
    if (this.timeWindows.length === 1) {
      this.doubleTimeWindow = false;
    } else {
      this.doubleTimeWindow = true;
    }
    const timeWindowDouble = [];
    let timeWindowRange, timeWindowRangeMinutes;
    let timeWindowStartOffsetFromDeparture, timeWindowStartOffsetFromDepartureMinutes;
    let timeWindowStart, timeWindowEnd, timeWindowStartMinutes, timeWindowEndMinutes;
    let timeWindowStartUnformatted, timeWindowEndUnformatted;
    this.timeWindows.forEach(timeWindow => {
      this.timeWindowIds.push(timeWindow.id);
      timeWindowRange = timeWindow.time_window_range;
      timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
      if (timeWindow.time_window_start_datetime) {
        timeWindowStart = moment(timeWindow.time_window_start_datetime).format();
      } else if (timeWindow.start) {
        timeWindowStart = moment(timeWindow.start, 'HH:mm:SS').format();
      }
      timeWindowEnd = moment(timeWindowStart).add(timeWindowRangeMinutes, 'minutes').format();
      timeWindowStartUnformatted = moment(timeWindowStart).format('HH:mm');
      timeWindowEndUnformatted = moment(timeWindowEnd).format('HH:mm');
      this.timeWindowUnformatted.push(timeWindowStartUnformatted);
      this.timeWindowUnformatted.push(timeWindowEndUnformatted);
      timeWindowStartMinutes = moment.duration(timeWindowStartUnformatted).asMinutes();
      timeWindowEndMinutes = moment.duration(timeWindowEndUnformatted).asMinutes();
      if (this.timeWindows.length === 1) {
        this.timeWindow = [timeWindowStartMinutes, timeWindowEndMinutes];
      } else {
        timeWindowDouble.push(timeWindowStartMinutes);
        timeWindowDouble.push(timeWindowEndMinutes);
      }
    });
    this.timeWindowDouble = timeWindowDouble;
    this.myForm.patchValue({
      'stopPoint': {
        'time_window_unformatted': this.timeWindow,
        'time_window_unformatted2': this.timeWindowDouble,
      }
    });
  }

  timeWindowSelected() {
    if (this.myForm.value.stopPoint.companyTimeWindow) {
      this.timeWindows = this.myForm.value.stopPoint.companyTimeWindow['CompanyTimeWindowCategory']['time_windows'][this.projectProblemDayOfWeek];
      this.setTimeWindows();
    }
  }

  patchAddresses() {
    this.myForm.patchValue({
      'stopPoint': {
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'value': this.freeformAddress,
          'lat': this.lat,
          'lon': this.lon,
        },
      },
    });
  }

  patchForm() {
    this.myForm.patchValue({
      'stopPoint': {
        'contact_name': this.contactName,
        'service_type': this.serviceType,
        'telephone': this.telephone,
        'companyTimeWindowCategoryId': this.selectedTimeWindowId,
        'time_windows': this.timeWindows,
        'duration': this.duration,
        'priority': this.priority,
        'load': this.load,
        'deliverySources': {
          'modelName': this.deliverySourceModelName,
          'modelId': this.deliverySourceModelId,
          'pricelist_service_type': this.pricelistServiceType,
        },
      },
    });
    M.updateTextFields();
    this.selectedAddress = {
      label: this.freeformAddress,
      position: [this.lat, this.lon],
    };
    if (this.freeformAddress) {
      (<HTMLInputElement>document.getElementById('delivery-custom-input')).value = this.freeformAddress.valueOf();
    }
  }

  setForm() {
    this.myForm.setValue({
      'stopPoint': {
        'id': this.stopPointId,
        'stopPointId': this.stopPointId,
        'project_problem_id': this.stopPointProjectProblemId,
        'service_type': this.serviceType,
        'contact_name': this.contactName,
        'pay_amount': this.payAmount,
        'requested_pay_on_delivery_payment_method': this.requestedPayOnDeliveryPaymentMethod,
        'executed_pay_on_delivery_payment_method': this.executedPayOnDeliveryPaymentMethod,
        'cheque_collection_date_pay_on_delivery': this.chequeCollectionDatePayOnDelivery,
        'email': this.email,
        'countryPrefix': this.countryPrefix,
        'phoneNumber': this.phoneNumber,
        'telephone': this.telephone,
        'companyTimeWindow': this.selectedTimeWindow,
        'companyTimeWindowCategoryId': this.selectedTimeWindowId,
        'doubleTimeWindow': this.doubleTimeWindow,
        'time_windows': this.timeWindows,
        'time_window_unformatted': this.timeWindow,
        'time_window_unformatted2': this.timeWindowDouble,
        'duration_seconds': this.durationSeconds,
        'duration': this.duration,
        'final': this.final,
        'entity_status': this.entityStatus,
        'notes': this.notes,
        'priority': this.priority,
        'load': this.load,
        'createCustomerEntityType': this.createCustomerEntityType,
        'deliverySources': {
          'modelName': this.deliverySourceModelName,
          'modelId': this.deliverySourceModelId,
          'pricelist_service_type': this.pricelistServiceType,
        },
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'value': this.freeformAddress,
          'lat': this.lat,
          'lon': this.lon,
          'term': this.selectedAddress,
        },
      },
    });
    M.updateTextFields();
    this.selectedAddress = {
      label: this.freeformAddress,
      position: [this.lat, this.lon],
    };
    if (this.freeformAddress) {
      (<HTMLInputElement>document.getElementById('delivery-custom-input')).value = this.freeformAddress.valueOf();
    }
  }

  resetForm() {
    this.errors = [];
    this.stopPointId = null;
    this.stopPointProjectProblemId = null;
    this.serviceType = this.globals.stopPointServiceTypeConstants['DELIVERY'];
    this.contactName = '';
    this.countryPrefix = this.globals.defaultCountryCode;
    this.phoneNumber = '';
    this.telephone = '';
    this.doubleTimeWindow = false;
    this.timeWindows = [];
    this.timeWindow = [480, 1200];
    this.timeWindowDouble = [480, 840, 1020, 1200];
    this.timeWindowUnformatted = ['08:00', '20:00'];
    this.timeWindowIds = [];
    this.durationUnformatted = '02:00';
    this.durationSeconds = [120];
    this.deliverySourceModelName = '';
    this.deliverySourceModelId = null;
    this.pricelistServiceType = null;
    this.payAmount = '';
    this.requestedPayOnDeliveryPaymentMethod = this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
    this.executedPayOnDeliveryPaymentMethod = this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
    this.chequeCollectionDatePayOnDelivery = moment().add(8, 'days').format('YYYY-MM-DD');
    this.final = false;
    this.entityStatus = 1;
    this.notes = '';
    this.freeformAddress = '';
    this.country = '';
    this.lat = '';
    this.lon = '';
    this.isPlace = false;
    this.placeName = '';
    this.timeZone = moment.tz.guess();
    this.positionSet = false;
    this.selectedTimeWindow = null;
    this.selectedTimeWindowId = null;
    this.inWarehouse = false;
    this.load = null;
    (<HTMLInputElement>document.getElementById('delivery-custom-input')).value = '';
    this.setForm();
    this.myForm.markAsUntouched();
    this.myForm.markAsPristine();
  }

  initializeSlider() {
    this.timeWindowOptions = {
      margin: 30, // how many minutes between start and stop
      step: 15,
      start: [480, 1200],
      connect: [false, true, false],
      range: {
        min: 0,
        max: 1439
      },
    };
    this.timeWindowOptionsDouble = {
      margin: 30, // how many minutes between start and stop
      step: 15,
      start: [480, 840, 1020, 1200],
      connect: [false, true, false, true, false],
      range: {
        min: 0,
        max: 1439
      },
    };
    this.durationOptions = {
      // margin: 15, // how many minutes between start and stop
      step: 15,
      start: [120],
      connect: 'lower',
      range: {
        min: 0,
        max: 1800
      },
    };
  }

  convertSecondsToTime = function () {
    let minutes = 0, seconds = 0;
    minutes = this.convertToHour(this.myForm.value.stopPoint.duration_seconds);
    seconds = this.convertToMinute(this.myForm.value.stopPoint.duration_seconds, minutes);
    this.durationUnformatted = this.formatHoursAndMinutes(minutes, seconds);
  };

  convertValuesToTime() {
    let hours = 0, minutes = 0;
    if (this.myForm.value.stopPoint.doubleTimeWindow) {
      this.myForm.value.stopPoint.time_window_unformatted2.forEach((timeWindow, i) => {
        hours = this.convertToHour(timeWindow);
        minutes = this.convertToMinute(timeWindow, hours);
        this.timeWindowUnformatted[i] = this.formatHoursAndMinutes(hours, minutes);
      });
    } else {
      this.myForm.value.stopPoint.time_window_unformatted.forEach((timeWindow, i) => {
        hours = this.convertToHour(timeWindow);
        minutes = this.convertToMinute(timeWindow, hours);
        this.timeWindowUnformatted[i] = this.formatHoursAndMinutes(hours, minutes);
      });
    }
  }

  convertToHour = function (value) {
    return Math.floor(value / 60);
  };

  convertToMinute = function (value, hour) {
    return value - hour * 60;
  };

  formatHoursAndMinutes = function (hours, minutes) {
    if (hours.toString().length === 1) { hours = '0' + hours; }
    if (minutes.toString().length === 1) { minutes = '0' + minutes; }
    return hours + ':' + minutes;
  };

  prepareForSubmit(pickupId, load, pricelistServiceType) {
    const stopFormValues = this.myForm.value.stopPoint;
    this.errors = [];

    if (stopFormValues.contact_name) {
      this.contactName = stopFormValues.contact_name;
      if (stopFormValues.contact_name === this.noNameLabel) {
        this.contactName = this.noNameConstant;
      }
    } else {
      this.contactName = this.noNameConstant;
    }
    if (stopFormValues.phoneNumber) {
      this.telephone = stopFormValues.countryPrefix + stopFormValues.phoneNumber;
    }
    this.deliverySourceModelId = pickupId;
    this.pricelistServiceType = pricelistServiceType;
    this.deliverySourceModelName = this.globals.stopPointModelName;
    if (!this.positionSet) {
      if (stopFormValues.address.term.customer) {
        this.lat = stopFormValues.address.term.customer.address.lat;
        this.lon = stopFormValues.address.term.customer.address.lon;
        this.freeformAddress = this.addressService.getAddressLabel(stopFormValues.address.term.customer.address);
      } else {
        this.lat = stopFormValues.address.term.position[0];
        this.lon = stopFormValues.address.term.position[1];
        this.freeformAddress = stopFormValues.address.term.title;
      }
      // this.lat = stopFormValues.address.term.position[0];
      // this.lon = stopFormValues.address.term.position[1];
      // this.freeformAddress = stopFormValues.address.term.label;
    }

    this.load = load;
    let durationSeconds;
    if (Array.isArray(stopFormValues.duration_seconds)) {
      durationSeconds = stopFormValues.duration_seconds[0];
    } else {
      durationSeconds = stopFormValues.duration_seconds;
    }
    this.duration = moment.duration(durationSeconds, 'seconds').toISOString();

    this.timeWindows = [];
    const projectProblemTimeStart = moment(this.projectProblemDepartureDatetime).format('HH:mm');
    const projectProblemTimeStartMoment = moment(projectProblemTimeStart, 'HH:mm');

    let index = 0;
    for (let i = 0; i < this.timeWindowUnformatted.length; i = i + 2) {
      const timeWindowStartMoment = moment(this.timeWindowUnformatted[i], 'HH:mm');
      const timeWindowEndMoment = moment(this.timeWindowUnformatted[i + 1], 'HH:mm');
      const timeWindowStart = timeWindowStartMoment.format('HH:mm:SS');

      if (
        moment(timeWindowEndMoment).isBefore(projectProblemTimeStartMoment) &&
        i === this.timeWindowUnformatted.length - 1
      ) {
        this.errors.push(this.timeWindowErrorMsg + ' ' + projectProblemTimeStart);
      }
      const timeWindowRangeDuration = moment.duration(timeWindowEndMoment.diff(timeWindowStartMoment));
      const timeWindowRangeMinutes = timeWindowRangeDuration.asMinutes();
      const timeWindowRange = moment.duration(timeWindowRangeMinutes, 'minutes').toISOString();

      this.timeWindows.push({
        id: this.timeWindowIds[index] ? this.timeWindowIds[index] : null,
        time_window_range: timeWindowRange,
        start: timeWindowStart
      });
      index++;
    }
    if (stopFormValues.companyTimeWindow) {
      this.selectedTimeWindowId = stopFormValues.companyTimeWindow['CompanyTimeWindowCategory']['id'];
    }

    this.patchForm();
  }

  public submitDeliveryForm(pickupId, load, pricelistServiceType) {
    this.prepareForSubmit(pickupId, load, pricelistServiceType);

    const myObserver = {
      next: (data) => {
        this.viewProjectProblemService.updateProjectProblemStatus();
        data['items'].forEach(stopPointResponseData => {
          if (stopPointResponseData.stopPoint.project_problem_id == this.projectProblemId) {
            this.projectProblemDataService.addStopPoint(stopPointResponseData);
          }
        });
      },
      error: (error) => {
        console.error(error);
        // this.isClickedOnce = false;
      },
      complete: () => {
        this.isClickedOnce = false;
        this.resetForm();
        this.toggleModal.emit('close');
      },
    };
    const projectProblemId = this.data?.stopPoint.project_problem_id ?? this.projectProblemId;
    this.stopPointService.saveStopPoint(this.myForm.value, this.stopPointId, projectProblemId, this.inWarehouse).pipe(take(1)).subscribe(myObserver)
  }

  timeWindowCheckboxClicked() {
    if (this.myForm.value.stopPoint.doubleTimeWindow) {
      this.doubleTimeWindow = false;
      this.timeWindowUnformatted = ['08:00', '20:00'];
    } else {
      this.doubleTimeWindow = true;
      this.timeWindowUnformatted = ['08:00', '14:00', '17:00', '20:00'];
    }
  }

  inputFocusOut() {
    if (!this.myForm.value.stopPoint.address.term.timeZone && !this.myForm.value.stopPoint.address.term.customer) {
      if (this.ngSelect.itemsList['_filteredItems']) {
        const firstItem = this.ngSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.ngSelect.select(firstItem);
        }
      }
    }
  }

  inputAddress(isPaste = false) {
    this.selectedAddress = '';
    this.myForm.patchValue({
      'stopPoint': {
        'address': {
          'term': this.selectedAddress,
        },
      },
    });

    if (isPaste) {
      setTimeout(() => {
        this.ngSelect.filter((<HTMLInputElement>document.getElementById('delivery-custom-input')).value);
      }, 50);
    } else {
      this.ngSelect.filter((<HTMLInputElement>document.getElementById('delivery-custom-input')).value);
    }
  }

  onAddressChange() {
    const stopFormValues = this.myForm.value.stopPoint;
    // TODO update the day of week and selected timewindows
    (<HTMLInputElement>document.getElementById('delivery-custom-input')).value = stopFormValues.address.term.label;
    if (stopFormValues.address.term) {
      if (stopFormValues.address.term.timeZone) {
        const timeZone = stopFormValues.address.term.timeZone;
        const targetDateTime = moment(this.projectProblemDepartureDatetime).tz(timeZone);
        this.projectProblemDayOfWeek = this.days[moment(targetDateTime).day()];
        if (this.selectedTimeWindow) {
          if (this.selectedTimeWindow['CompanyTimeWindowCategory']['time_windows']) {
            this.timeWindows = this.selectedTimeWindow['CompanyTimeWindowCategory']['time_windows'][this.projectProblemDayOfWeek];
            this.setTimeWindows();
          }
        }
      }
      if (stopFormValues.address.term.customer) {
        const customer = stopFormValues.address.term.customer;
        this.lat = customer.address.lat;
        this.lon = customer.address.lon;
        this.freeformAddress = this.addressService.getAddressLabel(customer.address);
        // this.draggableMarkerService.addDraggableMarker(Number(this.lat), Number(this.lon));

        this.serviceType = customer.stop_point_default_service_type;
        this.contactName = customer.contact_name;
        if (this.contactName === this.noNameConstant) {
          this.contactName = this.noNameLabel;
        }
        if (customer.telephone === 'n/a') { customer.telephone = ''; }
        this.telephone = customer.telephone;
        this.phoneNumber = this.telephone;
        if (this.telephone === 'n/a') { this.telephone = ''; }
        if (this.telephone === '+30n/a') { this.telephone = ''; }
        if (this.telephone) {
          if (this.telephone.length > 5) {
            const phoneObj = libphonenumber.parsePhoneNumber(this.telephone);
            this.phoneNumber = phoneObj.nationalNumber;
            this.countryPrefix = '+' + phoneObj.countryCallingCode;
          }
        }
        this.email = customer.email;

        this.timeWindows = customer.time_windows[this.projectProblemDayOfWeek];
        this.setTimeWindows();

        this.duration = customer.stop_point_default_duration;
        this.durationSeconds = [moment.duration(this.duration).asSeconds()];
        this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);

        this.entityStatus = customer.entity_status;

        this.notes = customer.note ? customer.note : '';

        this.setForm();
      } else {
        this.lat = stopFormValues.address.term.position[0];
        this.lon = stopFormValues.address.term.position[1];
        this.freeformAddress = stopFormValues.address.term.label;
        this.setAddress(stopFormValues.address.term.address);
        // this.draggableMarkerService.addDraggableMarker(Number(this.lat), Number(this.lon));
      }
    }
    this.updateFormValidity.emit('true');
  }

  addAddressesAutofillEvents() {
    setTimeout(() => {
      document.getElementById('delivery-custom-input').addEventListener('onautocomplete', () => {
        // load first address found
        this.addressAutofillInterval = setInterval(() => {
          if (this.ngSelect.itemsList['_filteredItems']) {
            const firstItem = this.ngSelect.itemsList['_filteredItems'][0];
            if (firstItem) {
              this.ngSelect.select(firstItem);
              clearInterval(this.addressAutofillInterval)
              this.addressAutofillInterval = null;
            }
          }
        }, 500);
      });
    }, 1000);
  }

  populatePaymentOptions() {
    this.paymentOptions = [
      {
        value: this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'],
        label: this.cashLabel
      },
      {
        value: this.globals.paymentOptionsConstants['CREDIT_CARD'],
        label: this.creditCardLabel
      },
      {
        value: this.globals.paymentOptionsConstants['BANK_DEPOSIT'],
        label: this.bankDepositLabel
      },
      {
        value: this.globals.paymentOptionsConstants['CHEQUE'],
        label: this.chequeLabel
      },
      {
        value: this.globals.paymentOptionsConstants['DAY_CHEQUE'],
        label: this.chequeDayLabel
      }
    ];
  }

  updateSelectedPaymentMethod(data) {
    this.requestedPayOnDeliveryPaymentMethod = data['value'];
    this.chequeCollectionDatePayOnDelivery = moment().add(8, 'days').format('YYYY-MM-DD');

    if (this.stopPointId) {
      this.executedPayOnDeliveryPaymentMethod = data['value'];
    } else {
      this.executedPayOnDeliveryPaymentMethod = null;
    }

    this.myForm.patchValue({
      'stopPoint': {
        'requested_pay_on_delivery_payment_method': this.requestedPayOnDeliveryPaymentMethod,
        'executed_pay_on_delivery_payment_method': this.executedPayOnDeliveryPaymentMethod,
        'cheque_collection_date_pay_on_delivery': this.chequeCollectionDatePayOnDelivery
      }
    });

    M.updateTextFields();
  }

  getTranslations() {
    this.listen.push(this.translate.get('STOP_POINT').subscribe((res: string) => {
      this.creditCardLabel = res['CREDIT_CARD'];
      this.cashLabel = res['CASH'];
      this.payOnDeliveryLabel = res['PAY_ON_DELIVERY'];
      this.bankDepositLabel = res['BANK_DEPOSIT'];
      this.chequeLabel = res['CHEQUE'];
      this.chequeDayLabel = res['DAY_CHEQUE'];
      this.populatePaymentOptions();
    }));
    this.listen.push(this.translate.get('ERROR.TIME_WINDOW_ERROR_MSG').subscribe((res: string) => { this.timeWindowErrorMsg = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
    this.projectProblemDayOfWeek = this.days[moment(this.projectProblemDepartureDatetime).day()];
    this.timeZone = moment.tz.guess();

    this.countryPrefix = this.globals.defaultCountryCode;
    this.duration = moment.duration(30, 'm').toISOString();
    const departureTimeElement = document.querySelectorAll('.timepicker');
    const timeInstances = M.Timepicker.init(departureTimeElement, {
      twelveHour: false
    });
    this.requestedPayOnDeliveryPaymentMethod = this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
    this.setForm();

    this.addresses = concat(
      of([]), // default items
      this.addressInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.addressesLoading = true),
        switchMap(term => this.dataService.getAddressesAndCustomers(term, this.projectProblemId).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.addressesLoading = false)
        ))
      )
    );

    const timeWindowsDataRefreshIntervalId = setInterval(timeWindowsDataChecker.bind(this), 200);
    function timeWindowsDataChecker() {
      if (this.globals.timeWindowsDataDone) {
        clearInterval(timeWindowsDataRefreshIntervalId);
        this.customTimeWindows = this.globals.customTimeWindows;
      }
    }
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
