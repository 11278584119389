import { CommonModule } from '@angular/common';
import { Directive, NgModule, ElementRef, Input, Optional, Host, Output, EventEmitter, HostBinding, Renderer2 } from '@angular/core';

@Directive({
  selector: '[translator]', 
  exportAs: 'translator'
})
export class LmTranslatorDirective {
  @Input('translator') translator;
  @Input('translatorElement') translatorElement;

  constructor(private el: ElementRef, private renderer: Renderer2){}
  child: HTMLElement|Element;

  ngAfterViewInit(): void {
    setTimeout(() => {
      const _el:HTMLElement = this.el.nativeElement;
      const target = (this.translatorElement && _el.querySelector(this.translatorElement)) ?? _el.querySelector('.lm-translator');
      if (target) target.textContent = this.translator;
  })

}}

@NgModule({
  imports: [CommonModule],
  exports: [LmTranslatorDirective],
  declarations: [LmTranslatorDirective]
})
export class LmTranslatorModule {}
