/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LmProjectProblemService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  // ------------------------- ADD DTOs ---------------------
  // GET BY ID
  static readonly projectProblemPostPath = 'api/v1/project/{projectId}/problem';

  // POST 
  apiProjectProblemPost$Response(params?: { body?: any, id?: string }): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmProjectProblemService.projectProblemPostPath, 'post');
    if (params) rb.body(params.body, 'application/*+json');
    if (params) rb.path('projectId', params.id);
    console.log(params);

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<any>>;
        })
      );
  }


  apiProjectProblemPost(params?: { body?: any, id?: string }): Observable<Array<any>> {
    return this.apiProjectProblemPost$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }

}
