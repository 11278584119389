import { ModalService } from '@app/services/modal.service';
import { DepotUtils } from '@app/utils/depot-utils';
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as moment from 'moment';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { Module } from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { MilyService } from '@app/services/mily.service';
import { GridsService } from '@app/services/grids.service';
import { AddressService } from '@app/services/address.service';
import { concat, Subject, of, Observable } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { DataService } from '@app/services/data.service';

@Component({
  selector: 'app-add-stop-grid',
  templateUrl: './add-stop-grid.component.html',
  styleUrls: ['./add-stop-grid.component.scss']
})
export class AddStopGridComponent implements OnInit {
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;

  listen = [];

  public modules: Module[] = [
    ServerSideRowModelModule,
    MenuModule,
    ColumnsToolPanelModule,
  ];
  columnDefs;
  rowModelType = 'serverSide';
  cacheBlockSize = 50;
  gridApi;
  gridColumnApi;
  stopPointsDataArray = [];
  rowData = [];
  projectProblemId;

  mode = '';
  selectAllCheckbox = false;
  isThroughHub = false;
  lastStopPointId = null;
  recurringPage = 0;
  cancelledPage = 0;
  nameTitle;
  addressTitle;
  timeWindowTitle;
  reasonTitle;
  volumeTitle;
  collaboratorTitle;
  notRoutedMsg = '';
  agreedShippingToday = '';

  isLastPage = false;
  noNameLabel = '';
  newMsg;

  partners = [];
  selectedPartnerItem;

  selectedCollaborator = null;
  collaboratorNames: Observable<any>;
  collaboratorNamesLoading = false;
  collaboratorNameInput = new Subject<string>();

  searchTextChanged = new Subject<string>();
  searchString: String = '';

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private viewProjectProblemService: ViewProjectProblemService,
    private milyService: MilyService,
    public gridsService: GridsService,
    private addressService: AddressService,
    private projectProblemDataService: ProjectProblemDataService,
    public stopPointUtils: StopPointUtils,
    private dataService: DataService,
    private modalService: ModalService,
    private depotUtils: DepotUtils,
  ) { }

  timeWindowRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().timeWindow) {
        columnObject += '<div class="double-cell standard-width text-center" title="' + params.getValue().timeWindow + '">' + params.getValue().timeWindow + '</div>';
        columnObject += '<div class="double-cell text-center"><div class="center-content"><span class="svg-fix">' + params.getValue().icon + '</span> <span>' + params.getValue().duration + '`</span></div></div>';
      }
    }
    return columnObject;
  }

  volumeRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().deliveryLoad) {
        columnObject += '<div class="double-cell text-center"><i class="fas fa-arrow-down"></i> ' + params.getValue().deliveryLoad + '</div>';
      }
      if (params.getValue().pickupLoad) {
        columnObject += '<div class="double-cell text-center"><i class="fas fa-arrow-up"></i> ' + params.getValue().pickupLoad + '</div>';
      }
      if (params.getValue().new) {
        columnObject += '<div class="grid-new-line-tag">' + this.newMsg + '</div>';
      }
    }
    return columnObject;
  }

  search($event) {
    this.searchTextChanged.next($event);
  }

  rowClicked(event) {
    if (event.data.isStopPoint) {
      this.viewProjectProblemService.openStopModal(
        event.data.id,
        event.data.projectProblemId,
        event.data.projectProblemDepartureDatetime,
      );
    }
  }

  onDepotChanged() {
    this.updateGrid();
  }

  checkForCancelledStops() {
    let url = 'api/v1/company/warehouse';
    url += '?stopPointId=null';
    url += '&limit=1';
    url += '&projectProblemId=' + this.projectProblemId;

    this.http.get(url).pipe(take(1)).subscribe(response => {
      if (response['items']['stopPoints']) {
        if (response['items']['stopPoints'].length) {
          this.milyService.loadCancelledPoints();
        }
      }
    });
  }

  checkForRecurringStops() {
    let url = 'api/v1/project/problems/' + this.projectProblemId + '/reappear-stop-points';
    url += '?pageSize=1';
    url += '&page=0';
    this.http.get(url).pipe(take(1)).subscribe(response => {
      if (response['items']) {
        if (response['items'].length) {
          this.milyService.loadRecurringPoints();
        }
      }
    });
  }

  getStopsGridData(mode) {
    this.mode = mode;
    setTimeout(() => {
      if (mode === 'recurring') {
        this.gridApi.setServerSideDatasource(this.createRecurringGridServerSideDatasource());
      } else if (mode === 'recipients') {
        this.gridApi.setServerSideDatasource(this.createRecipientsGridServerSideDatasource());
      } else {
        this.gridApi.setServerSideDatasource(this.createWarehouseGridServerSideDatasource());
      }
    }, 100);
  }

  createRecipientsGridServerSideDatasource() {
    return {
      getRows: (params) => {
        let url = 'api/v1/customers';
        url += '?pageSize=' + this.cacheBlockSize;
        url += '&page=' + this.recurringPage;
        url += '&searchQuery=' + this.searchString;
        this.http.get(url).pipe(take(1)).subscribe(response => {
          let lastRow = -1;
          // if we have cancelled stops
          if (response['items']) {
            if (response['items'].length) {
              this.isLastPage = false;
              this.recurringPage++;
              if (response['items'].length < this.cacheBlockSize) {
                lastRow = response['items'].length + ((this.recurringPage - 1) * this.cacheBlockSize);
                this.isLastPage = true;
              }
              params.successCallback(this.setRecipientStopsGridData(response['items']), lastRow);
              this.modalService.openAddStopModal();
            } else {
              // return no data to modal and load cancelled mode
              if (this.gridApi.getDisplayedRowCount()) {
                params.successCallback([], 0);
                if (!this.searchString) {
                  this.modalService.closeAddStopModal();
                }
              }
            }
          }
        }, error => {
          params.failCallback();
        });
      },
    };
  }

  createRecurringGridServerSideDatasource() {
    return {
      getRows: (params) => {
        let url = 'api/v1/project/problems/' + this.projectProblemId + '/reappear-stop-points';
        url += '?pageSize=' + this.cacheBlockSize;
        url += '&page=' + this.recurringPage;
        url += '&searchQuery=' + this.searchString;
        this.http.get(url).pipe(take(1)).subscribe(response => {
          let lastRow = -1;
          // if we have cancelled stops
          if (response['items']) {
            if (response['items'].length) {
              this.isLastPage = false;
              this.recurringPage++;
              if (response['items'].length < this.cacheBlockSize) {
                lastRow = response['items'].length + ((this.recurringPage - 1) * this.cacheBlockSize);
                this.isLastPage = true;
              }
              params.successCallback(this.setRecipientStopsGridData(response['items']), lastRow);
              this.modalService.openAddStopModal();
            } else {
              // return no data to modal and load cancelled mode
              if (this.gridApi.getDisplayedRowCount()) {
                params.successCallback([], 0);
                if (!this.searchString) {
                  this.modalService.closeAddStopModal();
                }
              }
            }
          }
        }, error => {
          params.failCallback();
        });
      },
    };
  }

  createWarehouseGridServerSideDatasource() {
    return {
      getRows: (params) => {
        let url = 'api/v1/company/warehouse';
        url += '?stopPointId=' + this.lastStopPointId;
        url += '&limit=' + this.cacheBlockSize;
        if (this.mode === 'newShipments') {
          url += '&isFromCollaborator=true';
        } else if (this.mode === 'newStopPointsFromDrivers') {
          url += '&isSentFromDriver=true';
        }
        url += '&searchQuery=' + this.searchString;
        if (this.mode === 'cancelled') {
          if (this.globals.isInRoute('partnerView/newShipmentsView')) {
            url += '&isForPartner=true';
          }
          if (this.projectProblemId) {
            url += '&projectProblemId=' + this.projectProblemId;
          }
          if (this.isThroughHub) {
            url += '&pricelist_service_type=' + this.globals.priceListServicesEnum.ThroughHub.key;
          }
        }

        url += '&depotId=' + String(this.projectProblemDataService.projectDepotId);

        this.http.get(url).pipe(take(1)).subscribe(response => {
          let lastRow = -1;
          // if we have cancelled stops
          if (response['items']['stopPoints']) {
            if (response['items']['stopPoints'].length) {
              this.isLastPage = false;
              this.cancelledPage++;
              if (response['items']['stopPoints'].length < this.cacheBlockSize) {
                lastRow = response['items']['stopPoints'].length + ((this.cancelledPage - 1) * this.cacheBlockSize);
                this.isLastPage = true;
              }
              this.lastStopPointId = response['items']['stopPointId'];

              const data = this.setCancelledStopsGridData(response['items']['stopPoints']);
              params.successCallback(data, lastRow);
              if (this.mode === 'newShipments' || this.mode === 'newStopPointsFromDrivers') {
                this.selectAllCheckbox = false;
                this.selectAll();
                this.selectAllCheckbox = true;
              }
              this.modalService.openAddStopModal();
            } else {
              if (this.gridApi.getDisplayedRowCount()) {
                params.successCallback([], 0);
                if (!this.searchString && !this.isThroughHub) {
                  this.modalService.closeAddStopModal();
                }
              }
            }
          }

        }, error => {
          this.modalService.closeAddStopModal();
          params.failCallback();
        });
      },
    };
  }

  setCancelledStopsGridData(data) {
    const stopPointsArray = data;
    // const stopPointsArray = data.stopPoints;
    // const projectProblemsObject = this.stopPointUtils.getProjectProblemsObject(data.projectProblems);
    this.stopPointsDataArray = [];
    let stopPoint, duration, timeWindowLabel, gridObject = {}, reason = '';
    let timeWindowStartOffsetFromDeparture, timeWindowStartOffsetFromDepartureMinutes, timeWindowRangeMinutes, timeWindowStart, timeWindowEnd;
    stopPointsArray.forEach(stopPointData => {
      stopPoint = stopPointData.stopPoint;
      timeWindowLabel = '';
      reason = this.notRoutedMsg;
      if (stopPoint.agreed_shipping) {
        if (stopPoint.agreed_shipping[0]) {
          if (stopPoint.agreed_shipping[0].date) {
            reason = this.agreedShippingToday;
          }
        }
      }
      if (stopPoint.fulfillment_status === 8) {
        stopPoint.fulfillment_events.forEach(event => {
          if (event.reason > 799 && event.description) {
            reason = event.description;
          }
        });
      }
      duration = [moment.duration(stopPoint.duration).asMinutes()];
      stopPoint.time_windows.forEach(timeWindow => {
        timeWindowRangeMinutes = moment.duration(timeWindow.time_window_range).asMinutes();
        timeWindowStartOffsetFromDeparture = timeWindow.time_window_start_offset_from_departure;
        timeWindowStartOffsetFromDepartureMinutes = moment.duration(timeWindowStartOffsetFromDeparture).asMinutes();
        timeWindowStart = moment(timeWindow.start, 'HH:mm:SS').format();
        timeWindowEnd = moment(timeWindowStart).add(timeWindowRangeMinutes, 'minutes').format();
        if (timeWindowLabel) { timeWindowLabel += ' & '; }
        timeWindowLabel += moment(timeWindowStart).format('HH:mm') + ' - ' + moment(timeWindowEnd).format('HH:mm');
      });
      let name = stopPoint.contact_name;
      if (name === '_NO_NAME') {
        name = this.noNameLabel;
      }

      let departureDatetime = stopPointData.projectProblem ? stopPointData.projectProblem.departure_datetime : null
      let projectProblemId = stopPointData.projectProblem ? stopPointData.projectProblem.id : null
      // let departureDatetime = null;
      // let projectProblemId = stopPointData.stopPoint.project_problem_id;
      // if (projectProblemsObject[projectProblemId]) {
      //   departureDatetime = projectProblemsObject[projectProblemId]['departure_datetime'];
      // }

      let collaboratorName = '';
      if (stopPoint.collaborator_name) {
        collaboratorName = stopPoint.collaborator_name;
      }
      if (stopPoint.collaborator) {
        collaboratorName = stopPoint.collaborator.collaboratorData.collaborator_name;
      }

      let isNew = false;
      const creationDate = moment(stopPoint.creation_datetime).format('DD/MM/YYYY');
      const today = moment().format('DD/MM/YYYY');
      if (creationDate === today) {
        isNew = true;
      }
      gridObject = {
        id: stopPoint.id,
        projectProblemId: stopPoint.project_problem_id,
        projectProblemDepartureDatetime: departureDatetime,
        isStopPoint: true,
        name: {
          name: name,
          phone: stopPoint.telephone ?? '-',
        },
        address: this.addressService.getAddressLabel(stopPoint.address),
        reason: reason,
        collaborator: collaboratorName,
        timeWindow: {
          duration: duration,
          timeWindow: timeWindowLabel,
          icon: this.svgIconsComponent.timelapse
        },
        volume: {
          deliveryLoad: stopPoint.load,
          pickupLoad: stopPoint.load,
          new: isNew,
        },
        fullData: stopPoint,
      };
      this.stopPointsDataArray.push(gridObject);
    });
    return this.stopPointsDataArray;
  }

  setRecipientStopsGridData(data) {
    let customer, duration, timeWindowLabel, gridObject = {};
    let timeWindowRangeMinutes, timeWindowStart, timeWindowEnd;
    this.stopPointsDataArray = [];
    data.forEach(customerData => {
      customer = customerData.customer;
      timeWindowLabel = '';
      duration = [moment.duration(customer.stop_point_default_duration).asMinutes()];
      const weekday = customer.weekday ?? this.projectProblemDataService.projectProblemDayOfWeek;
      customer.time_windows[weekday].forEach(timeWindow => {
        timeWindowRangeMinutes = moment.duration(timeWindow.time_window_range).asMinutes();
        timeWindowStart = moment(timeWindow.time_window_start_datetime).format();
        timeWindowEnd = moment(timeWindowStart).add(timeWindowRangeMinutes, 'minutes').format();
        if (timeWindowLabel) { timeWindowLabel += ' & '; }
        timeWindowLabel += moment(timeWindowStart).format('HH:mm') + ' - ' + moment(timeWindowEnd).format('HH:mm');
      });
      gridObject = {
        id: customer.id,
        name: {
          name: customer.contact_name,
          phone: customer.telephone
        },
        address: this.addressService.getAddressLabel(customer.address),
        timeWindow: {
          duration: duration,
          timeWindow: timeWindowLabel,
          icon: this.svgIconsComponent.timelapse
        },
        volume: {
          deliveryLoad: customer.stop_point_default_delivery_load,
          pickupLoad: customer.stop_point_default_pickup_load
        },
        fullData: customer
      };
      this.stopPointsDataArray.push(gridObject);
    });
    return this.stopPointsDataArray;
  }

  resetGrid() {
    this.selectedCollaborator = null;
    this.selectAllCheckbox = false;
    this.isThroughHub = false;
    this.lastStopPointId = null;
    this.recurringPage = 0;
    this.cancelledPage = 0;
    this.searchString = '';
    this.unSelectAll();
  }

  updateGrid() {
    this.selectAllCheckbox = false;
    this.lastStopPointId = null;
    this.recurringPage = 0;
    this.cancelledPage = 0;
    this.unSelectAll();
    // this.gridApi.purgeServerSideCache([]);
    this.gridApi.refreshServerSideStore({ purge: true });
  }

  getSelectedRows() {
    return this.gridApi.getSelectedRows();
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  selectAll() {
    if (!this.selectAllCheckbox) {
      this.gridApi.forEachNode((row, index) => {
        this.gridApi.getRowNode(row.id).selectThisNode(true);
      });
    } else {
      this.gridApi.forEachNode((row, index) => {
        this.gridApi.getRowNode(row.id).selectThisNode(false);
      });
    }
  }

  unSelectAll() {
    this.gridApi.forEachNode((row, index) => {
      this.gridApi.getRowNode(row.id).selectThisNode(false);
    });
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.nameTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.ADDRESS').subscribe((res: string) => { this.addressTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.TIME_WINDOW').subscribe((res: string) => { this.timeWindowTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.REASON').subscribe((res: string) => { this.reasonTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.COLLABORATOR').subscribe((res: string) => { this.collaboratorTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.VOLUME').subscribe((res: string) => { this.volumeTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.NOT_ROUTED').subscribe((res: string) => { this.notRoutedMsg = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.AGREED_SHIPPING').subscribe((res: string) => { this.agreedShippingToday = res; }));
    this.listen.push(this.translate.get('GENERIC.NEW').subscribe((res: string) => { this.newMsg = res; }));

    this.columnDefs = [
      {
        headerName: this.nameTitle,
        field: 'name',
        checkboxSelection: true, headerClass: 'header-with-checkbox',
        cellRenderer: this.gridsService.nameRenderer,
        width: this.gridsService.widthCalculator(15)
      },
      {
        headerName: this.addressTitle,
        field: 'address',
        cellRenderer: this.gridsService.addressRenderer,
        width: this.gridsService.widthCalculator(15)
      },
      {
        headerName: this.reasonTitle,
        field: 'reason',
        width: this.gridsService.widthCalculator(7)
      },
      {
        headerName: this.collaboratorTitle,
        field: 'collaborator',
        width: this.gridsService.widthCalculator(7)
      },
      {
        headerName: this.volumeTitle,
        field: 'volume',
        headerClass: 'text-center',
        cellRenderer: this.volumeRenderer.bind(this),
        width: this.gridsService.widthCalculator(6)
      },
    ];
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    // get partners
    this.selectedPartnerItem = {};
    if (this.globals.partnersData) {
      if (this.globals.partnersData['companies']) {
        this.globals.partnersData['companies'].forEach(partner => {
          this.partners.push({
            id: partner.id,
            name: partner.name
          })
        });
      } else {
        this.globals.partnersData.forEach(partner => {
          if (partner.companyCollaborator) {
            if (partner.companyCollaborator.partnerCompany) {
              this.partners.push({
                id: partner.companyCollaborator.partnerCompany.id,
                name: partner.companyCollaborator.collaborator.collaboratorData.collaborator_name
              });
            }
          }
        });
      }
      this.selectedPartnerItem = this.partners[0];
      M.updateTextFields();
    }

    this.collaboratorNames = concat(
      of([]), // default items
      this.collaboratorNameInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.collaboratorNamesLoading = true),
        switchMap(term => this.dataService.getCollaborators(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.collaboratorNamesLoading = false)
        ))
      )
    );

    this.searchTextChanged.pipe(
      debounceTime(700),
      distinctUntilChanged()).subscribe((text: string) => {
        this.updateGrid();
      });
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
