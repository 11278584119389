import { DepotUtils } from './../../utils/depot-utils';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { ModalGridService } from '@app/services/modal-grid.service';
import { take } from 'rxjs/operators';
import Cropper from 'cropperjs';
import { FormErrorsUtils } from '@app/utils/form-errors-utils';
import { ImageUtils } from '@app/utils/image-utils';
import * as libphonenumber from 'libphonenumber-js';

@Component({
  selector: 'app-member-form',
  templateUrl: './member-form.component.html',
  styleUrls: ['./member-form.component.scss']
})
export class MemberFormComponent implements OnInit {

  cropper;
  imageSliderOptions;
  imageSliderValue;
  slideValGlobal = 0.5;
  imageBase64;
  imageHash;
  avatar = '';
  avatarId = null;
  avatarSrc = 'data:image/png;base64,AVATAR_HASH'

  myForm: FormGroup;
  errors;
  memberId = null;
  name: String = '';
  picture = {};
  email: String = '';
  countryPrefix = '';
  phoneNumber = '';
  telephone = '';
  password: String = '';
  accessGroup = 1;
  rolesOptions;
  depotIds = [-1];
  depotsOptions = [];
  driverIds = [-1];
  driversOptions = [];
  status = 1;
  isClickedOnce = false;

  adminLabel = '';
  advancedLabel = '';
  viewerLabel = '';
  standardLabel = '';
  allDepotsLabel;
  allDriversLabel;

  allDrivers = [];

  listen = [];

  canSetPassword = true;

  constructor(
    private http: HttpClient,
    formBuilder: FormBuilder,
    public globals: Globals,
    private modalGridService: ModalGridService,
    private translate: TranslateService,
    private depotUtils: DepotUtils,
    private formErrorsUtils: FormErrorsUtils,
    private imageUtils: ImageUtils
  ) {
    this.myForm = formBuilder.group({
      'userProfile': formBuilder.group({
        'picture': [this.picture],
        'name': [this.name, Validators.required],
        'email': [this.email, Validators.required],
        'countryPrefix': [this.countryPrefix],
        'phoneNumber': [this.phoneNumber],
        'telephone': [this.telephone],
        'password': [this.password],
        'access_group': [this.accessGroup],
        'depot_ids': [{ value: this.depotIds, disabled: true }],
        'driver_ids': [{ value: this.driverIds, disabled: true }]
      })
    });
  }

  public getFormData(id) {
    this.http.get('api/v1/office-members/' + id).pipe(take(1)).subscribe(response => {
      const data = response['item']['userProfile'];
      // loadData
      this.memberId = data.id;
      this.accessGroup = data.access_group;

      if (data.access_group != this.globals.teamMemberTypeConstants['ADMIN']) {
        this.myForm.controls['userProfile']['controls']['depot_ids'].enable();
        this.myForm.controls['userProfile']['controls']['driver_ids'].enable();
        this.driverIds = data.driver_ids;
        this.depotIds = data.depot_ids;
        this.canSetPassword = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'];
      } else {
        this.driverIds = [-1];
        this.depotIds = [-1];
        this.myForm.controls['userProfile']['controls']['depot_ids'].disable();
        this.myForm.controls['userProfile']['controls']['driver_ids'].disable();
        this.canSetPassword = false;
      }
      if (this.memberId == this.globals.userProfileId) {
        this.canSetPassword = true;
      }

      if (data.picture) {
        this.imageHash = data.picture.imageHash;
        if (this.imageHash) {
          this.loadAvatarAsync(this.imageHash);
        }
      }
      this.name = data.name;
      this.email = data.email;
      
      if (data.telephone === 'n/a') { data.telephone = ''; }
      if (data.telephone) {
        if (data.telephone.length > 5) {
          const phoneObj = libphonenumber.parsePhoneNumber(data.telephone);
          this.phoneNumber = phoneObj.nationalNumber;
          this.countryPrefix = '+' + phoneObj.countryCallingCode;
        }
      }

      this.updateAvailableDrivers();
      this.setForm();
    });
  }

  loadAvatarAsync(imageHash) {
    this.imageUtils.fetchImagesViaHashes(`api/internal/v1/images/user-profile`, [imageHash]).then(avatars => {
      if (avatars[0]) {
        this.imageBase64 = avatars[0];
        this.avatar = this.avatarSrc.replace('AVATAR_HASH', this.imageBase64);
      } else this.avatar = undefined;
    });
  }

  patchForm() {
    this.myForm.patchValue({
      'userProfile': {
        'telephone': this.telephone,
      }
    });
    M.updateTextFields();
  }

  setForm() {
    this.myForm.setValue({
      'userProfile': {
        'picture': this.picture,
        'name': this.name,
        'email': this.email,
        'countryPrefix': this.countryPrefix,
        'phoneNumber': this.phoneNumber,
        'telephone': this.telephone,
        'password': this.password,
        'access_group': this.accessGroup,
        'depot_ids': this.depotIds,
        'driver_ids': this.driverIds,
      }
    });
    M.updateTextFields();
  }

  changeRoleGroup() {
    const selectedRole = this.myForm.value.userProfile.access_group;

    // Admin
    if (selectedRole == this.globals.teamMemberTypeConstants['ADMIN']) {
      this.depotIds = [-1];
      this.driverIds = [-1];
      this.myForm.patchValue({
        'userProfile': {
          'depot_ids': this.depotIds,
          'driver_ids': this.driverIds
        }
      });

      this.myForm.controls['userProfile']['controls']['depot_ids'].disable();
      this.myForm.controls['userProfile']['controls']['driver_ids'].disable();
    }
    else {
      this.myForm.patchValue({
        'userProfile': {
          'depot_ids': this.depotIds,
          'driver_ids': this.driverIds
        }
      });
      this.myForm.controls['userProfile']['controls']['depot_ids'].enable();
      this.myForm.controls['userProfile']['controls']['driver_ids'].enable();
    }
  }

  // updates the list of available drivers
  updateAvailableDrivers() {
    this.driversOptions = [{
      id: -1,
      name: this.allDriversLabel
    }];

    // if specific depot(s) are selected, make available drivers that belong to those depots only
    if (this.depotIds[0] != -1) {
      this.depotIds.forEach(depotId => {
        this.driversOptions = [...this.driversOptions.concat(this.depotUtils.getDriversOfDepot(this.allDrivers, depotId))];
      });
    }
    // if the 'All depots' option is selected, make all drivers available
    else {
      if (this.allDrivers.length) {
        this.allDrivers.forEach(driver => {
          this.driversOptions.push({
            id: driver.driver.id,
            name: driver.userProfile.name
          });
        });
      }
    }

    // remove any non-existent drivers from the selected drivers
    let availableDriversIds = [];
    this.driverIds.forEach((driverId) => {
      const isDriverAvailable = this.driversOptions.some(driverToCheck => { return (driverToCheck.id == driverId) ? true : false });
      if (isDriverAvailable) {
        availableDriversIds.push(driverId);
      }
    });

    this.driverIds = [];
    if (availableDriversIds.length) {
      availableDriversIds.forEach(id => {
        this.driverIds.push(id);
      });
    }
    // if no more drivers are selected, set dropdown value to 'All drivers'
    else {
      this.driverIds.push(-1);
    }

    this.myForm.patchValue({
      'userProfile': {
        'driver_ids': this.driverIds
      }
    });
  }

  onDepotAdded(addedDepot) {
    // if "All" exists, remove it before inserting the selected depot
    if (this.depotIds) {
      if (this.depotIds[0] == -1) {
        this.depotIds.splice(0, 1);
      }
    }

    // if "All" is the added depot, clear selections and insert only "All"
    if (addedDepot.id == -1) {
      this.depotIds = [-1];
    }
    // if it's a normal depot, then just insert it
    else {
      this.depotIds.push(addedDepot.id);
    }

    this.updateAvailableDrivers();

    this.myForm.patchValue({
      'userProfile': {
        'depot_ids': this.depotIds
      }
    });
  }

  onDepotRemoved(removedDepot) {
    let indexToRemove = this.depotIds.findIndex(depotId => depotId == removedDepot.value.id);
    this.depotIds.splice(indexToRemove, 1);

    // if no depots are selected after removal, use 'All'
    if (!this.depotIds.length) {
      this.depotIds.push(-1);
    }

    this.updateAvailableDrivers();

    this.myForm.patchValue({
      'userProfile': {
        'depot_ids': this.depotIds
      }
    });
  }

  onDriverAdded(addedDriver) {
    // if "All" exists, remove it before inserting the selected driver
    if (this.driverIds) {
      if (this.driverIds[0] == -1) {
        this.driverIds.splice(0, 1);
      }
    }

    // if "All" is the added driver, clear selections and insert only "All"
    if (addedDriver.id == -1) {
      this.driverIds = [-1];
    }
    // if it's a normal driver, then just insert them
    else {
      this.driverIds.push(addedDriver.id);
    }

    this.myForm.patchValue({
      'userProfile': {
        'driver_ids': this.driverIds
      }
    });
  }

  onDriverRemoved(removedDriver) {
    let indexToRemove = this.driverIds.findIndex(driverId => driverId == removedDriver.value.id);
    this.driverIds.splice(indexToRemove, 1);

    // if no drivers exist, use 'All drivers'
    if (!this.driverIds.length) {
      this.driverIds.push(-1);
    }

    this.myForm.patchValue({
      'userProfile': {
        'driver_ids': this.driverIds
      }
    });
  }

  resetForm() {
    this.errors = [];
    this.memberId = null;
    this.picture = {};
    this.name = '';
    this.email = '';
    this.countryPrefix = this.globals.defaultCountryCode;
    this.phoneNumber = '';
    this.telephone = '';
    this.password = '';
    this.status = 1;
    this.myForm.markAsUntouched();
    this.myForm.markAsPristine();
    this.imageHash = null
    this.imageBase64 = '';
    this.avatar = '';
    this.cropper = '';
    this.avatarId = null;
    this.accessGroup = this.globals.teamMemberTypeConstants['ADMIN'];
    this.depotIds = [-1];
    this.driverIds = [-1];
    this.setForm();
    this.canSetPassword = true;

    this.myForm.controls['userProfile']['controls']['depot_ids'].disable();
    this.myForm.controls['userProfile']['controls']['driver_ids'].disable();
  }

  initializeSelect() {
    const elems = document.querySelectorAll('select');
    const instances = M.FormSelect.init(elems);
  }

  // Avatar Popup
  resetUploaderPopup() {
    // this.imageBase64 = '';
    // this.avatar = '';
    const imageElement = document.getElementById('cropper-image-container'),
      imgPreview = document.querySelector('#preview-img') as HTMLImageElement;
    imgPreview.src = '/assets/lastmilyAssets/user.png';
    // if (imageElement) {
    imageElement.innerHTML = '';
    // }
    // if (this.cropper) {
    //   this.cropper.reset();
    // }
    this.cropper = '';
  }

  openUploaderPopup() {
    this.resetUploaderPopup();
    document.getElementById('avatar-upload-popup').classList.add('open');
    document.getElementById('avatar-upload-popup-bg').classList.remove('hidden');
  }

  closeUploaderPopup() {
    document.getElementById('avatar-upload-popup').classList.remove('open');
    document.getElementById('avatar-upload-popup-bg').classList.add('hidden');
  }

  cropImgFunc(event) {
    let result = document.querySelector('#cropper-image-container'),
      imgPreview = document.querySelector('#preview-img') as HTMLImageElement;
    if (event.target.files.length) {
      // start file reader
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target.result) {
          // create new image
          let img = document.createElement('img');
          img.id = 'image';
          img.src = String(event.target.result);
          img.width = 544;
          img.height = 370;
          // clean result before
          result.innerHTML = '';
          // append new image
          result.appendChild(img);
          // init cropper
          const cropper = new Cropper(img, {
            viewMode: 1,
            dragMode: 'move',
            aspectRatio: 1,
            autoCropArea: 0.68,
            minContainerWidth: 544,
            minContainerHeight: 370,
            center: false,
            zoomOnWheel: false,
            zoomOnTouch: false,
            cropBoxMovable: false,
            cropBoxResizable: false,
            guides: false,
            toggleDragModeOnDblclick: false,
            ready: (event) => {
              this.cropper = cropper;
            },
            crop: (event) => {
              let imgSrc = this.cropper.getCroppedCanvas({
                width: 125,
                height: 125
              }).toDataURL("image/png");
              imgPreview.src = imgSrc;
            }
          });

        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  getAvatar() {
    if (this.cropper) {
      this.imageBase64 = (this.cropper.getCroppedCanvas({
        width: 125,
        height: 125
      }).toDataURL()).replace('data:image/png;base64,', '');

      this.avatar = this.avatarSrc.replace('AVATAR_HASH', this.imageBase64);
    }
    this.closeUploaderPopup();
  }

  sliderChange() {
    // let slideVal = ui.value;
    let zoomRatio = Math.round((this.imageSliderValue - this.slideValGlobal) * 10) / 10;
    this.slideValGlobal = this.imageSliderValue;
    this.cropper.zoom(zoomRatio);
  }

  initializeSlider() {
    this.imageSliderOptions = {
      // margin: 15, // how many minutes between start and stop
      step: 0.1,
      start: [this.slideValGlobal],
      connect: 'lower',
      range: {
        min: 0,
        max: 1
      },
    };
  }
  // Avatar Popup (end functions)

  // Submit Form
  public submitMemberForm() {
    this.myForm.controls['userProfile']['controls']['depot_ids'].enable();
    this.myForm.controls['userProfile']['controls']['driver_ids'].enable();

    const formValue = this.myForm.value.userProfile;

    // existing avatar
    if (this.avatarId) {
      this.picture['id'] = this.avatarId;
      this.picture['base64'] = this.avatar.split(',')[1];
      if (this.avatar) {
        this.myForm.patchValue({
          'userProfile': {
            'picture': this.picture
          }
        });
      }
    }
    // new avatar
    else {
      this.picture['id'] = null;
      this.picture['base64'] = this.avatar.split(',')[1];
      if (this.avatar) {
        this.myForm.patchValue({
          'userProfile': {
            'picture': this.picture
          }
        });
      }
    }

    if (formValue.phoneNumber) {
      this.telephone = formValue.countryPrefix + formValue.phoneNumber;
    }
    this.patchForm();
    const myObserver = {
      next: (response) => { },
      error: (error) => {
        this.formErrorsUtils.checkResponseForErrorCodes(error);
        // const errorsObject = error.error.errors;
        // const errors = [];
        // const keyify = (obj, prefix = '') =>
        //   Object.keys(obj).reduce((res, el) => {
        //     if (obj[el]['0'] && typeof obj[el]['0'] === 'string') {
        //       errors.push(obj[el]['0']);
        //     }
        //     if (obj[el]['0'] && typeof obj[el]['0'] === 'object') {
        //       if (obj[el]['0']['load']) {
        //         errors.push(obj[el]['0']['load']['0']);
        //       }
        //     }
        //     if (Array.isArray(obj[el])) {
        //       return res;
        //     } else if (typeof obj[el] === 'object' && obj[el] !== null) {
        //       return [...res, ...keyify(obj[el], prefix + el + '.')];
        //     } else {
        //       return [...res, prefix + el];
        //     }
        //   }, []);
        // const output = keyify(errorsObject);
        // this.errors = errors;
        this.isClickedOnce = false;
      },
      complete: () => {
        this.isClickedOnce = false;
        this.modalGridService.closeRightModal();
        this.modalGridService.updateMemberGrid();
      },
    };

    // if all depots and/or all drivers are selected, place all ids in the form to their appropriate fields
    if (this.driverIds[0] == -1) {
      this.driverIds = [];
      this.driversOptions.forEach(driver => {
        if (driver.id != -1) {
          this.driverIds.push(driver.id);
        }
      });
    }
    if (this.depotIds[0] == -1) {
      this.depotIds = [];
      this.depotsOptions.forEach(depot => {
        if (depot.id != -1) {
          this.depotIds.push(depot.id);
        }
      });
    }
    this.myForm.patchValue({
      'userProfile': {
        'depot_ids': this.depotIds,
        'driver_ids': this.driverIds
      }
    });

    if (this.memberId) {
      this.http.put('api/v1/office-members/' + this.memberId, this.myForm.value).pipe(take(1)).subscribe(myObserver);
    } else {
      this.http.post('api/v1/office-members', this.myForm.value).pipe(take(1)).subscribe(myObserver);
    }
  }

  initDepotsAndDriversOptions() {
    this.http.get('api/internal/v2/drivers').subscribe(driversRes => {
      // get all drivers
      this.allDrivers = [...driversRes['items']];

      // get all depots
      let depots = [{
        id: -1,
        name: this.allDepotsLabel
      }];
      this.globals.depotsWithNamesArray.forEach(depot => {
        depots.push({
          id: depot.companyDepot.id,
          name: depot.name
        });
      });
      this.depotsOptions = [...depots];

      this.updateAvailableDrivers();
    });
  }

  getTranslations() {
    this.listen.push(this.translate.get('MEMBERS.ADMIN').subscribe((res: string) => { this.adminLabel = res; }));
    this.listen.push(this.translate.get('MEMBERS.ADVANCED').subscribe((res: string) => { this.advancedLabel = res; }));
    this.listen.push(this.translate.get('MEMBERS.VIEWER').subscribe((res: string) => { this.viewerLabel = res; }));
    this.listen.push(this.translate.get('MEMBERS.STANDARD').subscribe((res: string) => { this.standardLabel = res; }));
    this.listen.push(this.translate.get('MEMBERS.ALL_STATIONS').subscribe((res: string) => { this.allDepotsLabel = res; }));
    this.listen.push(this.translate.get('MEMBERS.ALL_DRIVERS').subscribe((res: string) => { this.allDriversLabel = res; }));

    this.initDepotsAndDriversOptions();

    // set roles options
    this.rolesOptions = [
      {
        'name': this.adminLabel,
        'type': this.globals.teamMemberTypeConstants['ADMIN']
      },
      {
        'name': this.advancedLabel,
        'type': this.globals.teamMemberTypeConstants['ADVANCED']
      },
      {
        'name': this.standardLabel,
        'type': this.globals.teamMemberTypeConstants['STANDARD']
      },
      {
        'name': this.viewerLabel,
        'type': this.globals.teamMemberTypeConstants['VIEWER']
      },
    ];
  }

  ngOnInit() {
    this.getTranslations();
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.initializeSlider();

    this.countryPrefix = this.globals.defaultCountryCode;
    this.myForm.patchValue({
      'userProfile': {
        'countryPrefix': this.countryPrefix,
      }
    });
  }
}
