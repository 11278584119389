// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .sup{margin-top:-1rem}
        .value{font-size: 3.2rem;font-weight:500}
        .productivity{color: #E69138}
        .experience{color: #38761D}
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImdlbmVyYWwtd2lkZ2V0LmNvbXBvbmVudC50cyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0lBQ0ksS0FBSyxnQkFBZ0I7UUFDakIsT0FBTyxpQkFBaUIsQ0FBQyxlQUFlO1FBQ3hDLGNBQWMsY0FBYztRQUM1QixZQUFZLGNBQWMiLCJmaWxlIjoiZ2VuZXJhbC13aWRnZXQuY29tcG9uZW50LnRzIiwic291cmNlc0NvbnRlbnQiOlsiXG4gICAgLnN1cHttYXJnaW4tdG9wOi0xcmVtfVxuICAgICAgICAudmFsdWV7Zm9udC1zaXplOiAzLjJyZW07Zm9udC13ZWlnaHQ6NTAwfVxuICAgICAgICAucHJvZHVjdGl2aXR5e2NvbG9yOiAjRTY5MTM4fVxuICAgICAgICAuZXhwZXJpZW5jZXtjb2xvcjogIzM4NzYxRH1cbiAgICAiXX0= */`, "",{"version":3,"sources":["webpack://./src/app/dashboard-report/hybrid-widgets/general-widget.component.ts"],"names":[],"mappings":";IACI,KAAK,gBAAgB;QACjB,OAAO,iBAAiB,CAAC,eAAe;QACxC,cAAc,cAAc;QAC5B,YAAY,cAAc;;AAElC,4iBAA4iB","sourcesContent":["\n    .sup{margin-top:-1rem}\n        .value{font-size: 3.2rem;font-weight:500}\n        .productivity{color: #E69138}\n        .experience{color: #38761D}\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
