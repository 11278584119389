import { Router } from '@angular/router';
import { GenericService } from '@app/services/generic.service';
import { take } from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { HttpClient } from '@angular/common/http';
import { ChartService } from '@app/services/chart.service';
import { DashboardProjectsLiveDummyComponent } from './dashboard-projects-live-dummy/dashboard-projects-live-dummy.component';
import { GridsService } from '@app/services/grids.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';

@Component({
    selector: 'app-dashboard-projects-live',
    templateUrl: './dashboard-projects-live.component.html'
})

export class DashboardProjectsLiveComponent implements OnInit, AfterViewInit {

    @ViewChild(DashboardProjectsLiveDummyComponent, { static: false }) dashboardProjectsLiveDummyComponent: DashboardProjectsLiveDummyComponent;

    listen = [];

    titleHeaderLabel;
    timeHeaderLabel;
    amountHeaderLabel;
    progressHeaderLabel;

    gridApi;
    columnDefs = [];
    rowData = [];

    projectsLiveArray = [];
    projectsLiveCount;

    projectProblemDataInterval;

    constructor(
        private http: HttpClient,
        public globals: Globals,
        public translate: TranslateService,
        public chartService: ChartService,
        public gridsService: GridsService,
        public genericService: GenericService,
        public router: Router,
        private projectProblemDataService: ProjectProblemDataService,
    ) {
        // this.listen.push(this.modalService.updateStopPointsDateListen().subscribe((dates) => {
        //     this.selectedDatesQuery = 'startDate=' + dates.start.replaceAll("/", "-") + '&endDate=' + dates.end.replaceAll("/", "-") + '&timezone=' + moment.tz.guess();
        //     this.updateView();
        // }));
    }

    onGridReady(params) {
        this.gridApi = params.api;
    }

    filterGrid(filterText) {
        this.gridApi.setQuickFilter(filterText);
    }

    setProjectsGridData(projects) {
        this.projectsLiveArray = [];

        let gridObject = {};
        if (projects.length) {
            projects.forEach(project => {
                const titleString = project.projectProblem.title === '' ? '-' : project.projectProblem.title;
                // title
                let titleObj = {
                    title: titleString,
                    // date: project.projectProblem.departure_datetime
                    date: moment(project.projectProblem.departure_datetime).format('DD-MM-YYYY')
                };

                // amount
                let amountObj = {
                    stopPointsCount: project.projectProblem.totalStopPointsCount,
                    routesCount: project.projectProblem.routeCount
                };

                // progress
                let progressPercentage
                if (project.projectProblem.completedStopPoints !== 0) {
                    progressPercentage = Math.floor(((project.projectProblem.completedStopPoints + project.projectProblem.canceledStopPointsCount) / Number(project.projectProblem.stopPointsCount)) * 100);
                } else {
                    progressPercentage = 0;
                }

                gridObject = {
                    id: project.projectProblem.id,
                    title: titleObj,
                    titleString: titleString,
                    time: moment(project.projectProblem.departure_datetime).format('hh:mm'),
                    amount: amountObj,
                    progress: progressPercentage,
                    objData: project
                };
                this.projectsLiveArray.push(gridObject);
            });
        } else {
            const noData = { noDataText: 'No data' };
            this.projectsLiveArray.push(noData);
        }

        return this.projectsLiveArray;
    }

    // when be is ready, we will probably pass the time type to the request url...
    initGrid(projectId) {
        let url = 'api/v1/project/problem?live=1';
        if (projectId) url += `&projectIds=${projectId}`
        this.http.get(url).subscribe(res => {
            this.gridApi.setRowData(this.setProjectsGridData(res['items']));
        });
    }

    rowClicked(event) {
        const target = event.event.target.closest('div');
        // if flag is pressed (show report)
        if (target.getAttribute('id') == 'report-button') {
            for (const projectProblem of this.projectsLiveArray) {
                if (projectProblem.id == event.data.objData.projectProblem.id) {
                    // load the selected pp's data & then load its report
                    this.projectProblemDataService.projectProblemId = event.data.objData.projectProblem.id;
                    this.projectProblemDataService.loadData();

                    this.projectProblemDataInterval = setInterval(() => {
                        if (this.projectProblemDataService.hasLoadedAllData) {
                            const reportData = {
                                projectProblemId: event.data.objData.projectProblem.id,
                                departureTime: event.data.objData.projectProblem.departure_datetime
                            };
                            this.projectProblemDataService.projectName = event.data.objData.title;
                            this.genericService.openProjectProblemReportModal(reportData);

                            clearInterval(this.projectProblemDataInterval);
                            this.projectProblemDataInterval = null;
                        }
                    }, 500);
                    break;
                }
            }
        } else {
            if (event.data.objData.projectProblem.id) {
                this.router.navigateByUrl('/projects/projectView/' + event.data.objData.projectProblem.id);
            }
        }
    }

    // grid renderers
    titleRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="double-cell standard-width">' + params.getValue().title + '</div>';
            columnObject += '<div class="double-cell bold-letters standard-width">' + params.getValue().date + '</div>';
        }
        return columnObject;
    }

    amountRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="double-cell standard-width"><i class="fas fa-map-marker-alt grey-letters" style="margin-right: 4px"></i>' + params.getValue().stopPointsCount + '</div>';
            columnObject += '<div class="double-cell standard-width"><i class="fas fa-user grey-letters" style="margin-right: 4px"></i>' + params.getValue().routesCount + '</div>';
        }
        return columnObject;
    }

    // progressRenderer(params) {
    //     let columnObject = '';
    //     if (params.getValue()) {
    //         columnObject += '<div class="single-cell standard-width">' + params.getValue() + '</div>';
    //     }
    //     return columnObject;
    // }

    completedRenderer(params) {
        let columnObject = '';
        const percentage = params.getValue();
        if (percentage || percentage === 0) {
            if (percentage >= 100) {
                columnObject += '<div class="blue-circle-icon" id="report-button"><i class="fas fa-flag"></i></div>';
            } else {
                const degrees = (Number(percentage) / 100) * 180;
                columnObject += '<div class="circle-wrap">';
                columnObject += '<div class="circle">';

                columnObject += '<div class="mask full" style="transform: rotate(' + degrees + 'deg)">';
                columnObject += '<div class="fill" style="transform: rotate(' + degrees + 'deg)"></div>';
                columnObject += '</div>';

                columnObject += '<div class="mask half">';
                columnObject += '<div class="fill" style="transform: rotate(' + degrees + 'deg)"></div>';
                columnObject += '</div>';

                columnObject += '<div class="inside-circle">';
                columnObject += percentage + '%';
                columnObject += '</div>';

                columnObject += '</div>';
                columnObject += '</div>';
            }
        }
        return columnObject;
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.TITLE').subscribe((res: string) => { this.titleHeaderLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.TIME').subscribe((res: string) => { this.timeHeaderLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.AMOUNT_HEADER').subscribe((res: string) => { this.amountHeaderLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.PROGRESS_HEADER').subscribe((res: string) => { this.progressHeaderLabel = res; }));

        // grid column names
        this.columnDefs = [
            {
                headerName: this.titleHeaderLabel,
                field: 'title',
                height: 30,
                cellRenderer: this.titleRenderer,
                width: this.gridsService.widthCalculatorContainerId(40, 'projects-live-grid-container')
            },
            {
                field: 'titleString',
                hide: true,
              },
            {
                headerName: this.timeHeaderLabel,
                field: 'time',
                width: this.gridsService.widthCalculatorContainerId(20, 'projects-live-grid-container')
            },
            {
                headerName: this.amountHeaderLabel,
                field: 'amount',
                cellRenderer: this.amountRenderer,
                width: this.gridsService.widthCalculatorContainerId(21, 'projects-live-grid-container')
            },
            {
                headerName: this.progressHeaderLabel,
                field: 'progress',
                cellRenderer: this.completedRenderer,
                width: this.gridsService.widthCalculatorContainerId(24, 'projects-live-grid-container')
            },
        ]
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
        this.initGrid(null);
    }

    ngAfterViewInit() { }

    ngOnDestroy() {
        clearInterval(this.projectProblemDataInterval);
        this.projectProblemDataInterval = null;
    }
}
