import { Component, OnInit, OnDestroy, Injector, AfterViewInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ILmChartWidgetTab, LmWidgetViewType } from '@app/model/widget';
import { LmHybridWidgetComponent } from '@app/shared/structure/widgets/widget-base';
import { LmDoughnutChartComponent } from '@app/shared/charts/doughnut-chart.component';
import { LmSemiCircleChartComponent } from '@app/shared/charts/semicircle-chart.component';

@UntilDestroy()
@Component({
    selector: 'lm-performance-dashboard-widget',
    styles:[`
    .sup{margin-top:-1rem}
        .value{font-size: 3.2rem;font-weight:500}
        .productivity{color: #E69138}
        .experience{color: #38761D}
    `],
    template: `
        <div class="lm-widget --chart lm-pdb0 lm-pdt0">
            <lm-widget-header [title]="title" [subtitle]="subtitle" [tabs]="tabs" [filters]="filters" [filterFn]="applyFilter"></lm-widget-header>

            <div class="lm-widget-content lm-jc-center-flex lm-mrgt5 lm-coloumn-flex lm-txt-center lm-pdb30">
                <div class="sup">You are in the</div>
                <div class="value">
                    <span *ngIf="activeView==='productivity'" [attr.class]="activeView">
                       AVG 50%
                    </span>
                    <span *ngIf="activeView==='experience'" [attr.class]="activeView">
                       TOP 10%
                    </span>
                </div>
            </div>

            <div class="lm-widget-footer lm-ai-center-flex"></div>
        </div>
    `
})
export class LmPerformanceDashboardWidget extends LmHybridWidgetComponent implements OnInit, OnDestroy, AfterViewInit {
    viewType: LmWidgetViewType;
    activeView;
    
    constructor(injector: Injector) {
        super(injector);
        this.viewType = 'html';
        this.activeView = 'productivity';
    }


    switchTab(tab:ILmChartWidgetTab){
        const {id} = tab;
        this.activeView = id;
    }

    ngOnInit() {
        super.ngOnInit();
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
