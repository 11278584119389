import { Component, OnInit, OnDestroy, Input, Injector, AfterViewInit, HostBinding, NgModule, ViewChild, forwardRef } from '@angular/core';
import { BarElement, ChartComponent, ChartData } from 'chart.js';
import { Observable, finalize, mergeWith, of, take, tap } from 'rxjs';
import { LMSwitchModule } from '../../controls/input/switch/switch.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LmChartWidgetComponent, LmSSChartWidgetComponent } from './widget-base';
import { CommonModule } from '@angular/common';
import { ChartModule, UIChart } from 'primeng/chart';
import { LmButtonModule } from '../button/button.component';
import { LmBarChartComponent, LmBarChartModule } from '@app/shared/charts/bar-chart.component';
import { ILmChartWidgetTab } from '@app/model/widget';
import { Paginator, PaginatorModule } from 'primeng/paginator';
import { LmWidgetHeaderModule } from '../header/lm-widget-header.component';

@UntilDestroy()
@Component({
    selector: 'lm-ssr-barchart-widget',
    styles:[`
        lm-bar-chart{width: 100%}
    `],
    template: `
        <div class="lm-widget --chart lm-pdb0 lm-pdt0">
            
            <lm-widget-header [loading]="loading" [title]="title" [subtitle]="subtitle" [tabs]="tabs" [filters]="filters" [filterFn]="applyFilter"></lm-widget-header>

            <div class="lm-widget-content lm-jc-center-flex lm-mrgt5" [ngClass]="{'lm-b-w': dataless}">
                <div class="lm-overlay-white lm-jc-center-flex lm-ai-center-flex lm-hidden" [ngStyle]="{'height': dataless ? '100%' : '0'}">
                    <span class="lm-chart-widget-no-data lm-translator"></span>
                </div>
                <lm-bar-chart [data]="data" [options]="chartOptions" [plugins]="chartPlugins" [ngStyle]="{'padding-bottom': pagination ? '20px' : '0'}"></lm-bar-chart>
                <p-paginator 
                    *ngIf="pagination && pageCount" 
                    #paginator 
                    class="lm-widget-paginator" 
                    (onPageChange)="onPageChange($event)" 
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="{first} of {totalRecords}" 
                    [rows]="1" 
                    [totalRecords]="pageCount" 
                    [showPageLinks]="false" 
                    [showFirstLastIcon]="false"
                >
                </p-paginator>
            </div>
            
            <div class="lm-widget-footer lm-ai-center-flex"></div>
        </div>
    `
})
export class LmSSRBarChartWidgetComponent extends LmSSChartWidgetComponent implements OnInit, OnDestroy, AfterViewInit {
    static nextId = 0;
    @HostBinding() id = `lm-ssr-barchart-widget-${LmSSRBarChartWidgetComponent.nextId++}`;
    @ViewChild(LmBarChartComponent) chart:LmBarChartComponent;
    @ViewChild(Paginator) paginator:Paginator;
    
    
    constructor(injector: Injector) {
        super(injector);
    }

    pageChange(e?){
        if(this.getChartFn$) return this.getChartFn$({currentPage:e.page, pageSize: this.pageSize, tabChartType: this.tabChartType}).pipe(take(1), untilDestroyed(this)).subscribe();
    }

    switchTab(tab:ILmChartWidgetTab){
        if(this.paginator) this.paginator.changePage(0);
    }

    ngOnInit() {
        super.ngOnInit();
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
}


@NgModule({
    imports: [CommonModule, ChartModule, LmButtonModule, LmBarChartModule, PaginatorModule, LMSwitchModule, LmWidgetHeaderModule],
    exports: [LmSSRBarChartWidgetComponent],
    declarations: [LmSSRBarChartWidgetComponent]
  })
  export class LmSSRBarChartWidgetModule {}