import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/auth.service';
import { Globals } from '@app/services/globals';
import { LoaderService } from '@app/services/loader.service';
import { HttpUtils } from '@app/utils/http-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
    selector: 'app-collaborator-nav',
    templateUrl: './collaborator-nav.component.html',
    styleUrls: ['./collaborator-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollaboratorNavComponent implements OnInit, AfterViewInit {
    navigationModel;
    userMenuNavigationModel;
    viewTitle = '';
    menuOpen = false;
    menuHover = false;
    userAuthenticated: boolean;
    listen = [];
    navVisible = false;
    translations;

    constructor(
        public router: Router, 
        public translate: TranslateService,
        public httpUtils: HttpUtils, 
        public globals: Globals,
        public authSvc: AuthService,
        public loaderService: LoaderService,
    ) {
        this.userAuthenticated = authSvc.isAuthenticated();
        this.setupNavbarItems();
        this.listen.push(this.translate.get('NAVBAR').subscribe((res: string) => {
            this.translations = res;
            this.setupNavbarTitles();
        }));
        this.listen.push(router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.updateNavVisibility();
            }
        }));
    }

    setupNavbarItems() {
        const membersLink = {icon: 'icon-teamoutline', activeIcon: 'icon-teamoutlineselected', routerLink: 'members', id:'navlink-members', viewTitle:'OFFICE_MEMBERS', tag: 'OFFICE_MEMBERS'};
        const settingsLink = {icon: 'icon-settingsoutline', activeIcon: 'icon-settingsoutlineselected', routerLink: 'settings', id:'navlink-settings', viewTitle:'SETTINGS', tag: 'SETTINGS'};

        this.navigationModel = [
            {icon: 'icon-dashboardoutline', activeIcon: 'icon-dashboardoutlineselected', routerLink: 'shipperDashboard', id:'navlink-dashboard', viewTitle:'DASHBOARD', tag: 'DASHBOARD'},
            {icon: 'icon-projectoutline', activeIcon: 'icon-projectoutlineselected', routerLink: 'collaboratorOverview', id:'navlink-shipper-overview', viewTitle:'OVERVIEW', tag: 'OVERVIEW'},
            {icon: 'icon-partnersoutline', activeIcon: 'icon-partnersoutlineselected', routerLink: 'partners', id:'navlink-partners', viewTitle:'PARTNERS', tag: 'PARTNERS'},
            {icon: 'icon-stoppointoutline', activeIcon: 'icon-stoppointsoutlineselected', routerLink: 'shipmentsHistory', id:'navlink-shipments-history', viewTitle:'SHIPMENTS', tag: 'SHIPMENTS'},
            {icon: 'icon-paymentsoutline', activeIcon: 'icon-paymentsoutlineselected', routerLink: 'collaboratorPayments', id:'navlink-payments', viewTitle:'PAYMENTS', tag: 'PAYMENTS'},
            {icon: 'icon-invoicesoutline', activeIcon: 'icon-invoicesoutlineselected', routerLink: 'collaboratorInvoices', id:'navlink-invoices', viewTitle:'INVOICES', tag: 'INVOICES'},
        ];
        if (!this.globals.restrictedUser) {
            this.navigationModel.splice(2, 0, {icon: 'icon-recipientsoutline', activeIcon: 'icon-recipientsoutlineselected', routerLink: 'customers', id:'navlink-customers', viewTitle:'CUSTOMERS', tag: 'CUSTOMERS'});
        }
        if (!this.globals.foodModeEnabled) {
            this.navigationModel.splice(4, 0, {icon: 'icon-newshipmentoutline', activeIcon: 'icon-newshipmentsoutlineselected', routerLink: 'newShipmentsView', id:'navlink-warehouse', viewTitle:'NEW_SHIPMENTS', tag: 'WAREHOUSE'});
        }
        this.userMenuNavigationModel = this.globals.restrictedUser ? [] : [membersLink, settingsLink];
    }

    setupNavbarTitles() {
        this.navigationModel.forEach(nav => {
            nav.tag = this.translations[nav.viewTitle];
        });
        this.userMenuNavigationModel.forEach(nav => {
            nav.tag = this.translations[nav.viewTitle];
        });
    }

    updateNavVisibility() {
        if (this.authSvc.isAuthenticated()) {
            if (this.globals.isPathIncludedInNoNavbarList()) {
                this.navVisible = false;
            } else {
                this.navVisible = true;
            }
            if (!this.userAuthenticated) {
                this.setupNavbarItems();
                this.setupNavbarTitles();
            }
        } else {
            this.navVisible = false;
        }
        this.userAuthenticated = this.authSvc.isAuthenticated();
    }

    ngAfterViewInit() {}

    ngOnInit() {
        this.router.events.pipe(untilDestroyed(this)).subscribe(res => {
            const title = (<any>res).snapshot?.data?.title; 
            if (title) this.viewTitle = title; 
        })
    }

    ngOnDestroy() {}
}
