import { Injectable, Injector } from '@angular/core';
import { SelectTypeAheadOptions as SelectTypeAheadOptions2 } from '../../shared/controls/input/select2/select-typeahead.component';
import { SelectOptions as SelectOptions2 } from '../../shared/controls/input/select2/select.component';
import { LmBaseSearchModel } from '../../model/base-search-model';
import { Observable, of, switchMap, tap } from 'rxjs';
import { hasValue, lmEnumTypeToVeronaType } from '../../shared/utils';
import { getEnumInfoValues } from '../enums-utils';
import { LmAreasLookUpService } from '@app/api/services/areas-lookup.service';
import { LmAddressLookUpService } from '@app/api/services/address-lookup.service';
import { Globals } from '@app/services/globals';
import { LmSettingsBasicServicesService } from '@app/api/services/settings-basic-services.service';
import { AddressService } from '@app/services/address.service';
import { LmSettingsTransportationRegionsService } from '@app/api/services/settings-transportation-regions.service';
import { LmSettingsPriceListsServicesService } from '@app/api/services/settings-price-lists-services.service';
import { LmPudoService } from '@app/api/services/pudo-services.service';

@Injectable({
  providedIn: 'root'
})
export class SelectMapperService {
  private readonly _defaultSearchModelPaginationArgs: LmBaseSearchModel = { pageSize: 300, page: 1 };

  constructor(private _injector: Injector, private _globals: Globals, private addressService: AddressService){}

  readonly pudoPointsCourier = () => {
    return this.resolveAutoGetAllFn(LmPudoService, 'apiPudoPointsGet')
    .pipe(switchMap(res => of((<any>res).items)))
  };

  readonly priceListRevaluationCategories: SelectOptions2 = {
    data$: of(getEnumInfoValues(this._globals.priceListRevaluationCategoriesEnum)),
    valueResolver: (id) => this.resolveEnumByIdFn(this._globals.priceListRevaluationCategoriesEnum, id),
    bindLabel: 'caption',
    bindValue: 'key'
  }

  readonly pudoPointsFatFree = (depotId) => {
    return this.resolveAutoGetAllWithParamsFn(LmPudoService, 'apiPudoPointsFatFreeGet', depotId)
    .pipe(switchMap(res => of((<any>res).items)))
  };
  
  readonly postalCodeLookUp = ({query}) => {
    return this.resolveTypeAheadFn(LmAreasLookUpService, 'apiAreasLookUpPost', query, 'search').pipe(
      switchMap(codes => {
        (<any>codes).result.forEach(code => code.fullName = `${code.name} - ${code.country_code}`)
        return of(codes)
      })
    )
  }

  readonly priceListsAreasLookUp = () => {
    return this.resolveAutoGetAllFn(LmSettingsTransportationRegionsService, 'apiSettingsTransportationRegionsAllGet').pipe(
      switchMap(areas => of((<any>areas).items.map(area => ({name: area.name, id: area.id}))))
    )
  };

  readonly priceListsLookUp = () => {
    return this.resolveAutoGetAllFn(LmSettingsPriceListsServicesService, 'apiSettingsPriceListsServicesAllGet').pipe(
      switchMap(priceLists => of((<any>priceLists).items.map(priceList => ({name: priceList.name, id: priceList.id}))))
    )
  };
  
  readonly shipmentsLookUp = () => {
    return of(getEnumInfoValues(this._globals.priceListShipmentEnum)).pipe(
      switchMap(shipments => of(shipments))
    )
  };

  readonly distancesLookUp = () => {
    return of(getEnumInfoValues(this._globals.priceListDistanceEnum)).pipe(
      switchMap(distances => of(distances))
    )
  };

  readonly priceListBasicServicesLookUp = () => {
    return of(getEnumInfoValues(this._globals.priceListServicesEnum)).pipe(
      switchMap(distances => of(distances))
    )
  };

  readonly basicServicesLookUp = () => {
    return this.resolveAutoGetAllFn(LmSettingsBasicServicesService, 'apiSettingsBasicServicesAllGet').pipe(
      switchMap(res => of((<any>res).items))
    )
  };

  readonly areasLookUp = ({query}) => {
    return this.resolveTypeAheadFn(LmAreasLookUpService, 'apiAreasLookUpPost', query, 'search')
  };

  readonly addressLookUp = ({query}) => {
    // debugger
    return this.resolveTypeAheadFn(LmAddressLookUpService, 'apiAddressLookUpGet', query, 'search').pipe(
      switchMap(addressesResponse => {
        const { items: { items} }=(<any>addressesResponse);
        const addresses = items.map(address=>{
          return { 
            ...address.address, 
            addressLabel: this.addressService.getAddressLabel(address.address),
            lat: address.position[0],
            lon: address.position[1],
            value: this.addressService.getAddressLabel(address.address)
          };
        });
        return of({ result: addresses })
      })
    )
  };

  readonly projects = () => {
    return getEnumInfoValues(this._globals.projectsArrayForSelect)
  };

  readonly driverLiveTrackingAccuracy = () => {
    return getEnumInfoValues(this._globals.driverLiveTrackingAccuracyOptionsForSelect)
  };

  readonly depots: SelectOptions2 = {
    data$: of(getEnumInfoValues(this._globals.depotsArrayForSelect)),
    valueResolver: (id) => this.resolveEnumByIdFn(this._globals.depotsArrayForSelect, id),
    bindLabel: 'caption', 
    bindValue: 'key'
  }

  readonly vehicles: SelectOptions2 = {
    data$: of(getEnumInfoValues(this._globals.vehiclesArrayForSelect)),
    valueResolver: (id) => this.resolveEnumByIdFn(this._globals.vehiclesArrayForSelect, id),
    bindLabel: 'caption',
    bindValue: 'key'
  }

  readonly projects2: SelectOptions2 = {
    data$: of(getEnumInfoValues(this._globals.projectsArrayForSelect)),
    valueResolver: (id) => this.resolveEnumByIdFn(this._globals.projectsArrayForSelect, id),
    bindLabel: 'caption',
    bindValue: 'key'
  }

  readonly priceListBasicServices: SelectOptions2 = {
    data$: of(getEnumInfoValues(this._globals.priceListServicesEnum)),
    valueResolver: (id) => this.resolveEnumByIdFn(this._globals.priceListServicesEnum, id),
    bindLabel: 'caption',
    bindValue: 'key'
  }

  readonly priceListAreaGroupsAdministrativeLevel: SelectOptions2 = {
    data$: of(getEnumInfoValues(this._globals.priceListAreaGroupsAdministrativeLevelEnum)),
    valueResolver: (id) => this.resolveEnumByIdFn(this._globals.priceListAreaGroupsAdministrativeLevelEnum, id),
    bindLabel: 'caption',
    bindValue: 'key'
  }

  //resolvers
  private resolveTypeAheadFn(searchableEntityService: any, searchMethodName: string, term: string, queryParam?: string, searchBody?:any): Observable<any[]> {
    if (!term || term === '' || term === ' ') {
      return of(null);
    }
    const searchModel = searchBody ?? {[queryParam]: term}
    return this.resolveAutoSearchFn(searchableEntityService, searchMethodName, searchModel);
  }

  private resolveValByIdFn(searchableEntityService: any, searchMethodName: string, id: string | string[]): Observable<any[]> {
    return this.resolveValByFieldFn(searchableEntityService, searchMethodName, id, 'id');
  }

  private resolveValByFieldFn(searchableEntityService: any, searchMethodName: string, value: string | string[], queryParam: string, searchBody?:any): Observable<any[]> {
    const searchModel = searchBody ?? {[queryParam]: value}
    return this.resolveAutoSearchFn(searchableEntityService, searchMethodName, searchModel);
  }

  resolveSearchByIdFn(searchableEntityService: any, searchMethodName: string, _id: string): Observable<any[]> {
    const entityService = this._injector.get<any>(searchableEntityService);
    const entityServiceName = entityService.constructor.name;

    return entityService[searchMethodName]({id: _id});
  }

  resolveAutoSearchFn(searchableEntityService: any, searchMethodName: string, searchModel: any): Observable<any[]> {
    const entityService = this._injector.get<any>(searchableEntityService);
    const entityServiceName = entityService.constructor.name;

    if (typeof entityService[searchMethodName] !== 'function') {
      throw new Error(`Service ${entityServiceName} does not contain a valid 'search' method`);
    }
    return entityService[searchMethodName]({ body: searchModel || {} });
  }

  private resolveEnumByIdFn(enumObject: any, key: any | any[]): Observable<any[]> {
    // Check for multiple values
    if (Array.isArray(key)) {
      if (key.length === 0) {
        return of(null);
      }

      const enumObjArray = [];
      for (const rec in enumObject) {
        if (key.includes(enumObject[rec].key)) {
          enumObjArray.push(enumObject[rec]);
        }
      }
      return of(enumObjArray);
    }

    for (const rec in enumObject) {
      if (enumObject[rec].key === key) {
        return of(enumObject[rec]);
      }
    }
    return of(null);
  }

  private resolveTypeAheadFnByField(
    searchableEntityService: any,
    searchMethodName: string,
    term: string,
    searchModel,
    fieldName: string,
    fieldSeachOperator = '@=',
    moreFilters: string[] = []
  ): Observable<any[]> {
    if (!term || term === '' || term === ' ') {
      return of(null);
    }

    term = term.replace(/,/g, '\\,');
    let searchFilter = `${fieldName}${fieldSeachOperator}${term}`;
    moreFilters.forEach((filter) => {
      searchFilter = searchFilter + ',' + filter;
    });
    return this.resolveAutoSearchFn(searchableEntityService, searchMethodName, searchModel);
  }

  private resolveEnumByValueFn(enumObject: any, key: any | any[]): Observable<any[]> {
    // Check for multiple values
    if (Array.isArray(key)) {
      if (key.length === 0) {
        return of(null);
      }

      const enumObjArray = [];
      for (const rec in enumObject) {
        if (key.includes(enumObject[rec].value)) {
          enumObjArray.push(enumObject[rec]);
        }
      }
      return of(enumObjArray);
    }

    for (const rec in enumObject) {
      if (enumObject[rec].value === key || enumObject[rec].key === key) {
        return of(enumObject[rec]);
      }
    }
    return of(null);
  }

  resolveJSONByCode(_json, code) {
    let results = [];
    if (Array.isArray(code)) {
      if (code.length === 0) return of(null);

      _json._value.forEach((j) => {
        if (code.includes(j['code'])) results.push(j);
      });
    } else results.push(_json._value.find((v) => v.code === code[0]));
    return of(results);
  }

  resolveAutoGetAllWithParamsFn(searchableEntityService: any, searchMethodName: string, methodInput: any): Observable<any[]> {
    const entityService = this._injector.get<any>(searchableEntityService);
    const entityServiceName = entityService.constructor.name;

    if (typeof entityService[searchMethodName] !== 'function') {
      throw new Error(`Service ${entityServiceName} does not contain a valid 'search' method`);
    }
    return entityService[searchMethodName](methodInput);
  }

  resolveAutoGetAllFn(searchableEntityService: any, searchMethodName: string): Observable<any[]> {
    const entityService = this._injector.get<any>(searchableEntityService);
    const entityServiceName = entityService.constructor.name;

    if (typeof entityService[searchMethodName] !== 'function') {
      throw new Error(`Service ${entityServiceName} does not contain a valid 'search' method`);
    }
    return entityService[searchMethodName]();
  }

  getSelectDisplayValue(item: any, fields: string[], delimeter = '-'): Observable<any> {
    const displayValue = [];
    for (const field of fields) {
      if (hasValue(item[field])) {
        displayValue.push(item[field]);
      }
    }
    return of(displayValue.join(delimeter));
  }

  searchBy(data: any[], term: string): Observable<any> {
    const property = this['property'];
    return of(data.filter((val) => val[property].toLocaleLowerCase().includes(term.toLocaleLowerCase())));
  }
}
