import countries from 'i18n-iso-countries';
import enCountriesJson from "i18n-iso-countries/langs/en.json";
import elCountriesJson from "i18n-iso-countries/langs/el.json";
import deCountriesJson from "i18n-iso-countries/langs/de.json";
import roCountriesJson from "i18n-iso-countries/langs/ro.json";
import { ImportUtils } from '@app/utils/import-utils';
import { GridUtils } from '@app/utils/grid-utils';
// import { Chart } from 'chart.js';
import { Component, ViewChild, OnInit, ElementRef, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { AgGridAngular } from '@ag-grid-community/angular';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { AddressService } from '@app/services/address.service';
import { ChartService } from '@app/services/chart.service';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { Module } from '@ag-grid-community/core';
import { StopsCustomDateModalComponent } from '@app/modals/stops-custom-date-modal/stops-custom-date-modal.component';
import { ModalService } from '@app/services/modal.service';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { CookieService } from 'ngx-cookie-service';
import { ImporterService } from '@app/services/importer.service';
import { MilyService } from '@app/services/mily.service';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { VoucherFormModalComponent } from '@app/modals/voucher-form-modal/voucher-form-modal.component';
import { PaymentStatusSelectEditorComponent } from '@app/grids/grid-editors/payment-status-select-editor/payment-status-select-editor.component';
import { DataService } from '@app/services/data.service';
import { GenericUtils } from '@app/utils/generic-utils';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  ChartOptions
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

@Component({
  selector: 'app-stops-grid',
  templateUrl: './stops-grid.component.html',
  styleUrls: ['./../grids.scss', './stops-grid.component.scss']
})
export class StopsGridComponent implements OnInit {
  @ViewChild('stopPointsGrid', { static: false }) stopPointsGrid: AgGridAngular;
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild('filtersContainer', { static: false }) filtersContainer;
  @ViewChild('statsContainer', { static: false }) statsContainer;
  @ViewChild('gridContainer', { static: false }) gridContainer;
  @ViewChild('filterAllContainer', { static: false }) filterAllContainer;
  @ViewChild('filterNewContainer', { static: false }) filterNewContainer;
  @ViewChild('filterInProgressContainer', { static: false }) filterInProgressContainer;
  @ViewChild('filterHistoryContainer', { static: false }) filterHistoryContainer;
  @ViewChild('filterInWarehouseContainer', { static: false }) filterInWarehouseContainer;
  @ViewChild('filterIncomingContainer', { static: false }) filterIncomingContainer;
  @ViewChild('filterToBeInvoicedContainer', { static: false }) filterToBeInvoicedContainer;
  @ViewChild('openChartButton', { static: false }) openChartButton;
  @ViewChild('openFiltersButton', { static: false }) openFiltersButton;
  @ViewChild('openMapButton', { static: false }) openMapButton;
  @ViewChild('openPrintButton', { static: false }) openPrintButton;
  @ViewChild('openXlsButton', { static: false }) openXlsButton;
  @ViewChild('printDropdown', { static: false }) printDropdown;
  @ViewChild('checkboxAll', { static: false }) checkboxAll;
  @ViewChild('mapContainer', { static: false }) mapContainer;
  @ViewChild(StopsCustomDateModalComponent, { static: false }) stopsCustomDateModalComponent: StopsCustomDateModalComponent;
  @ViewChild(VoucherFormModalComponent, { static: false }) voucherFormModalComponent: VoucherFormModalComponent;
  @ViewChild('fileInput', { read: ElementRef, static: false }) fileInput: ElementRef;

  public modules: Module[] = [
    ServerSideRowModelModule,
    // MenuModule,
    // ColumnsToolPanelModule,
  ];

  generalStopPointsUrl = 'api/internal/v2/company/stop-points';
  stopPointsRequest = null;
  stopPointsPaginationRequest = null;

  columnDefs;
  excelStyles;
  rowModelType = 'serverSide';
  defaultPaginationSize = 100;
  cacheBlockSize = 100;
  gridApi;
  gridColumnApi;
  stopPointsDataArray = [];
  rowData: any;
  pagesCount = 0;
  fullDataShownForExport = false;
  bulkRequestsSize = 2000;
  maxRequestsToMake = 5;
  isEditingActive = false;

  listen = [];
  searchTextChanged = new Subject<string>();
  searchString: String = '';
  userQuestionUpdate = new Subject<string>();
  selectAllCheckbox = false;
  // domLayout = 'autoHeight';
  nameCodeTitle;
  addressTitle;
  receiverTitle;
  dateTitle;
  cityTitle;
  countryTitle;
  streetTitle;
  postalCodeTitle;
  senderCityTitle;
  senderCountryTitle;
  senderStreetTitle;
  senderPostalCodeTitle;
  timeTitle;
  completeDateTitle;
  completeTimeTitle;
  packagesCountTitle;
  fuelSurchargeTitle;
  taxTitle;
  totalChargeTitle;
  fareChargeTitle;
  collaboratorTitle;
  volumeWeightTitle;
  payOnDeliveryTitle;
  payOnDeliveryStatusTitle;
  shipmentStatusTitle;
  noteTitle;
  voucherPayPriceTitle;
  paidByTitle;
  consignorLabel;
  receiverLabel;
  senderLabel;
  collaboratorLabel;
  recipientLabel;
  arrivalTimeTitle;
  completionTimeTitle;
  cancelOtherReasonMsg;
  cancelNotThereMSG;
  cancelWrongAddressMSG;
  cancelDidNotAcceptMSG;
  cancelAgreedShippingMSG;
  cancelAgreedShippingWrongAddressMSG;
  cancelPickupFromDepotMSG;
  currency;
  canceledLabel = '';
  completedLabel = '';
  servicesTitle;
  servicesChargeTitle;
  litersUnit;
  kilosUnit;

  whenArray = ['today', 'last week', 'last month', 'custom'];
  selectedWhen;
  filterType = '';
  codFilter = '';
  stopPointsArray = [];
  relatedStopPointsArray = [];
  allStopPointsObject = {};
  chargeCategories = [];
  basicVoucherServices = [];
  noVouchersAlert;

  // chart
  chartExpanded = true;
  chartDisabled = false;
  chart;
  chartData = {};
  charts = [];
  selectedChart;
  months = [];
  chartValues = [];
  dataLoading = false;
  pageSize = 10;
  currentChartPage = 0;
  firstPage = true;
  lastPage = false;
  noNameLabel = '';
  returnLabel = '';
  noNameConstant = '_NO_NAME';
  returnConstant = '_RETURN';

  searchTypeOptions = [];
  selectedSearchType = '';

  selectedDate = '';
  stopsPeriods = [];
  selectedStopsPeriod;
  selectedDatesQuery = '';
  customDateString = '';
  invoiceStatusTitle;
  recentLabel: string;
  lastWeekLabel: string;
  lastMonthLabel: string;
  customLabel: string;
  barcodeTitle: string;
  nameTitle: string;
  phoneTitle: string;
  allTitle: string;
  systemicVoucherTrackingNumberTitle: string;
  telephoneTitle: string;
  serialNumberTitle: string;
  paymentStatusTitle;
  loadTitle;
  weightTitle;

  stopPointsCount;
  selectedStopPoint = null;

  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  allowFilterPresses = true;
  printMode = 'all';

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private viewProjectProblemService: ViewProjectProblemService,
    public dataService: DataService,
    public gridsService: GridsService,
    private chartService: ChartService,
    private addressService: AddressService,
    private modalService: ModalService,
    private cookieService: CookieService,
    private importerService: ImporterService,
    private milyService: MilyService,
    public stopPointUtils: StopPointUtils,
    public gridUtils: GridUtils,
    public genericUtils: GenericUtils,
    public importUtils: ImportUtils
  ) {
    this.options = { concurrency: 1 };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;

    countries.registerLocale(enCountriesJson);
    countries.registerLocale(elCountriesJson);
    countries.registerLocale(deCountriesJson);
    countries.registerLocale(roCountriesJson);

    this.listen.push(this.modalService.updateStopPointsDateListen().subscribe((dates) => {
      if (!dates.end) {
        dates.end = dates.start;
      }
      this.selectedDatesQuery = 'startDate=' + dates.start.replaceAll("/", "-") + '&endDate=' + dates.end.replaceAll("/", "-") + '&timezone=' + moment.tz.guess();
      this.customDateString = dates.start + ' - ' + dates.end;
      this.updateGrid();
      this.selectedStopsPeriod = {
        label: this.customDateString,
        value: 'custom'
      };
    }));
    this.listen.push(this.modalService.updateStopPointsGridListen().subscribe((data) => {
      this.updateGrid();
    }));
    this.listen.push(this.milyService.uploadPointsDoneListen().subscribe((data) => {
      this.updateGrid();
    }));
    this.listen.push(this.importerService.mapRefillListen().subscribe((response) => {
      this.updateGrid();
      this.openFilters();
    }));
    this.listen.push(this.importerService.loadAndOpenHelperListen().subscribe(type => {
      this.openMapAction();
    }));
    this.listen.push(this.importerService.printListen().subscribe(type => {
      if (this.printMode === 'selected') {
        this.printSelected();
      } else if (this.printMode === 'ups selected') {
        this.printSelected(true);
      }
    }));
  }

  updateGrid() {
    this.deselectGridCheckboxes();
    this.stopPointsArray = [];
    this.relatedStopPointsArray = [];
    this.closeDropdown();
    const gridContainer = document.querySelector('#stop-points .ag-body-viewport');
    gridContainer.scrollTo(0, 0);
    this.pagesCount = 0;
    // this.gridApi.purgeServerSideCache([]);
    this.gridApi.refreshServerSideStore({ purge: true });
    this.loadPagination();
  }

  // chart
  getDataForChart() {
    let data = [];
    // switch (this.selectedChart.value) {
    //   case 'most-visited':
    //     data = this.whenArray;
    //     break;
    // }
    return data;
  }

  displayDummyChartValues() {
    this.chartDisabled = true;

    this.months = [];
    for (let i = 1; i < 8; i++) {
      this.months.push(moment.utc().add(i, 'day').format('DD-MM'));
    }

    this.chartValues = [22, 17, 16, 16, 15, 9, 13];
  }

  getChartData() {
    this.dataLoading = true;
    let params = '?pageSize=' + this.pageSize;
    params += '&page=' + this.currentChartPage;
    params += '&date=' + moment().utc().format();
    // params += '&days=' + this.selectedWhen.value;
    if (this.currentChartPage === 0) {
      this.firstPage = true;
    } else {
      this.firstPage = false;
    }
    this.displayDummyChartValues();
    this.initChart();
  }

  initChart() {
    const ctx = <HTMLCanvasElement>document.getElementById('stop-point-chart');
    const data = this.getDataForChart();
    if (this.chart) { this.chart.destroy(); }
    const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptions));
    chartOptions.plugins.legend.display = true;
    if (ctx) {
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.months,
          datasets: [
            {
              label: this.completedLabel,
              data: this.chartValues,
              //fill: true,
              borderColor: '#00aeba',
              // borderColor: '#4fbd76',
              // backgroundColor: '#00aeba70',
              backgroundColor: '#00aeba70',
            },
          ]
        },
        options: chartOptions
      });
    }
  }

  // white bar button actions
  removeActiveButtonClass() {
    const activeButton = document.querySelector('.white-bar-right-button.active');
    if (activeButton) {
      activeButton.classList.remove('active');
    }
  }

  openChart() {
    this.removeActiveButtonClass();
    this.openChartButton.nativeElement.classList.add('active');
    this.gridContainer.nativeElement.classList.remove('hidden');
    this.statsContainer.nativeElement.classList.remove('hidden');
    this.filtersContainer.nativeElement.classList.add('hidden');
    this.checkboxAll.nativeElement.classList.remove('hidden');

    // this.mapContainer.nativeElement.classList.add('hidden');
    this.mapContainer.nativeElement.classList.remove('visible');
  }

  openFilters() {
    this.removeActiveButtonClass();
    this.openFiltersButton.nativeElement.classList.add('active');

    this.gridContainer.nativeElement.classList.remove('hidden');
    this.statsContainer.nativeElement.classList.add('hidden');
    this.filtersContainer.nativeElement.classList.remove('hidden');
    this.checkboxAll.nativeElement.classList.remove('hidden');

    // this.mapContainer.nativeElement.classList.add('hidden');
    this.mapContainer.nativeElement.classList.remove('visible');
  }

  openMapAction() {
    // this.globals.comingSoonAlert();
    // this.removeActiveButtonClass();
    // this.openMapButton.nativeElement.classList.add('active');

    // this.gridContainer.nativeElement.classList.add('hidden');
    // this.statsContainer.nativeElement.classList.add('hidden');
    // this.filtersContainer.nativeElement.classList.add('hidden');
    // this.checkboxAll.nativeElement.classList.add('hidden');

    // this.mapContainer.nativeElement.classList.remove('hidden');
    this.mapContainer.nativeElement.classList.add('visible');
    // this.stopsGridMapComponent.initMap();

    // this.globals.comingSoonAlert();
  }

  // print dropdown + options
  dropdownToggle() {
    if (this.printDropdown.nativeElement.classList.contains('hidden')) {
      this.openPrintButton.nativeElement.classList.add('active');
      this.printDropdown.nativeElement.classList.remove('hidden');
    } else {
      this.openPrintButton.nativeElement.classList.remove('active');
      this.printDropdown.nativeElement.classList.add('hidden');
    }
  }

  showCashOnDeliveryFilter($event) {
    if ($event.target.checked) {
      this.codFilter = 'isCod=1';
      this.allowFilterPresses = false;
      this.updateGrid();

      setTimeout(() => {
        this.allowFilterPresses = true;
      }, 1000);
    }
    else {
      this.codFilter = 'isCod=0';
      this.allowFilterPresses = false;
      this.updateGrid();

      setTimeout(() => {
        this.allowFilterPresses = true;
      }, 1000);
    }
  }

  printItems(printMode) {
    if (printMode === 'selected') {
      this.printSelected();
    } else if (printMode === 'ups selected') {
      this.printSelected(true);
    }
  }

  markStopPointsAsPrinted(stopPointsWithVouchers) {
    // PUT every non-printed stop point (that doesn't have an error)
    let markedStopPoints = [];
    stopPointsWithVouchers.forEach(stopPoint => {
      if (stopPoint.stopPoint) { stopPoint = stopPoint.stopPoint; }
      let stopPointObj = {
        stopPoint: {
          project_problem_id: stopPoint.project_problem_id ? stopPoint.project_problem_id : null,
          stopPointId: stopPoint['id'],
          voucher: {
            options: {
              is_printed: true
            }
          }
        }
      };

      if (stopPoint.error_status == this.globals.stopPointErrorStatusConstants['NO_ERROR']) {
        markedStopPoints.push(stopPointObj);
      }
    });

    this.http.put('api/v1/stop-points', { stopPoints: markedStopPoints }).pipe(take(1)).subscribe(response => {
      this.updateGrid();
    });
  }

  unSelectAllRows() {
    this.gridApi.forEachNode((row, index) => {
      this.gridApi.getRowNode(row.id).selectThisNode(false);
    });
  }

  deleteStopPoint() {
    const stopPointsToDelete = {
      stop_points: [
        {
          id: this.selectedStopPoint.id,
          warehouse_id: this.selectedStopPoint.fullData.warehouse_id,
          project_problem_id: this.selectedStopPoint.fullData.project_problem_id
        }
      ]
    };

    // look for selected nodes to delete multiple
    const selectedStopPoints = this.gridApi.getSelectedNodes();
    selectedStopPoints.forEach(stopPoint => {
      if (stopPoint.data.fullData.id != this.selectedStopPoint.id) {
        stopPointsToDelete.stop_points.push(
          {
            id: stopPoint.data.fullData.id,
            warehouse_id: stopPoint.data.fullData.warehouse_id,
            project_problem_id: stopPoint.data.fullData.project_problem_id
          }
        );
      }
    });

    this.http.post('api/v1/stop-points-delete', stopPointsToDelete).pipe(take(1)).subscribe(response => {
      this.unSelectAllRows();
      this.updateGrid();
    });
    this.closeDropdown();
  }

  printSelected(forUps = false) {
    let selectedStopPointsWithVouchers = this.getSelectedWithVouchersOnly(this.getSelectedRows());
    if (selectedStopPointsWithVouchers.length) {
      const stopPointsWithVouchers = this.filterVouchersToPrint(selectedStopPointsWithVouchers);

      if (!forUps) {
        this.stopPointUtils.printVouchers(stopPointsWithVouchers, true);
      }

      let markedStopPoints = [];
      let stopPointObj;
      selectedStopPointsWithVouchers.forEach(stopPointData => {
        if (stopPointData.stopPoint) { stopPointData = stopPointData.stopPoint }
        if (stopPointData.voucher && !stopPointData.voucher.is_printed) {
          stopPointObj = {
            stopPoint: {
              project_problem_id: stopPointData.project_problem_id ? stopPointData.project_problem_id : null,
              stopPointId: stopPointData.id,
              voucher: {
                options: {
                  is_printed: true
                }
              }
            }
          };
          markedStopPoints.push(stopPointObj);
        }
      });

      if (markedStopPoints.length) {
        if (forUps) {
          this.stopPointUtils.printImageVouchers(stopPointsWithVouchers, false);
        } else {
          this.stopPointUtils.printVouchers(stopPointsWithVouchers, true);
          this.markStopPointsAsPrinted(stopPointsWithVouchers);
        }
      }
    } else {
      alert(this.noVouchersAlert);
    }

    this.dropdownToggle();
  }

  filterVouchersToPrint(vouchers) {
    const vouchersToPrint = [];

    vouchers.forEach(stopPointData => {
      if (stopPointData.stopPoint) { stopPointData = stopPointData.stopPoint }
      if (stopPointData.error_status !== this.globals.stopPointErrorStatusConstants['ERROR']) {
        vouchersToPrint.push({ stopPoint: stopPointData });
      }
    });

    return vouchersToPrint;
  }

  getSelectedWithVouchersOnly(selectedRows) {
    let selectedWithVouchersArray = [];

    selectedRows.forEach(selectedStopPoint => {
      // this.stopPointsArray.forEach(item => {
      if (selectedStopPoint.fullData.voucher) {
        // get collaborator name for voucher (we used pickup's contact_name because stopPoint.collaborator might be the consignor's name instead)
        if (selectedStopPoint.stopPointData['service_type'] == this.globals.stopPointServiceTypeConstants['DELIVERY']) {
          const relatedStopPointId = selectedStopPoint.stopPointData['related_stop_point_id'];
          if (relatedStopPointId) {
            selectedStopPoint.stopPointData['collaborator_name'] = this.allStopPointsObject[relatedStopPointId]['contact_name'];
          }
        } else {
          selectedStopPoint.stopPointData['collaborator_name'] = this.stopPointUtils.getCollaboratorName(selectedStopPoint.stopPointData);
        }

        selectedWithVouchersArray.push(selectedStopPoint.stopPointData);
      }
      // if the stop point doesn't have a voucher, then look for its related stop point in the fetched stop points
      else {
        this.stopPointsArray.forEach(stopPoint => {
          const relatedStopPointId = stopPoint.related_stop_point_id;
          if (relatedStopPointId && this.allStopPointsObject[relatedStopPointId]) {
            if (stopPoint.id == selectedStopPoint.fullData.id && this.allStopPointsObject[relatedStopPointId].voucher) {
              selectedWithVouchersArray.push(this.allStopPointsObject[relatedStopPointId]);
            }
          }
        });
      }
    });

    return selectedWithVouchersArray;
  }

  deselectGridCheckboxes() {
    this.gridApi.deselectAll();
  }

  // restore the grid settings for normal use
  removeExportSettingsFromGrid() {
    this.gridApi.paginationSetPageSize(this.defaultPaginationSize);
    this.cacheBlockSize = this.defaultPaginationSize;
    this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.defaultPaginationSize);
    this.fullDataShownForExport = false;
    this.pagesCount = 0;
    this.stopPointsArray = [];
    this.setServerSideDatasource();
  }

  // set grid to have no pagination and load all items to export
  openXlsAction() {
    this.gridApi.paginationSetPageSize(this.bulkRequestsSize);
    this.cacheBlockSize = this.bulkRequestsSize;
    this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.bulkRequestsSize);
    this.fullDataShownForExport = true;
    this.setServerSideDatasource();
  }

  // export data to xls
  exportToXls() {
    const excelParams = {
      processCellCallback: formattingFunction,
      allColumns: true,
      columnKeys: ['xlsDate', 'xlsTime', 'barcode', 'xlsSystemicVoucherTrackingNumber', 'collaborator',
        'xlsSenderStreet', 'xlsSenderPostalCode', 'xlsSenderCity', 'xlsSenderCountry', 'name',
        'xlsStreet', 'xlsPostalCode', 'xlsCity', 'xlsCountry', 'telephone',
        'xlsCompleteDate', 'xlsCompleteTime', 'xlsPackagesCount', 'xlsWeight', 'xlsVolume', 'xlsFareCharge',
        'paymentStatus', 'xlsPodAmount', 'xlsPodStatus',
        'xlsShipmentStatus', 'paidBy', 'note'],
      fileName: this.genericUtils.removeDotsFromString('lastmily_export')
    };
    function formattingFunction(params) {
      if (params.value) {
        if (params.value.name || params.value.name === '') {
          let nameValue = params.value.name;
          if (params.value.customerId) {
            nameValue += " - " + params.value.customerId;
          }
          return nameValue;
        } else if (params.value.load || params.value.weight) {
          let label = '';
          if (params.value.load) {
            label += params.value.load + 'lt '
          }
          if (params.value.weight) {
            label += params.value.weight + 'kg '
          }
          return label;
        } else if (params.value.time) {
          return params.value.time + ' ' + params.value.date;
        } else if (params.value.date) {
          return params.value.date;
        } else if (params.value.payOnDeliveryValue) {
          return `(${params.value.label}) ${params.value.payOnDeliveryValue}`;
        } else if (params.value.label) {
          return params.value.label;
        } else {
          return params.value;
        }
      }
    }
    this.gridApi.exportDataAsExcel(excelParams);
  }

  // filter button actions
  removeActiveFilterClass() {
    document.querySelector('.filter-type-box.active').classList.remove('active');
  }

  filterAll() {
    if (this.allowFilterPresses) {
      this.removeActiveFilterClass();
      this.filterAllContainer.nativeElement.classList.add('active');

      this.filterType = '';
      this.updateGrid();

      this.allowFilterPresses = false;
      setTimeout(() => {
        this.allowFilterPresses = true;
      }, 1000);
    }
  }

  filterNew() {
    if (this.allowFilterPresses) {
      this.removeActiveFilterClass();
      this.filterNewContainer.nativeElement.classList.add('active');

      this.filterType = 'isPrinted=0'; // 0 to get only new
      this.updateGrid();

      this.allowFilterPresses = false;
      setTimeout(() => {
        this.allowFilterPresses = true;
      }, 1000);
    }
  }

  filterHistory() {
    if (this.allowFilterPresses) {
      this.removeActiveFilterClass();
      this.filterHistoryContainer.nativeElement.classList.add('active');

      this.filterType = 'history=1';
      this.updateGrid();

      this.allowFilterPresses = false;
      setTimeout(() => {
        this.allowFilterPresses = true;
      }, 1000);
    }
  }

  filterInProgress() {
    if (this.allowFilterPresses) {
      this.removeActiveFilterClass();
      this.filterInProgressContainer.nativeElement.classList.add('active');

      this.filterType = 'inProgress=1';
      this.updateGrid();

      this.allowFilterPresses = false;
      setTimeout(() => {
        this.allowFilterPresses = true;
      }, 1000);
    }
  }

  filterInWarehouse() {
    if (this.allowFilterPresses) {
      this.removeActiveFilterClass();
      this.filterInWarehouseContainer.nativeElement.classList.add('active');

      this.filterType = 'inWarehouse=1';
      this.updateGrid();

      this.allowFilterPresses = false;
      setTimeout(() => {
        this.allowFilterPresses = true;
      }, 1000);
    }
  }

  filterIncoming() {
    if (this.allowFilterPresses) {
      this.removeActiveFilterClass();
      this.filterIncomingContainer.nativeElement.classList.add('active');

      this.filterType = 'incoming=1';
      this.updateGrid();

      this.allowFilterPresses = false;
      setTimeout(() => {
        this.allowFilterPresses = true;
      }, 1000);
    }
  }

  onUploadOutput(output: UploadOutput): void {
    const jwt = this.cookieService.get('jwt');
    switch (output.type) {
      case 'allAddedToQueue':
        // uncomment this if you want to auto upload files when added
        const event: UploadInput = {
          type: 'uploadAll',
          // url: '/api/v1/import-analyzer',
          url: '/api/v1/project/problems/' + 1 + '/import-analyzer',
          fieldName: 'companyOrders',
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + jwt,
            'language': this.globals.currentLang
          }
        };
        this.uploadInput.emit(event);
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.files.push(output.file);
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        // remove file from array when removed
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        // handle import analyzer errors
        this.importUtils.handleAnalyzerErrors(output['file']['response']['errors']);

        // The file is uploaded
        if (this.files) {
          const file = this.files[this.files.length - 1];
          const data = file.response.items;
          if (data) {
            const fileHash = data.csvSheets[0].csvFile;
            const constantFieldsArray = [];
            this.importerService.openImporterMatcher(data);
          }
        }

        break;
    }
  }

  getLastRowId() {
    if (this.stopPointsArray.length) {
      const lastRow = this.stopPointsArray[this.stopPointsArray.length -1];
      return lastRow['id'];
    } else {
      return 0;
    }
  }

  getUrl(pageSize) {
    const searchQuery = `?searchQuery=${this.searchString}&searchType=${this.selectedSearchType}`;

    let url = this.generalStopPointsUrl;
    url += searchQuery;
    url += '&pageSize=' + pageSize;
    // url += '&page=' + this.pagesCount;
    url += '&lastRowId=' + this.getLastRowId();
    url += '&' + this.filterType;
    if (this.codFilter) url += '&' + this.codFilter;
    url += '&' + this.selectedDatesQuery;
    return url
  }

  getStopsGridData(pageSize) {
    return this.http.get(this.getUrl(pageSize));
  }

  // request and pass all grid data for xls export
  loadAllItemsToExport(params) {
    this.stopPointsArray = [];
    this.getStopsGridData(this.bulkRequestsSize).pipe(take(1)).subscribe(stopsResponse => {
      this.stopPointsArray =  stopsResponse['items']['stopPoints'];
      this.chargeCategories = stopsResponse['items']['charge_categories'];
      this.basicVoucherServices = stopsResponse['items']['basic_voucher_services'];
      this.displayDataToGrid(params, this.stopPointsArray);
      this.exportToXls();
      this.removeExportSettingsFromGrid();
    });
  }

  // request and pass grid data page by page
  loadPagination() {
    if (this.stopPointsPaginationRequest) {
      this.stopPointsPaginationRequest.unsubscribe();
    }
    const url = this.getUrl(this.defaultPaginationSize) + '&isOnlyCount=true';
    this.stopPointsPaginationRequest = this.http.get(url).pipe(take(1)).subscribe(response => {
      this.stopPointsPaginationRequest = null;
      this.stopPointsCount = response['itemsMeta'].totalCount;
    });
  }

  // request and pass grid data page by page
  loadSinglePage(params) {
    if (this.stopPointsRequest) {
      this.stopPointsRequest.unsubscribe();
      const noData = { noDataText: '...' }
      params.successCallback([noData], 1);
    }
    this.stopPointsRequest =  this.getStopsGridData(this.cacheBlockSize).pipe(take(1)).subscribe(response => {
      this.stopPointsRequest = null;
      this.stopPointsArray = this.stopPointsArray.concat(response['items']['stopPoints']);
      this.relatedStopPointsArray = this.relatedStopPointsArray.concat(response['items']['related_stop_points']);
      this.allStopPointsObject = this.stopPointUtils.getAllStopPointsObject(this.stopPointsArray, this.relatedStopPointsArray);
      this.chargeCategories = this.chargeCategories.concat(response['items']['charge_categories']);
      this.basicVoucherServices = this.basicVoucherServices.concat(response['items']['basic_voucher_services']);
      this.displayDataToGrid(params, response['items']['stopPoints']);
      this.gridApi.hideOverlay();
    },
      (error) => {
        this.stopPointsRequest = null;
        console.error(error);
        params.failCallback();
        this.gridApi.hideOverlay();
      }
    );
  }

  // the first data source when grid is initialized
  createServerSideDatasource() {
    return {
      getRows: (params) => {
        this.gridApi.showLoadingOverlay();
        this.loadSinglePage(params);
      },
    };
  }

  // set another data source to change between page sizes to export all items
  setServerSideDatasource() {
    const dataSource = {
      rowCount: null,
      getRows: (rowsParams) => {
        setTimeout(() => {
          this.gridApi.showLoadingOverlay();
          if (this.fullDataShownForExport) {
            this.loadAllItemsToExport(rowsParams);
          } else {
            this.loadSinglePage(rowsParams);
          }
        }, 500);
      }
    };
    this.gridApi.setServerSideDatasource(dataSource);
  }

  // pass the data array to the grid
  // here the pages (and rows) are calculated
  displayDataToGrid(params, stopPointsArray) {
    if (stopPointsArray.length) {
      let lastRow = -1;
      this.pagesCount++;
      if (this.stopPointsArray.length != this.pagesCount * this.cacheBlockSize) {
        lastRow = this.stopPointsArray.length;
      }
      if (this.fullDataShownForExport) {
        lastRow = stopPointsArray.length;
      }
      params.successCallback(this.setStopsGridData(stopPointsArray), lastRow);
    } else {
      if (this.pagesCount) {
        const lastRow = this.pagesCount * this.cacheBlockSize;
        params.successCallback(this.setStopsGridData(stopPointsArray), lastRow);
      } else {
        const noData = { noDataText: 'No data' }
        params.successCallback([noData], 1);
      }
    }
  }

  cellValueChanged(event) {
    const selectedRows = this.getSelectedRows();
    const stopPointsToUpdate = this.gridUtils.getStopPointsDataToSavePayOnDeliveryStatus(event, selectedRows);

    if (stopPointsToUpdate.length) {
      this.http.put('api/v1/stop-points', { stopPoints: stopPointsToUpdate }).pipe(take(1)).subscribe(response => {
        this.updateGrid();
      });
    }
  }

  setStopsGridData(stopPoints) {
    let stopPointPhone, datetime, completeDatetimeMoment, collaborator, payOnDelivery, serviceType, entityStatus, fulfillmentStatus, cancelledReason, gridObject = {};
    this.stopPointsDataArray = [];
    if (stopPoints.length) {
      this.dataService.statusPerStopPointMap = this.gridUtils.getStatusPerStopPointMap(stopPoints);
      stopPoints.forEach(stopPoint => {
        if (stopPoint.related_to === this.globals.stopPointRelatedToConstants['SELF']) {
          const relatedStopPointId = stopPoint.related_stop_point_id;
          stopPointPhone = stopPoint.telephone ?? '-';
          cancelledReason = '';
          serviceType = this.globals.stopPointServiceTypeLabels[stopPoint.service_type];
          entityStatus = this.globals.stopPointEntityStatusLabels[stopPoint.entity_status];
          fulfillmentStatus = this.globals.stopPointFulfillmentStatusLabels[stopPoint.fulfillment_status];
          if (stopPoint.creation_datetime) {
            datetime = {
              date: moment(stopPoint.creation_datetime).format('DD MMM YYYY'),
              time: moment(stopPoint.creation_datetime).format('HH:mm')
            }
          } else {
            datetime = {
              date: '-',
              time: '-'
            }
          }
          const completedReasons = [
            this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']].AT_TIME,
            this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']].COMPLETED_HANDED,
          ]

          completeDatetimeMoment = null;
          if (
            stopPoint.fulfillment_status == this.globals.stopPointFulfillmentStatusConstants['COMPLETED'] &&
            stopPoint.solution
          ) {
            completeDatetimeMoment = moment(stopPoint.solution.atTime);
          } else {
            stopPoint.fulfillment_events.forEach(event => {
              if (completedReasons.includes(event.reason)) {
                completeDatetimeMoment = moment(event.fulfillment_datetime);
              }
            });
          }

          // collaborator name
          if (stopPoint.collaborator && stopPoint.collaborator.collaboratorData) {
            collaborator = {
              id: stopPoint.collaborator.collaboratorData.id,
              name: stopPoint.collaborator.collaboratorData.collaborator_name
            }
          } else if (stopPoint.sender_name) {
            collaborator = {
              id: null,
              name: stopPoint.sender_name
            }
          } else if (stopPoint.related_stop_point_id && this.allStopPointsObject[stopPoint.related_stop_point_id]) {
            collaborator = {
              id: null,
              name: this.allStopPointsObject[stopPoint.related_stop_point_id].contact_name
            }
          }
          else if (stopPoint.contact_name) {
            collaborator = {
              id: null,
              name: stopPoint.contact_name
            }
          } else {
            collaborator = {
              id: null,
              name: stopPoint.collaborator_name ?? '-',
            }
          }

          if (stopPoint.pay_amount) {
            payOnDelivery = stopPoint.pay_amount ? (Number(stopPoint.pay_amount).toFixed(2) + this.currency) : '-';
          } else {
            payOnDelivery = '-';
          }

          // Time (Time Windows)
          let timeWindowStart, timeWindowEnd;
          let timeWindowSecondStart, timeWindowSecondEnd;
          let timeWindowRange, timeWindowRangeMinutes;
          if (stopPoint.time_windows[0]) {
            timeWindowStart = moment(stopPoint.time_windows[0].start, 'HH:mm:ss').format('HH:mm');
            timeWindowRange = stopPoint.time_windows[0].time_window_range;
            timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
            timeWindowEnd = moment(timeWindowStart, 'HH:mm').add(timeWindowRangeMinutes, 'minutes').format('HH:mm');

            if (stopPoint.time_windows[1]) {
              timeWindowSecondStart = moment(stopPoint.time_windows[1].start, 'HH:mm:ss').format('HH:mm');
              timeWindowRange = stopPoint.time_windows[1].time_window_range;
              timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
              timeWindowSecondEnd = moment(timeWindowSecondStart, 'HH:mm').add(timeWindowRangeMinutes, 'minutes').format('HH:mm');
            }
          }

          // volume
          let volumeObj = this.stopPointUtils.getStopPointVolumeWeightColumnData(stopPoint);

          let name = stopPoint.contact_name;
          if (name.includes(this.noNameConstant)) {
            name = name.replace(this.noNameConstant, this.noNameLabel);
          }
          if (name.includes(this.returnConstant)) {
            name = name.replace(this.returnConstant, this.returnLabel);
          }

          // barcode (always use the delivery's barcode/voucher hash)
          let barcodeValue;
          if (stopPoint.service_type == this.globals.stopPointServiceTypeConstants['PICKUP'] && relatedStopPointId && this.allStopPointsObject[relatedStopPointId]) {
            barcodeValue = this.stopPointUtils.getBarcodeOrVoucherHash(this.allStopPointsObject[relatedStopPointId]);
          } else {
            barcodeValue = this.stopPointUtils.getBarcodeOrVoucherHash(stopPoint);
          }

          // paid by
          let paidByValue;
          if (stopPoint.voucher) {
            switch (stopPoint.voucher.paid_by) {
              case this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR']:
                paidByValue = this.consignorLabel
                break;
              case this.globals.stopPointCollaboratorTypesConstants['RECEIVER']:
                paidByValue = this.receiverLabel
                break;
              case this.globals.stopPointCollaboratorTypesConstants['SENDER']:
                paidByValue = this.senderLabel
                break;
            }
          } else {
            paidByValue = '-';
          }

          // fulfillment status
          // (if delivery w/o pickup or independent pickup)
          let fulfillmentObj = this.gridUtils.getFulfillmentStatus(stopPoint);

          // if pickup & delivery
          if (stopPoint.related_stop_point_id &&
            this.allStopPointsObject[stopPoint.related_stop_point_id] &&
            stopPoint.service_type == this.globals.stopPointServiceTypeConstants['DELIVERY']) {
            fulfillmentObj = this.gridUtils.showFulfillmentBasedOnDeliveryStatus(stopPoint, this.allStopPointsObject);
          }

          // packages count
          let itemsCountSum = 0;
          if (stopPoint.mass_and_dimensions?.length) {
            stopPoint.mass_and_dimensions.forEach(item => {
              itemsCountSum += item.count;
            });
          }

          let addressStreet = stopPoint.address.street;
          if (stopPoint.address.houseNumber) {
            addressStreet += ` ${stopPoint.address.houseNumber}`;
          }

          const countriesObject = countries.getNames(this.globals.currentLang, { select: 'official' });
          let senderAddressStreet = '-', senderCity = '-', senderPostalCode = '-', senderCountry = '-';
          if (stopPoint.collaborator?.address) {
            senderCity = stopPoint.collaborator.address.city;
            senderPostalCode = stopPoint.collaborator.address.postalCode;
            senderCountry = countriesObject[countries.toAlpha2(stopPoint.collaborator.address.countryCode)];
            senderAddressStreet = stopPoint.collaborator.address.street;

            if (stopPoint.collaborator.address.houseNumber) {
              senderAddressStreet += ` ${stopPoint.collaborator.address.houseNumber}`;
            }
          }

          // systemic voucher tracking number
          let systemicVoucherTrackingNumber = '-';
          if (stopPoint.voucher?.company_voucher_X_systemic_courier?.tracking_number) {
            systemicVoucherTrackingNumber = stopPoint.voucher?.company_voucher_X_systemic_courier?.tracking_number;
          }

          // fare charge
          let fareCharge = '-';
          if (stopPoint.voucher && this.globals.accessRole != this.globals.teamMemberTypeConstants['STANDARD']) {
            fareCharge = Number(stopPoint.voucher.pay_price).toFixed(2) + this.currency;
          }

          // telephone to string to avoid scientific format
          let receiverPhone = '-';
          if (stopPointPhone) {
            receiverPhone = String(stopPointPhone);
          }

          gridObject = {
            id: stopPoint.id,
            name: {
              name: name ?? '-',
              phone: stopPointPhone,
              barcode: barcodeValue ?? '-',
              customerId: stopPoint.stop_point_identification_number
            },
            note: stopPoint.note ?? '-',
            barcode: barcodeValue ?? '-',
            serialNumber: stopPoint.serial_number ?? '-',
            telephone: receiverPhone ?? '-',
            datetime: datetime,
            collaborator: collaborator,
            address: this.addressService.getAddressLabel(stopPoint.address),
            volumeWeight: volumeObj,
            time: {
              date: completeDatetimeMoment ? completeDatetimeMoment.format('DD/MM @ HH:mm') : '-',
              twFirstStart: timeWindowStart,
              twFirstEnd: timeWindowEnd,
              twSecondStart: timeWindowSecondStart,
              twSecondEnd: timeWindowSecondEnd
            },
            state: entityStatus,
            entityStatusConstant: stopPoint.entity_status,
            payOnDelivery: this.gridUtils.getPayAmountStatus(stopPoint),
            type: serviceType,
            status: {
              colorClass: fulfillmentObj.colorClass,
              label: fulfillmentObj.statusValue
            },
            paymentStatus: this.gridUtils.getPaymentStatus(stopPoint),
            projectProblemId: stopPoint.project_problem_id ? stopPoint.project_problem_id : null,
            errorStatusConstant: stopPoint.error_status,
            paidBy: paidByValue,
            xlsShipmentStatus: fulfillmentObj.statusValue.replace('<br /> ', ''),
            xlsDate: datetime.date,
            xlsTime: datetime.time,
            xlsCompleteTime: completeDatetimeMoment ? completeDatetimeMoment.format('HH:mm') : '-',
            xlsCompleteDate: completeDatetimeMoment ? completeDatetimeMoment.format('DD/MM/YYYY') : '-',
            xlsPackagesCount: itemsCountSum,
            xlsStreet: addressStreet,
            xlsCity: stopPoint.address.city,
            xlsCountry: countriesObject[countries.toAlpha2(stopPoint.address.countryCode)],
            xlsPostalCode: stopPoint.address.postalCode,
            xlsSenderStreet: senderAddressStreet,
            xlsSenderCity: senderCity,
            xlsSenderCountry: senderCountry,
            xlsSenderPostalCode: senderPostalCode,
            xlsFareCharge: fareCharge,
            xlsPodStatus: this.gridUtils.getPayAmountStatus(stopPoint).label,
            xlsPodAmount: this.gridUtils.getPayAmountStatus(stopPoint).payOnDeliveryValue,
            xlsSystemicVoucherTrackingNumber: systemicVoucherTrackingNumber,
            xlsVolume: String(volumeObj.load) + this.litersUnit,
            xlsWeight: String(volumeObj.weight) + this.kilosUnit,
            fullData: stopPoint,
            stopPointData: stopPoint
          };
          this.stopPointsDataArray.push(gridObject);
        }
      });
    } else {
      const noData = { noDataText: 'No data' }
      this.stopPointsDataArray.push(noData);
    }

    return this.stopPointsDataArray;
  }

  // checkboxes
  getSelectedRows() {
    return this.gridApi.getSelectedRows();
  }

  selectAll() {
    if (!this.selectAllCheckbox) {
      this.gridApi.forEachNode((row, index) => {
        this.gridApi.getRowNode(row.id).selectThisNode(true);
      });
    } else {
      this.gridApi.forEachNode((row, index) => {
        this.gridApi.getRowNode(row.id).selectThisNode(false);
      });
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  initGrid() {
    let intervalId = setInterval(() => {
      if (this.gridApi) {
        this.gridApi.setServerSideDatasource(this.createServerSideDatasource());
        clearInterval(intervalId);
        this.checkForHelperPoints();
      }
    }, 100);
  }

  checkForHelperPoints() {
    // let stopPointsForHelper = [];
    const baseURL = 'api/v1/helper-stop-points'

    this.http.get(baseURL + '?page=0&pageSize=1').pipe(take(1)).subscribe(response => {
      if (response['items'].length) {
        this.milyService.helper('importer');
      }
    });

  }

  cellEditingStarted(event) {
    this.isEditingActive = true;
  }

  cellEditingStopped(event) {
    // row click event fires right after cell edit stop event, so we add a delay to stop row click from opening SP modal
    setTimeout(() => {
      this.isEditingActive = false;
    }, 100);
  }

  rowClicked(event) {
    const target = event.event.target.closest('div');
    if (event.column.colId !== "payOnDelivery" && !this.isEditingActive) {
      if (!event.data.noDataText && !target.classList.contains('dropdown-dots')) {
        this.viewProjectProblemService.openStopModal(event.data.id, event.data.projectProblemId);
        this.closeDropdown();
      }
    }
  }

  search($event) {
    this.searchTextChanged.next($event);
  }

  uploadStops() {
    document.getElementById('general-csv-uploader').click();
  }

  toggleNewStopPointButton() {
    if (document.getElementById('new-stop-point-button').classList.contains('hidden')) {
      document.getElementById('new-stop-point-button').classList.remove('hidden');
    } else {
      document.getElementById('new-stop-point-button').classList.add('hidden');
    }
  }

  createNewStopPoint() {
    // this.voucherFormModalComponent.openModal();
    this.viewProjectProblemService.openStopFormModal();
  }

  comingSoon() {
    this.globals.comingSoonAlert();
  }

  searchTypeChange() {
    if (this.searchString) this.updateGrid();
  }

  stopsPeriodChange() {
    let startDate = '', endDate = '', timezone = '';
    timezone = moment.tz.guess()
    if (this.selectedStopsPeriod.value === 'custom') {
      this.stopsCustomDateModalComponent.openModal();
    } else {
      let recentParam = '';
      if (this.selectedStopsPeriod.value === 1) {
        recentParam = '&isRecent=1';
      }
      startDate = moment().subtract(this.selectedStopsPeriod.value, 'days').format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
      this.selectedDatesQuery = 'startDate=' + startDate + '&endDate=' + endDate + '&timezone=' + timezone + recentParam;
      this.updateGrid();
    }
  }

  onFirstDataRendered(params) { }

  redLettersClass(params) {
    if (params.data) {
      if (params.data.errorStatusConstant === this.globals.stopPointErrorStatusConstants['ERROR']) {
        return true;
      }
    }
    return false;
  }

  // custom renderer for status
  statusRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      columnObject += '<div class="voucher-status-container">' + '<div class="single-cell standard-width ' + params.getValue().colorClass + '">' + params.getValue().label + '</div>' + '</div>';
    }
    return columnObject;
  }

  closeDropdown() {
    const dropdown = document.getElementById('stop-point-dropdown-container');
    this.selectedStopPoint = null;
    dropdown.style.display = 'none';
  }

  // custom renderer for dropdown actions
  dropdownRenderer(params) {
    const dots = document.createElement('div');
    dots.classList.add('dropdown-dots');
    dots.innerHTML = '<i class="fas fa-ellipsis-v"></i>';
    const dropdown = document.getElementById('stop-point-dropdown-container');
    dots.addEventListener('click', () => {
      setTimeout(() => {
        if (!this.selectedStopPoint) {
          dropdown.style.top = 'calc(' + dots.getBoundingClientRect().bottom + 'px - 10px)';
          dropdown.style.left = 'calc(' + dots.getBoundingClientRect().left + 'px - 9vw)';
          dropdown.style.display = 'block';
          this.selectedStopPoint = params.data;
        } else {
          this.closeDropdown();
        }
      }, 20);
    });
    return dots;
  }

  getTranslations() {
    this.listen.push(this.translate.get('STOP_POINT.RECEIVER_NAME').subscribe((res: string) => { this.receiverTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.ADDRESS').subscribe((res: string) => { this.addressTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.RECEIVER_CITY').subscribe((res: string) => { this.cityTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.RECEIVER_COUNTRY').subscribe((res: string) => { this.countryTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.RECEIVER_ADDRESS').subscribe((res: string) => { this.streetTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.RECEIVER_POSTAL_CODE').subscribe((res: string) => { this.postalCodeTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SENDER_CITY').subscribe((res: string) => { this.senderCityTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SENDER_COUNTRY').subscribe((res: string) => { this.senderCountryTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SENDER_ADDRESS').subscribe((res: string) => { this.senderStreetTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SENDER_POSTAL_CODE').subscribe((res: string) => { this.senderPostalCodeTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.DATE_CREATED').subscribe((res: string) => { this.dateTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.TIME_CREATED').subscribe((res: string) => { this.timeTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.VOLUME').subscribe((res: string) => { this.loadTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.MASS').subscribe((res: string) => { this.weightTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.COMPLETE_DATE').subscribe((res: string) => { this.completeDateTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.COMPLETE_TIME').subscribe((res: string) => { this.completeTimeTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.SYSTEMIC_VOUCHER_TRACKING_NUMBER').subscribe((res: string) => { this.systemicVoucherTrackingNumberTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.COUNT').subscribe((res: string) => { this.packagesCountTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SENDER_NAME').subscribe((res: string) => { this.collaboratorTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.VAT_CHARGE').subscribe((res: string) => { this.taxTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.TOTAL_CHARGE').subscribe((res: string) => { this.totalChargeTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.FARE_CHARGE').subscribe((res: string) => { this.fareChargeTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.FUEL_SURCHARGE').subscribe((res: string) => { this.fuelSurchargeTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.SHIPMENT_STATUS').subscribe((res: string) => { this.shipmentStatusTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.NOTE').subscribe((res: string) => { this.noteTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.VOUCHER_PAY_PRICE').subscribe((res: string) => { this.voucherPayPriceTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.PAID_BY').subscribe((res: string) => { this.paidByTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.CONSIGNOR').subscribe((res: string) => { this.consignorLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.RECEIVER').subscribe((res: string) => { this.receiverLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SENDER').subscribe((res: string) => { this.senderLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.COLLABORATOR').subscribe((res: string) => { this.collaboratorLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.PAY_ON_DELIVERY_STATUS').subscribe((res: string) => { this.payOnDeliveryStatusTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.RECIPIENT').subscribe((res: string) => { this.recipientLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.BARCODE').subscribe((res: string) => { this.barcodeTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.nameTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.PHONE').subscribe((res: string) => { this.phoneTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.ALL').subscribe((res: string) => { this.allTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.RECEIVER_PHONE').subscribe((res: string) => { this.telephoneTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.SERIAL').subscribe((res: string) => { this.serialNumberTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.ARRIVAL_TIME').subscribe((res: string) => { this.arrivalTimeTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.COMPLETION_TIME').subscribe((res: string) => { this.completionTimeTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.PAYMENT_STATUS').subscribe((res: string) => { this.paymentStatusTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.RETURN').subscribe((res: string) => { this.returnLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.STOP_POINTS_NO_VOUCHERS').subscribe((res: string) => { this.noVouchersAlert = res; }));
    this.listen.push(this.translate.get('STOP_POINT.VOLUME_WEIGHT').subscribe((res: string) => { this.volumeWeightTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.PAY_ON_DELIVERY').subscribe((res: string) => { this.payOnDeliveryTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
    this.listen.push(this.translate.get('GENERIC.RECENT').subscribe((res: string) => { this.recentLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.LAST_WEEK').subscribe((res: string) => { this.lastWeekLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.LAST_MONTH').subscribe((res: string) => { this.lastMonthLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.CUSTOM').subscribe((res: string) => { this.customLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.STATUS_COMPLETED').subscribe((res: string) => { this.completedLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.STATUS_CANCELED').subscribe((res: string) => { this.canceledLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.SERVICES').subscribe((res: string) => { this.servicesTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.SERVICES_CHARGE').subscribe((res: string) => { this.servicesChargeTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.LITERS_UNIT').subscribe((res: string) => { this.litersUnit = res; }));
    this.listen.push(this.translate.get('GENERIC.KILOS').subscribe((res: string) => { this.kilosUnit = res; }));
    this.getChartData();

    const addressClassRules = {
      'medium-red-letters': this.redLettersClass.bind(this)
    };

    this.excelStyles = [
      {
        id: 'stringType',
        dataType: 'String',
      },
    ];


    this.columnDefs = [
      {
        headerName: this.receiverTitle,
        field: 'name',
        sortable: false,
        filter: true,
        checkboxSelection: true,
        cellRenderer: this.gridsService.nameRenderer,
        width: this.gridsService.widthCalculator(15)
      },
      {
        headerName: this.telephoneTitle,
        field: 'telephone',
        hide: true,
        cellClass: 'stringType'
      },
      {
        headerName: this.barcodeTitle,
        field: 'barcode',
        hide: true,
      },
      {
        headerName: this.systemicVoucherTrackingNumberTitle,
        field: 'xlsSystemicVoucherTrackingNumber',
        hide: true,
      },
      {
        headerName: this.collaboratorTitle,
        field: 'collaborator',
        sortable: false,
        cellRenderer: this.gridsService.collaboratorRenderer,
        width: this.gridsService.widthCalculator(9)
      },
      {
        headerName: this.addressTitle,
        field: 'address',
        sortable: false,
        filter: true,
        cellRenderer: this.gridsService.addressRenderer,
        cellClassRules: addressClassRules,
        width: this.gridsService.widthCalculator(15)
      },
      {
        headerName: this.streetTitle,
        field: 'xlsStreet',
        hide: true
      },
      {
        headerName: this.cityTitle,
        field: 'xlsCity',
        hide: true
      },
      {
        headerName: this.countryTitle,
        field: 'xlsCountry',
        hide: true
      },
      {
        headerName: this.senderPostalCodeTitle,
        field: 'xlsSenderPostalCode',
        hide: true
      },
      {
        headerName: this.senderStreetTitle,
        field: 'xlsSenderStreet',
        hide: true
      },
      {
        headerName: this.senderCityTitle,
        field: 'xlsSenderCity',
        hide: true
      },
      {
        headerName: this.senderCountryTitle,
        field: 'xlsSenderCountry',
        hide: true
      },
      {
        headerName: this.postalCodeTitle,
        field: 'xlsPostalCode',
        hide: true
      },
      {
        headerName: this.dateTitle,
        field: 'xlsDate',
        hide: true
      },
      {
        headerName: this.timeTitle,
        field: 'xlsTime',
        hide: true
      },
      {
        headerName: this.completeDateTitle,
        field: 'xlsCompleteDate',
        hide: true
      },
      {
        headerName: this.completeTimeTitle,
        field: 'xlsCompleteTime',
        hide: true
      },
      {
        headerName: this.loadTitle,
        field: 'xlsVolume',
        hide: true
      },
      {
        headerName: this.weightTitle,
        field: 'xlsWeight',
        hide: true
      },
      {
        headerName: this.packagesCountTitle,
        field: 'xlsPackagesCount',
        hide: true
      },
      {
        headerName: this.fareChargeTitle,
        field: 'xlsFareCharge',
        hide: true
      },
      {
        headerName: this.packagesCountTitle,
        field: 'xlsPackagesCount',
        hide: true
      },
      {
        headerName: this.dateTitle,
        field: 'datetime',
        cellRenderer: this.gridsService.dateTimeRenderer,
        width: this.gridsService.widthCalculator(10)
      },
      {
        headerName: this.completionTimeTitle,
        field: 'time',
        sortable: false,
        width: this.gridsService.widthCalculator(5),
        cellRenderer: this.gridsService.timeRenderer
      },
      {
        headerName: this.volumeWeightTitle,
        field: 'volumeWeight',
        sortable: false,
        cellRenderer: this.gridsService.volumeWeightCountRenderer,
        width: this.gridsService.widthCalculator(5)
      },
      {
        headerName: this.paymentStatusTitle,
        field: 'paymentStatus',
        sortable: false,
        cellRenderer: this.gridsService.paymentStatusRenderer.bind(this),
        width: this.gridsService.widthCalculator(8)
      },
      // exists to print pod status
      {
        headerName: this.payOnDeliveryStatusTitle,
        field: 'payOnDeliveryStatus',
        hide: true
      },
      {
        headerName: this.payOnDeliveryTitle,
        field: 'payOnDelivery',
        sortable: false,
        cellRenderer: this.gridsService.payOnDeliveryRenderer,
        cellEditorFramework: PaymentStatusSelectEditorComponent,
        editable: (params) => this.gridsService.isStopPointRowWithPayAmount(params),
        width: this.gridsService.widthCalculator(10)
      },
      {
        headerName: this.shipmentStatusTitle,
        field: 'status',
        sortable: false,
        cellRenderer: this.statusRenderer,
        width: this.gridsService.widthCalculator(13)
      },
      {
        headerName: this.shipmentStatusTitle,
        field: 'xlsShipmentStatus',
        hide: true
      },
      // exists to print POD status
      {
        headerName: this.payOnDeliveryStatusTitle,
        field: 'xlsPodStatus',
        hide: true
      },
      // exists to print POD amount
      {
        headerName: this.payOnDeliveryTitle,
        field: 'xlsPodAmount',
        hide: true
      },
      {
        headerName: this.paidByTitle,
        field: 'paidBy',
        hide: true
      },
      {
        headerName: this.noteTitle,
        field: 'note',
        hide: true
      },
      {
        headerName: '', field: '',
        cellClass: 'dropdown-cell',
        cellRenderer: this.dropdownRenderer.bind(this),
        width: this.gridsService.widthCalculator(5)
      }
    ];

    this.searchTypeOptions = [
      {
        label: this.barcodeTitle,
        value: 'barcode',
      },
      {
        label: this.nameTitle,
        value: 'name',
      },
      {
        label: this.phoneTitle,
        value: 'telephone',
      },
      {
        label: this.addressTitle,
        value: 'address',
      },
      {
        label: this.allTitle,
        value: 'all',
      },
    ];
    this.selectedSearchType = 'barcode';

    this.stopsPeriods = [
      {
        label: this.recentLabel,
        value: 1
      },
      {
        label: this.lastWeekLabel,
        value: 6
      },
      {
        label: this.lastMonthLabel,
        value: 29
      },
      {
        label: this.customLabel,
        value: 'custom'
      },
    ];
    this.selectedStopsPeriod = {
      label: this.recentLabel,
      value: 1
    };
  }

  ngOnInit() {
    const timezone = moment.tz.guess();
    const startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    this.selectedDatesQuery = 'startDate=' + startDate + '&endDate=' + endDate + '&timezone=' + timezone;
    this.initGrid();
    this.loadPagination();

    this.importerService.disableCreateStopPoint();

    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe((text: string) => {
        if (text.length > 2 || !text.length) {
          this.updateGrid();
        }
      });
  }

  ngAfterViewInit() {
    this.fileInput.nativeElement.onclick = (event) => { event.target.value = null; };
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }
}
