import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ImporterService } from '@app/services/importer.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { Globals } from '@app/services/globals';
import { MilyService } from '@app/services/mily.service';
import { AddressService } from '@app/services/address.service';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, mergeAll, finalize, take } from 'rxjs/operators';
import { DataService } from '@app/services/data.service';
import { Router } from '@angular/router';
import { GenericService } from '@app/services/generic.service';
import { Console } from 'console';
import { DepotUtils } from '@app/utils/depot-utils';
import { LmNotificationService } from '@app/core/services/notification.service';

@Component({
  selector: 'app-importer-modal',
  templateUrl: './importer-modal.component.html',
  styleUrls: ['./importer-modal.component.scss']
})
export class ImporterModalComponent implements OnInit, OnDestroy {
  @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;
  @ViewChild('collaboratorNameNgSelect', { static: false }) collaboratorNameNgSelect: NgSelectComponent;
  @ViewChild('collaboratorAddressNgSelect', { static: false }) collaboratorAddressNgSelect: NgSelectComponent;

  getHelperDataUrl = 'api/v1/helper-stop-points';
  getPartnerHelperDataUrl = 'api/v1/partner-helper-stop-points';

  isFormValid = true;
  listen = [];
  intervals = [];
  depotsDataRefreshIntervalId;
  refreshIntervalId;

  isClickedOnce = false;
  alert;
  maxPointsLimitReachedAlert = '';

  projectProblemId;

  jobId = null;
  isPendingDelete;

  // basicVoucherServicesOptions = [];
  // selectedBasicVoucherService;
  matcherGroups = [];
  sheetsOptions = [];
  selectedSheet = {};
  selectedGroup = {};
  importerFields = [];
  importerFieldsNoEmpty = [];

  systemConstantsObject = {};
  systemConstants = [];

  locationSystemConstants = [];
  pickupSystemConstants = [];
  alternativeLocationSystemConstants = [];
  recipientSystemConstants = [];
  collaboratorSystemConstants = [];
  routingSystemConstants = [];
  stopPointSystemConstants = [];

  alternativeLocationsEnabled = false;

  selectedConstant = {};
  previousSelectedConstant = {};
  selectedDepotItem;
  selectedPartnerItem;
  depots = [];
  partners = [];

  csvFile;
  name = '';
  id = null;
  namePlaceholder = '';
  csvFileColumnHeaderNames = {};
  // makeNewGroup = false;
  milyOpenedForErrorStops = false;
  stepsSystemEnabled = false;
  visibleStep = null;
  // pickupSteps = [2];
  lastStep = 5;
  valueChanged = false;
  recipientLabelInStopPointData = true;

  basicServicesOptions = [];
  shipmentTypeOptions = [];
  collaboratorTypeOptions = [];
  selectedService = {};
  defaultService = {};
  selectedShipmentType = {};
  selectedCollaboratorType = {};
  selectedFoodDepot;

  vouchersEnabled = false;
  chargesEnabled = false;

  isPickupAndDelivery = false;
  isSameDay = false;

  myFirstTemplateMsg = '';
  onlyPickupMsg = '';
  onlyDeliveryMsg = '';
  expressMsg;
  throughHubMsg;
  nextDayMsg;
  serviceChangedLabel;
  priceListNotFoundLabel;

  collaboratorName = null;
  collaboratorData = null;
  collaboratorBasicServiceIds = null;

  selectedCollaborator = {};
  collaboratorNames: Observable<any>;
  collaboratorNamesLoading = false;
  collaboratorNameInput = new Subject<string>();

  collaboratorId = null;

  collaboratorSelectedAddress = {};
  collaboratorAddresses: Observable<any>;
  collaboratorAddressesLoading = false;
  collaboratorAddressInput = new Subject<string>();

  errorsMsg = {
    102: '',
    103: '',
    300: '',
    512: '',
    'missingValue': ''
  };

  constructor(
    private http: HttpClient,
    private importerService: ImporterService,
    private viewProjectProblemService: ViewProjectProblemService,
    public translate: TranslateService,
    private projectProblemDataService: ProjectProblemDataService,
    public globals: Globals,
    private milyService: MilyService,
    private addressService: AddressService,
    private dataService: DataService,
    public router: Router,
    private genericService: GenericService,
    private depotUtils: DepotUtils,
    private _notificationSvc: LmNotificationService,
  ) {
    this.listen.push(this.importerService.openImporterMatcherListen().subscribe((data) => {
      this.emptyImporterModal();
      this.loadImporterData(data);
      this.openImporterModal(data['previousSavedImportMatcherPreferencesGroups']);
    }));
  }

  deleteSelectedGroupMilyConfirm() {
    this.milyService.setConfirmMessageType('deleteImporterGroup');
    this.milyService.confirmMessageObservable.pipe(take(1)).subscribe(res => {
      if (res == 'ok') {
        this.deleteSelectedGroup();
      }
    });
  }

  deleteSelectedGroup() {
    if (!this.isPendingDelete) {
      this.isPendingDelete = true;
      this.matcherGroups.forEach((group, groupIndex) => {
        if (group['import_matcher_group_id'] == this.selectedGroup['import_matcher_group_id']) {
          this.matcherGroups.splice(groupIndex, 1);
        }
      });
      this.matcherGroups = [...this.matcherGroups];

      this.http.delete('api/v1/import-matcher-group/' + this.selectedGroup['import_matcher_group_id']).subscribe(response => {
        this.isPendingDelete = false;

        if (this.matcherGroups.length) {
          this.selectedGroup = this.matcherGroups[0];
          this.groupChanged();
        } else {
          this.selectedGroup = null;
          this.createNewGroup();
        }
      });
    }
  }

  loadImporterData(data) {
    const initialCsv = data.csvSheets[0];
    this.csvFile = initialCsv.csvFile;
    this.selectedSheet = initialCsv;
    this.sheetsOptions = data.csvSheets;
    this.systemConstantsObject = data.matcherFieldSettings;
    // console.log(this.systemConstantsObject);
    this.updateImporterDataFields(initialCsv);
  }

  isFieldVisibleInGroup(fieldObject) {
    const formVisibility = fieldObject['form_visibility'];
    // console.log(fieldObject['label'], formVisibility);
    if (formVisibility && formVisibility['voucher'] && formVisibility['no_voucher']) {
      if (this.vouchersEnabled) {
        const collaboratorTypeSelected = this.selectedCollaboratorType['constant'];
        const shipmentTypeSelected = this.selectedShipmentType['constant'];
        if (shipmentTypeSelected && collaboratorTypeSelected) {
          fieldObject['mandatory'] = formVisibility['voucher'][shipmentTypeSelected][collaboratorTypeSelected]['mandatory'];
          return formVisibility['voucher'][shipmentTypeSelected][collaboratorTypeSelected]['visibility'];
        }
      } else {
        const shipmentType = Object.values(this.globals.priceListShipmentEnum).find(item => item.key === this.selectedService['shipment_type']);
        const shipmentTypeSelected =  this.globals.convertToUpperSnakeCase(shipmentType.value);
        fieldObject['mandatory'] = formVisibility['no_voucher'][shipmentTypeSelected]['mandatory'];
        return formVisibility['no_voucher'][shipmentTypeSelected]['visibility'];
      } 
    } 
    return false;
  }

  withVoucherChanged() {
    this.selectedCollaborator = null;
    this.collaboratorName = '';
    this.collaboratorData = null;
    this.collaboratorBasicServiceIds = null;
    this.collaboratorId = null;
    this.selectedService = null;
    const collaboratorNameΙnputElement = (<HTMLInputElement>document.getElementById('collaborator-importer-name-custom-input'));
    if (collaboratorNameΙnputElement) {
      collaboratorNameΙnputElement.value = this.collaboratorName;
    }
    this.collaboratorSelectedAddress = null;
    const collaboratorAddressΙnputElement = (<HTMLInputElement>document.getElementById('collaborator-importer-address-custom-input'));
    if (collaboratorAddressΙnputElement) {
      collaboratorAddressΙnputElement.value = '';
    }
    this.setupServicesOptions(); 
    this.matcherOptionsChanged();
    this.checkFormValidity();
  }

  matcherOptionsChanged() {
    this.updateImporterDataFields(this.selectedSheet);
  }

  updateImporterDataFields(sheetData) {
    this.importerFields = [];
    this.importerFieldsNoEmpty = [];
    this.locationSystemConstants = [];
    this.alternativeLocationSystemConstants = [];
    this.pickupSystemConstants = [];
    this.collaboratorSystemConstants = [];
    this.recipientSystemConstants = [];
    this.routingSystemConstants = [];
    this.stopPointSystemConstants = [];
    this.systemConstants = [];
    this.csvFileColumnHeaderNames = {};

    Object.keys(this.systemConstantsObject).forEach(field => {
      // console.log(this.isFieldVisibleInGroup(this.systemConstantsObject[field]));
      if (!this.systemConstantsObject[field]['hidden'] && this.isFieldVisibleInGroup(this.systemConstantsObject[field])) {
        this.systemConstantsObject[field]['constant'] = field;
        this.systemConstants.push(this.systemConstantsObject[field]);
        if (this.systemConstantsObject[field]['import_matcher_group'] === 'IMG_LO') {
          this.locationSystemConstants.push(this.systemConstantsObject[field]);
        } else if (this.systemConstantsObject[field]['import_matcher_group'] === 'IMG_AL') {
          this.alternativeLocationSystemConstants.push(this.systemConstantsObject[field]);
        } else if (this.systemConstantsObject[field]['import_matcher_group'] === 'IMG_PP') {
          this.pickupSystemConstants.push(this.systemConstantsObject[field]);
        } else if (this.systemConstantsObject[field]['import_matcher_group'] === 'IMG_SR') {
          this.collaboratorSystemConstants.push(this.systemConstantsObject[field]);
        } else if (this.systemConstantsObject[field]['import_matcher_group'] === 'IMG_RE') {
          this.recipientSystemConstants.push(this.systemConstantsObject[field]);
        } else if (this.systemConstantsObject[field]['import_matcher_group'] === 'IMG_RO') {
          this.routingSystemConstants.push(this.systemConstantsObject[field]);
        } else if (this.systemConstantsObject[field]['import_matcher_group'] === 'IMG_SP') {
          this.stopPointSystemConstants.push(this.systemConstantsObject[field]);
        }
      }
    });

    this.recipientLabelInStopPointData = true;
    const stopPointCollaboratorTypesConstants = this.globals.stopPointCollaboratorTypesConstants;

    if (
      this.selectedCollaboratorType['value'] == stopPointCollaboratorTypesConstants['RECEIVER'] ||
      (!this.vouchersEnabled && this.selectedService['shipment_type'] == this.globals.priceListShipmentEnum.ShopDoor.key) ||
      (this.vouchersEnabled && this.selectedService['shipment_type'] == this.globals.priceListShipmentEnum.DoorShop.key && this.selectedCollaboratorType['value'] == stopPointCollaboratorTypesConstants['CONSIGNOR'])
    ) {
      this.recipientLabelInStopPointData = false;
    }
    // console.log(this.recipientLabelInStopPointData);

    function sortByOrder(a, b) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    }
    this.locationSystemConstants.sort(sortByOrder);
    this.pickupSystemConstants.sort(sortByOrder);
    this.alternativeLocationSystemConstants.sort(sortByOrder);
    this.collaboratorSystemConstants.sort(sortByOrder);
    this.recipientSystemConstants.sort(sortByOrder);
    this.routingSystemConstants.sort(sortByOrder);
    this.stopPointSystemConstants.sort(sortByOrder);

    this.csvFileColumnHeaderNames = sheetData.csvFileColumnHeaderNames;
    Object.keys(sheetData.csvFileColumnHeaderNames).forEach(field => {
      let label = field;
      if (sheetData.csvFileColumnHeaderNames[field]) {
        label += ' (' + sheetData.csvFileColumnHeaderNames[field] + ')';
      }

      const dropdownObject = {
        label: label,
        value: field
      };
      this.importerFields.push(dropdownObject);
      this.importerFieldsNoEmpty.push(dropdownObject);
    });
  }

  createNewGroup() {
    // this.makeNewGroup = true;
    this.selectedGroup = {};
    this.emptyGroup();
    this.displayImporterStepByStep();
    this.name = '';
    this.id = null;
  }

  groupChanged() {
    if (this.selectedGroup) {
      this.loadGroup(this.selectedGroup['previousSavedImportMatcherPreferences'], this.selectedGroup['collaborator']);
      this.name = this.selectedGroup['group_name'];
      this.id = this.selectedGroup['import_matcher_group_id'];

      if (this.chargesEnabled) {
        this.selectedService = this.basicServicesOptions.find(service => service.id == this.selectedGroup['service_id']);

        this.selectedShipmentType = this.shipmentTypeOptions.find(type => type.value == this.selectedGroup['shipment_type']);
        if (!this.selectedShipmentType) {this.selectedShipmentType = this.shipmentTypeOptions[0]; console.warn('Could not find shipmentType, selecting the first one');}

        if (this.selectedGroup['collaborator_type']) {
          this.selectedCollaboratorType = this.collaboratorTypeOptions.find(type => type.value == this.selectedGroup['collaborator_type']);
          if (!this.selectedCollaboratorType) {this.collaboratorTypeOptions[0]; console.warn('Could not find scollaboratorType, selecting the first one');}
        }
      } else {
        this.selectedService = this.basicServicesOptions.find(service => service.shipment_type == this.selectedGroup['shipment_type'] && service.pricelist_service_type == this.selectedGroup['pricelist_service_type ']);
      }
      
    } else {
      this.id = null;
      this.name = '';
      this.selectedService = this.defaultService;
    }
    if (!this.selectedService) { this.selectedService = this.defaultService; console.warn('Could not find basicService, selecting the default'); }

    this.matcherOptionsChanged();
    this.checkFormValidity();
  }

  sheetChanged(sheetData) {
    this.csvFile = sheetData.csvFile;
    this.csvFileColumnHeaderNames = sheetData.csvFileColumnHeaderNames;
    this.loadGroup(this.selectedGroup['previousSavedImportMatcherPreferences'], this.selectedGroup['collaborator']);
    this.updateImporterDataFields(sheetData);
  }

  sheetChangedSetup(sheetData) {
    this.csvFile = sheetData.csvFile;
    this.csvFileColumnHeaderNames = sheetData.csvFileColumnHeaderNames;
    this.updateImporterDataFields(sheetData);
  }

  constantChanged(changedField) {
    this.valueChanged = true;
    // if the field that changed is not notes
    // remove the option from dropdown if selected or add it if unselected
    if (changedField['constant_name'] !== 'M_NOTE') {
      if (this.selectedConstant[changedField.constant_name]) {
        if (this.selectedConstant[changedField.constant_name].length) {
          this.removeValueFromDropdown(this.selectedConstant[changedField.constant_name][this.selectedConstant[changedField.constant_name].length - 1]);
        } else {
          this.removeValueFromDropdown(this.selectedConstant[changedField.constant_name]);
        }
        this.systemConstants.forEach(constant => {
          if (this.selectedConstant[constant.constant_name]) {
            // if the target field is not notes (do not remove option if is in notes)
            if (this.selectedConstant[constant.constant_name].length && constant.constant_name !== 'M_NOTE') {
              const indexesToRemove = [];
              this.selectedConstant[constant.constant_name].forEach((selectedConstant, index) => {
                if (this.selectedConstant[changedField.constant_name].value) {
                  if (selectedConstant.value === this.selectedConstant[changedField.constant_name].value && constant !== changedField) {
                    indexesToRemove.push(index);
                  }
                } else if (this.selectedConstant[changedField.constant_name][0]) {
                  this.selectedConstant[changedField.constant_name].forEach(field => {
                    if (selectedConstant.value === field.value && constant !== changedField) {
                      indexesToRemove.push(index);
                    }
                  });
                }
              });
              indexesToRemove.forEach(index => {
                this.selectedConstant[constant.constant_name].splice(index, 1);
              });
            }

            if (this.selectedConstant[changedField.constant_name].value) {
              if (this.selectedConstant[constant.constant_name].value === this.selectedConstant[changedField.constant_name].value && constant !== changedField) {
                this.selectedConstant[constant.constant_name] = null;
              }
            } else if (this.selectedConstant[changedField.constant_name][0]) {
              this.selectedConstant[changedField.constant_name].forEach(field => {
                if (this.selectedConstant[constant.constant_name].value === field.value && constant !== changedField) {
                  this.selectedConstant[constant.constant_name] = null;
                }
              });
            }
          }
        });
      }

      if (
        this.previousSelectedConstant[changedField.constant_name] &&
        this.previousSelectedConstant[changedField.constant_name] !== this.selectedConstant[changedField.constant_name]
      ) {
        if (this.previousSelectedConstant[changedField.constant_name].length) {
          const differences = this.previousSelectedConstant[changedField.constant_name].filter(x => !this.selectedConstant[changedField.constant_name].includes(x));
          differences.forEach(difference => {
            if (difference.value) {
              this.importerFields.push(difference);
            }
          });
        } else {
          if (this.previousSelectedConstant[changedField.constant_name].value) {
            this.importerFields.push(this.previousSelectedConstant[changedField.constant_name]);
          }
        }
        this.importerFields = this.importerFields.slice(0);
      }

      this.previousSelectedConstant = { ...this.selectedConstant };
    }

    // allow either depot name OR depot identification number to be matched to a field
    if (changedField.constant_name == 'M_DPID') {
      this.selectedConstant['M_DPNM'] = null;
    } else if (changedField.constant_name == 'M_DPNM') {
      this.selectedConstant['M_DPID'] = null;
    }
  }

  updateStopPointsOnImportError() {
    let getHelperDataUrl = '';

    // if we are in a pp
    if (this.projectProblemId) {
      getHelperDataUrl += '?projectProblemId=' + this.projectProblemId;
      this.milyOpenedForErrorStops = false;
      this.projectProblemDataService.updateStopPoints(true);
      const dataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
      function dataChecker() {
        if (!this.projectProblemDataService.updatingStopPoints) {
          clearInterval(dataRefreshIntervalId);
          this.http.get(getHelperDataUrl).pipe(take(1)).subscribe(helperResponse => {
            this.milyService.uploadPointsDone();
            if (helperResponse['items'].length) {
            } else {
              this.viewProjectProblemService.updateProjectProblemStatus();
            }
          });
        }
      }
    } else if (this.router.url.includes('newShipmentsView')) {
      this.milyService.uploadPointsDone();
      this.genericService.newShipmentsGenerateGridData();
    } else {
      this.http.get(getHelperDataUrl).pipe(take(1)).subscribe(helperResponse => {
        this.milyService.uploadPointsDone();
        if (helperResponse['items'].length) {
          const itemsLength = helperResponse['items'].length;
          for (let i = 0; i < itemsLength; i++) {
            if (helperResponse['items'][i].importConflictItem.conflict_type === 'address_conflict') {
              this.milyService.helper('importer');
              break;
            }
          }
        }
      });
    }
  }

  jobChecker() {
    this.http.get('/api/v1/company/import/' + this.jobId).pipe(take(1)).subscribe(
      response => {
        if (response['isDone'] === true) {
          clearInterval(this.refreshIntervalId);
          this.genericService.newShipmentsPickupButtonToggle(false); // re-enable request for pickup button
          this.genericService.canRequestForPickup = true; // re-enable request for pickup button (collaborator food -> collaborator overview)
          let getHelperDataUrl = this.getHelperDataUrl;
          if (this.globals.collaboratorModeEnabled) {
            getHelperDataUrl = this.getPartnerHelperDataUrl;
          }

          const report = response['report'];
          if (report && report.new_stop_points_count && report.total_lines) {
            // create mily message for successfully uploaded stop points
            const uploadReport = {
              hasErrors: false,
              newStopPointsCount: report.new_stop_points_count,
              totalLines: report.total_lines
            };
            this.milyService.importFileLineErrors({ ...uploadReport });
          }

          // if we are in a pp
          if (this.projectProblemId) {
            getHelperDataUrl += '?projectProblemId=' + this.projectProblemId;
            this.milyOpenedForErrorStops = false;
            this.projectProblemDataService.updateStopPoints(true);
            const dataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
            function dataChecker() {
              if (!this.projectProblemDataService.updatingStopPoints) {
                clearInterval(dataRefreshIntervalId);
                this.http.get(getHelperDataUrl).pipe(take(1)).subscribe(helperResponse => {
                  this.milyService.uploadPointsDone();
                  if (helperResponse['items'].length) {
                  } else {
                    this.viewProjectProblemService.updateProjectProblemStatus();
                  }
                });
              }
            }
          } else if (this.router.url.includes('newShipmentsView')) {
            this.milyService.uploadPointsDone();
            this.genericService.newShipmentsGenerateGridData();
          } else {
            this.http.get(getHelperDataUrl).pipe(take(1)).subscribe(helperResponse => {
              this.milyService.uploadPointsDone();
              if (helperResponse['items'].length) {
                const itemsLength = helperResponse['items'].length;
                for (let i = 0; i < itemsLength; i++) {
                  if (helperResponse['items'][i].importConflictItem.conflict_type === 'address_conflict') {
                    this.milyService.helper('importer');
                    break;
                  }
                }
                // }
              }
            });
          }

        }
      },
      error => {
        this.updateStopPointsOnImportError();
        let priceListErrorFound = false;

        // create mily message for non-imported lines due to errors
        let errorLinesMessage = '';
        const errorLines = Object.keys(error.error['errors']);
        const uploadReport = {
          hasErrors: true,
          newStopPointsCount: error.error['report'].new_stop_points_count,
          totalLines: error.error['report'].total_lines,
          linesMessage: ''
        };

        // array of line numbers
        errorLines.forEach(line => {
          errorLinesMessage += line + ', ';

          // TODO this is bad, ask BE to change this to an error code 
          if (error.error['errors'][line] && error.error['errors'][line][0]) {
            if (error.error['errors'][line][0]['error'] == "Voucher creation Aborted, No applicable PriceList found") priceListErrorFound = true;
          }
        });
        errorLinesMessage = errorLinesMessage.slice(0, -2);
        uploadReport.linesMessage = errorLinesMessage;

        // create mily message
        if (errorLines.length) {
          this.milyService.importFileLineErrors({ ...uploadReport });
        }
        this.milyService.uploadPointsDone();
        if (priceListErrorFound) this._notificationSvc.showWarning('', this.priceListNotFoundLabel);

        clearInterval(this.refreshIntervalId);
      }
    );
  }

  waitForJob() {
    this.jobChecker();
    this.refreshIntervalId = setInterval(this.jobChecker.bind(this), 1000);
    this.intervals.push(this.refreshIntervalId);

    this.isClickedOnce = false;
    this.closeImporterModal();
    this.milyService.uploadingPoints();
  }

  submitImporter() {
    this.isClickedOnce = true;
    this.genericService.newShipmentsPickupButtonToggle(true);
    const myObserver = {
      next: (response) => {
        this.jobId = response['jobId'];
        this.waitForJob();
      },
      error: (error) => {
        this.isClickedOnce = false;
        if (error.error.errors) {
          if (error.error.errors.import) {
            const importErrors = error.error.errors.import;
            if (importErrors.maxPointsLimitReached === true) {
              this.milyService.alert(this.maxPointsLimitReachedAlert);
            }
            if (importErrors.matcherFieldConstants) {
              console.error(importErrors.matcherFieldConstants);
              Object.keys(importErrors.matcherFieldConstants).forEach(errorCode => {
                let alertMsg = '';
                let constantsWithErrorLabels = '';
                importErrors.matcherFieldConstants[errorCode].forEach(constantWithError => {
                  if (constantsWithErrorLabels !== '') {
                    constantsWithErrorLabels += ', ';
                  }
                  const fullConstantWithError = this.globals.stopPointImportFieldConfigsConstantNamesToData[constantWithError]['originalKey'];
                  constantsWithErrorLabels += this.systemConstantsObject[fullConstantWithError]['label'];
                });
                alertMsg += this.errorsMsg[errorCode].replace('SYSTEM_CONSTANT', constantsWithErrorLabels) + ' ';
                this.milyService.alert(alertMsg);
              });
            }
            if (importErrors.preProcess) {
              let alertMsg = '';
              Object.keys(importErrors.preProcess).forEach(line => {
                let constantsWithErrorLabels = '';
                importErrors.preProcess[line]['missingValues'].forEach(constantWithError => {
                  if (constantsWithErrorLabels !== '') {
                    constantsWithErrorLabels += ', ';
                  }
                  const fullConstantWithError = this.globals.stopPointImportFieldConfigsConstantNamesToData[constantWithError]['originalKey'];
                  constantsWithErrorLabels += this.systemConstantsObject[fullConstantWithError]['label'];
                });
                alertMsg += this.errorsMsg['missingValue'].replace('SYSTEM_CONSTANT', constantsWithErrorLabels) + ' ';
                alertMsg = alertMsg.replace('FILE_LINE', line);
              });
              this.milyService.alert(alertMsg);
            }
          }
        }
      },
      complete: () => { },
    };

    // if the user didn't select any of the import groups and hasn't created a new one, add a group named "my first template"
    if (!this.id && !this.name) {
      this.name = this.myFirstTemplateMsg;
    }

    let depotId;
    if (this.selectedDepotItem) {
      depotId = this.selectedDepotItem.companyDepot.id;
    }
    console.log(this.selectedService['shipment_type']);
    console.log(this.globals.priceListShipmentEnum);

    const matcherData = {
      projectProblemId: this.projectProblemId,
      companyDepotIds: this.router.url.includes('newShipmentsView') || this.router.url.includes('collaboratorOverview') ? [] : [depotId],
      csvFile: this.csvFile,
      import_matcher_group_id: this.id,
      group_name: this.name,
      isReverseGeocoding: this.alternativeLocationsEnabled,
      matcher: {},
      pickup_address: {},
      service_id: this.selectedService['id'],
      shipment_type: (this.chargesEnabled && this.vouchersEnabled) ? this.selectedShipmentType['value'] : this.selectedService['shipment_type'],
      pricelist_service_type : this.selectedService['pricelist_service_type'],
    };

    // [food mode]: shop company depot id
    if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
      matcherData['shopCompanyDepotId'] = this.selectedFoodDepot['companyDepot']['id'];
    }

    if (this.globals.vouchersEnabled && this.collaboratorData) {
      const collaboratorObj = {
        collaborator_id: this.collaboratorData['collaboratorData']['id'],
        type: this.selectedCollaboratorType['value']
      };
      matcherData['companyStopPointVoucherCollaborators'] = [collaboratorObj];
      matcherData['collaborator'] = this.collaboratorData;
      if (this.collaboratorSelectedAddress) {
        if (this.collaboratorSelectedAddress['address']) {
          this.collaboratorSelectedAddress['address']['lat'] = this.collaboratorSelectedAddress['position'][0];
          this.collaboratorSelectedAddress['address']['lon'] = this.collaboratorSelectedAddress['position'][1];
          matcherData['pickup_address'] = this.collaboratorSelectedAddress['address'];
        }
      }
    }
    if (this.globals.collaboratorModeEnabled && this.selectedPartnerItem) {
      const collaboratorObj = {
        collaborator_id: this.selectedPartnerItem['collaborator_id'],
        type: this.selectedCollaboratorType['value']
      };
      matcherData['companyStopPointVoucherCollaborators'] = [collaboratorObj];
    }

    if (this.router.url.includes('newShipmentsView') || this.router.url.includes('collaboratorOverview')) {
      let partnerId;
      if (this.selectedPartnerItem) {
        partnerId = this.selectedPartnerItem.id
      } else {
        partnerId = this.globals.partnersArray[0]['id'];
      }
      matcherData['company_partner'] = { id: partnerId };
    }

    this.systemConstants.forEach((constant, index) => {
      if (this.selectedConstant[constant.constant_name]) {
        if (this.selectedConstant[constant.constant_name].value) {
          matcherData.matcher[this.selectedConstant[constant.constant_name].value] = constant.constant_name;
        }
      }
    });

    // go through the options in the multi select (notes) and make an array for those fields
    this.systemConstants.forEach((constant, index) => {
      if (this.selectedConstant[constant.constant_name]) {
        if (this.selectedConstant[constant.constant_name].length) {
          this.selectedConstant[constant.constant_name].forEach(selectedConstant => {
            if (Array.isArray(matcherData.matcher[selectedConstant.value])) {
              const arrayForMultipleFields = matcherData.matcher[selectedConstant.value];
              arrayForMultipleFields.push(constant.constant_name);
              matcherData.matcher[selectedConstant.value] = arrayForMultipleFields;
            } else if (matcherData.matcher[selectedConstant.value]) {
              const arrayForMultipleFields = [];
              arrayForMultipleFields.push(matcherData.matcher[selectedConstant.value]);
              arrayForMultipleFields.push(constant.constant_name);
              matcherData.matcher[selectedConstant.value] = arrayForMultipleFields;
            } else {
              matcherData.matcher[selectedConstant.value] = constant.constant_name;
            }
          });
        }
      }
    });
    const url = '/api/v1/company/import?isReverseGeocoding=' + (this.alternativeLocationsEnabled ? 'true' : 'false');
    // this.isClickedOnce = false;
    // console.log(matcherData);
    this.http.post(url, JSON.stringify(matcherData)).pipe(take(1)).subscribe(myObserver);
  }

  collaboratorInputFocusOut() {
    if (!this.collaboratorSelectedAddress['timeZone']) {
      if (this.collaboratorAddressNgSelect.itemsList['_filteredItems']) {
        const firstItem = this.collaboratorAddressNgSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.collaboratorAddressNgSelect.select(firstItem);
        }
      }
    }
  }

  collaboratorInputAddress() {
    this.collaboratorSelectedAddress = '';
    this.collaboratorAddressNgSelect.filter((<HTMLInputElement>document.getElementById('collaborator-importer-address-custom-input')).value);
  }

  onCollaboratorAddressChange() {
    (<HTMLInputElement>document.getElementById('collaborator-importer-address-custom-input')).value = this.collaboratorSelectedAddress['label'];
  }

  collaboratorInputName() {
    this.selectedCollaborator = '';
    this.selectedCollaborator = null;
    this.collaboratorData = null;
    this.collaboratorBasicServiceIds = null;
    this.collaboratorName = (<HTMLInputElement>document.getElementById('collaborator-importer-name-custom-input')).value;
    this.collaboratorNameNgSelect.filter((<HTMLInputElement>document.getElementById('collaborator-importer-name-custom-input')).value);
    if (!this.selectedCollaborator && !this.collaboratorName) {
      this.collaboratorSelectedAddress = null;
      this.collaboratorId = null;
      const collaboratorAddressΙnputElement = (<HTMLInputElement>document.getElementById('collaborator-importer-address-custom-input'));
      if (collaboratorAddressΙnputElement) {
        collaboratorAddressΙnputElement.value = '';
      }
      if (!this.chargesEnabled) this.vouchersEnabled = false;
      this.collaboratorBasicServiceIds = null;
      this.setupServicesOptions();
      this.matcherOptionsChanged();
      this.checkFormValidity();
    }
  }

  onCollaboratorNameChange() {
    // TODO update the day of week and selected timeWindows
    if (this.selectedCollaborator) {
      this.vouchersEnabled = true;
      this.setupServicesOptions();
      const collaborator = this.selectedCollaborator['companyCollaborator']['collaborator'];
      if (collaborator) {
        this.collaboratorData = collaborator;
        const collaboratorData = collaborator['collaboratorData'];
        if (collaboratorData) {
          (<HTMLInputElement>document.getElementById('collaborator-importer-name-custom-input')).value = collaboratorData['collaborator_name'];
          if (collaboratorData['id']) {
            this.collaboratorId = collaboratorData['id'];
          }
          this.collaboratorSelectedAddress = null;
          if (collaborator['address']) {
            const addressLabel = this.addressService.getAddressLabel(collaborator['address']);
            (<HTMLInputElement>document.getElementById('collaborator-importer-address-custom-input')).value = addressLabel;
          }

          // this.updateBasicVoucherServiceOptions();
        }
      }
    } else {
      this.collaboratorName = '';
      this.vouchersEnabled = false;
      if (this.globals.collaboratorModeEnabled) this.vouchersEnabled = true;
      this.setupServicesOptions();
    }
    this.setupServicesOptions();
    this.checkFormValidity();
  }

  onFoodDepotChange(newSelectedDepot) {
    this.selectedDepotItem = newSelectedDepot;
  }

  emptyImporterModal() {
    this.valueChanged = false;
    this.stepsSystemEnabled = false;
    this.visibleStep = null;
    this.isFormValid = true;
    this.importerFields = [];
    this.importerFieldsNoEmpty = [];
    this.systemConstantsObject = {};
    this.systemConstants = [];
    this.locationSystemConstants = [];
    this.pickupSystemConstants = [];
    this.alternativeLocationSystemConstants = [];
    this.collaboratorSystemConstants = [];
    this.recipientSystemConstants = [];
    this.routingSystemConstants = [];
    this.stopPointSystemConstants = [];
    this.selectedConstant = [];
    // this.basicVoucherServicesOptions = [];
    this.sheetsOptions = [];
    this.selectedSheet = {};
    // this.selectedBasicVoucherService = null;
    this.csvFile = '';
    this.collaboratorId = null;
    this.collaboratorData = null;
    this.collaboratorBasicServiceIds = null;
    this.alternativeLocationsEnabled = false;
    this.recipientLabelInStopPointData = true;
    this.selectedService = this.defaultService;
    this.selectedShipmentType = this.shipmentTypeOptions[0];
    // this.selectedServiceType = 'delivery';
    // if (this.globals.isInRoute('/newShipmentsView')) {
    //   this.selectedServiceType = 'nextDay';
    // }

    if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
      this.selectedFoodDepot = this.globals.depotsWithNamesArray[0];
    }
  }

  previousStep() {
    if (this.visibleStep > 0) {
      this.visibleStep--;
    }
  }

  nextStep() {
    if (this.visibleStep < this.lastStep) {
      this.visibleStep++;
    }
  }

  displayImporterStepByStep() {
    this.visibleStep = 1;
    this.stepsSystemEnabled = true;
    this.name = this.myFirstTemplateMsg;
  }

  openImporterModal(data) {
    if (data) {
      if (data.length) {
        // this.displayImporterStepByStep();
        this.matcherGroups = data;
        this.selectedGroup = data[0];
        this.loadGroup(this.selectedGroup['previousSavedImportMatcherPreferences'], this.selectedGroup['collaborator']);
        this.name = this.selectedGroup['group_name'];
        this.id = this.selectedGroup['import_matcher_group_id'];

        if (this.chargesEnabled) {
          this.selectedService = this.basicServicesOptions.find(service => service.id == this.selectedGroup['service_id']);

          this.selectedShipmentType = this.shipmentTypeOptions.find(type => type.value == this.selectedGroup['shipment_type']);
          if (!this.selectedShipmentType) {this.selectedShipmentType = this.shipmentTypeOptions[0]; console.warn('Could not find shipmentType, selecting the first one');}

          if (this.selectedGroup['collaborator_type']) {
            this.selectedCollaboratorType = this.collaboratorTypeOptions.find(type => type.value == this.selectedGroup['collaborator_type']);
            if (!this.selectedCollaboratorType) {this.collaboratorTypeOptions[0]; console.warn('Could not find scollaboratorType, selecting the first one');}
          }
        } else {
          this.selectedService = this.basicServicesOptions.find(service => service.shipment_type == this.selectedGroup['shipment_type'] && service.pricelist_service_type == this.selectedGroup['pricelist_service_type ']);
        }
        if (!this.selectedService) { this.selectedService = this.defaultService; console.warn('Could not find basicService, selecting the default'); }
      } else {
        this.displayImporterStepByStep();
      }
    }

    this.matcherOptionsChanged();
    this.checkFormValidity();

    this.genericService.comm100ZIndexFix();
    const modal = document.querySelector('.importer-modal');
    const modalBackground = document.querySelector('.importer-modal-background');
    modal.classList.remove('closed');
    modal.classList.add('open');
    modalBackground.classList.remove('hidden');
    if (!this.projectProblemId) {
      modal.classList.add('one-bar-height');
    }
  }

  emptyGroup() {
    const importerFields = this.importerFields;
    this.systemConstants.forEach((constant, index) => {
      if (this.selectedConstant[constant.constant_name] && constant.constant_name !== 'M_NOTE') {
        if (this.selectedConstant[constant.constant_name].length) {
          this.selectedConstant[constant.constant_name].forEach(constantItem => {
            importerFields.push(constantItem);
          });
        } else {
          importerFields.push(this.selectedConstant[constant.constant_name]);
        }
      }
      this.selectedConstant[constant.constant_name] = null;
    });
    this.importerFields = importerFields;
    // this is done because otherwise the dropdown options do not update
    this.importerFields = this.importerFields.slice();
    this.valueChanged = false;
    this.collaboratorId = null;
    this.collaboratorName = null;
    this.collaboratorData = null;
    this.collaboratorBasicServiceIds = null;
    this.vouchersEnabled =  false;
    if (this.globals.collaboratorModeEnabled) this.vouchersEnabled = true;
    const inputElement = (<HTMLInputElement>document.getElementById('collaborator-importer-name-custom-input'));
    if (inputElement) {
      inputElement.value = this.collaboratorName;
    }
    this.setupServicesOptions();
    this.matcherOptionsChanged();
  }

  removeValueFromDropdown(object) {
    let indexToRemove = -1;
    if (object.value) {
      this.importerFields.forEach((field, index) => {
        if (field.value === object.value) {
          indexToRemove = index;
        }
      });
      if (indexToRemove > -1) {
        this.importerFields.splice(indexToRemove, 1);
        this.importerFields = this.importerFields.slice(0);
      }
    }
  }

  loadGroup(group, collaborator) {
    this.emptyGroup();
    Object.keys(group).forEach(shortKey => {
      if (this.globals.stopPointImportFieldConfigsConstantNamesToData[shortKey]) {
        const originalKey = this.globals.stopPointImportFieldConfigsConstantNamesToData[shortKey]['originalKey'];
        let label = '';
        if (this.systemConstantsObject[originalKey]) {
          if (!this.globals.stopPointImportFieldConfigsConstantNamesToData[shortKey]['allow_match_with_multiple_constants']) {
            if (this.csvFileColumnHeaderNames[group[shortKey][0]] || this.csvFileColumnHeaderNames[group[shortKey][0]] === '') {
              label = group[shortKey][0];
              if (this.csvFileColumnHeaderNames[group[shortKey][0]]) {
                label += ' (' + this.csvFileColumnHeaderNames[group[shortKey][0]] + ')';
              }
              this.selectedConstant[shortKey] = {
                label: label,
                value: group[shortKey][0]
              };
              if (shortKey !== 'M_NOTE') {
                this.removeValueFromDropdown(this.selectedConstant[shortKey]);
              }
            }
          } else {
            this.selectedConstant[shortKey] = [];
            group[shortKey].forEach(field => {
              if (this.csvFileColumnHeaderNames[field] || this.csvFileColumnHeaderNames[field] === '') {
                label = field;
                if (this.csvFileColumnHeaderNames[field]) {
                  label += ' (' + this.csvFileColumnHeaderNames[field] + ')';
                }
                const data = {
                  label: label,
                  value: field
                };
                this.selectedConstant[shortKey].push(data);
                if (shortKey !== 'M_NOTE') {
                  this.removeValueFromDropdown(data);
                }
              }
            });
            // }
          }
        }
      }
    });
    this.previousSelectedConstant = { ...this.selectedConstant };
    const inputElement = (<HTMLInputElement>document.getElementById('collaborator-importer-name-custom-input'));
    if (collaborator) {
      this.collaboratorData = collaborator;
      const collaboratorData = collaborator['collaboratorData'];
      if (collaboratorData) {
        if (collaboratorData['id']) {
          this.collaboratorId = collaboratorData['id'];
        }
        this.collaboratorName = collaboratorData['collaborator_name'];
        if (inputElement) {
          inputElement.value = collaboratorData['collaborator_name'];
        }
      }
      this.collaboratorBasicServiceIds = collaborator.company_collaborator_basic_service_ids ?? null;
      this.collaboratorSelectedAddress = null;
      if (collaborator['address']) {
        const addressLabel = this.addressService.getAddressLabel(collaborator['address']);
        if ((<HTMLInputElement>document.getElementById('collaborator-importer-address-custom-input'))) {
          (<HTMLInputElement>document.getElementById('collaborator-importer-address-custom-input')).value = addressLabel;
        }

        // this.updateBasicVoucherServiceOptions();
      }
      this.vouchersEnabled =  true;
    } else {
      this.collaboratorId = null;
      this.vouchersEnabled =  false;
      if (this.globals.collaboratorModeEnabled) this.vouchersEnabled = true;
      this.collaboratorName = '';
      if (inputElement) {
        inputElement.value = '';
      }
    }
    this.setupServicesOptions();

    this.checkFormValidity();
  }

  closeImporterModal() {
    const modal = document.querySelector('.importer-modal');
    const modalBackground = document.querySelector('.importer-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.emptyImporterModal();
  }

  checkFormValidity() {
    this.isFormValid = true;
    if (this.chargesEnabled && this.vouchersEnabled && !this.collaboratorId && !this.globals.collaboratorModeEnabled) {
      this.isFormValid = false;
    }
  }

  setupServicesOptions() {
    const services = [];
    let consignorsBasicServiceIds = [];
    const hasSelectedCollaborator = (this.selectedCollaborator && this.selectedCollaborator['companyCollaborator']) || this.collaboratorBasicServiceIds;
    let currentBasicServices = [...this.globals.basicServices];
    if (this.globals.collaboratorModeEnabled) {
      currentBasicServices = this.globals.partners[this.selectedPartnerItem['id']].services;
      this.chargesEnabled = this.selectedPartnerItem.is_pricelist_enabled ?? false;
      this.vouchersEnabled = true;
    } else {
      if (hasSelectedCollaborator) {
        consignorsBasicServiceIds = this.collaboratorBasicServiceIds;
        if (!consignorsBasicServiceIds) {
          consignorsBasicServiceIds = this.selectedCollaborator['companyCollaborator'].connection.basic_service_ids ?? [];
        }
        currentBasicServices = this.globals.basicServices.filter(item => consignorsBasicServiceIds.includes(item.id));

        // if the selected service is no longer available, select the fist available and notify the user
        if (!consignorsBasicServiceIds.includes(this.selectedService['id'])) {
          this._notificationSvc.showWarning('', this.serviceChangedLabel);
        }
      }
    }
    if (this.chargesEnabled && this.vouchersEnabled) {
      currentBasicServices.forEach(service => {
        services.push({
          name: service.name,
          id: service.id,
          pricelist_service_type: service.type,
          is_default: service.is_default,
          shipment_type: this.globals.priceListShipmentEnum.DoorDoor.key,
        });
      });
    } else {
      const defaultExpressService = currentBasicServices.find(service => (service.is_default && service.type == this.globals.priceListServicesEnum.Express.key));
      const defaultExpressServiceId = defaultExpressService ? defaultExpressService.id : null;
      const defaultNextDayService = currentBasicServices.find(service => (service.is_default && service.type == this.globals.priceListServicesEnum.NextDay.key));
      const defaultNextDayServiceId = defaultNextDayService ? defaultNextDayService.id : null;
      const defaultThroughHubService = currentBasicServices.find(service => (service.is_default && service.type == this.globals.priceListServicesEnum.ThroughHub.key));
      const defaultThroughHubServiceId = defaultThroughHubService ? defaultThroughHubService.id : null;

      services.push({
        name: this.onlyDeliveryMsg,
        id: defaultExpressServiceId,
        pricelist_service_type: this.globals.priceListServicesEnum.Express.key,
        is_default: false,
        shipment_type: this.globals.priceListShipmentEnum.ShopDoor.key,
      });
      services.push({
        name: this.onlyPickupMsg,
        id: defaultNextDayServiceId,
        pricelist_service_type: this.globals.priceListServicesEnum.NextDay.key,
        is_default: false,
        shipment_type: this.globals.priceListShipmentEnum.DoorShop.key,
      });
      services.push({
        name: this.nextDayMsg,
        id: defaultNextDayServiceId,
        pricelist_service_type: this.globals.priceListServicesEnum.NextDay.key,
        is_default: true,
        shipment_type: this.globals.priceListShipmentEnum.DoorDoor.key,
      });
      services.push({
        name: this.throughHubMsg,
        id: defaultThroughHubServiceId,
        pricelist_service_type: this.globals.priceListServicesEnum.ThroughHub.key,
        is_default: true,
        shipment_type: this.globals.priceListShipmentEnum.DoorDoor.key,
      });
      services.push({
        name: this.expressMsg,
        id: defaultExpressServiceId,
        pricelist_service_type: this.globals.priceListServicesEnum.Express.key,
        is_default: true,
        shipment_type: this.globals.priceListShipmentEnum.DoorDoor.key,
      });
    }
    this.basicServicesOptions = [...services];
    this.populateShipmentTypeOptions();

    if (this.chargesEnabled) {
      this.defaultService = this.basicServicesOptions.find(service => service.pricelist_service_type == this.globals.priceListServicesEnum.NextDay.key);
    } else {
      this.defaultService = this.basicServicesOptions.find(service => service.shipment_type == this.globals.priceListShipmentEnum.ShopDoor.key);
    }
    if (!this.defaultService) {this.defaultService = this.basicServicesOptions[0]; console.warn('Could not find defaultBasicService, selecting the first one');}
    if (!this.selectedService || (this.selectedService && hasSelectedCollaborator && !consignorsBasicServiceIds.includes(this.selectedService['id']))) {
      this.selectedService = this.defaultService;
    }
  }

  populateShipmentTypeOptions() {
    this.shipmentTypeOptions = [];
    Object.keys(this.globals.priceListShipmentEnum).forEach(key => {
      this.shipmentTypeOptions.push({
        label: this.globals.priceListShipmentEnum[key]['caption'],
        value: this.globals.priceListShipmentEnum[key]['key'],
        constant: this.globals.convertToUpperSnakeCase(key),
      });
    });

    const defaultNextDayService = this.basicServicesOptions.find(service => (service.is_default && service.pricelist_service_type == this.globals.priceListServicesEnum.NextDay.key));
    if (!defaultNextDayService) this.shipmentTypeOptions = this.shipmentTypeOptions.filter(obj => obj.value !== this.globals.priceListShipmentEnum.DoorShop.key);

    if (!this.selectedShipmentType || !this.shipmentTypeOptions.find(option => option.value === this.selectedShipmentType['value'])) {
      this.selectedShipmentType = this.shipmentTypeOptions[0];
    }
  }

  setupPartners () {
    // get partners
    this.selectedPartnerItem = {};
    if (this.globals.partnersData) {
      if (this.globals.partnersData['companies']) {
        this.globals.partnersData['companies'].forEach(partner => {
          this.partners.push({
            id: partner.id,
            name: partner.name,
            is_pricelist_enabled: partner.is_pricelist_enabled,
            collaborator_id: partner.collaborator_id
          })
        });
      } else {
        this.globals.partnersData.forEach(partner => {
          if (partner.companyCollaborator) {
            if (partner.companyCollaborator.partnerCompany) {
              this.partners.push({
                id: partner.companyCollaborator.partnerCompany.id,
                name: partner.companyCollaborator.collaborator.collaboratorData.collaborator_name,
                is_pricelist_enabled: partner.is_pricelist_enabled,
                collaborator_id: partner.collaborator_id
              });
            }
          }
        });
      }
      this.selectedPartnerItem = this.partners[0];
      if (this.globals.collaboratorModeEnabled) { this.chargesEnabled = this.selectedPartnerItem.is_pricelist_enabled ?? false }
      M.updateTextFields();
    }
  }

  getTranslations() {
    this.listen.push(this.translate.get('IMPORTER.ALERT').subscribe((res: string) => { this.alert = res; }));
    this.listen.push(this.translate.get('IMPORTER.MAX_POINTS_ALERT').subscribe((res: string) => { this.maxPointsLimitReachedAlert = res; }));
    this.listen.push(this.translate.get('IMPORTER.102').subscribe((res: string) => { this.errorsMsg['102'] = res; }));
    this.listen.push(this.translate.get('IMPORTER.103').subscribe((res: string) => { this.errorsMsg['103'] = res; }));
    this.listen.push(this.translate.get('IMPORTER.300').subscribe((res: string) => { this.errorsMsg['300'] = res; }));
    this.listen.push(this.translate.get('IMPORTER.512').subscribe((res: string) => { this.errorsMsg['512'] = res; }));
    this.listen.push(this.translate.get('IMPORTER.PRICE_LIST_NOT_FOUND').subscribe((res: string) => { this.priceListNotFoundLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SERVICE_CHANGED').subscribe((res: string) => { this.serviceChangedLabel = res; }));
    this.listen.push(this.translate.get('IMPORTER.MISSING_VALUES_ALERT').subscribe((res: string) => { this.errorsMsg['missingValue'] = res; }));
    this.listen.push(this.translate.get('IMPORTER.MY_FIRST_TEMPLATE').subscribe((res: string) => { this.myFirstTemplateMsg = res; }));
    this.listen.push(this.translate.get('STOP_POINT').subscribe((res: string) => {
       this.onlyDeliveryMsg = res['ONLY_DELIVERY']; 
       this.onlyPickupMsg = res['ONLY_PICKUP']; 
       this.nextDayMsg = res['NEXT_DAY']; 
       this.throughHubMsg = res['THROUGH_HUB']; 
       this.expressMsg = res['SAME_DAY_DELIVERY']; 
       this.setupServicesOptions();

       if (this.globals.vouchersEnabled) {
        this.collaboratorTypeOptions = [
          { label: res['SENDER'], value: this.globals.stopPointCollaboratorTypesConstants['SENDER'], constant: 'sender' },
          { label: res['RECEIVER'], value: this.globals.stopPointCollaboratorTypesConstants['RECEIVER'], constant: 'receiver' },
          { label: res['CONSIGNOR'], value: this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'], constant: 'consignor' },
        ];
        this.selectedCollaboratorType = this.collaboratorTypeOptions[0];
       }
    }));
  }

  ngOnInit() {
    this.chargesEnabled = this.globals.chargesEnabled;
    this.setupPartners();
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
    
    // set default depot (in pp, use project depot | everywhere else, use first depot as default)
    if (this.globals.isInRoute('projectView')) {
      this.depotsDataRefreshIntervalId = setInterval(() => {
        if (this.projectProblemDataService.dataReady() && this.projectProblemDataService.projectDepotId) {
          clearInterval(this.depotsDataRefreshIntervalId);
          const depot = this.depotUtils.getFirstDepotWithDepotId(this.projectProblemDataService.projectDepotId);
          this.selectedDepotItem = {
            companyDepot: {
              id: depot.companyDepot.id,
              address: {
                value: depot.companyDepot.address.value,
              },
            },
            name: this.depotUtils.getDepotName(depot.companyDepot)
          };
        }
      }, 500);
      this.intervals.push(this.depotsDataRefreshIntervalId);
    } else {
      this.depotsDataRefreshIntervalId = setInterval(() => {
        if (this.globals.depotsDataDone) {
          clearInterval(this.depotsDataRefreshIntervalId);
          const depot = this.depotUtils.getFirstDepotWithDepotId(this.globals.depotId);
          this.selectedDepotItem = {
            companyDepot: {
              id: depot.companyDepot.id,
              address: {
                value: depot.companyDepot.address.value,
              },
            },
            name: this.depotUtils.getDepotName(depot.companyDepot)
          };
        }
      }, 500);
      this.intervals.push(this.depotsDataRefreshIntervalId);
    }

    this.collaboratorNames = concat(
      of([]), // default items
      this.collaboratorNameInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.collaboratorNamesLoading = true),
        switchMap(term => this.dataService.getCollaborators(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.collaboratorNamesLoading = false)
        ))
      )
    );

    this.collaboratorAddresses = concat(
      of([]), // default items
      this.collaboratorAddressInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.collaboratorAddressesLoading = true),
        switchMap(term => this.dataService.getAddresses(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.collaboratorAddressesLoading = false)
        ))
      )
    );
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
    this.intervals.forEach(interval => {
      clearInterval(interval);
    });
  }

}
