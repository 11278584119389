import { DashboardProjectsLiveComponent } from './../dashboard-views/dashboard-projects-live/dashboard-projects-live.component';
import { DashboardShipmentsComponent } from '@app/dashboard/dashboard-views/dashboard-shipments/dashboard-shipments.component';
import { AfterViewInit, Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { StopsCustomDateModalComponent } from '@app/modals/stops-custom-date-modal/stops-custom-date-modal.component';
import { Globals } from '@app/services/globals';
import { DashboardProductivityComponent } from '../dashboard-views/dashboard-productivity/dashboard-productivity.component';
import { DashboardDriversComponent } from '../dashboard-views/dashboard-drivers/dashboard-drivers.component';
import { DashboardRatingComponent } from '../dashboard-views/dashboard-rating/dashboard-rating.component';
import { DateRangePickerModalComponent } from '@app/modals/date-range-picker-modal/date-range-picker-modal.component';
import { DashboardActivityComponent } from '../dashboard-views/dashboard-activity/dashboard-activity.component';
import { DashboardConsignorsComponent } from '../dashboard-views/dashboard-consignors/dashboard-consignors.component';
import { ProjectProblemReportModalComponent } from '@app/modals/project-problem-report-modal/project-problem-report-modal.component';
import { DriverReportModalComponent } from '@app/modals/driver-report-modal/driver-report-modal.component';

@Component({
    selector: 'app-dashboard-dummy',
    templateUrl: './dashboard-dummy.component.html',
    styleUrls: ['./dashboard-dummy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DashboardDummyComponent implements OnInit, AfterViewInit {

    @ViewChild(DateRangePickerModalComponent, { static: false }) dateRangePickerModalComponent: DateRangePickerModalComponent;
    @ViewChild(DashboardShipmentsComponent, { static: false }) dashboardShipmentsComponent: DashboardShipmentsComponent;
    @ViewChild(DashboardProductivityComponent, { static: false }) dashboardProductivityComponent: DashboardProductivityComponent;
    @ViewChild(DashboardDriversComponent, { static: false }) dashboardDriversComponent: DashboardDriversComponent;
    @ViewChild(DashboardActivityComponent, { static: false }) dashboardActivityComponent: DashboardActivityComponent;
    @ViewChild(ProjectProblemReportModalComponent, { static: false }) projectProblemReportModalComponent: ProjectProblemReportModalComponent;
    @ViewChild(DriverReportModalComponent, { static: false }) driverReportModalComponent: DriverReportModalComponent;

    @ViewChild(DashboardProjectsLiveComponent, { static: false }) dashboardProjectsLiveComponent: DashboardProjectsLiveComponent;
    @ViewChild(DashboardRatingComponent, { static: false }) dashboardRatingComponent: DashboardRatingComponent;
    @ViewChild(DashboardConsignorsComponent, { static: false }) dashboardConsignorsComponent: DashboardConsignorsComponent;

    @Input() activeShipmentsChartTitle: String;
    @Input() activeProductivityChartTitle: String;
    @Input() activeDriversChartTitle: String;
    @Input() logoBase64: String;
    @Input() projects: Array<any>;
    @Input() partners: Array<any>;

    @Input() shipmentsFilterSelected: String;
    @Input() productivityFilterSelected: String;
    @Input() driversFilterSelected: String;
    @Input() selectedProject: Object;
    @Input() selectedPartner: Object;

    @Input() hasFinishedLoadingShipmentsStats: Boolean;
    @Input() hasFinishedLoadingDriversStats: Boolean;
    @Input() hasFinishedLoadingProductivityStats: Boolean;
    @Input() hasFinishedLoadingCollaboratorsStats: Boolean;

    @Output() filterByTimeEvent = new EventEmitter<string>();
    @Output() changeProjectSelectEvent = new EventEmitter<string>();
    @Output() changePartnerSelectEvent = new EventEmitter<string>();
    @Output() checkForNextActivityPageEvent = new EventEmitter<string>();
    @Output() shipmentsFilterSelectEvent = new EventEmitter<string>();
    @Output() productivityFilterSelectEvent = new EventEmitter<string>();
    @Output() driversFilterSelectEvent = new EventEmitter<string>();
    @Output() newProjectProblemEvent = new EventEmitter<string>();

    constructor(
        public globals: Globals,
    ) { }

    filterByTime(event) {
        this.filterByTimeEvent.emit(event.target.getAttribute('time'));
    }

    changeProjectSelect(selectedProject) {
        this.changeProjectSelectEvent.emit(selectedProject);
    }

    changePartnerSelect(selectedPartner) {
        this.changePartnerSelectEvent.emit(selectedPartner);
    }

    checkForNextActivityPage() {
        this.checkForNextActivityPageEvent.emit();
    }

    shipmentsFilterSelect(filterType) {
        this.shipmentsFilterSelectEvent.emit(filterType);
    }

    productivityFilterSelect(filterType) {
        this.productivityFilterSelectEvent.emit(filterType);
    }

    driversFilterSelect(filterType) {
        this.driversFilterSelectEvent.emit(filterType);
    }

    newProjectProblem() {
        this.newProjectProblemEvent.emit();
    }

    ngOnInit() { }

    ngAfterViewInit() { }
}
