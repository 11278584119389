import { LM_BAR_CHART_HEART_OPTIONS, LM_BAR_CHART_MONEY_OPTIONS, LM_BAR_CHART_RATING_OPTIONS, LM_STACKED_CHART_BORDER_RADIUS } from "@app/model/charts-config";
import { ILmChartWidgetTab } from "@app/model/widget";

const pagesize = 7;

export const SCORE: ILmChartWidgetTab = {
    id: 'productivity_rating',
    title: 'Productivity',
    subtitle: 'SCORE',
    pageSize: pagesize,
    btn:{id: 'productivity_rating', icon: 'lm-icon icon-star', textOnly: true, color: "#ccc", activeColor: '#ffc107', active: true},
    data: {
        labels: [],
        datasets: [
            {
                backgroundColor: ['#fac505', '#fac505', '#c7980a'],
                data: [],
            }
        ]
    }, 
    filters: [],
    chartOptions: LM_BAR_CHART_RATING_OPTIONS,
    chartType: 'bar'
};

export const VISITED_STOPS_PER_HOUR: ILmChartWidgetTab = {
    id: 'visited_per_hour',
    title: 'Productivity',
    subtitle: 'SHIPMENTS_PER_HOUR',
    pageSize: pagesize,
    btn:{id: 'visited_per_hour', icon: 'lm-icon icon-map', textOnly: true, color: "#ccc", activeColor: '#ba6ef5', active: true},
    data: {
        labels: [],
        datasets: [
            {
                backgroundColor: ['#940bff', '#ba6ef5'],
                data: [],
            }
        ]
    }, 
    chartType: 'bar'
};

export const PACKAGES: ILmChartWidgetTab = {
    id: 'packages',
    title: 'Productivity',
    subtitle: 'PACKAGES',
    pageSize: pagesize,
    btn:{id: 'packages', icon: 'lm-icon icon-box', textOnly: true, color: "#ccc", activeColor: '#de921f', active: true},
    data: {
        labels: [],
        datasets: [
            {
                backgroundColor: ['#de921f', '#ffb84e'],
                data: [],
            }
        ]
    }, 
    chartType: 'bar'
};

export const AVERAGE_DELAY: ILmChartWidgetTab = {
    id: 'average_delay',
    title: 'Productivity',
    subtitle: 'AVERAGE_DELAY',
    pageSize: pagesize,
    btn:{id: 'average_delay', icon: 'lm-icon icon-timer', textOnly: true, color: "#ccc", activeColor: '#ba6ef5', active: true},
    data: {
        labels: [],
        datasets: [
            {
                backgroundColor: ['#940bff', '#ba6ef5'],
                data: [],
            }
        ]
    }, 
    chartType: 'bar'
};

export const COST_PER_SHIPMENT: ILmChartWidgetTab = {
    id: 'cost_per_shipment',
    title: 'Productivity',
    subtitle: 'COST_PER_SHIPMENT',
    pageSize: pagesize,
    btn:{id: 'cost_per_shipment', icon: 'lm-icon icon-dollar-coin', textOnly: true, color: "#ccc", activeColor: '#1c90d4', active: true},
    data: {
        labels: [],
        datasets: [
            {
                backgroundColor: ['#1c90d4', '#51bfff'],
                data: [],
            }
        ]
    },
    chartOptions: LM_BAR_CHART_MONEY_OPTIONS,
    chartType: 'bar'
};

export const RECIPIENT_EXPERIENCE: ILmChartWidgetTab = {
    id: 'recipient_experience',
    title: 'Productivity',
    subtitle: 'CUSTOMER_EXPERIENCE',
    pageSize: pagesize,
    btn:{id: 'recipient_experience', icon: 'lm-icon icon-heart', textOnly: true, color: "#ccc", activeColor: '#c74c96', active: true},
    data: {
        labels: [],
        datasets: [
            {
                backgroundColor: ['#b64388', '#faa6d9'],
                data: [],
            }
        ]
    },
    chartOptions: LM_BAR_CHART_HEART_OPTIONS,
    chartType: 'bar'
};
