import { Injectable } from '@angular/core';
import { AddressService } from '@app/services/address.service';
import { Globals } from '@app/services/globals';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class ActivityUtils {
    listen = [];
    yesterdayLabel;
    twoDaysAgoLabel;
    nowLabel;
    driverFinishRouteLabel;
    invoicingLabel;
    invoicingCollaboratorLabel;
    paymentLabel;
    paymentCollaboratorLabel;
    inviteSentLabel;
    inviteAcceptedLabel;
    shipmentsAcceptedLabel;
    shipmentsThinkLabel;
    shipmentsCollaboratorThinkLabel;
    shipmentsCompletedLabel;
    shipmentsCollaboratorCompletedLabel;
    requestedNewPickupsLabel;
    projectCompletedLabel;
    projectThinkAutoLabel;
    projectThinkLabel;
    projectAddShipmentsLabel;


    constructor(
        private globals: Globals,
        private addressService: AddressService,
        public translate: TranslateService,
    ) {
        this.getTranslations();
    }

    getAvatarUrl (activity) {
        let url = '';
        if (activity.type == 'route') {
            if (!this.globals.collaboratorModeEnabled) {
                url = `api/internal/v1/images/drivers/${activity.imageHash}`;
            } else {
                url = `api/internal/v1/images/partner-drivers/${activity.imageHash}`;
            }
        } else if (activity.type == 'invoice' || activity.type == 'payment' || activity.type == 'pickups') {
            if (!this.globals.collaboratorModeEnabled) {
                url = `api/internal/v1/images/company-logo/${activity.imageHash}`;
            } else {
                url = `api/internal/v1/images/partner-company-logo/${activity.imageHash}`;
            }
        } else if (activity.type == 'collaborator' || activity.type == 'project' || activity.type == 'shipments') {
            if (!this.globals.collaboratorModeEnabled) {
                url = `api/internal/v1/images/user-profile/${activity.imageHash}`;
            } else {
                url = `api/internal/v1/images/partner-user-profile/${activity.imageHash}`;
            }
        }
        return url;
    }

    getActivitiesFormatted (items) {
        let activities = [];
        items.activityLogs.forEach(log => {
            // time label
            let timeLabel;
            let timezone = moment.tz.guess();
            let time = moment.utc(log['creation_datetime']).tz(timezone).format('HH:mm');
            // let date = moment.utc(log['creation_datetime']).tz(timezone).format('DD-MM'); // can be used for older than 2 days entries
            let hoursDiff = moment().diff(moment.utc(log['creation_datetime']).tz(timezone), 'hours');
            let minsDiff = moment().diff(moment.utc(log['creation_datetime']).tz(timezone), 'minutes');

            if (hoursDiff > 24 && hoursDiff < 48) {
                timeLabel = this.yesterdayLabel.toLowerCase();
            } else if (hoursDiff >= 48 && hoursDiff < 72) {
                timeLabel = this.twoDaysAgoLabel.toLowerCase();
            } else if (hoursDiff >= 72) {
                timeLabel = moment.utc(log['creation_datetime']).tz(timezone).format('DD/MM');
            }
            else if (minsDiff < 5) {
                timeLabel = this.nowLabel.toLowerCase();
            } else {
                timeLabel = time;
            }

            // driver event log
            if (log['driver_id']) {
                let userName, initials, imageHash;
                items['userProfiles'].forEach(user => {
                    if (log['driver_id'] == user['driver_id']) {
                        userName = user['name'];
                        imageHash = user['hash'];
                    }
                });

                if (log['is_from_mily']) {
                    userName = 'Mily';
                    initials = 'M';
                } else {
                    // will check for avatar first & if not found then find initials of name
                    if (userName) {
                        const names = userName.split(' ');
                        if (names.length > 1) {
                            initials = (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
                        } else {
                            initials = names[0].charAt(0).toUpperCase();
                        }
                    } else {
                        initials = '-';
                    }
                }

                // pp departure datetime
                let projectProblemDepartureDatetime;
                items['projectProblems'].forEach(projectProblem => {
                    if (projectProblem['id'] == log['driver_project_problem_id']) {
                        projectProblemDepartureDatetime = projectProblem['departure_datetime'];
                    }
                });

                activities.push({
                    id: log['id'],
                    time: timeLabel,
                    hoverTimeLabel: timeLabel == this.yesterdayLabel || timeLabel == this.twoDaysAgoLabel ? timeLabel + ' ' + time : time,
                    title: userName,
                    avatar: null,
                    imageHash: imageHash,
                    text: this.driverFinishRouteLabel,
                    type: 'route',
                    isFromMily: log['is_from_mily'],
                    initials: initials,
                    driverId: log['driver_id'],
                    projectProblemDepartureDatetime: projectProblemDepartureDatetime,
                    projectProblemId: log['driver_project_problem_id']
                });
            }
            // invoicing event log
            else if (log['invoice_id']) {
                let userName, collaboratorName, initials, imageHash;
                if (this.globals.collaboratorModeEnabled) {
                    items['companies'].forEach(partner => {
                        if (log['partner_company_id'] == partner['id']) {
                            userName = partner['name'];
                            imageHash = partner['hash'];
                        }
                    });
                } else {
                    items['userProfiles'].forEach(user => {
                        if (log['invoice_user_profile_id'] == user['id']) {
                            userName = user['name'];
                            imageHash = user['hash'];
                        }
                    });
                }

                items['collaborators'].forEach(collaborator => {
                    if (log['collaborator_id'] == collaborator['id']) {
                        collaboratorName = collaborator['collaborator_name'];
                    }
                });

                // collaborator -> get due on datetime & creation datetime
                let dueOnDatetimeValue, creationDatetimeValue;
                if (this.globals.collaboratorModeEnabled) {
                    items['invoices'].forEach(invoice => {
                        if (invoice['id'] == log['invoice_id']) {
                            creationDatetimeValue = moment(invoice['creation_datetime']).format('DD/MM');
                            dueOnDatetimeValue = moment(invoice['due_on_datetime']).format('DD/MM');
                        }
                    });
                }

                let invoicingLabelValue;
                if (!this.globals.collaboratorModeEnabled) {
                    invoicingLabelValue = this.invoicingLabel.replace('COLLABORATOR_COMPANY_NAME', collaboratorName);
                } else {
                    invoicingLabelValue = this.invoicingCollaboratorLabel.replace('START_DATE', creationDatetimeValue).replace('END_DATE', dueOnDatetimeValue);
                }

                let isFromMily;
                if (log['is_from_mily'] && !this.globals.collaboratorModeEnabled) {
                    isFromMily = true;
                    userName = 'Mily';
                    initials = 'M';
                } else {
                    // will check for avatar first & if not found then find initials of name
                    if (userName) {
                        const names = userName.split(' ');
                        if (names.length > 1) {
                            initials = (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
                        } else {
                            initials = names[0].charAt(0).toUpperCase();
                        }
                    } else {
                        initials = '-';
                    }
                }

                // collaborator value
                let collaboratorValue;
                if (this.globals.collaboratorModeEnabled) {
                    collaboratorValue = log['partner_company_id'];
                } else {
                    collaboratorValue = log['collaborator_id'];
                }

                activities.push({
                    id: log['id'],
                    time: timeLabel,
                    hoverTimeLabel: timeLabel == this.yesterdayLabel || timeLabel == this.twoDaysAgoLabel ? timeLabel + ' ' + time : time,
                    title: userName,
                    avatar: null,
                    imageHash: imageHash,
                    text: invoicingLabelValue,
                    type: 'invoice',
                    isFromMily: isFromMily,
                    initials: initials,
                    collaboratorId: collaboratorValue,
                    invoiceId: log['invoice_id']
                });
            }
            // pay on delivery event log
            else if (log['pay_on_delivery_id']) {
                let userName, collaboratorName, initials, imageHash;
                if (this.globals.collaboratorModeEnabled) {
                    items['companies'].forEach(partner => {
                        if (log['partner_company_id'] == partner['id']) {
                            userName = partner['name'];
                            imageHash = partner['hash'];
                        }
                    });
                } else {
                    items['userProfiles'].forEach(user => {
                        if (log['pay_on_delivery_user_profile_id'] == user['id']) {
                            userName = user['name'];
                            imageHash = user['hash'];
                        }
                    });
                }

                items['collaborators'].forEach(collaborator => {
                    if (log['collaborator_id'] == collaborator['id']) {
                        collaboratorName = collaborator['collaborator_name'];
                    }
                });

                // collaborator -> get due on datetime & creation datetime
                let dueOnDatetimeValue;
                if (this.globals.collaboratorModeEnabled) {
                    items['payOnDeliveries'].forEach(pod => {
                        if (pod['id'] == log['pay_on_delivery_id']) {
                            dueOnDatetimeValue = moment(pod['due_on_datetime']).format('DD/MM');
                        }
                    });
                }

                let podLabelValue;
                if (!this.globals.collaboratorModeEnabled) {
                    podLabelValue = this.paymentLabel.replace('COLLABORATOR_COMPANY_NAME', collaboratorName);
                } else {
                    podLabelValue = this.paymentCollaboratorLabel.replace('DUE_ON_DATE', dueOnDatetimeValue);
                }

                let isFromMily;
                if (log['is_from_mily'] && !this.globals.collaboratorModeEnabled) {
                    isFromMily = true;
                    userName = 'Mily';
                    initials = 'M';
                } else {
                    // will check for avatar first & if not found then find initials of name
                    if (userName) {
                        const names = userName.split(' ');
                        if (names.length > 1) {
                            initials = (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
                        } else {
                            initials = names[0].charAt(0).toUpperCase();
                        }
                    } else {
                        initials = '-';
                    }
                }

                // collaborator value
                let collaboratorValue;
                if (this.globals.collaboratorModeEnabled) {
                    collaboratorValue = log['partner_company_id'];
                } else {
                    collaboratorValue = log['collaborator_id'];
                }

                activities.push({
                    id: log['id'],
                    time: timeLabel,
                    hoverTimeLabel: timeLabel == this.yesterdayLabel || timeLabel == this.twoDaysAgoLabel ? timeLabel + ' ' + time : time,
                    title: userName,
                    avatar: null,
                    imageHash: imageHash,
                    text: podLabelValue,
                    type: 'payment',
                    isFromMily: isFromMily,
                    initials: initials,
                    collaboratorId: collaboratorValue,
                    paymentId: log['pay_on_delivery_id']
                });
            }
            // collaborator invite & handling for collaborator portal
            else if (log['collaborator_invite_collaborator_id']) {
                let userName, avatar, collaboratorName, initials, imageHash;

                items['userProfiles'].forEach(user => {
                    if (log['collaborator_invite_user_profile_id'] == user['id']) {
                        userName = user['name'];
                        // avatar = user['avatar'];
                        imageHash = user['hash'];
                    }
                });

                items['collaborators'].forEach(collaborator => {
                    if (log['collaborator_invite_collaborator_id'] == collaborator['id']) {
                        collaboratorName = collaborator['collaborator_name'];
                    }
                });


                if (log['is_from_mily']) {
                    userName = 'Mily';
                    initials = 'M';
                } else {
                    // will check for avatar first & if not found then find initials of name
                    if (userName) {
                        const names = userName.split(' ');
                        if (names.length > 1) {
                            initials = (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
                        } else {
                            initials = names[0].charAt(0).toUpperCase();
                        }
                    } else {
                        initials = '-';
                    }
                }

                // invite types
                let inviteLabel;
                if (log['collaborator_invite_type'] == this.globals.dashboardActivityCollaboratorInviteConstants['ACCEPTED']) {
                    inviteLabel = this.inviteAcceptedLabel.replace('COLLABORATOR_COMPANY_NAME', collaboratorName);
                } else {
                    inviteLabel = this.inviteSentLabel.replace('COLLABORATOR_COMPANY_NAME', collaboratorName);
                }

                activities.push({
                    id: log['id'],
                    time: timeLabel,
                    hoverTimeLabel: timeLabel == this.yesterdayLabel || timeLabel == this.twoDaysAgoLabel ? timeLabel + ' ' + time : time,
                    title: userName,
                    avatar: avatar,
                    imageHash: imageHash,
                    text: inviteLabel,
                    type: 'collaborator',
                    collaboratorId: log['collaborator_invite_collaborator_id'],
                    isFromMily: log['is_from_mily'],
                    initials: initials
                });
            }
            // collaborator shipments (courier sees these for their collaborators)
            else if (log['collaborator_shipments_collaborator_id'] || log['collaborator_shipments_type'] || log['collaborator_shipments_type'] === 0) {
                let userName, initials, shipmentsLabel, collaboratorName, imageHash;
                items['userProfiles'].forEach(user => {
                    if (log['collaborator_shipments_user_profile_id'] == user['id']) {
                        userName = user['name'];
                        imageHash = user['hash'];
                    }
                });

                // if in collaborator portal -> use own company as collaborator
                if (this.globals.collaboratorModeEnabled) {
                    items['companies'].forEach(company => {
                        if (log['collaborator_shipments_partner_company_id'] == company['id']) {
                            collaboratorName = company['name'];
                        }
                    });
                } else {
                    items['collaborators'].forEach(collaborator => {
                        if (log['collaborator_shipments_collaborator_id'] == collaborator['id']) {
                            collaboratorName = collaborator['collaborator_name'];
                        }
                    });
                }

                if (log['is_from_mily']) {
                    userName = 'Mily';
                    initials = 'M';
                } else {
                    // will check for avatar first & if not found then find initials of name
                    if (userName) {
                        const names = userName.split(' ');
                        if (names.length > 1) {
                            initials = (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
                        } else {
                            initials = names[0].charAt(0).toUpperCase();
                        }
                    } else {
                        initials = '-';
                    }
                }

                // status types
                // collaborator
                if (this.globals.collaboratorModeEnabled) {
                    if (log['collaborator_shipments_type'] == this.globals.dashboardActivityCollaboratorShipmentsConstants['ACCEPTED']) {
                        shipmentsLabel = this.shipmentsAcceptedLabel.replace('COLLABORATOR_COMPANY_NAME', collaboratorName);
                    } else if (log['collaborator_shipments_type'] == this.globals.dashboardActivityCollaboratorShipmentsConstants['THINK']) {
                        shipmentsLabel = this.shipmentsCollaboratorThinkLabel.replace('COLLABORATOR_COMPANY_NAME', collaboratorName);
                    } else {
                        shipmentsLabel = this.shipmentsCollaboratorCompletedLabel;
                    }
                }
                // courier
                else {
                    if (log['collaborator_shipments_type'] == this.globals.dashboardActivityCollaboratorShipmentsConstants['ACCEPTED']) {
                        shipmentsLabel = this.shipmentsAcceptedLabel;
                    } else if (log['collaborator_shipments_type'] == this.globals.dashboardActivityCollaboratorShipmentsConstants['THINK']) {
                        shipmentsLabel = this.shipmentsThinkLabel;
                    } else {
                        shipmentsLabel = this.shipmentsCompletedLabel;
                    }
                }

                activities.push({
                    id: log['id'],
                    time: timeLabel,
                    hoverTimeLabel: timeLabel == this.yesterdayLabel || timeLabel == this.twoDaysAgoLabel ? timeLabel + ' ' + time : time,
                    title: userName,
                    avatar: null,
                    imageHash: imageHash,
                    text: shipmentsLabel.replace('COLLABORATOR_COMPANY_NAME', collaboratorName),
                    type: 'shipments',
                    isFromMily: log['is_from_mily'],
                    initials: initials,
                    status: log['collaborator_shipments_type']
                });
            }
            // project problem
            else if (log['project_problem_id']) {
                let userName, initials, projectLabel, projectName, projectProblemDatetime, imageHash;
                items['userProfiles'].forEach(user => {
                    if (log['project_problem_user_profile_id'] == user['id']) {
                        userName = user['name'];
                        imageHash = user['hash'];
                    }
                });

                if (log['is_from_mily']) {
                    userName = 'Mily';
                    initials = 'M';
                } else {
                    // will check for avatar first & if not found then find initials of name
                    if (userName) {
                        const names = userName.split(' ');
                        if (names.length > 1) {
                            initials = (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
                        } else {
                            initials = names[0].charAt(0).toUpperCase();
                        }
                    } else {
                        initials = '-';
                    }
                }

                // project name & project problem time
                items['projectProblems'].forEach(projectProblem => {
                    if (log['project_problem_id'] == projectProblem['id']) {
                        projectName = projectProblem['project_title'];
                        projectProblemDatetime = projectProblem['departure_datetime'];
                    }
                });

                // status types
                if (log['project_problem_type'] == this.globals.dashboardActivityProjectProblemConstants['ADD_SHIPMENTS']) {
                    projectLabel = this.projectAddShipmentsLabel.replace('PROJECT_NAME', projectName);
                } else if (log['project_problem_type'] == this.globals.dashboardActivityProjectProblemConstants['THINK']) {
                    projectLabel = this.projectThinkLabel.replace('PROJECT_NAME', projectName);
                } else if (log['project_problem_type'] == this.globals.dashboardActivityProjectProblemConstants['THINK_AUTO']) {
                    projectLabel = this.projectThinkAutoLabel.replace('PROJECT_NAME', projectName);
                } else {
                    projectLabel = this.projectCompletedLabel.replace('PROJECT_NAME', projectName);
                }

                activities.push({
                    id: log['id'],
                    time: timeLabel,
                    hoverTimeLabel: timeLabel == this.yesterdayLabel || timeLabel == this.twoDaysAgoLabel ? timeLabel + ' ' + time : time,
                    title: userName,
                    avatar: null,
                    imageHash: imageHash,
                    text: projectLabel,
                    type: 'project',
                    isFromMily: log['is_from_mily'],
                    initials: initials,
                    projectProblemId: log['project_problem_id'],
                    projectProblemStatus: log['project_problem_type'],
                    projectName: projectName,
                    projectProblemDatetime: projectProblemDatetime
                });
            }
            // pickup request
            else if (log['collaborator_pickup_request_collaborator_id']) {
                let userName, initials, imageHash;
                items['collaborators'].forEach(collaborator => {
                    if (log['collaborator_pickup_request_collaborator_id'] == collaborator['id']) {
                        userName = collaborator['collaborator_name'];
                        imageHash = collaborator['hash'];
                    }
                });

                if (log['is_from_mily']) {
                    userName = 'Mily';
                    initials = 'M';
                } else {
                    // will check for avatar first & if not found then find initials of name
                    if (userName) {
                        const names = userName.split(' ');
                        if (names.length > 1) {
                            initials = (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
                        } else {
                            initials = names[0].charAt(0).toUpperCase();
                        }
                    } else {
                        initials = '-';
                    }
                }

                activities.push({
                    id: log['id'],
                    time: timeLabel,
                    hoverTimeLabel: timeLabel == this.yesterdayLabel || timeLabel == this.twoDaysAgoLabel ? timeLabel + ' ' + time : time,
                    title: userName,
                    avatar: null,
                    imageHash: imageHash,
                    text: this.requestedNewPickupsLabel,
                    type: 'pickups',
                    isFromMily: log['is_from_mily'],
                    initials: initials,
                    projectProblemId: log['project_problem_id'],
                    projectProblemStatus: log['project_problem_type']
                });
            }
        });
        return activities;
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.YESTERDAY').subscribe((res: string) => { this.yesterdayLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.TWO_DAYS_AGO').subscribe((res: string) => { this.twoDaysAgoLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.NOW').subscribe((res: string) => { this.nowLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_FINISH_ROUTE').subscribe((res: string) => { this.driverFinishRouteLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_INVOICING').subscribe((res: string) => { this.invoicingLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_INVOICING_COLLABORATOR').subscribe((res: string) => { this.invoicingCollaboratorLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_PAYMENT').subscribe((res: string) => { this.paymentLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_PAYMENT_COLLABORATOR').subscribe((res: string) => { this.paymentCollaboratorLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_INVITE_SENT').subscribe((res: string) => { this.inviteSentLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_INVITE_ACCEPTED').subscribe((res: string) => { this.inviteAcceptedLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_SHIPMENTS_COMPLETED').subscribe((res: string) => { this.shipmentsCompletedLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_SHIPMENTS_COLLABORATOR_COMPLETED').subscribe((res: string) => { this.shipmentsCollaboratorCompletedLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_SHIPMENTS_THINK').subscribe((res: string) => { this.shipmentsThinkLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_SHIPMENTS_COLLABORATOR_THINK').subscribe((res: string) => { this.shipmentsCollaboratorThinkLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_SHIPMENTS_ACCEPTED').subscribe((res: string) => { this.shipmentsAcceptedLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_REQUESTED_NEW_PICKUPS').subscribe((res: string) => { this.requestedNewPickupsLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_PROJECT_ADD_SHIPMENTS').subscribe((res: string) => { this.projectAddShipmentsLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_PROJECT_THINK').subscribe((res: string) => { this.projectThinkLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_PROJECT_THINK_AUTO').subscribe((res: string) => { this.projectThinkAutoLabel = res; }));
        this.listen.push(this.translate.get('DASHBOARD.ACTIVITY_PROJECT_COMPLETE').subscribe((res: string) => { this.projectCompletedLabel = res; }));
    }
}
