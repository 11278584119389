import { CommonModule } from '@angular/common';
import { Directive, ElementRef, Input, NgModule, HostListener} from '@angular/core';


  @Directive({
    selector: '[scrolly]',
    host: {
        '(window:wheel)': 'scroll($event)'
      }
  })
  export class LmHorizontalScrollerDirective {
    @Input('scrolly') selector: string;
    scroller: Element | HTMLElement;
    allowed = false;

    @HostListener('mouseenter') onMouseEnter() {this.allowed = true}
    @HostListener('mouseleave') onMouseLeave() {this.allowed = false}


    constructor(private _el: ElementRef) { }

    scroll(e){
      if(this.scroller && this.allowed) this.scroller.scrollLeft += (e.deltaY > 0 ? 10 : -10);
    }

    ngAfterViewInit(): void {
      setTimeout(() => {
        this.scroller = this._el.nativeElement.querySelector(this.selector);
      })
    }

    
    ngOnInit() {}

    ngOnDestroy() {}

  }
  @NgModule({
    imports: [CommonModule],
    exports: [LmHorizontalScrollerDirective],
    declarations: [LmHorizontalScrollerDirective]
  })
  export class LmHorizontalScrollerDirectiveModule {}