import { Component, OnInit, OnDestroy, Input, Injector, AfterViewInit, HostBinding, NgModule } from '@angular/core';
import { ChartData, DoughnutController } from 'chart.js';
import { Observable, finalize, mergeWith, of, take, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { INITIAL_DATA } from '@app/model/initial-data';
import { LmBaseWidgetComponent, LmChartWidgetComponent } from './widget-base';
import { CommonModule } from '@angular/common';
import { ChartModule } from 'primeng/chart';
import { LmButtonModule } from '../button/button.component';
import { LmDoughnutChartModule } from '@app/shared/charts/doughnut-chart.component';
import { LmWidgetHeaderModule } from '../header/lm-widget-header.component';

@UntilDestroy()
@Component({
    selector: 'lm-doughnutchart-widget',
    styles:[`
        lm-doughnut-chart{width: 100%}
    `],
    template: `
        <div class="lm-widget --chart lm-pdb0 lm-pdt0">
            <lm-widget-header [title]="title" [subtitle]="subtitle" [tabs]="tabs" [filters]="filters" [filterFn]="applyFilter"></lm-widget-header>
            <div class="lm-widget-content lm-jc-center-flex lm-mrgt5" [ngClass]="{'lm-b-w': dataless}">
                <div class="lm-overlay-white lm-jc-center-flex lm-ai-center-flex lm-hidden" [ngStyle]="{'height': dataless ? '100%' : '0'}">
                    <span class="lm-chart-widget-no-data lm-translator"></span>
                </div>
                <lm-doughnut-chart [data]="data" [options]="chartOptions"></lm-doughnut-chart>
            </div>
        </div>
    `
})
export class LmDoughnutChartWidgetComponent extends LmChartWidgetComponent implements OnInit, OnDestroy, AfterViewInit {
    static nextId = 0;
    @HostBinding() id = `lm-doughnutchart-widget-${LmDoughnutChartWidgetComponent.nextId++}`;
    
    constructor(injector: Injector) {
        super(injector);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
    ngOnInit() {
        super.ngOnInit();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
}


@NgModule({
    imports: [CommonModule, ChartModule, LmButtonModule, LmDoughnutChartModule, LmWidgetHeaderModule],
    exports: [LmDoughnutChartWidgetComponent],
    declarations: [LmDoughnutChartWidgetComponent]
  })
  export class LmDoughnutChartWidgetModule {}