import { Component, OnInit, OnDestroy, Injector, AfterViewInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ILmChartWidgetTab, LmWidgetViewType } from '@app/model/widget';
import { LmHybridWidgetComponent } from '@app/shared/structure/widgets/widget-base';
import { LmDoughnutChartComponent } from '@app/shared/charts/doughnut-chart.component';
import { LmSemiCircleChartComponent } from '@app/shared/charts/semicircle-chart.component';

@UntilDestroy()
@Component({
    selector: 'lm-deliveries-widget',
    styles:[`
        lm-bar-chart, lm-doughnut-chart, lm-pie-chart, lm-semicircle-chart{width: 100%}
    `],
    template: `
        <div class="lm-widget --chart lm-pdb0 lm-pdt0">
            <lm-widget-header [title]="title" [subtitle]="subtitle" [tabs]="tabs" [filters]="filters" [filterFn]="applyFilter"></lm-widget-header>

            <div *ngIf="viewType==='chart'" class="lm-widget-content lm-jc-center-flex lm-mrgt5" [ngClass]="{'lm-b-w': dataless}">
                <!-- <div class="lm-overlay-white lm-jc-center-flex lm-ai-center-flex lm-hidden" [ngStyle]="{'height': dataless ? '100%' : '0'}">
                    <span class="lm-chart-widget-no-data lm-translator"></span>
                </div> -->
                <lm-doughnut-chart #chart [data]="data" [options]="chartOptions"></lm-doughnut-chart>
            </div>

            <div *ngIf="viewType==='semicircle'" class="lm-widget-content lm-jc-center-flex lm-mrgt5">
                <lm-semicircle-chart [responsify]="{child:'.lm-semicircle .-content'}" #semicircle [value]="value" [options]="chartOptions"></lm-semicircle-chart>
            </div>
            
            <div class="lm-widget-footer lm-ai-center-flex"></div>
        </div>
    `
})
export class LmDeliveriesWidgetComponent extends LmHybridWidgetComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(LmDoughnutChartComponent) chart:LmDoughnutChartComponent;
    @ViewChild(LmSemiCircleChartComponent) semicircle:LmSemiCircleChartComponent;
    viewType: LmWidgetViewType;
    
    constructor(injector: Injector) {
        super(injector);
        this.viewType = 'chart';
    }


    switchTab(tab:ILmChartWidgetTab){
        const {viewType} = tab;
        this.viewType = viewType;
        if(viewType === 'chart') this.setChart(tab);
        if(viewType === 'semicircle') this.setValue(tab);
        
        if (this[viewType]) this[viewType].refresh(tab);
    }

    ngOnInit() {
        super.ngOnInit();
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
