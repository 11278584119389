import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '@app/services/modal.service';
import { Globals } from '@app/services/globals';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { Module } from '@ag-grid-community/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
// import { Chart } from 'chart.js';
import * as moment from 'moment';
import { ChartService } from '@app/services/chart.service';
import { GridsService } from '@app/services/grids.service';
import { CollaboratorService } from '@app/services/collaborator.service';
import { GenericUtils } from '@app/utils/generic-utils';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    ChartOptions
  } from 'chart.js';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );

@Component({
    selector: 'app-customer-payments-grid',
    templateUrl: './customer-payments-grid.component.html',
    styleUrls: ['./../grids.scss']
})
export class CustomerPaymentsGridComponent implements OnInit, OnDestroy {

    @ViewChild('statsContainer', { static: false }) statsContainer;
    @ViewChild('gridContainer', { static: false }) gridContainer;
    @ViewChild('filtersContainer', { static: false }) filtersContainer;

    listen = [];

    public modules: Module[] = [
        ServerSideRowModelModule,
        // MenuModule,
        // ColumnsToolPanelModule,
    ];
    columnDefs;
    rowModelType = 'serverSide';
    defaultPaginationSize = 50;
    cacheBlockSize = 50;
    gridApi;
    gridColumnApi;
    rowData = [];

    months = [];
    invoiceNumberTotal = [];
    invoiceNumberCompleted = [];
    invoiceNumberCancelled = [];

    pagesCount = 0;
    selectedDatesQuery = '';
    customerPaymentsDataArray = [];
    paymentsCount = 0;
    searchTextChanged = new Subject<string>();
    searchString: String = '';
    clicked;
    transactionNumberTitle;
    collaboratorTitle;
    statusTitle;
    untilTitle;
    createdDateTitle;
    numberTitle;
    amountTitle;
    currency;
    actionsTitle;
    issuedByMily;
    issuedByYou;
    sentLabel;
    paidLabel; // used in status & action button!
    payLabel;
    viewedLabel;
    overdueLabel;
    createdLabel;
    detailsLabel;
    paymentMethodsLabel;
    bankIbanTitle;
    ibanLabel = 'IBAN';
    bankLabel = '';
    showSortDropdown = false;
    selectedOrder = '';
    creditCardLabel = '';
    cashLabel = '';
    bankDepositLabel = '';
    chequeLabel = '';
    canceledLabel = '';
    completedLabel = '';

    collaboratorId;

    selectedPayment = null;

    chart;
    chartData = {};
    customerNames = [];
    customerShortNames = [];
    mostVisited = [];

    dataLoading = false;
    pageSize = 10;
    currentChartPage = 0;
    firstPage = true;
    lastPage = false;
    charts = [];
    selectedChart;
    periods = [];
    selectedPeriod;

    chartExpanded = true;
    chartDisabled = false;

    noNameLabel = '';

    hoverBoxPayOnDelivery = 0;
    hoverBoxCreditCard = 0;
    hoverBoxBankDeposit = 0;
    hoverBoxCheque = 0;

    // xls
    fullDataShownForExport = false;
    bulkRequestsSize = 2000;
    maxRequestsToMake = 5;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        private modalService: ModalService,
        public globals: Globals,
        private modalGridService: ModalGridService,
        private chartService: ChartService,
        public gridsService: GridsService,
        private collaboratorService: CollaboratorService,
        private genericUtils: GenericUtils,
    ) {
        this.listen.push(this.modalGridService.listenUpdateCustomerGrid().subscribe((response) => {
            this.updateGrid();
        }));
    }

    // restore the grid settings for normal use
    removeExportSettingsFromGrid() {
        this.gridApi.paginationSetPageSize(this.defaultPaginationSize);
        this.cacheBlockSize = this.defaultPaginationSize;
        this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.defaultPaginationSize);
        this.fullDataShownForExport = false;
        this.pagesCount = 0;
        this.setServerSideDatasource();
    }

    // export xls
    openXlsAction() {
        this.gridApi.paginationSetPageSize(this.paymentsCount);
        this.cacheBlockSize = this.paymentsCount;
        this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.paymentsCount);
        this.fullDataShownForExport = true;
        this.setServerSideDatasource();
    }

    // export data to xls
    exportToXls() {
        const excelParams = {
            processCellCallback: this.formattingFunction.bind(this),
            allColumns: true,
            fileName: this.genericUtils.removeDotsFromString('lastmily_export'),
            columnKeys: ['transaction_number', 'collaborator', 'status', 'until_date', 'created_date_issued_by', 'amount', 'iban', 'bank_name', 'paymentMethodsAmounts']
        }; 

        // function 
        this.gridApi.exportDataAsExcel(excelParams);
    }

    formattingFunction(params) {
        if (params.value) {
            if (params.value.name || params.value.name === '') {
                return params.value.name;
            } else if (!isNaN(params.value.payment_methods_total)) {
                let values = '';
                if (params.value['bank_deposit']) {
                    values += this.bankDepositLabel + ': ' + params.value['bank_deposit'] + this.currency + ' ';
                }
                if (params.value['sum_cheque']) {
                    values += this.chequeLabel + ': ' + params.value['sum_cheque'] + this.currency + ' ';
                }
                if (params.value['credit_card']) {
                    values += this.creditCardLabel + ': ' + params.value['credit_card'] + this.currency + ' ';
                }
                if (params.value['pay_on_delivery']) {
                    values += this.cashLabel + ': ' + params.value['pay_on_delivery'] + this.currency + ' ';
                }
                return values;
            } else if (params.value.label) {
                return params.value.label;
            } else {
                return params.value;
            }
        }
    }

    // update grid
    updateGrid() {
        this.pagesCount = 0;
        // this.gridApi.purgeServerSideCache([]);
        this.gridApi.refreshServerSideStore({ purge: true });
        const gridContainer = document.querySelector('#payments-grid-container .ag-body-viewport');
        gridContainer.scrollTo(0, 0);
    }

    newButtonClicked() {
        this.modalService.openPaymentModal(null);
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    openChart() {
        this.chartExpanded = true;
        this.statsContainer.nativeElement.classList.add('expanded');
        this.gridContainer.nativeElement.classList.add('reduced');
    }

    closeChart() {
        this.chartExpanded = false;
        this.statsContainer.nativeElement.classList.remove('expanded');
        this.gridContainer.nativeElement.classList.remove('reduced');
    }

    closeFilters() {
        this.filtersContainer.nativeElement.classList.add('hidden');
    }

    openFilters() {
        this.filtersContainer.nativeElement.classList.remove('hidden');
    }

    emptyChartArrays() {
        this.customerNames = [];
        this.customerShortNames = [];
        this.mostVisited = [];
    }

    getFirstWord(str) {
        return str.split(' ')[0];
    }

    loadNextChartPage() {
        if (!this.lastPage && !this.dataLoading) {
            this.dataLoading = true;
            this.currentChartPage++;
            this.emptyChartArrays();
            this.getChartData();
        }
    }

    loadPreviousChartPage() {
        if (!this.firstPage && !this.dataLoading) {
            this.dataLoading = true;
            this.currentChartPage--;
            this.emptyChartArrays();
            this.getChartData();
        }
    }

    displayDummyChartValues() {
        this.months = [];
        for (let i = 1; i < 8; i++) {
            this.months.push(moment.utc().add(i, 'day').format('DD-MM'));
        }

        this.invoiceNumberTotal = [22, 17, 16, 16, 15, 9, 13];
        this.invoiceNumberCompleted = [17, 15, 12, 14, 14, 8, 9];

        for (let i = 1; i < 8; i++) {
            this.invoiceNumberCancelled.push(this.invoiceNumberTotal[i] - this.invoiceNumberCompleted[i]);
        }

        this.chartDisabled = true;
    }

    checkIfAllZero(): Boolean {
        let allZero = true;
        this.mostVisited.forEach(element => {
            if (element !== '0') {
                allZero = false;
            }
        });
        return allZero;
    }

    getChartData() {
        this.dataLoading = true;
        let params = '?pageSize=' + this.pageSize;
        params += '&page=' + this.currentChartPage;
        params += '&ids=';
        params += '&date=' + moment().utc().format();
        params += '&days=' + this.selectedPeriod.value;
        if (this.currentChartPage === 0) {
            this.firstPage = true;
        } else {
            this.firstPage = false;
        }
        this.displayDummyChartValues();
        this.initChart();
        // this.http.get('api/v1/stats/customer-stats' + params).pipe(take(1)).subscribe(response => {
        //     this.dataLoading = false;
        //     const data = response['items'];
        //     if (Object.keys(data).length) {
        //         if (Object.keys(data).length < this.pageSize) {
        //             this.lastPage = true;
        //         } else if (Object.keys(data).length === this.pageSize) {
        //             this.lastPage = false;
        //         }
        //         Object.keys(data).forEach(id => {
        //             if (data[id]) {
        //                 this.chartData[id] = data[id];
        //                 this.customerNames.push(data[id].contact_name);
        //                 this.customerShortNames.push(this.getFirstWord(data[id].contact_name));
        //                 this.mostVisited.push(data[id].stopPointsCount);
        //             }
        //         });
        //         if (this.checkIfAllZero()) {
        //             this.displayDummyChartValues();
        //         }
        //     } else {
        //         this.lastPage = true;
        //         this.displayDummyChartValues();
        //     }
        //     this.initChart();
        // });
    }

    chartTypeChange() {
        this.initChart();
    }

    chartPeriodChange() {
        this.currentChartPage = 0;
        this.firstPage = true;
        this.lastPage = false;
        this.emptyChartArrays();
        this.getChartData();
    }

    initChart() {
        const ctx = <HTMLCanvasElement>document.getElementById('payments-chart');
        if (this.chart) { this.chart.destroy(); }
        const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptions));
        chartOptions.plugins.legend.display = true;
        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: this.months,
                    datasets: [
                        {
                            label: this.completedLabel,
                            data: this.invoiceNumberCompleted,
                            //fill: true,
                            borderColor: '#00aeba',
                            backgroundColor: '#00aeba70',
                        },
                        {
                            label: this.canceledLabel,
                            data: this.invoiceNumberTotal,
                            fill: '-1',
                            borderColor: '#00aeba',
                            backgroundColor: '#FF000030',
                        },
                    ]
                },
                options: chartOptions
            });
        }
    }

    getPaymentGridData(pageSize) {
        const searchQuery = `&searchQuery=${this.searchString}`;
        let url = 'api/v1/pay-on-delivery';
        url += '?pageSize=' + pageSize;
        url += '&page=' + this.pagesCount;
        url += '&' + this.selectedDatesQuery;

        if (this.globals.isInRoute('collaboratorView')) {
            url += '&collaborator_id=' + this.collaboratorId;
        }

        url += searchQuery;

        return this.http.get(url);
    }

    displayDataToGrid(params, dataArray, pagesCount) {
        if (dataArray.length) {
            let lastRow = -1;
            this.pagesCount++;
            if ((dataArray.length < this.cacheBlockSize) || this.pagesCount === (pagesCount)) {
                lastRow = dataArray.length + ((this.pagesCount - 1) * this.cacheBlockSize);
            }
            params.successCallback(this.setCustomerViewGridData(dataArray), lastRow);

        } else {
            if (this.pagesCount) {
                const lastRow = (this.pagesCount - 1) * this.cacheBlockSize;
                params.successCallback(this.setCustomerViewGridData(dataArray), lastRow);
            } else {
                const noData = { noDataText: 'No data' }
                params.successCallback([noData], 1);
            }
        }
    }

    // request and pass all grid data for xls export
    loadAllPagesToExport(params) {
        const pageSize = this.bulkRequestsSize;
        if (this.paymentsCount) {
            let pagesCount = Math.ceil(this.paymentsCount / pageSize);
            if (pagesCount > this.maxRequestsToMake) { pagesCount = this.maxRequestsToMake; }
            const done = [];
            let customerPaymentsDataArray = [];
            for (let i = 0; i < pagesCount; i++) {
                done.push(false);
                this.pagesCount = i;
                this.getPaymentGridData(pageSize).pipe(take(1)).subscribe(response => {
                    customerPaymentsDataArray = customerPaymentsDataArray.concat(response['items']['pay_on_delivery_payments']);
                    done[i] = true;
                    if (done.every(Boolean)) {
                        this.customerPaymentsDataArray = customerPaymentsDataArray;
                        this.displayDataToGrid(params, customerPaymentsDataArray, 1);
                        this.exportToXls();
                        this.removeExportSettingsFromGrid();
                    }
                });
            }
        }
    }

    // request and pass grid data page by page
    loadSinglePage(params) {
        this.getPaymentGridData(this.cacheBlockSize).pipe(take(1)).subscribe(response => {
            this.paymentsCount = response['itemsMeta'].totalCount;
            this.customerPaymentsDataArray = response['items']['pay_on_delivery_payments'];
            this.displayDataToGrid(params, this.customerPaymentsDataArray, response['itemsMeta']['pagesCount']);
            this.gridApi.hideOverlay();
        },
            (error) => {
                console.error(error);
                params.failCallback();
                this.gridApi.hideOverlay();
            }
        );
    }

    // set another data source to change between page sizes to export all items
    setServerSideDatasource() {
        const dataSource = {
            rowCount: null,
            getRows: (rowsParams) => {
                setTimeout(() => {
                    this.gridApi.showLoadingOverlay();
                    if (this.fullDataShownForExport) {
                        this.loadAllPagesToExport(rowsParams);
                    } else {
                        this.loadSinglePage(rowsParams);
                    }
                }, 500);
            }
        };
        this.gridApi.setServerSideDatasource(dataSource);
    }

    createServerSideDatasource() {
        return {
            getRows: (params) => {
                this.gridApi.showLoadingOverlay();
                this.loadSinglePage(params);
            },
        };
    }

    // set invoice as paid
    setPaid(paymentId) {
        let paidPayment = {
            "status": this.globals.invoiceStatusConstants['PAID'],
        };

        const myObserver = {
            next: (response) => {
                this.modalGridService.updateCustomerGrid();
            },
            error: (error) => {
                console.error(error);
            },
            complete: () => {

            },
        };

        this.http.put('api/v1/pay-on-delivery/' + paymentId, JSON.stringify(paidPayment)).pipe(take(1)).subscribe(myObserver);
    }

    // show invoice details
    showDetails() { }

    // dropdown actions
    detailsPayment() {
        if (this.selectedPayment) {
            this.modalService.openPaymentModal(this.selectedPayment.objData);
        }
        this.closeDropdown();
    }
    editPayment() {
        this.globals.comingSoonAlert();
        this.closeDropdown();
    }
    sendReminderPayment() {
        this.globals.comingSoonAlert();
        this.closeDropdown();
    }
    sendAgainPayment() {
        this.globals.comingSoonAlert();
        this.closeDropdown();
    }
    exportPdfPayment() {
        this.globals.comingSoonAlert();
        this.closeDropdown();
    }
    printPayment() {
        if (this.selectedPayment) {
            window.open('/paymentPrint/' + this.selectedPayment.hash, '_blank');
        }
    }
    deletePayment() {
        if (this.selectedPayment) {
            this.http.delete('api/v1/pay-on-delivery/' + this.selectedPayment.id).pipe(take(1)).subscribe(response => {
                this.modalGridService.updateCustomerGrid();
            });
        }
    }

    closeDropdown() {
        const dropdown = document.getElementById('payments-dropdown-container');
        this.selectedPayment = null;
        dropdown.style.display = 'none';
    }

    // custom renderer for dropdown actions
    dropdownRenderer(params) {
        const dots = document.createElement('div');
        dots.classList.add('dropdown-dots');
        dots.innerHTML = '<i class="fas fa-ellipsis-v"></i>';
        const dropdown = document.getElementById('payments-dropdown-container');
        dots.addEventListener('click', () => {
            setTimeout(() => {
                if (!this.selectedPayment) {
                    // dropdown.style.top = 'calc(' + dots.getBoundingClientRect().bottom + 'px - 10px)';
                    dropdown.style.top = 'min(100vh, 50vh + 55px)';
                    dropdown.style.left = 'calc(' + dots.getBoundingClientRect().left + 'px - 9vw)';
                    dropdown.style.display = 'block';
                    this.selectedPayment = params.data;
                } else {
                    this.closeDropdown();
                }
            }, 20);
        });
        return dots;
    }
    //

    // custom renderer for actions (button)
    actionsButtonRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue()) {
                columnObject += '<div class="invoices-actions-button-container">';
                columnObject += '<div class="single-cell standard-width">' + '<button class="invoices-actions-button ' + params.getValue().paymentActionClass + '">' + params.getValue().paymentActionLabel + '</button>' + '</div>';
                columnObject += '</div>';
            }
        }
        return columnObject;
    }
    //

    setCustomerViewGridData(payments) {
        this.customerPaymentsDataArray = [];
        let gridObject = {};
        let toGetPaidSum = 0;
        if (payments.length) {
            payments.forEach(payment => {
                // Until Date
                let untilDateFormatted = moment(payment.due_on_datetime).format('DD/MM/YYYY');

                // Created Date + Issued by
                let createdDateIssuedBy: string;
                let dateCreatedFormatted = moment(payment.creation_datetime).format('DD/MM/YYYY');

                if (payment.issued_by == this.globals.invoiceStatusCollaboratorConstants['COMPANY']) {
                    createdDateIssuedBy = dateCreatedFormatted + " " + this.issuedByYou;
                } else {
                    createdDateIssuedBy = dateCreatedFormatted + " " + this.issuedByMily;
                }

                // Sent Date + Sent by
                let sentDateBy: string;
                let dateSendFormatted = moment(payment.sent_date).format('DD/MM/YYYY');

                if (payment.sent_by == this.globals.invoiceStatusCollaboratorConstants['MILY']) {
                    sentDateBy = dateSendFormatted + " " + this.issuedByMily;
                } else {
                    sentDateBy = dateSendFormatted + " " + this.issuedByYou;
                }

                // Status Label & Correct invoice action (button)
                let statusLabel, paymentActionLabel, paymentActionClass, colorClass;
                switch (payment.status) {
                    case this.globals.invoiceStatusConstants['CREATED']:
                        statusLabel = this.createdLabel;
                        paymentActionLabel = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? this.payLabel : this.detailsLabel;
                        paymentActionClass = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? 'pay-button' : 'details-button';
                        colorClass = 'status-created';
                        break;
                    case this.globals.invoiceStatusConstants['VIEWED']:
                        statusLabel = this.viewedLabel;
                        paymentActionLabel = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? this.payLabel : this.detailsLabel;
                        paymentActionClass = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? 'pay-button' : 'details-button';
                        colorClass = 'status-viewed';
                        break;
                    case this.globals.invoiceStatusConstants['PAID']:
                        statusLabel = this.paidLabel;
                        paymentActionLabel = this.detailsLabel;
                        paymentActionClass = 'details-button';
                        colorClass = 'status-paid';
                        break;
                    case this.globals.invoiceStatusConstants['OVERDUE']:
                        statusLabel = this.overdueLabel;
                        paymentActionLabel = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? this.payLabel : this.detailsLabel;
                        paymentActionClass = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? 'pay-button' : 'details-button';
                        colorClass = 'status-overdue';
                        break;
                    case this.globals.invoiceStatusConstants['SENT']:
                        statusLabel = this.sentLabel;
                        paymentActionLabel = this.detailsLabel;
                        paymentActionClass = 'details-button';
                        colorClass = 'status-sent';
                        break;
                    default:
                        statusLabel = 'CASE ERROR';
                }

                // payment methods & amounts
                let paymentMethodsAmounts = { payment_methods_total: payment.pay_amount };
                if (payment.pay_on_delivery_pay_payment_amount['bank_deposit']) {
                    paymentMethodsAmounts['bank_deposit'] = payment.pay_on_delivery_pay_payment_amount['bank_deposit']['amount'];
                } else {
                    paymentMethodsAmounts['bank_deposit'] = 0;
                }
                if (payment.pay_on_delivery_pay_payment_amount['sum_cheque']) {
                    paymentMethodsAmounts['sum_cheque'] = payment.pay_on_delivery_pay_payment_amount['sum_cheque']['amount'];
                } else {
                    paymentMethodsAmounts['sum_cheque'] = 0;
                }
                if (payment.pay_on_delivery_pay_payment_amount['credit_card']) {
                    paymentMethodsAmounts['credit_card'] = payment.pay_on_delivery_pay_payment_amount['credit_card']['amount'];
                } else {
                    paymentMethodsAmounts['credit_card'] = 0;
                }
                if (payment.pay_on_delivery_pay_payment_amount['pay_on_delivery']) {
                    paymentMethodsAmounts['pay_on_delivery'] = payment.pay_on_delivery_pay_payment_amount['pay_on_delivery']['amount'];
                } else {
                    paymentMethodsAmounts['pay_on_delivery'] = 0;
                }

                gridObject = {
                    id: payment.id,
                    hash: payment.hash,
                    transaction_number: this.collaboratorService.generateIdWithZeros(payment.id),
                    status: {
                        colorClass: colorClass,
                        label: statusLabel
                    },
                    collaborator: {
                        id: payment.collaborator_id,
                        name: payment.collaborator_name
                    },
                    bankDetails: {
                        iban: payment.collaborator_iban ?? '-',
                        bank_name: payment.sender_bank_name ?? '-'
                    },
                    iban: payment.collaborator_iban ?? '-',
                    bank_name: payment.sender_bank_name ?? '-',
                    is_payed: payment.is_payed,
                    until_date: untilDateFormatted,
                    created_date_issued_by: createdDateIssuedBy,
                    sent_date_issued_by: sentDateBy,
                    vouchers: payment.vouchers,
                    amount: payment.pay_amount.toFixed(2) + this.currency,
                    paymentMethodsAmounts: paymentMethodsAmounts,
                    actions: {
                        paymentActionLabel: paymentActionLabel,
                        paymentActionClass: paymentActionClass
                    },
                    objData: payment
                };
                this.customerPaymentsDataArray.push(gridObject);
                toGetPaidSum += payment.pay_amount;
            });
        } else {
            const noData = { noDataText: 'No data' }
            this.customerPaymentsDataArray.push(noData);
        }

        return this.customerPaymentsDataArray;
    }

    rowClicked(event) {
        const target = event.event.target.closest('div');
        if (
            !event.data.noDataText && !target.classList.contains('dropdown-dots')
            && !event.event.target.classList.contains('pay-button')
            && !target.classList.contains('hyperlink')
            // && !event.event.target.classList.contains('details-button')
        ) {
            this.modalService.openPaymentModal(event.data.objData);
        }
        // pay button 
        else if (event.event.target.classList.contains('pay-button')) {
            this.setPaid(event.data.id);
        }
        // details button
        // else if (event.event.target.classList.contains('details-button')) {
        //     this.showDetails(event.data.objData);
        // }
    }

    togglePaymentsHoverBox(isOpen) {
        if (isOpen) {
            document.getElementById('payments-hover-box').style.display = 'flex';
        } else {
            document.getElementById('payments-hover-box').style.display = 'none';
        }
    }

    cellMouseOver(event) {
        if (event.column.colId == 'paymentMethodsAmounts') {
            this.hoverBoxPayOnDelivery = event.data.paymentMethodsAmounts['pay_on_delivery'];
            this.hoverBoxCreditCard = event.data.paymentMethodsAmounts['credit_card'];
            this.hoverBoxBankDeposit = event.data.paymentMethodsAmounts['bank_deposit'];
            this.hoverBoxCheque = event.data.paymentMethodsAmounts['sum_cheque'];

            const target = event.event.target.closest('div');
            const box = document.getElementById('payments-hover-box');

            // check if the box is in viewport & handle it appropriately
            this.togglePaymentsHoverBox(true);
            box.style.top = 'calc(' + target.getBoundingClientRect().bottom + 'px)';
            if (!this.genericUtils.isElementInViewport(box)) {
                box.style.top = 'calc(' + target.getBoundingClientRect().bottom + 'px - 200px)';
            }


        } else {
            this.togglePaymentsHoverBox(false);
        }
    }

    cellMouseOut(event) {
        this.togglePaymentsHoverBox(false);
    }

    onFirstDataRendered(params) { }

    createdDateRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().name) {
                columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
                columnObject += '<div class="double-cell"><i class="fas fa-phone-alt"></i> ' + params.getValue().phone;
            }
            if (params.getValue().customerId) {
                columnObject += ' <i class="fas fa-fingerprint"></i> ' + params.getValue().customerId + '</div>';
            } else {
                columnObject += '</div>';
            }
        }
        return columnObject;
    }

    paymentMethodsRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="payment-methods-cell">';

            columnObject += '<div class="column">';
            columnObject += '<div class="display-flex margin-right-auto">';
            columnObject += '<i class="fas fa-coins grey-letters right-margin-small"></i>';
            columnObject += String(params.getValue().pay_on_delivery) + this.currency;
            columnObject += '</div>';

            columnObject += '<div class="display-flex margin-right-auto">';
            columnObject += '<i class="fas fa-credit-card grey-letters right-margin-small"></i>';
            columnObject += String(params.getValue().credit_card) + this.currency;
            columnObject += '</div>';
            columnObject += '</div>';

            columnObject += '<div class="column">';
            columnObject += '<div class="display-flex margin-right-auto">';
            columnObject += '<i class="fas fa-pen-alt grey-letters right-margin-small left-margin-big"></i>';
            columnObject += String(params.getValue().sum_cheque) + this.currency;
            columnObject += '</div>';

            columnObject += '<div class="display-flex margin-right-auto">';
            columnObject += '<i class="fas fa-university grey-letters right-margin-small left-margin-big"></i>';
            columnObject += String(params.getValue().bank_deposit) + this.currency;
            columnObject += '</div>';
            columnObject += '</div>';

            columnObject += '</div>';
            columnObject += '</div>';
        }
        return columnObject;
    }

    bankDetailsRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="double-cell standard-width" title="' + params.getValue().iban + '">' + params.getValue().iban + '</div>';
            columnObject += '<div class="double-cell standard-width" title="' + params.getValue().bank_name + '">' + params.getValue().bank_name + '</div>';
        }
        return columnObject;
    }

    // custom renderer for status
    statusRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="invoice-status-container">' + '<div class="single-cell standard-width ' + params.getValue().colorClass + '">' + params.getValue().label + '</div>' + '</div>';
        }

        return columnObject;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    initGrid(id) {
        let intervalId = setInterval(() => {
            if (this.gridApi) {
                this.collaboratorId = id;
                this.gridApi.setServerSideDatasource(this.createServerSideDatasource());
                clearInterval(intervalId);
            }
        }, 100);
    }

    getTranslations() {
        let createdDateTitleCombined: string;

        this.listen.push(this.translate.get('STOP_POINT.COLLABORATOR').subscribe((res: string) => { this.collaboratorTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_PAYMENTS.TRANSACTION_NUMBER').subscribe((res: string) => { this.transactionNumberTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.STATUS').subscribe((res: string) => { this.statusTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.UNTIL').subscribe((res: string) => { this.untilTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.CREATED_DATE').subscribe((res: string) => { this.createdDateTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.NUMBER').subscribe((res: string) => { this.numberTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.AMOUNT').subscribe((res: string) => { this.amountTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.ACTIONS').subscribe((res: string) => { this.actionsTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.BY_MILY').subscribe((res: string) => { this.issuedByMily = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.BY_YOU').subscribe((res: string) => { this.issuedByYou = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.SENT').subscribe((res: string) => { this.sentLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.VIEWED').subscribe((res: string) => { this.viewedLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.PAID').subscribe((res: string) => { this.paidLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.PAY').subscribe((res: string) => { this.payLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.OVERDUE').subscribe((res: string) => { this.overdueLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.CREATED').subscribe((res: string) => { this.createdLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.ACTION_DETAILS').subscribe((res: string) => { this.detailsLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_PAYMENTS.PAYMENT_METHODS').subscribe((res: string) => { this.paymentMethodsLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
        this.listen.push(this.translate.get('STOP_POINT.STATUS_COMPLETED').subscribe((res: string) => { this.completedLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.STATUS_CANCELED').subscribe((res: string) => { this.canceledLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_COLLABORATORS.BANK').subscribe((res: string) => {
            this.bankIbanTitle = this.ibanLabel + ' / ' + res;
            this.bankLabel = res;
        }));
        this.listen.push(this.translate.get('STOP_POINT').subscribe((res: string) => {
            this.creditCardLabel = res['CREDIT_CARD'];
            this.cashLabel = res['CASH'];
            this.bankDepositLabel = res['BANK_DEPOSIT'];
            this.chequeLabel = res['BANK_DEPOSIT'];
        }));

        this.getChartData();

        // specific collaborator grid
        if (this.globals.isInRoute('collaboratorView')) {
            this.columnDefs = [
                { headerName: this.transactionNumberTitle, field: 'transaction_number', width: this.gridsService.widthCalculator(15) },
                {
                    headerName: this.statusTitle,
                    field: 'status',
                    cellRenderer: this.statusRenderer,
                    width: this.gridsService.widthCalculator(12.5)
                },
                {
                    headerName: this.untilTitle,
                    field: 'until_date',
                    width: this.gridsService.widthCalculator(10)
                },
                {
                    headerName: this.createdDateTitle,
                    field: 'created_date_issued_by',
                    width: this.gridsService.widthCalculator(15)
                },
                {
                    headerName: this.amountTitle,
                    field: 'amount',
                    width: this.gridsService.widthCalculator(15)
                },
                {
                    headerName: this.paymentMethodsLabel,
                    field: 'paymentMethodsAmounts',
                    cellRenderer: this.paymentMethodsRenderer.bind(this),
                    width: this.gridsService.widthCalculator(17.5)
                },
                {
                    headerName: this.actionsTitle,
                    field: 'actions',
                    cellRenderer: this.actionsButtonRenderer.bind(this),
                    width: this.gridsService.widthCalculator(15)
                },
                {
                    headerName: '', field: '',
                    cellClass: 'dropdown-cell',
                    cellRenderer: this.dropdownRenderer.bind(this),
                    width: this.gridsService.widthCalculator(5)
                }
            ];
        }
        // general collaborators grid
        else {
            this.columnDefs = [
                {
                    headerName: this.collaboratorTitle,
                    field: 'collaborator',
                    cellRenderer: this.gridsService.collaboratorNewTabRenderer,
                    width: this.gridsService.widthCalculator(10.5)
                },
                // { headerName: this.transactionNumberTitle, field: 'transaction_number', width: this.gridsService.widthCalculator(12.5) },
                {
                    headerName: this.statusTitle,
                    field: 'status',
                    cellRenderer: this.statusRenderer,
                    width: this.gridsService.widthCalculator(15)
                },
                {
                    headerName: this.untilTitle,
                    field: 'until_date',
                    width: this.gridsService.widthCalculator(10)
                },
                {
                    headerName: this.createdDateTitle,
                    field: 'created_date_issued_by',
                    width: this.gridsService.widthCalculator(15)
                },
                {
                    headerName: this.amountTitle,
                    field: 'amount',
                    width: this.gridsService.widthCalculator(7.5)
                },
                {
                    headerName: this.bankIbanTitle,
                    field: 'bankDetails',
                    width: this.gridsService.widthCalculator(10),
                    cellRenderer: this.bankDetailsRenderer
                },
                {
                    headerName: this.paymentMethodsLabel,
                    field: 'paymentMethodsAmounts',
                    cellRenderer: this.paymentMethodsRenderer.bind(this),
                    width: this.gridsService.widthCalculator(10)
                },
                {
                    headerName: this.ibanLabel,
                    field: 'iban',
                    hide: true,
                },
                {
                    headerName: this.bankLabel,
                    field: 'bank_name',
                    hide: true,
                },
                {
                    headerName: this.actionsTitle,
                    field: 'actions',
                    cellRenderer: this.actionsButtonRenderer.bind(this),
                    width: this.gridsService.widthCalculator(14.5)
                },
                {
                    headerName: '', field: '',
                    cellClass: 'dropdown-cell',
                    cellRenderer: this.dropdownRenderer.bind(this),
                    width: this.gridsService.widthCalculator(5)
                }
            ];
        }
    }

    ngOnInit(): void {
        this.charts = [
            {
                label: 'Invoices',
                value: 'invoices',
            },
        ];
        this.periods = this.chartService.periods;
        this.selectedChart = this.charts[0];
        this.selectedPeriod = this.periods[4];

        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        // init grid for all payments
        if (!this.globals.isInRoute('collaboratorView')) {
            this.initGrid(null);
        }

        // search update
        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((text: string) => {
                this.pagesCount = 0;
                // this.gridApi.purgeServerSideCache([]);
                this.gridApi.refreshServerSideStore({ purge: true });
            });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
        if (this.chart) { this.chart.destroy(); }
    }
}
