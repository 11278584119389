// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    :host button{border:none;}
    :host ::ng-deep .p-button.p-button-text:enabled:hover{background: none !important}
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJ1dHRvbi5jb21wb25lbnQudHMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtJQUNJLGFBQWEsV0FBVyxDQUFDO0lBQ3pCLHNEQUFzRCwyQkFBMkIiLCJmaWxlIjoiYnV0dG9uLmNvbXBvbmVudC50cyIsInNvdXJjZXNDb250ZW50IjpbIlxuICAgIDpob3N0IGJ1dHRvbntib3JkZXI6bm9uZTt9XG4gICAgOmhvc3QgOjpuZy1kZWVwIC5wLWJ1dHRvbi5wLWJ1dHRvbi10ZXh0OmVuYWJsZWQ6aG92ZXJ7YmFja2dyb3VuZDogbm9uZSAhaW1wb3J0YW50fVxuICAgICJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/structure/button/button.component.ts"],"names":[],"mappings":";IACI,aAAa,WAAW,CAAC;IACzB,sDAAsD,2BAA2B;;AAErF,wbAAwb","sourcesContent":["\n    :host button{border:none;}\n    :host ::ng-deep .p-button.p-button-text:enabled:hover{background: none !important}\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
