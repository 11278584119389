import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { LmDialogContext } from "@app/model/dialog";
import { LmDriversService } from "@app/api/services/drivers.service";
import { IDriverDataDto } from "@app/api/models/drivers-dto";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { take, tap } from "rxjs/operators";
import * as moment from "moment-timezone";
import Cropper from "cropperjs";
import { LmDialogComponent } from "@app/shared/containers/dialog/dialog.component";
import { IAddressDto } from "../../api/models/address-dto";
import { LmButtonConfig } from "@app/shared/structure/button/button.component";
import { SelectMapperService } from "@app/core/services/select-mapper.service";
import { RegionalMapComponent } from "@app/forms/driver-form/regional-map/regional-map.component";
import { AddressService } from "@app/services/address.service";
import { FormErrorsUtils } from "@app/utils/form-errors-utils";
import { DepotUtils } from "@app/utils/depot-utils";
import { getEnumInfoValues } from "@app/core/enums-utils";
import { of } from "rxjs";
import { ImageUtils } from "@app/utils/image-utils";
import * as libphonenumber from 'libphonenumber-js';
import { PrimeIcons } from "primeng/api";
import { Button, ButtonModule} from "primeng/button";
import { hasValue } from "@app/shared/utils";


@UntilDestroy()
@Component({
  templateUrl: "drivers-modal.component.html",
  styleUrls: ["./drivers-modal.component.scss"],
  providers: [],
})
export class DriversModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(LmDialogComponent) dialog: LmDialogComponent;
  @ViewChild("routingSettingsButton") closeButton: ElementRef;
  @ViewChild(RegionalMapComponent, { static: false })
  regionalMapComponent: RegionalMapComponent;
  globals;
  modalGridService;
  isInit = true;
  values: IDriverDataDto;
  saveButton: LmButtonConfig;
  saveAndDuplicateButton: LmButtonConfig;
  deleteButton: LmButtonConfig;

  projectsArrayForSelect: Array<object>;

  modalTitle: string = "";

  id: number;
  name: string;
  telephone: string;
  phone: string;
  countryPrefix: string;
  usualDepartureDatetime: string;
  dailyWorkingHours: number;
  repository: number;
  driverWorkType: number;
  liveTrackingAccuracy: number;
  dailyWage: number;
  callTariff: number;
  costPerShipment: number;
  projectIds: Array<number>;
  depotId: number;
  vehicleId: number;
  startAddress: IAddressDto;
  endAddress: IAddressDto;
  
  canDriverChangeTimeWindows: boolean;
  canDriverReorderSequence: boolean;
  isPickerMode: number;

  imageSliderOptions;
  imageSliderValue;
  slideValGlobal = 0.5;
  imageBase64;
  avatar = "";
  avatarSrc = "data:image/png;base64,AVATAR_HASH";
  cropper;

  expandedView = false;
  area = false;
  mapOnlyView = false;

  onStartAddressSelect: (e) => void;
  onEndAddressSelect: (e) => void;

  constructor(
    public config: LmDialogContext<any>,
    private service: LmDriversService,
    public selectMapperSvc: SelectMapperService,
    private addressService: AddressService,
    private formErrorsUtils: FormErrorsUtils,
    private depotUtils: DepotUtils,
    private imageUtils: ImageUtils,
  ) {
    const {
      data: { modalGridService, globals, values, translations },
    } = config;
    this.globals = globals;
    this.modalGridService = modalGridService;


    this.saveButton = {
      command: () => this.save(),
    };
    this.saveButton.color = "#00aeba";
    this.saveAndDuplicateButton = {
      command: () => this.save(false),
    };
    this.saveAndDuplicateButton.color = "#037b83";
    this.deleteButton = {
      command: () => this.delete(),
    };
    this.deleteButton.color = "#dddddd";

    if (values.driver) {
      this.loadData(values);
    } else {
      this.loadDefaultValues();
    }
    this.modalTitle = this.id ? translations['EDIT_DRIVER'] : translations['NEW_DRIVER'];

    this.onStartAddressSelect = (e) => {
      this.startAddress = e;
    };
    this.onEndAddressSelect = (e) => {
      this.endAddress = e;
    };
  }

  // openPopup(){
  //   const w = 400;
  //   const x = innerWidth/2-w/2;
  //   const h = 600;
  //   const y = innerHeight/2-h/2
  //   window.open("https://search.google.com/local/writereview?placeid=ChIJzWe0YZGzoRQR08LIxkfr6Ho", 'parent', `left=${x}, top=${y}, toolbar=no, location=no, directories=no,status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}`);    
  // }

  enableMaponlyView(decision){
    this.mapOnlyView = decision;
    this.areaChanged();
  }

  loadDefaultValues() {
    this.id = undefined;
    this.name = "";
    this.phone = "";
    this.dailyWorkingHours = undefined;
    this.usualDepartureDatetime = undefined;
    this.repository = undefined;
    this.dailyWage = undefined;
    this.callTariff = undefined;
    this.costPerShipment = undefined;
    this.area = false;
    this.canDriverChangeTimeWindows = false;
    this.canDriverReorderSequence = false;
    this.avatar = undefined;
    this.projectIds = undefined;
    this.driverWorkType = 0;
    this.isPickerMode = 0;
    setTimeout(() => {
      this.vehicleId = undefined;
      this.liveTrackingAccuracy = this.globals.driverLiveTrackingAccuracyOptionsForSelect[0].value;
      if(this.regionalMapComponent) this.regionalMapComponent.resetMap();
      this.countryPrefix = this.globals.defaultCountryCode;
    }, 200);
    this.depotId = this.globals.depotId;
    const depotAddress = this.getDefaultDepotAddress();
    this.startAddress = depotAddress;
    this.endAddress = depotAddress;
    this.updateButtons();
    this.updateProjectArrayForSelect();
  }

  private isFormValid(): boolean {
    return this.name && this.phone && this.dailyWorkingHours > 0;
  }

  private updateButtons() {
    setTimeout(() => {
      this.saveButton.disabled = !this.isFormValid();
      this.saveAndDuplicateButton.disabled = !this.isFormValid();
    }, 100);
  }

  private isPickerModeChanged() {
    if(this.isPickerMode == 1) {
      this.dailyWorkingHours = 8;
    }
  }

  private getDefaultDepotAddress() {
    const depotAddress = this.globals.depotsArray[0]["companyDepot"]["address"];
    depotAddress.addressLabel =
      this.addressService.getAddressLabel(depotAddress);
    return depotAddress;
  }

  private getDepotAddress() {
    const depotAddress = this.depotUtils.getFirstDepotWithDepotId(this.depotId)["companyDepot"]["address"];
    depotAddress.addressLabel = this.addressService.getAddressLabel(depotAddress);
    return depotAddress;
  }

  private isDepotAddress(address) {
    const depotAddress = this.getDepotAddress();
    if (address == depotAddress) return true;
    return false;
  }

  private loadData(data) {

    const driverData = data.driver;
    const driverLocationsData = data.driverLocations;
    const userProfileData = data.userProfile;

    const {driverImage:{base64, imageHash}} = data;

    this.id = data.driver.id;
    setTimeout(() => {
      // this.regionalMapComponent.loadDriverRegion(this.id);
      this.regionalMapComponent.loadDriverModalRegion(this.id);
    }, 300);
    this.name = userProfileData.name;
    this.telephone = userProfileData.telephone;
    this.usualDepartureDatetime = moment(
      driverData.usual_departure_datetime
    ).format("HH:mm");
    this.dailyWorkingHours = moment
      .duration(driverData.daily_working_hours)
      .asHours();
    this.repository = driverData.repository;
    this.dailyWage = driverData.daily_wage;
    this.callTariff = driverData.call_tariff;
    this.costPerShipment = driverData.cost_per_shipment;
    setTimeout(() => {
      this.liveTrackingAccuracy = driverData.live_tracking_accuracy;
      this.vehicleId = data.vehicle.id;
    }, 100);
    this.driverWorkType = driverData.driver_work_type;
    this.isPickerMode = driverData.is_picker_mode_enabled ?? 0;
    this.canDriverChangeTimeWindows = driverData.can_driver_change_time_windows ?? false;
    this.canDriverReorderSequence = driverData.can_driver_reorder_sequence ?? false;

    setTimeout(() => {
      this.depotId = driverLocationsData.companyDepot?.modelId ?? this.globals.depotId;
      this.updateProjectArrayForSelect();
      const depotAddress = this.getDepotAddress();
      if (
        driverLocationsData.locationStart?.modelName ===
        this.globals.addressModelName
      ) {
        const address = driverLocationsData.locationStart.address;
        address.addressLabel = this.addressService.getAddressLabel(address);
        this.startAddress = address;
      } else {
        this.startAddress = depotAddress;
      }
      if (
        driverLocationsData.locationEnd?.modelName ===
        this.globals.addressModelName
      ) {
        const address = driverLocationsData.locationEnd.address;
        address.addressLabel = this.addressService.getAddressLabel(address);
        this.endAddress = address;
      } else {
        this.endAddress = depotAddress;
      }
      
      const projectIds = [];
      driverData.projects.forEach((project) => {
        projectIds.push(project.id);
      });
      setTimeout(() => {
        this.projectIds = projectIds.length ? projectIds : undefined;
      }, 100);
    }, 100);

    if (this.telephone === 'n/a') { this.telephone = ''; }
    if (this.telephone) {
      if (this.telephone.length > 5) {
        const phoneObj = libphonenumber.parsePhoneNumber(this.telephone);
        this.phone = phoneObj.nationalNumber;
        setTimeout(() => {
          this.countryPrefix = '+' + phoneObj.countryCallingCode;
        }, 100);
      }
    }

    if (imageHash) this.loadAvatarAsync(imageHash);
    else if (base64) this.avatar = this.avatarSrc.replace("AVATAR_HASH", base64);
    else this.avatar = undefined;
    this.updateButtons();
  }

  loadAvatarAsync(imageHash) {
    this.imageUtils.fetchImagesViaHashes(`api/internal/v1/images/drivers`, [imageHash]).then(avatars => {
      if (avatars[0]) {
        this.imageBase64 = avatars[0];
        this.avatar = this.avatarSrc.replace('AVATAR_HASH', this.imageBase64);
      } else this.avatar = undefined;
    });
  }

  private provideModel(comp: DriversModalComponent): IDriverDataDto {
    if (!moment(comp.usualDepartureDatetime).isValid()) {
      comp.usualDepartureDatetime = moment(
        comp.usualDepartureDatetime,
        "HH:mm"
      ).format();
    }

    const date = new Date();
    const data = {
      driver: {
        id: comp.id,
        usual_departure_datetime_timezone: moment.tz.guess(),
        usual_departure_datetime: moment(comp.usualDepartureDatetime).format(),
        daily_working_hours: moment
          .duration(comp.dailyWorkingHours, "hours")
          .toISOString(),
        repository: comp.repository,
        driver_work_type: comp.driverWorkType,
        is_picker_mode_enabled: comp.isPickerMode,
        default_vehicle_id: this.vehicleId,
        live_tracking_accuracy: comp.liveTrackingAccuracy,
        daily_wage: comp.dailyWage,
        call_tariff: comp.callTariff,
        cost_per_shipment: comp.costPerShipment,
        projectIds: comp.projectIds,
        can_driver_change_time_windows: comp.canDriverChangeTimeWindows,
        can_driver_reorder_sequence: comp.canDriverReorderSequence,
      },
      userProfile: {
        name: comp.name,
        telephone: comp.countryPrefix + comp.phone,
      },
      driverLocations: {
        companyDepot: {
          modelId: comp.depotId,
        },
        locationStart: {
          modelName: this.isDepotAddress(comp.startAddress)
            ? comp.globals.companyDepotModelName
            : comp.globals.addressModelName,
          modelId: this.isDepotAddress(comp.startAddress) ? comp.depotId : null,
          address: this.isDepotAddress(comp.startAddress)
            ? null
            : comp.startAddress,
        },
        locationEnd: {
          modelName: this.isDepotAddress(comp.endAddress)
            ? comp.globals.companyDepotModelName
            : comp.globals.addressModelName,
          modelId: this.isDepotAddress(comp.endAddress) ? comp.depotId : null,
          address: this.isDepotAddress(comp.endAddress)
            ? null
            : comp.endAddress,
        },
      },
    };
    if (comp.imageBase64) {
      data["driverImage"] = {};
      data["driverImage"]["base64"] = comp.imageBase64;
    }
    return data;
  }

  onValueChanged() {}

  regionDataReceived(event) {
    if (event === "data") {
      this.area = true;
      this.areaChanged();
    }
  }

  depotChanged() {
    const depotAddress = this.getDepotAddress();
    this.startAddress = depotAddress;
    this.endAddress = depotAddress;
    this.projectIds = undefined;
    this.updateProjectArrayForSelect();
  }
  
  private updateProjectArrayForSelect() {
    const projectsArrayForSelect = [];
    this.globals.projectsArray.forEach((projectData) => {
      if (projectData.project.depot_ids.includes(this.depotId)) {
        projectsArrayForSelect.push({
          key: projectData.project.id,
          value: projectData.project.id,
          caption: projectData.project.title,
        });
      }
    });
    this.projectsArrayForSelect = projectsArrayForSelect;
  }

  areaChanged() {
    setTimeout(() => {
      this.regionalMapComponent.mapToggled();
      if(!this.area && this.mapOnlyView) this.mapOnlyView = false;
    }, 500);
  }

  resetUploaderPopup() {
    // this.imageBase64 = '';
    // this.avatar = '';
    const imageElement = document.getElementById("cropper-image-container"),
      imgPreview = document.querySelector("#preview-img") as HTMLImageElement;
    imgPreview.src = "/assets/lastmilyAssets/user.png";
    // if (imageElement) {
    imageElement.innerHTML = "";
    // }
    // if (this.cropper) {
    //   this.cropper.reset();
    // }
    this.cropper = "";
  }

  openUploaderPopup() {
    this.resetUploaderPopup();
    document.getElementById("avatar-upload-popup").classList.add("open");
    document
      .getElementById("avatar-upload-popup-bg")
      .classList.remove("hidden");
  }

  closeUploaderPopup() {
    document.getElementById("avatar-upload-popup").classList.remove("open");
    document.getElementById("avatar-upload-popup-bg").classList.add("hidden");
  }

  cropImgFunc(event) {
    let result = document.querySelector("#cropper-image-container"),
      imgPreview = document.querySelector("#preview-img") as HTMLImageElement;
    if (event.target.files.length) {
      // start file reader
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target.result) {
          // create new image
          let img = document.createElement("img");
          img.id = "image";
          img.src = String(event.target.result);
          img.width = 544;
          img.height = 370;
          // clean result before
          result.innerHTML = "";
          // append new image
          result.appendChild(img);
          // init cropper
          const cropper = new Cropper(img, {
            viewMode: 1,
            dragMode: "move",
            aspectRatio: 1,
            autoCropArea: 0.68,
            minContainerWidth: 544,
            minContainerHeight: 370,
            center: false,
            zoomOnWheel: false,
            zoomOnTouch: false,
            cropBoxMovable: false,
            cropBoxResizable: false,
            guides: false,
            toggleDragModeOnDblclick: false,
            ready: (event) => {
              this.cropper = cropper;
            },
            crop: (event) => {
              if (this.cropper) {
                let imgSrc = this.cropper
                  .getCroppedCanvas({
                    width: 125,
                    height: 125,
                  })
                  .toDataURL("image/png");
                imgPreview.src = imgSrc;
              }
            },
          });
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  initializeSlider() {
    this.imageSliderOptions = {
      // margin: 15, // how many minutes between start and stop
      step: 0.1,
      start: [this.slideValGlobal],
      connect: "lower",
      range: {
        min: 0,
        max: 1,
      },
    };
  }

  getAvatar() {
    if (this.cropper) {
      this.imageBase64 = this.cropper
        .getCroppedCanvas({
          width: 125,
          height: 125,
        })
        .toDataURL()
        .replace("data:image/png;base64,", "");
      this.avatar = this.avatarSrc.replace("AVATAR_HASH", this.imageBase64);
    }
    this.closeUploaderPopup();
  }

  sliderChange() {
    // let slideVal = ui.value;
    let zoomRatio =
      Math.round((this.imageSliderValue - this.slideValGlobal) * 10) / 10;
    this.slideValGlobal = this.imageSliderValue;
    this.cropper.zoom(zoomRatio);
  }

  beforeSave() {
    this.telephone = this.countryPrefix + this.phone;
  }

  afterSave(response, closeAfterSave: boolean) {
    if (this.area) {
      this.regionalMapComponent.driverId = response["item"]["driver"]["id"];
      this.regionalMapComponent.savePolygonChanges();
    } 
    else this.regionalMapComponent.deleteRegion();

    this.modalGridService.updateDriverGrid();
    if (closeAfterSave) this.config.close();
    else this.loadDefaultValues();
    
  }

  fixDailyWorkingHours() {
    if (this.dailyWorkingHours < 1) this.dailyWorkingHours = 1;
    if (this.dailyWorkingHours > 24) this.dailyWorkingHours = 24;
  }

  save(closeAfterSave = true) {
    this.fixDailyWorkingHours();
    if (this.id) {
      this.service
        .apiDriversPut({
          body: this.provideModel(this),
          id: this.id.toString(),
        })
        .pipe(take(1), untilDestroyed(this))
        .subscribe((res) => {
          this.afterSave(res, closeAfterSave);
        });
    } else {
      this.service
        .apiDriversPost({
          body: this.provideModel(this),
        })
        .pipe(take(1), untilDestroyed(this))
        .subscribe(
          (res) => {
            this.afterSave(res, closeAfterSave);
          },
          (error) => {
            this.formErrorsUtils.checkResponseForErrorCodes(error);
          }
        );
    }
  }

  delete() {
    return this.service.apiDriversDelete({id: this.id.toString()})
      .pipe(take(1), untilDestroyed(this))
      .subscribe(_ => this.config.close());
  }

  ngOnInit() {
    this.initializeSlider();
  }

  ngAfterViewInit(): void {
    setTimeout((_) => (this.isInit = false));
  }

  ngOnDestroy(): void {}
  ok = () => {};
}
