import { LM_STACKED_CHART_BORDER_RADIUS } from "@app/model/charts-config";
import { ILmChartWidgetTab } from "@app/model/widget";

const pagesize = 7;

export const IN_TOTAL: ILmChartWidgetTab = {
    id: 'shipments_in_total',
    title: 'Shipments',
    subtitle: 'IN_TOTAL',
    pageSize: pagesize,
    btn:{id: 'shipments_in_total', icon: 'lm-icon icon-pin', textOnly: true, color: "#ccc", activeColor: '#00aeba', active: true},
    data: {
        labels: [],
        datasets: [
            {
                label: 'DELIVERED',
                backgroundColor: ['#00aeba'],
                data: null,
                stack: 'completed'
            },
            {
                label: 'CANCELED',
                backgroundColor: ['#ffc9cc'],
                data: null,
                stack: 'cancelled'
            }
        ]
    }, 
    filters: [],
    chartOptions: {
        scales: {
            x: {
                stacked: true
            }
        },
        plugins:{
            legend:{
                display: true,
                position: 'bottom',
                align: 'start',
            },
        }
    },
    chartType: 'bar'
};

export const DELIVERIES_AND_PICKUPS: ILmChartWidgetTab = {
    id: 'deliveries_and_pickups',
    title: 'Shipments',
    subtitle: 'DELIVERIES_AND_PICKUPS',
    pageSize: pagesize,
    btn: {id: 'deliveries_and_pickups', icon: 'lm-icon icon-box-approved', textOnly: true, color: "#ccc", activeColor: '#00AEBA'} ,
    data: {
        labels: [],
        datasets: [
            {
                label: 'DELIVERIES',
                backgroundColor: ['#00AEBA'],
                data: [],
                order: 1,
                // barPercentage: 0.5,
                // categoryPercentage: 0.5,
                stack: 'deliveries'
            },
            {
                label: 'TOTAL_DELIVERIES',
                backgroundColor: ['#BCDDE0'],
                data: [],
                order: 2,
                // barPercentage: 0.5,
                // categoryPercentage: 0.5,
                stack: 'deliveries'
            },
            {
                label: 'PICKUPS',
                backgroundColor: ['#0AB14A'],
                data: [],
                order: 1,
                // barPercentage: 0.5,
                // categoryPercentage: 0.5,
                stack: 'pickups'
            },
            {
                label: 'TOTAL_PICKUPS',
                backgroundColor: ['#D4E5C6'],
                data: [],
                order: 2,
                // barPercentage: 0.5,
                // categoryPercentage: 0.5,
                stack: 'pickups'
            },
            {
                label: 'LIVE_TRACKING',
                type: 'line',
                data: [],
                borderColor: '#c74c96',
                borderWidth: 1,
                pointBackgroundColor: '#c74c96',
                fill: false,
                spanGaps: false,
                order: 0,
                stack: 'tracking'
            }
        ]
    },
    filters: [],
    chartOptions: {
        scales: {
            x: {
                stacked: true
            },
            y: {  // Προσθήκη stacking και στον y άξονα
                stacked: false
            }
        },
        datasets:{
            bar:{
                borderSkipped: 'bottom'
            }
        },
        plugins:{
            legend:{
                display: true,
                position: 'bottom',
                align: 'start',
                labels:{
                    padding: 20,
                    usePointStyle: true,
                    pointStyle: 'circle',
                    color: '#999',
                    font:{
                        size: 12
                    }
                }
            }
        },
    },
    chartType: 'bar'
};

export const ON_TIME: ILmChartWidgetTab = {
    id: 'on_time_deliveries',
    title: 'Shipments',
    subtitle: 'ON_TIME_DELIVERIES',
    pageSize: pagesize,
    btn: {id: 'on_time_deliveries', icon: 'lm-icon icon-timecheck', textOnly: true, color: "#ccc", activeColor: '#ba6ef5'},
    data: {
        labels: [],
        datasets: [
            { 
                backgroundColor: ['#ba6ef5'],
                data: null,
            }
        ]
    },
    chartType: 'bar'
}

export const FIRST_ATTEMPT_SUCCESS_RATE: ILmChartWidgetTab = {
    id: 'first_attempt_success_rate',
    title: 'Shipments',
    subtitle: 'FIRST_TIME',
    pageSize: pagesize,
    btn: {id: 'first_attempt_success_rate', icon: 'lm-icon icon-one', textOnly: true, color: "#ccc", activeColor: '#00aeba'},
    data: {
        labels: [],
        datasets: [
            { 
                backgroundColor: ['#00aeba'],
                data: null,
            }
        ]
    },
    chartType: 'bar'
}

export const TOTAL_DISTANCE: ILmChartWidgetTab = {
    id: 'total_miles',
    title: 'Shipments',
    subtitle: 'TOTAL_DISTANCE',
    pageSize: pagesize,
    btn: {id: 'total_miles', icon: 'lm-icon icon-road', textOnly: true, color: "#ccc", activeColor: '#666b7d'},
    data: {
        labels: [],
        datasets: [
            { 
                backgroundColor: ['#666b7d'],
                data: null,
            }
        ]
    },
    chartType: 'bar'
}

export const CO2_EMISSSIONS: ILmChartWidgetTab = {
    id: 'total_co2_emissions',
    title: 'Shipments',
    subtitle: 'EMISSIONS',
    pageSize: pagesize,
    btn: {id: 'total_co2_emissions', icon: 'lm-icon icon-leaf', textOnly: true, color: "#ccc", activeColor: '#27ab83'},
    data: {
        labels: [],
        datasets: [
            { 
                backgroundColor: ['#27ab83'],
                data: null,
            }
        ]
    },
    chartType: 'bar'
}