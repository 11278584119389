import { chartImageAnnotation, chartAnnotationY, chartBarThicknessOffset } from "@app/shared/utils";
import { BubbleDataPoint, Chart, ChartData, ChartDataset, ChartOptions, ChartTypeRegistry, ScatterDataPoint } from "chart.js";
import { AnnotationOptions, PartialEventContext } from "chartjs-plugin-annotation";


export const LM_BAR_CHART_BASE_OPTIONS: ChartOptions = {
  responsive: false,
    datasets:{
        bar:{
            barThickness: 'flex',
            maxBarThickness: 40,
        }
    },
    elements:{
        bar:{
            borderRadius: 40,
            borderSkipped: false
        }
    },
    plugins:{
        legend:{
            display: false,
            labels:{
              padding: 20,
              usePointStyle: true,
              pointStyle: 'circle',
              color: '#999',
              font:{
                  size: 12
              }
          }
        },
        tooltip: {
          enabled: true
        }
    },
    scales: {
        y: {
            beginAtZero: true,
            ticks: {
              color: '#ccc',
            },
            grid: {
                color: '#f6f6f6'
            },
        },
        x: {
            grid: {
                display: false,
            },
            ticks: {
              color: '#ccc',
            }
        }
    }
}

export const LM_BAR_CHART_HEART_OPTIONS: ChartOptions = {
  scales: {
      y: {
          beginAtZero: true,
          min: 0,
          max: 5,
          ticks: {
              stepSize: 1,
              callback: function(value) {
                  return value + ' ❤';
              }
          }
      },
      x: {
          grid: {
              display: false
          }
      }
  }
};

export const LM_BAR_CHART_RATING_OPTIONS: ChartOptions = {
  scales: {
      y: {
          beginAtZero: true,
          min: 0,
          max: 5,
          ticks: {
              stepSize: 1,
              callback: function(value) {
                  return value + '★';
              }
          }
      },
      x: {
          grid: {
              display: false
          }
      }
  }
};

export const LM_BAR_CHART_MONEY_OPTIONS: ChartOptions = {
  scales: {
      y: {
          beginAtZero: true,
          ticks: {
              stepSize: 1,
              callback: function(value) {
                  return value + '€';
              }
          }
      },
      x: {
          grid: {
              display: false
          }
      }
  }
};

export const LM_PIE_CHART_BASE_OPTIONS: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins:{
      legend:{
          display: false,
          position: 'top'
      },
      tooltip: {
        enabled: true
      }
  }
}

export const LM_DOUGHNUT_CHART_BASE_OPTIONS: ChartOptions<'doughnut'> = {
  cutout: '65%',
  plugins:{
      legend:{
          display: false
      },
      tooltip: {
        enabled: true
      }
  }
}


export function lmChartMultilineLabels(_chart:Chart):void{
    _chart.data.labels.forEach((l:any,i,a) => {
        if(/(\s)/.test(l)) a[i] = l.split(l.indexOf(/(\s)/.test(l))) 
    })
}

export function lmVerticalLabelAnnotation(label): AnnotationOptions{
  return {
    type: 'label',
    // drawTime: 'afterDatasetsDraw',
    padding: {left:1, bottom: 10},
    content: label,
    height:7,
    // content: ({chart:{data:{datasets, labels}}}) => `${label}:   ${datasets[0].data[labels.indexOf(label)]}%`,
    yValue: label,
    xValue: 0,
    position: { y: 'end', x: '0%' }
  }
}


export function lmAvatarAnnotation(label, img): AnnotationOptions{
  return {
    type: 'label',
    drawTime: 'afterDatasetsDraw',
    // init: true,
    padding: 5,
    // borderWidth: 4,
    // borderColor: 'rgba(255,255,255,.3)',
    borderRadius: 30,
    content: (e:PartialEventContext) => chartImageAnnotation(e, img),
    position: { x: 'center', y: 'end' },
    xValue: label,
    yValue: (e:PartialEventContext, anno) =>  chartAnnotationY(e, anno),
    // yAdjust: (e) => chartBarThicknessOffset(e, 1)
  }
}

export const LM_STACKED_CHART_BORDER_RADIUS = {
  topLeft: 0,
  topRight: 0,
  bottomLeft: 100,
  bottomRight: 100,
}

