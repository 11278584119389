// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        lm-semicircle-chart{width: 100%; max-width:300px}
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlbWljaXJjbGVjaGFydC13aWRnZXQuY29tcG9uZW50LnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7UUFDUSxvQkFBb0IsV0FBVyxFQUFFLGVBQWUiLCJmaWxlIjoic2VtaWNpcmNsZWNoYXJ0LXdpZGdldC5jb21wb25lbnQudHMiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgICAgICAgbG0tc2VtaWNpcmNsZS1jaGFydHt3aWR0aDogMTAwJTsgbWF4LXdpZHRoOjMwMHB4fVxuICAgICJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/structure/widgets/semicirclechart-widget.component.ts"],"names":[],"mappings":";QACQ,oBAAoB,WAAW,EAAE,eAAe;;AAExD,gYAAgY","sourcesContent":["\n        lm-semicircle-chart{width: 100%; max-width:300px}\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
