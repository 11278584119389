import { LM_BAR_CHART_HEART_OPTIONS, LM_BAR_CHART_MONEY_OPTIONS, LM_BAR_CHART_RATING_OPTIONS, LM_STACKED_CHART_BORDER_RADIUS, lmAvatarAnnotation } from "@app/model/charts-config";
import { ILmChartWidgetTab } from "@app/model/widget";
import { chartAvatar1,  chartAvatar2, chartAvatar3, chartAvatar4, chartAvatar5 } from '@app/dashboard-report/dashboard-report-dummy-imgs';
import { ChartOptions } from "chart.js";

const mainPalette = ['#741b47', '#b45f06', '#274e13', '#351c75', '#0b5393', '#06aeba'];

const mainData = {
    labels: [],
    datasets: [
        {
            backgroundColor: mainPalette,
            data: []
        }
    ]
};
const mainChartOptions:ChartOptions= {
    // ...LM_BAR_CHART_RATING_OPTIONS,
    plugins:{
        annotation:{
            clip: false,
            annotations:undefined
        }
    }
};
const defaultPageSize = 14;

export const DRIVER_PRODUCTIVITY: ILmChartWidgetTab = {
    id: 'productivity',
    title: 'Drivers',
    subtitle: 'PRODUCTIVITY',
    btn:{id: 'productivity', icon: 'lm-icon icon-star', textOnly: true, color: "#ccc", activeColor: '#ffc107', active: true},
    data: mainData,
    chartType: 'bar',
    tabChartType: 'productivity',
    currentPage: 0,
    pageSize: defaultPageSize,
    chartOptions: {...mainChartOptions, 
        datasets:{
            bar:{
                barThickness: 40
            }
        },
        scales:{
            y:{
                grace:'60%',
                beginAtZero: true,
                min: 0,
                max: 5,
                ticks:{
                    callback: (tickValue, index, ticks) =>{
                        return index < mainPalette.length ? tickValue + '★' : '';
                    },
                }
            }
        }
    },
};

export const DRIVER_VISITED_STOPS_PER_HOUR: ILmChartWidgetTab = {
    id: 'visited_per_hour',
    title: 'Drivers',
    subtitle: 'SHIPMENTS_PER_HOUR',
    btn:{id: 'visited_per_hour', icon: 'lm-icon icon-map', textOnly: true, color: "#ccc", activeColor: '#ba6ef5'},
    data: mainData,
    chartType: 'bar',
    tabChartType: 'visited_per_hour',
    currentPage: 0,
    pageSize: defaultPageSize,
    chartOptions: {...mainChartOptions, 
        datasets:{
            bar:{
                barThickness: 40
            }
        },
        scales:{
            y:{
                grace:'60%',
            }
        }
    }
};

export const DRIVER_PACKAGES: ILmChartWidgetTab = {
    id: 'total_packages',
    title: 'Drivers',
    subtitle: 'PACKAGES',
    btn:{id: 'total_packages', icon: 'lm-icon icon-box', textOnly: true, color: "#ccc", activeColor: '#de921f'},
    data: mainData,
    chartType: 'bar',
    tabChartType: 'total_packages',
    currentPage: 0,
    pageSize: defaultPageSize,
    chartOptions: {...mainChartOptions, 
        datasets:{
            bar:{
                barThickness: 40
            }
        },
        scales:{
            y:{
                grace:'60%',
            }
        }
    }
};


export const DRIVER_COST_PER_SHIPMENT: ILmChartWidgetTab = {
    id: 'cost_per_shipment',
    title: 'Drivers',
    subtitle: 'COST_PER_SHIPMENT',
    btn:{id: 'cost_per_shipment', icon: 'lm-icon icon-dollar-coin', textOnly: true, color: "#ccc", activeColor: '#1c90d4'},
    data: mainData,
    chartType: 'bar',
    tabChartType: 'cost_per_shipment',
    currentPage: 0,
    pageSize: defaultPageSize,
    chartOptions: {...mainChartOptions, 
        datasets:{
            bar:{
                barThickness: 40
            }
        },
        scales:{
            y:{
                grace:'60%',
            }
        }
    }
};

export const DRIVER_RECIPIENT_EXPERIENCE: ILmChartWidgetTab = {
    id: 'rating_count',
    title: 'Drivers',
    subtitle: 'CUSTOMER_EXPERIENCE',
    btn:{id: 'rating_count', icon: 'lm-icon icon-heart', textOnly: true, color: "#ccc", activeColor: '#ba6ef5'},
    data: mainData,
    chartType: 'bar',
    tabChartType: 'rating_count',
    currentPage: 0,
    pageSize: defaultPageSize,
    chartOptions: {...mainChartOptions, 
        datasets:{
            bar:{
                barThickness: 40
            }
        },
        scales:{
        y:{
            grace:'60%',
            beginAtZero: true,
            min: 0,
            max: 5,
            ticks:{
                callback: (tickValue, index, ticks) =>{
                    return index < mainPalette.length ? tickValue + ' ❤' : '';
                },
            }
        }
    }},
};

// export const DRIVER_PRODUCTIVITY: ILmChartWidgetTab = {
//     id: 'productivity_rating',
//     title: 'Drivers',
//     subtitle: 'PRODUCTIVITY',
//     btn:{id: 'productivity_rating', icon: 'lm-icon icon-star', textOnly: true, color: "#ccc", activeColor: '#ffc107', active: true},
//     data: mainData,
//     chartType: 'bar',
//     tabChartType: 'productivity',
//     chartOptions: {...mainChartOptions, 
//         datasets:{
//             bar:{
//                 barThickness: 40
//             }
//         },
//         scales:{
//             y:{
//                 grace:'60%',
//                 beginAtZero: true,
//                 min: 0,
//                 max: 5,
//                 ticks:{
//                     callback: (tickValue, index, ticks) =>{
//                         return index < mainPalette.length ? tickValue + '★' : '';
//                     },
//                 }
//             }
//         }
//     },
// };

// export const DRIVER_VISITED_STOPS_PER_HOUR: ILmChartWidgetTab = {
//     id: 'visited_completed_stops_count',
//     title: 'Drivers',
//     subtitle: 'SHIPMENTS_PER_HOUR',
//     btn:{id: 'visited_completed_stops_count', icon: 'lm-icon icon-map', textOnly: true, color: "#ccc", activeColor: '#ba6ef5'},
//     data: mainData,
//     chartType: 'bar',
//     tabChartType: 'visited_per_hour',
//     chartOptions: {...mainChartOptions, 
//         datasets:{
//             bar:{
//                 barThickness: 40
//             }
//         },
//         scales:{
//             y:{
//                 grace:'60%',
//             }
//         }
//     }
// };

// export const DRIVER_PACKAGES: ILmChartWidgetTab = {
//     id: 'total_packages',
//     title: 'Drivers',
//     subtitle: 'PACKAGES',
//     btn:{id: 'total_packages', icon: 'lm-icon icon-box', textOnly: true, color: "#ccc", activeColor: '#de921f'},
//     data: mainData,
//     chartType: 'bar',
//     tabChartType: 'total_packages',
//     chartOptions: {...mainChartOptions, 
//         datasets:{
//             bar:{
//                 barThickness: 40
//             }
//         },
//         scales:{
//             y:{
//                 grace:'60%',
//             }
//         }
//     }
// };


// export const DRIVER_COST_PER_SHIPMENT: ILmChartWidgetTab = {
//     id: 'cost',
//     title: 'Drivers',
//     subtitle: 'COST_PER_SHIPMENT',
//     btn:{id: 'cost', icon: 'lm-icon icon-dollar-coin', textOnly: true, color: "#ccc", activeColor: '#1c90d4'},
//     data: mainData,
//     chartType: 'bar',
//     tabChartType: 'cost_per_shipment',
//     chartOptions: {...mainChartOptions, 
//         datasets:{
//             bar:{
//                 barThickness: 40
//             }
//         },
//         scales:{
//             y:{
//                 grace:'60%',
//             }
//         }
//     }
// };

// export const DRIVER_RECIPIENT_EXPERIENCE: ILmChartWidgetTab = {
//     id: 'recipient_experience',
//     title: 'Drivers',
//     subtitle: 'CUSTOMER_EXPERIENCE',
//     btn:{id: 'recipient_experience', icon: 'lm-icon icon-heart', textOnly: true, color: "#ccc", activeColor: '#ba6ef5'},
//     data: mainData,
//     chartType: 'bar',
//     tabChartType: 'rating_count',
//     chartOptions: {...mainChartOptions, 
//         datasets:{
//             bar:{
//                 barThickness: 40
//             }
//         },
//         scales:{
//         y:{
//             grace:'60%',
//             beginAtZero: true,
//             min: 0,
//             max: 5,
//             ticks:{
//                 callback: (tickValue, index, ticks) =>{
//                     return index < mainPalette.length ? tickValue + ' ❤' : '';
//                 },
//             }
//         }
//     }},
// };
