import { Component, OnInit } from '@angular/core';
import { Globals } from '@app/services/globals';
import { Router } from '@angular/router';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

    constructor(
        public globals: Globals,
        public router: Router
    ) {
    }

    ngOnInit() {
        if(this.globals.companyIsPudo) return this.router.navigate(['pudo-app'])
        if (this.globals.collaboratorModeEnabled) this.router.navigate(['shipperDashboard']);
        else this.router.navigate(['dashboard']);
    }
}
