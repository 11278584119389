import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { LmDialogContext } from "@app/model/dialog";
import { IDriverDataDto } from "@app/api/models/drivers-dto";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { take, tap } from "rxjs/operators";
import * as moment from "moment-timezone";
import { LmDialogComponent } from "@app/shared/containers/dialog/dialog.component";
import { IAddressDto } from "../../api/models/address-dto";
import { LmButtonConfig } from "@app/shared/structure/button/button.component";
import { SelectMapperService } from "@app/core/services/select-mapper.service";
import { AddressService } from "@app/services/address.service";
import { FormErrorsUtils } from "@app/utils/form-errors-utils";
import { getEnumInfoValues } from "@app/core/enums-utils";
import { of } from "rxjs";
import { LmProjectProblemService } from "@app/api/services/project-problem.service";
import { IProjectProblemDto } from "@app/api/models/project-problem-dto";
import { Router } from "@angular/router";


@UntilDestroy()
@Component({
  templateUrl: "project-problem-modal.component.html",
  styleUrls: ["./project-problem-modal.component.scss"],
  providers: [],
})
export class ProjectProblemModalFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(LmDialogComponent) dialog: LmDialogComponent;
  globals;
  isInProject = false;
  saveButton: LmButtonConfig;
  defaultDepartureTime = '08:00';

  id: number;
  projectId: number;
  title: string;
  departureTime: string;
  departureDate: string;

  constructor(
    public config: LmDialogContext<any>,
    private service: LmProjectProblemService,
    public selectMapperSvc: SelectMapperService,
    public router: Router,
  ) {
    const {
      data: { globals, values },
    } = config;
    this.globals = globals;


    this.saveButton = {
      command: () => this.save(),
    };
    this.saveButton.color = "#00aeba";

    this.loadDefaultValues();
    if (values) {
      this.loadData(values);
    }
  }

  loadDefaultValues() {
    this.id = undefined;
    this.projectId = undefined;
    this.title = "";
    this.departureTime = this.defaultDepartureTime;
    this.departureDate = moment().add(1,'days').format('DD/MM/YYYY');
    this.updateButtons();
  }

  private isFormValid(): boolean {
    return Boolean(this.departureTime && this.departureDate);
  }

  private updateButtons() {
    setTimeout(() => {
      this.saveButton.disabled = !this.isFormValid();
    }, 100);
  }

  private loadData(data) {
    setTimeout(() => {
      this.projectId = Number(data.projectId);
    }, 100);
    this.departureTime = moment(data.departure_time).format('HH:mm');

    this.updateButtons();
  }

  private provideModel(comp: ProjectProblemModalFormComponent): IProjectProblemDto {
    const unformattedDatetime = moment(comp.departureDate, 'DD/MM/YYYY').format('YYYY-MM-DD') + ' ' + moment(comp.departureTime, 'HH:mm').format('HH:mm');
    const data = {
      id: comp.id,
      projectId: comp.projectId,
      title: comp.title ?? null,
      departure_datetime: moment(unformattedDatetime, 'YYYY-MM-DD HH:mm').utc().format().replace('Z', '+00:00'),
    };
    return data;
  }

  onValueChanged() {}

  beforeSave() {
    
  }

  afterSave(response) {
    this.config.close();
    this.router.navigateByUrl('/projects/projectView/' + response.item.projectProblem.id);
  }

  save() {
    this.service
      .apiProjectProblemPost({
        body: {
          project: {
            problem: this.provideModel(this)
          }
        },
        id: this.projectId.toString(),
      })
      .pipe(take(1), untilDestroyed(this))
      .subscribe((res) => {
        this.afterSave(res);
      });
  }

  ngOnInit() {
    this.isInProject = !this.globals.isInRoute('dashboard');
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {}
  ok = () => {};
}
