import { AfterContentInit, AfterViewInit, Directive, EventEmitter, HostBinding, Injector, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { LmButtonConfig } from "../button/button.component";
import { LmNotificationService } from "@app/core/services/notification.service";
import { Chart, ChartData, ChartDataset, ChartOptions, ChartType, DatasetController, Plugin, PluginChartOptions } from "chart.js";
import { ILmBaseWidgetTab, ILmChartWidgetFilterConfig, ILmChartWidgetTab, ILmWidgetFilterConfig, LmWidgetViewType } from "@app/model/widget";
import { LmRadioComponent } from "@app/shared/controls/input/radio/radio.component";
import { LmCheckboxComponent } from "@app/shared/controls/input/checkbox/checkbox.component";
import { LmSwitchComponent } from "@app/shared/controls/input/switch/switch.component";
import { LmWidgetHeaderComponent } from "../header/lm-widget-header.component";
import { Observable, of } from "rxjs";


// export interface LmBaseWidgetComponent{
//     getTab?: (...args) => Observable<any>;
//     // getTabCb: (...args) => Observable<any>;
// }

@Directive()
export abstract class LmBaseWidgetComponent implements OnInit, AfterViewInit, OnDestroy{
    id: string;
    @Input() VM?: any;
    @Input() data?: any;
    @Input() value?: any;
    @Input() title?: string;
    @Input() titleIcon?: string;
    @Input() subtitle?: string;
    @Input() styles?: any;
    @Input() cssClass?: string;
    @Input() tabconfig?: ILmBaseWidgetTab[];
    @Input() tabs?: LmButtonConfig[];
    @Input() activeTab?: ILmBaseWidgetTab;
    @Input() hideTabs = false;
    @Input() actions?:  LmButtonConfig[];
    @Input() pagination?: boolean;
    @Input() pages?: any;
    @Input() currentPage?: number;
    @Input() pageSize?:number;
    @Input() pageCount?: number;
    @Input() isServerSide?: boolean;
    @Input() tabChartType?: string;
    @Input() loading: boolean;
    @Input() dataless?: boolean;
    @Input() headerTpl?: TemplateRef<unknown>;
    @Input() bodyTpl?: TemplateRef<unknown>;
    @Input() footerTpl?: TemplateRef<unknown>;
    @Input() filtering?: boolean;
    @Input() filters?: ILmWidgetFilterConfig[];
    @Input() hideFilters = false;
    @Input() getChartFn$: (...args) => Observable<any>;
    applyFilter: (...args) => void;

    @ViewChild(LmWidgetHeaderComponent) widgetHeader: LmWidgetHeaderComponent;
    @Output() tabSwitched = new EventEmitter<boolean>();
    
    protected abstract initCb: (...args) => void | any;
    protected abstract switchTabCb: (...args) => void | any;
    protected abstract pageChangeCb: (e) => void | any; 
    protected notificationSvc: LmNotificationService;

    constructor(protected injector: Injector) {
        this.notificationSvc = injector.get(LmNotificationService);

        this.applyFilter = (filter) => {
            this.data = filter.on ? filter.data : filter.initialData;
        }
    }

    setHeader(tab: ILmBaseWidgetTab) {
        this.subtitle = tab.subtitle;
        this.title = tab.title;
    }

    setPagination(tab: ILmBaseWidgetTab) {
        const {tabChartType, pageSize, currentPage, pageCount} = tab;
        if(tabChartType){
            this.tabChartType = tabChartType;
            this.pageSize = pageSize ?? 14;
            this.currentPage = currentPage;
            this.pageCount = pageCount;
        }
    }

    onPageChange(e){
        if(this.pageChangeCb) this.pageChangeCb(e);
    }


    canSwitch(desicion){
        this.tabs.forEach(tab => tab.disabled = !desicion)
    }

    configureFilters(tab){
        const {filters} = tab;
        this.filtering = filters && !!filters.length;
        if(!this.filtering) return this.filters = null;
        this.filters = filters.map(filter => ({...filter, initialData: this.data}));
    }

    switch(tab:ILmBaseWidgetTab){
        const {id:_id, tabChartType, pageSize, currentPage, pageCount} = tab;

        this.tabs.forEach(tab => tab.active = tab.id === _id ? true : false);
        this.activeTab = this.tabconfig.find(({id}) => id === _id);

        this.title = this.activeTab.title;
        this.subtitle = this.activeTab.subtitle;
        if(tabChartType){
            this.tabChartType = tabChartType;
            this.pageSize = pageSize ?? 14;
            this.currentPage = currentPage;
            this.pageCount = pageCount;
        }
        this.switchTabCb(tab);
        this.configureFilters(tab);
    }


    ngOnInit(): void {
        this.loading = true;
        if(this.tabconfig && !!this.tabconfig.length){
            this.tabs = this.tabconfig.map(tab => ({...tab.btn, command: () => this.switch(tab)}));
            this.switch(this.tabconfig[0]);
        }
        this.initCb();
    }

    ngAfterViewInit(): void {}
    ngOnDestroy(): void {}
}


@Directive()
    export abstract class LmChartWidgetComponent extends LmBaseWidgetComponent implements OnInit, AfterViewInit, OnDestroy{
    @Input() data: any;
    @Input() dataless?: boolean;
    @Input() chartOptions?: ChartOptions;
    @Input() chartPlugins?: Plugin | Plugin[] | any;
    @Input() chartType?: string;
    @Input() activeTab?: ILmChartWidgetTab;
    @Input() pages?: ChartData | any;

    protected initCb = this.init.bind(this);
    protected switchTabCb = this.switchTab.bind(this);
    protected pageChangeCb = this.pageChange.bind(this);
    
    constructor(protected injector: Injector) {
        super(injector);
    }

    init(){
        if(this.activeTab) {
            this.setChart(this.activeTab);
            this.chartOptions = this.activeTab.chartOptions;
            this.configureFilters(this.activeTab);
        }
    }

    pageChange(e?){
        const {page} = e;
        this.data = this.pages[page];
    }

    switchTab(tab:ILmChartWidgetTab){
        this.setHeader(tab);
        this.setChart(tab);
    }

    setChart(tab){
        const {pages} = tab;
        this.pagination = pages && !!pages.length;
        this.pages = pages;
        this.data = this.pagination ? this.pages[0] : tab.data;
        this.dataless = !this.data?.labels || !this.data?.labels?.length;
        this.chartType = tab.chartType;
        if(this.loading) this.loading = false;
    }
    
    ngOnInit() {
        super.ngOnInit();
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
}

@Directive()
export abstract class LmSSChartWidgetComponent extends LmChartWidgetComponent implements OnInit, AfterViewInit, OnDestroy{
    @Input() activeTab?: ILmChartWidgetTab;
    
    constructor(protected injector: Injector) {
        super(injector);
    }

    init(){
        if(this.activeTab) {
            this.setChart(this.activeTab);
            this.chartOptions = this.activeTab.chartOptions;
            this.configureFilters(this.activeTab);
        }
    }

    ngOnInit() {
        super.ngOnInit();
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
}


@Directive()
export abstract class LmHybridWidgetComponent extends LmChartWidgetComponent implements OnInit, AfterViewInit, OnDestroy{
    viewType:LmWidgetViewType;
    
    constructor(protected injector: Injector) {
        super(injector);
    }

    setValue(tab){
        this.value = tab.value;
        this.chartOptions = tab.chartOptions;
    }
    
    ngOnInit() {
        super.ngOnInit();
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
}