import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StopPointPickupServiceService {

  private submitDeliveryFormListeners = new Subject<any>();
  private loadDeliveryFormListeners = new Subject<any>();

  submitDeliveryFormListen(): Observable<any> {
    return this.submitDeliveryFormListeners.asObservable();
  }

  loadDeliveryFormListen(): Observable<any> {
    return this.loadDeliveryFormListeners.asObservable();
  }

  submitDeliveryForm(id, load, pricelistServiceType) {
    this.submitDeliveryFormListeners.next({
      id: id,
      load: load,
      pricelistServiceType: pricelistServiceType,
    });
  }

  loadDeliveryForm(id, projectProblemId) {
    this.loadDeliveryFormListeners.next({
      id: id,
      projectProblemId: projectProblemId
    });
  }

}
