import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { DataService } from '@app/services/data.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { GridsService } from '@app/services/grids.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { take } from 'rxjs/operators';
import * as libphonenumber from 'libphonenumber-js';

@Component({
  selector: 'app-wizard-drivers',
  templateUrl: './wizard-drivers.component.html',
  styleUrls: ['./wizard-drivers.component.scss']
})
export class WizardDriversComponent implements OnInit {
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;

  listen = [];

  vehicleTypes = [];

  bicycleLabel = '';
  scooterLabel = '';
  carLabel = '';
  vanLabel = '';
  largeVanLabel = '';
  truckLabel = '';

  columnDefs;
  gridApi;
  gridColumnApi;
  domLayout = 'autoHeight';
  driversDataArray = [];
  rowData: any;

  listenUpdateDriverGrid;
  nameTitle;
  phoneTitle;
  vehicleTitle;
  departureTitle;

  vehicles = [];
  myForm: FormGroup;
  errors;
  data;
  driverId = null;
  name: String = '';
  countryPrefix = '';
  phoneNumber = '';
  telephone = '';
  defaultVehicleId = null;
  dailyWorkingHours = null;
  dailyWorkingHoursUnformatted = null;
  usualDepartureDatetimeUnformatted = '';
  usualDepartureDatetime = '';
  usualDepartureTimezone = '';
  stops = null;
  kilometers: String = '';
  status = 1;
  isClickedOnce = false;
  addressTerm: String = '';
  addressType: String = 'houseNumber';
  freeformAddress = 'Dummy text';
  country: String = '';
  startDepotId;
  endDepotId;
  portalAccess = false;
  portalSettingsConfirmMsg;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    formBuilder: FormBuilder,
    private dataService: DataService,
    private projectProblemDataService: ProjectProblemDataService,
    public gridsService: GridsService,
    private sanitizer: DomSanitizer,
  ) {
    this.myForm = formBuilder.group({
      'countryPrefix': [this.countryPrefix],
      'phoneNumber': [this.phoneNumber],
      'driver': formBuilder.group({
        'id': [this.driverId],
        'daily_working_hours': [this.dailyWorkingHours],
        'daily_working_hours_unformatted': [this.dailyWorkingHoursUnformatted],
        'default_vehicle_id': [this.defaultVehicleId],
        'usual_departure_datetime': [this.usualDepartureDatetime],
        'usual_departure_datetime_unformatted': [this.usualDepartureDatetimeUnformatted],
        'usual_departure_datetime_timezone': [this.usualDepartureTimezone],
        'portal_access_sw': [this.portalAccess],
        'projectsIds': [[]],
      }),
      'userProfile': formBuilder.group({
        'name': [this.name, Validators.required],
        'telephone': [this.telephone],
      }),
      'driverLocations': formBuilder.group({
        'companyDepot': formBuilder.group({
          'modelName': 'CompanyDepot',
          'modelId': [this.startDepotId],
        }),
        'locationStart': formBuilder.group({
          'modelName': 'CompanyDepot',
          'modelId': [this.startDepotId],
        }),
        'locationEnd': formBuilder.group({
          'modelName': 'CompanyDepot',
          'modelId': [this.endDepotId],
        }),
      }),
    });
  }

  cancelRenderer(params) {
    return '<i class="fas fa-times"></i>';
  }

  updateGrid() {
    this.setDriversGridData();
    this.getAvailableVehicles();
  }

  public setDriversGridData() {
    const self = this;
    this.http.get('api/internal/v2/drivers').pipe(take(1)).subscribe(response => {
      self.driversDataArray = [];
      let make, model, gridObject = {};
      if (response['items'].length) {
        response['items'].forEach(driverData => {
          const driver = driverData.driver;
          const userProfile = driverData.userProfile;
          let typeLabel = '';
          if (this.vehicleTypes[driverData.vehicle.vehicle_type]) { typeLabel = this.vehicleTypes[driverData.vehicle.vehicle_type].typeName; }
          gridObject = {
            id: driver.id,
            name: userProfile.name,
            phone: userProfile.telephone,
            typeLabel: typeLabel,
            departure: moment(driver.usual_departure_datetime).format('HH:mm'),
          };
          self.driversDataArray.push(gridObject);
        });
      } else {
        const noData = { noDataText: 'No data' }
        self.driversDataArray.push(noData);
      }
      self.rowData = of(self.driversDataArray);

    },
      (error) => {
        console.error(error);
      });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  cellClicked(event) {
    if (event.column.colId.includes('delete')) {
      this.deleteDriver(event.data.id);
    } else {
      if (!event.data.noDataText) {
        this.getFormData(event.data.id);
      }
    }
  }

  deleteDriver(id) {
    const myObserver = {
      next: (response) => {
        this.updateGrid();
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => { },
    };
    this.http.delete('api/v1/drivers/' + id).pipe(take(1)).subscribe(myObserver);
  }

  public getFormData(id) {
    this.http.get('api/internal/v2/drivers/' + id).pipe(take(1)).subscribe(response => {
      this.data = response['item'];
      const driver = this.data.driver;
      const userProfile = this.data.userProfile;
      this.driverId = driver.id;
      this.dailyWorkingHours = driver.daily_working_hours;
      this.dailyWorkingHoursUnformatted = moment.duration(driver.daily_working_hours).asHours();
      this.usualDepartureDatetime = driver.usual_departure_datetime;
      this.usualDepartureDatetimeUnformatted = moment(this.usualDepartureDatetime).format('HH:mm');
      this.usualDepartureTimezone = driver.usual_departure_datetime_timezone;
      this.name = userProfile.name;
      this.telephone = userProfile.telephone;
    
      if (this.telephone === 'n/a') { this.telephone = ''; }
      if (this.telephone) {
        this.phoneNumber = this.telephone;
        if (this.telephone.length > 5) {
          const phoneObj = libphonenumber.parsePhoneNumber(this.telephone);
          this.phoneNumber = phoneObj.nationalNumber;
          this.countryPrefix = '+' + phoneObj.countryCallingCode;
        }
      }
      const vehicle = this.data.vehicle;
      if (vehicle.id) {
        this.defaultVehicleId = vehicle.id;
      } else {
        this.defaultVehicleId = null;
      }
      this.portalAccess = this.data.driver.portal_access_sw;
      this.setForm();
    });
  }

  patchPortalValues() {
    this.myForm.patchValue({
      'driver': {
        'portal_access_sw': this.portalAccess,
      },
    });
  }

  patchForSubmit() {
    this.myForm.patchValue({
      'driver': {
        'id': this.driverId,
        'daily_working_hours': this.dailyWorkingHours,
        'usual_departure_datetime': this.usualDepartureDatetime,
        'usual_departure_datetime_timezone': this.usualDepartureTimezone,
      },
      'userProfile': {
        'telephone': this.telephone,
      },
      'driverLocations': {
        'companyDepot': {
          'modelName': 'CompanyDepot',
          'modelId': this.startDepotId,
        },
        'locationStart': {
          'modelName': 'CompanyDepot',
          'modelId': this.startDepotId,
        },
        'locationEnd': {
          'modelName': 'CompanyDepot',
          'modelId': this.endDepotId,
        },
      },
    });
    M.updateTextFields();
  }

  setForm() {
    this.myForm.setValue({
      'countryPrefix': this.countryPrefix,
      'phoneNumber': this.phoneNumber,
      'driver': {
        'id': this.driverId,
        'default_vehicle_id': this.defaultVehicleId,
        'daily_working_hours': this.dailyWorkingHours,
        'daily_working_hours_unformatted': this.dailyWorkingHoursUnformatted,
        'usual_departure_datetime': this.usualDepartureDatetime,
        'usual_departure_datetime_unformatted': this.usualDepartureDatetimeUnformatted,
        'usual_departure_datetime_timezone': this.usualDepartureTimezone,
        'portal_access_sw': this.portalAccess,
        'projectsIds': [],
      },
      'userProfile': {
        'name': this.name,
        'telephone': this.telephone,
      },
      'driverLocations': {
        'companyDepot': {
          'modelName': 'CompanyDepot',
          'modelId': this.startDepotId,
        },
        'locationStart': {
          'modelName': 'CompanyDepot',
          'modelId': this.startDepotId,
        },
        'locationEnd': {
          'modelName': 'CompanyDepot',
          'modelId': this.endDepotId,
        },
      },
    });
    M.updateTextFields();
  }

  resetForm() {
    this.errors = [];
    this.driverId = null;
    this.name = '';
    this.countryPrefix = this.globals.defaultCountryCode;
    this.phoneNumber = '';
    this.telephone = '';
    this.defaultVehicleId = null;
    this.dailyWorkingHours = this.globals.defaultWorkingHours;
    this.dailyWorkingHoursUnformatted = null;
    if (this.dailyWorkingHours) {
      this.dailyWorkingHoursUnformatted = moment.duration(this.dailyWorkingHours).asHours();
    }
    this.usualDepartureDatetime = this.globals.defaultDepartureDateTime;
    this.usualDepartureDatetimeUnformatted = '';
    if (this.usualDepartureDatetime) {
      this.usualDepartureDatetimeUnformatted = moment(this.usualDepartureDatetime).format('HH:mm');
    }
    this.usualDepartureTimezone = moment.tz.guess();
    this.status = 1;
    this.portalAccess = this.globals.defaultPortalAccess ? true : false;
    if (this.globals.depotId) {
      if (!this.endDepotId) { this.endDepotId = this.globals.depotId; }
      if (!this.startDepotId) { this.startDepotId = this.globals.depotId; }
    } else {
      this.endDepotId = null;
      this.startDepotId = null;
    }
    this.setForm();
    this.myForm.markAsUntouched();
    this.myForm.markAsPristine();
  }

  initializeSelect() {
    const select = document.querySelectorAll('select');
    const instancesSelect = M.FormSelect.init(select);
    const dateElement = document.querySelectorAll('.datepicker');
    const dateInstances = M.Datepicker.init(dateElement);
    const timePicker = document.querySelectorAll('.timepicker');
    const instancesTimePicker = M.Timepicker.init(timePicker, {
      twelveHour: false
    });
  }

  portalEnabled() {
    if (!this.globals.portalAccess && this.myForm.value.driver.portal_access_sw) {
      if (confirm(this.portalSettingsConfirmMsg)) {
        const settingsRequestObserver = {
          next: (response) => {
            this.globals.getCompanyData();
            this.globals.portalAccess = true;
          },
          error: (error) => {
            console.error(error);
          },
          complete: () => { },
        };
        const data = {
          companySettings: {
            portal_access_sw: true
          }
        };
        this.http.put('api/v1/companies/' + this.globals.companyId, data).pipe(take(1)).subscribe(settingsRequestObserver);
      } else {
        this.portalAccess = false;
        this.patchPortalValues();
      }
    }
  }

  public submitWizardForm(saveAndClone = false) {
    // this.isClickedOnce = true;
    const formValue = this.myForm.value;
    this.startDepotId = this.globals.depotId;
    this.endDepotId = this.globals.depotId;
    if (formValue.phoneNumber) {
      this.telephone = formValue.countryPrefix + formValue.phoneNumber;
    }
    const departureTimeElement = document.querySelector('#departure-time-driver-form');
    const departureTime = M.Timepicker.getInstance(departureTimeElement).time;
    if (moment(departureTime, 'HH:mm', true).isValid() && departureTime) {
      this.usualDepartureDatetimeUnformatted = departureTime;
      this.usualDepartureDatetime = moment(departureTime, 'HH:mm').format();
      const today = moment().format('YYYY-MM-DD');
      const departureDatetimeUnformatted = today + ' ' + departureTime;
      this.usualDepartureDatetime = moment(departureDatetimeUnformatted, 'YYYY-MM-DD HH:mm').format();
    }
    this.dailyWorkingHours = moment.duration(Number(formValue.driver.daily_working_hours_unformatted), 'hours').toISOString();
    this.patchForSubmit();
    const myObserver = {
      next: (response) => {
        this.resetForm();
        this.setDriversGridData();
        this.getAvailableVehicles();
      },
      error: (error) => {
        const errorsObject = error.error.errors;
        const errors = [];
        if (error.error.message) {
          errors.push(error.error.message);
        }
        // const keyify = (obj, prefix = '') =>
        //   Object.keys(obj).reduce((res, el) => {
        //     if (obj[el]['0'] && typeof obj[el]['0'] === 'string') {
        //       errors.push(obj[el]['0']);
        //     }
        //     if (obj[el]['0'] && typeof obj[el]['0'] === 'object') {
        //       if (obj[el]['0']['load']) {
        //         errors.push(obj[el]['0']['load']['0']);
        //       }
        //     }
        //     if (Array.isArray(obj[el])) {
        //       return res;
        //     } else if (typeof obj[el] === 'object' && obj[el] !== null) {
        //       return [...res, ...keyify(obj[el], prefix + el + '.')];
        //     } else {
        //       return [...res, prefix + el];
        //     }
        //   }, []);
        // const output = keyify(errorsObject);
        this.errors = errors;
        this.isClickedOnce = false;
      },
      complete: () => {
        if (!saveAndClone) { this.resetForm(); }
      },
    };
    const currentFormValue = this.myForm.value;
    if (currentFormValue.driver.usual_departure_datetime && currentFormValue.userProfile.name && currentFormValue.userProfile.telephone) {
      if (this.driverId) {
        this.http.put('api/v1/drivers/' + this.driverId, JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
      } else {
        this.http.post('api/v1/drivers', JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
      }
    }
  }

  setVehicleTypes() {
    const bicycle = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle0);
    const scooter = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle1);
    const car = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle2);
    const van = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle3);
    const largeVan = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle4);
    const truck = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle5);

    this.vehicleTypes = [
      {
        index: 0,
        image: bicycle,
        typeName: this.bicycleLabel
      },
      {
        index: 1,
        image: scooter,
        typeName: this.scooterLabel
      },
      {
        index: 2,
        image: car,
        typeName: this.carLabel
      },
      {
        index: 3,
        image: van,
        typeName: this.vanLabel
      },
      {
        index: 4,
        image: largeVan,
        typeName: this.largeVanLabel
      },
      {
        index: 5,
        image: truck,
        typeName: this.truckLabel
      }
    ];
  }

  getAvailableVehicles() {
    this.http.get('api/v1/vehicles?isGrouped=true&isAvailable=true').pipe(take(1)).subscribe(response => {
      this.vehicles = [];
      this.vehicles = response['items'];
    });
  }

  getTranslations() {
    this.listen.push(this.translate.get('ALERTS.PORTAL_SETTINGS_DISABLED').subscribe((res: string) => { this.portalSettingsConfirmMsg = res; }));
    this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.nameTitle = res; }));
    this.listen.push(this.translate.get('DRIVER.PHONE').subscribe((res: string) => { this.phoneTitle = res; }));
    this.listen.push(this.translate.get('DRIVER.VEHICLE_TYPE').subscribe((res: string) => { this.vehicleTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.DEPARTURE_TIME').subscribe((res: string) => { this.departureTitle = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.BICYCLE').subscribe((res: string) => { this.bicycleLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.SCOOTER').subscribe((res: string) => { this.scooterLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.CAR').subscribe((res: string) => { this.carLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.VAN').subscribe((res: string) => { this.vanLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.LARGE_VAN').subscribe((res: string) => { this.largeVanLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.TRUCK').subscribe((res: string) => { this.truckLabel = res; }));

    this.columnDefs = [
      {
        headerName: this.nameTitle,
        field: 'name',
        width: this.gridsService.widthCalculatorContainerId(30, 'drivers-grid-container')
      },
      {
        headerName: this.phoneTitle,
        field: 'phone',
        width: this.gridsService.widthCalculatorContainerId(20, 'drivers-grid-container')
      },
      {
        headerName: this.vehicleTitle,
        field: 'typeLabel',
        width: this.gridsService.widthCalculatorContainerId(20, 'drivers-grid-container')
      },
      {
        headerName: this.departureTitle,
        field: 'departure',
        width: this.gridsService.widthCalculatorContainerId(20, 'drivers-grid-container')
      },
      {
        headerName: '',
        field: 'delete',
        width: this.gridsService.widthCalculatorContainerId(10, 'drivers-grid-container'),
        cellRenderer: this.cancelRenderer
      },
    ];
    this.setDriversGridData();
    setTimeout(() => {
      this.setVehicleTypes();
    }, 300);
  }

  ngOnInit() {
    const self = this;

    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.usualDepartureTimezone = moment.tz.guess();
    const elems = document.querySelectorAll('select');
    const instances = M.FormSelect.init(elems);

    this.getAvailableVehicles();

    const depotsDataRefreshIntervalId = setInterval(depotsDataChecker, 200);
    function depotsDataChecker() {
      if (self.globals.depotsDataDone) {
        clearInterval(depotsDataRefreshIntervalId);
        self.startDepotId = self.globals.depotId;
        self.endDepotId = self.globals.depotId;
      }
    }

    this.countryPrefix = this.globals.defaultCountryCode;
    this.myForm.patchValue({
      'countryPrefix': this.countryPrefix,
    });
    self.portalAccess = self.globals.defaultPortalAccess ? true : false;
    self.patchPortalValues();

  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
