// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        lm-bar-chart{width: 100%}
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNzci1iYXJjaGFydC13aWRnZXQuY29tcG9uZW50LnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7UUFDUSxhQUFhLFdBQVciLCJmaWxlIjoic3NyLWJhcmNoYXJ0LXdpZGdldC5jb21wb25lbnQudHMiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgICAgICAgbG0tYmFyLWNoYXJ0e3dpZHRoOiAxMDAlfVxuICAgICJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/structure/widgets/ssr-barchart-widget.component.ts"],"names":[],"mappings":";QACQ,aAAa,WAAW;;AAEhC,wUAAwU","sourcesContent":["\n        lm-bar-chart{width: 100%}\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
